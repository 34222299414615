import { Form, Typography } from "antd";
import React from "react";
import ReflexesForm from "../../../../IpEMR/InspectionForms/Examination/SystemicExaminationForm/CNSForm/ReflexesForm";

const OpReflexesForm = ({ onValuesChange, formData }) => {
  return (
    <>
      <Form
        id="reflexes-form"
        onValuesChange={(cur, all) => onValuesChange("reflexes", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.reflexes}
      >
        <Typography>Reflexes</Typography>
        <div className="subsection-card">
          <ReflexesForm />
        </div>
      </Form>
    </>
  );
};

export default OpReflexesForm;
