import React, { useEffect, useState } from "react";
import { Button } from "antd";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { PlusOutlined } from "@ant-design/icons";
import OnboardingForm from "./OnboardingForm";
import OnboardingTable from "./OnboardingTable";
import { getPartnerListApi } from "../../../services/partner.services";
const PartnerOnboarding = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    setLoading(true);
    getPartnerListApi()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);
  return (
    <>
      {data && data?.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => setOpen(true)}
            >
              <PlusOutlined /> Partners
            </Button>
          </div>
          <OnboardingTable data={data} refresh={onRefresh} loading={loading} />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80%",
          }}
        >
          <Button
            type="primary"
            className={btnStyles.mdBtn}
            onClick={() => setOpen(true)}
          >
            <PlusOutlined /> Partners
          </Button>
        </div>
      )}
      {open && (
        <OnboardingForm open={open} setOpen={setOpen} refresh={onRefresh} />
      )}
    </>
  );
};

export default PartnerOnboarding;
