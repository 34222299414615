import React from "react";
import CommonTable from "../../CommonTable";
import dayjs from "dayjs";

const CannulationDecannulationTable = ({ data }) => {
  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      render: (val) => val + 1,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Time",
      dataIndex: "process_time",
      render: (time) => dayjs(time)?.format("DD, MMM hh:mm A"),
    },
    // {
    //   title: "Captured Content",
    //   dataIndex: "",
    // },
  ];
  return (
    <>
      <CommonTable
        data={
          data?.respiratory_care_tracheostomy_care_cannulation_decannulation
            ?.nurse_data
        }
        columns={columns}
        //   titleData={() => {}}
      />
    </>
  );
};

export default CannulationDecannulationTable;
