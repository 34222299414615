import React from "react";
import CommonTable from "../../CommonTable";
import dayjs from "dayjs";

const SuctionView = ({ data }) => {
  const columns = [
    {
      title: "Time",
      dataIndex: "process_time",
      render: (time) => dayjs(time)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "Catheter Size",
      dataIndex: "catheter_size",
    },
    {
      title: "Suction",
      dataIndex: "suction",
    },
    {
      title: "Pressure (cmh20)",
      dataIndex: "pressure",
    },
    {
      title: "Culture",
      dataIndex: "culture",
    },
    {
      title: "Secretions",
      dataIndex: "secretions",
    },
  ];
  return (
    <>
      <CommonTable
        data={data?.respiratory_care_tracheostomy_care_suction?.nurse_data}
        columns={columns}
        //   titleData={() => {}}
      />
    </>
  );
};

export default SuctionView;
