export const shoulderOptions = [
  { label: "Yergason's test", value: "yergasons_test" },
  { label: "Speed's test (Biceps straight arm)", value: "speeds_test" },
  { label: "Near impingement test", value: "near_impingement_test" },
  { label: "Supraspinatus (empty can) test", value: "supraspinatus_test" },
  { label: "Drop arm test", value: "drop_arm_test" },
  {
    label: "Posterior internal impingement test",
    value: "posterior_internal_impingement_test",
  },
  { label: "Clunk test", value: "clunk_test" },
  {
    label: "Anterior apprehension sign",
    value: "anterior_apprehension_sign",
  },
  {
    label: "Posterior apprehension sign",
    value: "posterior_apprehension_sign",
  },
  {
    label: "Acromioclavicular shear test",
    value: "acromioclavicular_shear_test",
  },
  { label: "Adson's test", value: "adsons_test" },
  {
    label: "Costoclavicular syndrome (military brace) test",
    value: "costoclavicular_syndrome_test",
  },
  { label: "Wright (hyperabduction) test", value: "wright_test" },
  { label: "ROOS elevated arm test", value: "roos_elevated_arm_test" },
  { label: "Upper limb tension tests", value: "upper_limb_tension_tests" },
];

export const elbowOptions = [
  {
    label: "Ligament instability tests (medial and lateral stability)",
    value: "ligament_instability_tests",
  },
  {
    label: "Lateral epicondylitis tests ('tennis elbow stability')",
    value: "lateral_epicondylitis_tests",
  },
  {
    label: "Medial epicondylitis ('golfer's elbow') test",
    value: "medial_epicondylitis_test",
  },
  { label: "Tinel's sign", value: "tinels_sign" },
  {
    label: "Pronator teres syndrome test",
    value: "pronator_teres_syndrome_test",
  },
];

export const kneeOptions = [
  {
    label:
      "Collateral ligament instability tests (medial and lateral stability)",
    value: "collateral_instability_test",
  },
  { label: "Lachman stress test", value: "lachman_test" },
  {
    label: "Pivot shift (anterolateral rotary instability)",
    value: "pivot_shift_test",
  },
  { label: "Posterior sag test", value: "posterior_sag_test" },
  { label: "Posterior drawer test", value: "posterior_sag_test" },
  { label: "Reverse Lachman", value: "reverse_lachman" },

  { label: "McMurray test", value: "mcmurray_test" },
  { label: "Apley test", value: "apley_test" },
  { label: "Hughston's plica test", value: "hughstons_plica_test" },
  {
    label: "Patellar apprehension test",
    value: "patellar_apprehension_test",
  },
  { label: "Clarke's sign", value: "clarkes_sign" },
  {
    label: "Ballotable Patella (Patellar tap test)",
    value: "ballotable_patella",
  },
  { label: "Fluctuation test", value: "fluctuation_test" },
  { label: "Q-angle measurement", value: "q_angle_measurement" },
  { label: "Noble Compression test", value: "noble_compression_test" },
  { label: "Tinel's sign", value: "knee_tinels_sign" },
];

export const wristAndHandOptions = [
  { label: "Finkelstein test", value: "finkelstein_test" },
  { label: "Bunnel-Littler test", value: "bunnel_littler_test" },
  { label: "Tight retinacular test", value: "tight_retinacular_test" },
  {
    label: "Ligamentous instability tests (medial and lateral stability)",
    value: "ligamentous_instability_tests",
  },
  { label: "Froment's sign", value: "froments_sign" },
  { label: "Tinel's sign", value: "wrist_tinels_sign" },
  { label: "Phalen's test", value: "phalens_test" },
  {
    label: "Two point discrimination test",
    value: "two_point_discrimination_test",
  },
  { label: "Allen test", value: "allen_test" },
];

export const hipOptions = [
  { label: "Patrick (FABER) test", value: "patrick_faber_test" },
  { label: "Grind (Scouring) test", value: "grind_scouring_test" },
  { label: "Trendelenburg's sign", value: "trendelenburg_sign" },
  { label: "Thomas test", value: "thomas_test" },
  { label: "Ober's test", value: "obers_test" },
  { label: "Ely test", value: "ely_test" },
  { label: "90-90 Hamstring test", value: "ninety_ninety_hamstring_test" },
  { label: "Piriformis test", value: "piriformis_test" },
  { label: "Leg length test", value: "leg_length_test" },
  { label: "Craig's test", value: "craigs_test" },
];

export const ankleOptions = [
  {
    label: "Neutral subtalar positioning",
    value: "neutral_subtalar_positioning",
  },
  { label: "Anterior drawer test", value: "anterior_drawer_test" },
  { label: "Talar tilt", value: "talar_tilt" },
  { label: "Thompson test", value: "thompson_test" },
  { label: "Tinel's sign", value: "ankle_tinels_sign" },
  { label: "Morton's test", value: "mortons_test" },
];

export const cervicalOptions = [
  { label: "Vertebral artery test", value: "vertebral_artillery_test" },
  { label: "Hautant's test", value: "hautants_test" },
  {
    label: "Transverse ligament stress test",
    value: "transverse_ligament_stress_test",
  },
  { label: "Anterior shear test", value: "anterior_shear_test" },
  {
    label: "Foraminal compression (Spurling's) test",
    value: "foraminal_compression_test",
  },
  {
    label: "Maximum cervical compression test",
    value: "maximum_cervical_compression_test",
  },
  { label: "Distraction test", value: "distraction_test" },
  { label: "Shoulder abduction test", value: "shoulder_abduction_test" },
  { label: "Lhermitte's sign", value: "lhermittes_sign" },
  { label: "Romberg's test", value: "rombergs_test" },
];

export const thoracicOptions = [
  { label: "Rib springing", value: "rib_springing" },
  { label: "Thoracic springing", value: "thoracic_springing" },
  { label: "Slump test", value: "slump_test" },
];

export const lumbarOptions = [
  { label: "Slump test", value: "lumbar_slump_test" },
  { label: "Lasegue's (straight leg raising) test", value: "lasegue_test" },
  {
    label: "Femoral nerve traction test",
    value: "femoral_nerve_traction_test",
  },
  { label: "Valsava maneuver", value: "valsava_maneuver" },
  { label: "Babinski test", value: "babinski_test" },
  { label: "Quadrant test", value: "quadrant_test" },
  { label: "Stork standing test", value: "stork_standing_test" },
  {
    label: "McKenzie's side glide test",
    value: "mckenzie_side_glide_test",
  },
  { label: "Bicycle (van Gelderen) test", value: "bicycle_test" },
];

export const sacroiliacOptions = [
  { label: "Gillet's test", value: "gillets_test" },
  {
    label: "Ipsilateral anterior rotation test",
    value: "ipsilateral_anterior_rotation_test",
  },
  { label: "Gaenslen's test", value: "gaenslens_test" },
  {
    label: "Long sitting (supine to sit) test",
    value: "long_sitting_test",
  },
  { label: "Goldthwait's test", value: "goldthwaits_test" },
];

export const tnjOptions = [
  { label: "TMJ compression", value: "tmj_compression" },
];

export const positiveNegativeOptions = [
  { label: "Positive", value: "positive" },
  { label: "Negative", value: "negative" },
];
export const specialTestCategories = [
  {
    key: "1",
    label: "Shoulder Special Tests",
    value: "shoulder_special_tests",
  },
  {
    key: "2",
    label: "Elbow Special Tests",
    value: "elbow_special_tests",
  },
  {
    key: "3",
    label: "Wrist And Hand Special Tests",
    value: "wrist_hand_special_tests",
  },
  {
    key: "4",
    label: "Hip Special Tests",
    value: "hip_special_tests",
  },
  {
    key: "5",
    label: "Knee Special Tests",
    value: "knee_special_tests",
  },
  {
    key: "6",
    label: "Ankle and Foot Special Tests",
    value: "ankle_foot_special_tests",
  },
  {
    key: "7",
    label: "Cervical Special Tests",
    value: "cervical_special_tests",
  },
  {
    key: "8",
    label: "Thoracic Special Tests",
    value: "thoracic_special_tests",
  },
  {
    key: "9",
    label: "Lumbar Special Tests",
    value: "lumbar_special_tests",
  },
  {
    key: "10",
    label: "Sacroiliac Joint Special Tests",
    value: "sacroiliac_joint_special_tests",
  },
  {
    key: "11",
    label: "TMJ Special Tests",
    value: "tmj_special_tests",
  },
];

export const orthoTherapyOptions = [
  { label: "IFT", value: "ift", durations: ["7 days", "10 days", "15 days"] },
  {
    label: "Ultrasound",
    value: "ultrasound",
    durations: ["7 days", "10 days", "15 days"],
  },
  { label: "SWD", value: "swd", durations: ["7 days", "10 days", "15 days"] },
  {
    label: "LASER",
    value: "laser",
    durations: ["7 days", "10 days", "15 days"],
  },
  {
    label: "Trigger release",
    value: "trigger_release",
    durations: ["7 days", "10 days", "15 days"],
  },
  {
    label: "Conditioning training",
    value: "conditioning_training",
    durations: ["7 days", "10 days", "15 days", "30 days"],
  },
  {
    label: "Dry needling",
    value: "dry_needling",
    durations: ["7 days", "10 days", "15 days"],
  },
  {
    label: "Post fracture Rehab",
    value: "post_fracture_rehab",
    durations: ["4 weeks", "6 weeks", "8 weeks", "12 weeks"],
  },
  {
    label: "Post Joint replacement rehab",
    value: "post_joint_replacement_rehab",
    durations: ["4 weeks", "6 weeks", "8 weeks", "12 weeks"],
  },
  {
    label: "Joint /Spine Mobilization",
    value: "joint_spine_mobilization",
    durations: ["7 days", "10 days", "15 days"],
  },
  {
    label: "Stretch therapy",
    value: "stretch_therapy",
    durations: ["7 days", "10 days", "15 days"],
  },
  {
    label: "Muscle stimulation",
    value: "muscle_stimulation",
    durations: ["7 days", "10 days", "15 days"],
  },
  { label: "Tens", value: "tens", durations: ["7 days", "10 days", "15 days"] },
  {
    label: "Splinting/Bracing",
    value: "splinting_bracing",
    durations: ["2 weeks", "4 weeks", "6 weeks", "8 weeks", "12 weeks"],
  },
  {
    label: "MET",
    value: "met",
    durations: ["4 weeks", "6 weeks", "8 weeks", "12 weeks"],
  },
  {
    label: "Vaccum therapy",
    value: "vaccum_therapy",
    durations: ["7 days", "10 days", "15 days"],
  },
];
