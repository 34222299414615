import { Form, Typography } from "antd";
import React from "react";
import MMTScaleItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MMTScaleComponents/MMTScaleItems";

const MMTForm = ({ onValuesChange, formData }) => {
  return (
    <div className="op-forms">
      <Form
        id="mmt-form"
        onValuesChange={(cur, all) => onValuesChange("mmt", all)}
        className="ip-forms"
        layout="vertical"
        initialValues={formData?.mmt}
      >
        <Typography>Manual Muscle Testing</Typography>

        <MMTScaleItems scaleType={"mmt"} />
      </Form>
    </div>
  );
};

export default MMTForm;
