import React from "react";
import {
  coordinationTests,
  intactImpairedOptions,
} from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const CoordinationItems = ({ form }) => {
  return (
    <>
      {coordinationTests.map((test) => (
        <DeselectableRadioGroup
          label={test.label}
          name={test.name}
          options={intactImpairedOptions}
          form={form}
        />
      ))}
    </>
  );
};

export default CoordinationItems;
