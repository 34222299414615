import React, { useEffect, useState } from "react";
import CaloriesAndProtien from "./CaloriesAndProtien";
import { Divider } from "antd";
import TypeOfDiet from "./TypeOfDiet";
import OtherSpecifications from "./OtherSpecifications";
import PatientFamilyEducation from "./PatientFamilyEducation";
import { NutritionistInitialContext } from "../HistoryForm/NutritionistInitialAssessmentProvider";

const NutritionistDietAdvice = ({ form, formData }) => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    form.setFieldsValue(formData?.diet_advice);
    setTimeout(() => setRefresh((prev) => !prev), 1000);
  }, [formData]);
  return (
    <>
      <CaloriesAndProtien />
      <Divider />
      <TypeOfDiet context={NutritionistInitialContext} form={form} />
      <Divider />
      <OtherSpecifications context={NutritionistInitialContext} />
      <Divider />
      <PatientFamilyEducation form={form} />
    </>
  );
};

export default NutritionistDietAdvice;
