import React, { useState } from "react";
import PainSelection from "../../PhysioScales/VAScaleComponents/PainSelection";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import { noYesOptions } from "../../../../Options/commonOptions";
import { Form, Input } from "antd";

const PainScale = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const showPain = form.getFieldValue("pain_scale");

  return (
    <>
      {/* <Form.Item label="Pain Scale" name="pain_scale">
        <Radio.Group
          options={painOptions}
          onChange={(e) => setPainSelection(e.target.value)}
        />
      </Form.Item> */}
      <DeselectableRadioGroup
        label={"Pain Scale"}
        name={"pain_scale"}
        options={noYesOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {showPain === "yes" && <PainSelection form={form} />}
      <Form.Item name={"pain_scale_comments"}>
        <Input placeholder="Comments" width={"60%"} />
      </Form.Item>
    </>
  );
};

export default PainScale;
