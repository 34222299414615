import { Form, Typography } from "antd";
import React from "react";
import MMTScaleItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MMTScaleComponents/MMTScaleItems";

const VCGForm = ({ onValuesChange, formData }) => {
  return (
    <div className="op-forms">
      <Form
        id="vcg-form"
        onValuesChange={(cur, all) => onValuesChange("vcg", all)}
        className="ip-forms"
        layout="vertical"
        initialValues={formData?.vcg}
      >
        <Typography>Voluntary Control Grading</Typography>

        <MMTScaleItems scaleType={"vcg"} />
      </Form>
    </div>
  );
};

export default VCGForm;
