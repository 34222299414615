import { Checkbox, Form, Input } from "antd";
import React from "react";
import { cardioCongenitalOptions } from "../OpPhysioOptions";

const CongenitalDisorders = () => {
  return (
    <>
      <Form.Item label="Congenital Disorders" name={"congenital_disorders"}>
        <Checkbox.Group options={cardioCongenitalOptions} />
      </Form.Item>
      <Form.Item name={"congenital_disorders_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item>
    </>
  );
};

export default CongenitalDisorders;
