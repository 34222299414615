import React from "react";
import { Form, Input } from "antd";
import { sportsTherapyOptions } from "../../../../IpEMR/Options/physioOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const SportsTherapy = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  return (
    <Form
      id="sports-form"
      form={form}
      onValuesChange={(cur, all) => onValuesChange("therapy", all)}
      layout="vertical"
      className="ip-forms"
      initialValues={formData?.therapy}
    >
      {sportsTherapyOptions?.map((subOption) => (
        <DeselectableRadioGroup
          name={subOption?.name_key}
          label={subOption?.label}
          options={subOption?.options}
          form={form}
        />
      ))}
      <Form.Item name={"postural_correction"}>
        <Input.TextArea
          rows={2}
          placeholder="Postural Correction"
          style={{ width: "60%" }}
        />
      </Form.Item>
      <Form.Item name={"therapy_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item>
    </Form>
  );
};

export default SportsTherapy;
