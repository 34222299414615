import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPatientDetailsApi } from "../../services/patient.services";
import { UserContext } from "../../context/UserContext";
import PatientMenu from "./PatientMenu";
import DoctorIP from "./DoctorIP";
import NurseIP from "./NurseIP";
import SpeechAndSwallowTherapistIP from "./SpeechAndSwallowTherapistIP";
import RespiratoryTherapistIP from "./RespiratoryTherapistIP";
import NutritionistIP from "./NutritionistIP";
import PhysiotherapistIP from "./PhysiotherapistIP";
import InvestigationsIp from "./InvestigationsIp/InvestigationsIP";
import useQuery from "../../hooks/useQuery";
import ProgressMonitoring from "./ProgressMonitoring/ProgressMonitoring";
import DietChart from "./Diet/DietChart";
import PatientGallery from "./PatientGallery/PatientGallery";
import PsychologistIP from "./PsychologistIP";
import ReceptionistIp from "./ReceptionistIp/ReceptionistIp";
import NutritionistDiet from "./Diet/NutritionistDiet/NutritionistDiet";
import { getCookie } from "../../helpers/localStorage";
import { Role } from "../../constants/defaultKeys";
import MonitoringCharts from "./MonitoringCharts/MonitoringCharts";
import DischargeSummary from "./Forms/DischargeSummary/DischargeSummary";
import EmrDownload from "./Forms/EmrDownload/EmrDownload";
import ReferralForm from "./FormsIp/ReferralForm";

const PatientsIP = () => {
  const navigate = useNavigate();
  const params = useQuery();
  const { userDetails } = useContext(UserContext);
  const userRole = userDetails?.currRole || getCookie(Role);
  const [current, setCurrent] = useState("ia");
  const [patientData, setPatientData] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const refreshPage = () => setRefresh((prev) => !prev);
  const url = new URL(window?.location?.href);

  const role = userRole?.toLowerCase()?.replaceAll("_", "-");

  const onClick = (e) => {
    setCurrent(e.key);
    // url?.searchParams?.set("patient",params?.patient)
    url?.searchParams?.set("type", e.key);
    // navigate(`/${role}/${query?.tab}?patient=${params?.patient}&type=${e.key}`);
    navigate(`${document?.location.pathname}?${url.searchParams.toString()}`, {
      replace: true,
      // This prevents adding a new entry in the history stack
    });
  };

  useEffect(() => {
    getPatientDetailsApi(params?.patient)
      .then((res) => setPatientData(res?.data?.data))
      .catch((err) => console.log(err));
  }, [refresh, params?.patient, params?.type]);

  const renderComponent = () => {
    switch (userRole) {
      case "Doctor":
        return (
          <DoctorIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Nurse":
        return (
          <NurseIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Speech_Swallow_Therapist":
        return (
          <SpeechAndSwallowTherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Respiratory_Therapist":
        return (
          <RespiratoryTherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Nutritionist":
        return (
          <NutritionistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Physiotherapist":
        return (
          <PhysiotherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Receptionist":
        return (
          <ReceptionistIp
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "Psychologist":
        return (
          <PsychologistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      default:
        return null;
    }
  };

  function subComponent() {
    switch (params.type) {
      case "investigations":
        return <InvestigationsIp patientData={patientData?.admission} />;
      case "progress_monitoring":
        return <ProgressMonitoring patient={params?.patient} />;
      case "diet":
        return role === "nurse" ? <DietChart /> : <NutritionistDiet />;
      case "monitoring-charts":
        return <MonitoringCharts patient={params?.patient} />;
      case "gallery":
        return <PatientGallery />;
      case "discharge-summary":
        return <DischargeSummary />;
      case "referral-form":
        return <ReferralForm patient={params?.patient} />;
      case "emr-download":
        return <EmrDownload />;
      case "doctor":
        return (
          <DoctorIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "nurse":
        return (
          <NurseIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "speech_swallow_therapist":
        return (
          <SpeechAndSwallowTherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "respiratory_therapist":
        return (
          <RespiratoryTherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "nutritionist":
        return (
          <NutritionistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      case "physiotherapist":
        return (
          <PhysiotherapistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );

      case "psychologist":
        return (
          <PsychologistIP
            patientData={patientData}
            params={params}
            refreshPage={refreshPage}
          />
        );
      default:
        break;
    }
  }

  return (
    <Fragment>
      <PatientMenu
        current={current}
        onClick={onClick}
        userRole={userRole}
        params={params}
        navigate={navigate}
        patientData={patientData}
      />
      <div style={{ padding: "0px 24px 2px" }}>
        {renderComponent()}
        {subComponent()}
      </div>
    </Fragment>
  );
};

export default PatientsIP;
