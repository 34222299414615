import React from "react";
import { Table, Space, Tag, Typography, Collapse } from "antd";
import { capitalizeFirstLetter } from "../../../../helpers/utility";
import { tracheostomyOptions } from "../../../IpEMR/Options/careItemsOptions";
import tagStyles from "../../../../styles/Custom/Tag.module.css";
import CommonTable from "../CommonTable";
import dayjs from "dayjs";
import DailyReviewTable from "./TracheostomyTables/DailyReviewTable";
import InnerCannulaView from "./TracheostomyTables/InnerCannulaView";
import SubgloticSuctionView from "./TracheostomyTables/SubgloticSuctionView";
import SuctionView from "./TracheostomyTables/SuctionView";
import CannulationDecannulationTable from "./TracheostomyTables/CannulationDecannulationTable";
import TrailOfDecannulation from "./TracheostomyTables/TrailOfDecannulation";

const Tracheostomy = ({ data }) => {
  console.log(data);
  const columns = [
    {
      title: "Catheter Size",
      dataIndex: "catheter_size",
    },
    {
      title: "Suction",
      dataIndex: "suction",
    },
    {
      title: "Suction Pressure (cmh20)",
      dataIndex: "suction_pressure",
    },
    {
      title: "Secretions",
      dataIndex: "secretions",
    },
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];

  const collapseItems = [
    {
      key: "1",
      label: "Tracheostomy Daily Review View",
      children: <DailyReviewTable data={data} />,
    },
    {
      key: "2",
      label: "Tracheostomy Inner Cannula View",
      children: <InnerCannulaView data={data} />,
    },
    {
      key: "3",
      label: "Tracheostomy Subglottic Suction View",
      children: <SubgloticSuctionView data={data} />,
    },
    {
      key: "4",
      label: "Tracheostomy Suction View",
      children: <SuctionView data={data} />,
    },
    {
      key: "5",
      label: "Cannulation/Decannulation View",
      children: <CannulationDecannulationTable data={data} />,
    },
    {
      key: "6",
      label: "Trail of Decannulation",
      children: <TrailOfDecannulation data={data} />,
    },
  ];
  const cItems = (iteration) => {
    return [
      {
        key: "1",
        label: "Tracheostomy Daily Review View",
        children: <DailyReviewTable data={data?.[iteration]} />,
      },
      {
        key: "2",
        label: "Tracheostomy Inner Cannula View",
        children: <InnerCannulaView data={data?.[iteration]} />,
      },
      {
        key: "3",
        label: "Tracheostomy Subglottic Suction View",
        children: <SubgloticSuctionView data={data?.[iteration]} />,
      },
      {
        key: "4",
        label: "Tracheostomy Suction View",
        children: <SuctionView data={data?.[iteration]} />,
      },
      {
        key: "5",
        label: "Cannulation/Decannulation View",
        children: <CannulationDecannulationTable data={data?.[iteration]} />,
      },
      {
        key: "6",
        label: "Trail of Decannulation",
        children: <TrailOfDecannulation data={data?.[iteration]} />,
      },
    ];
  };
  return (
    <div>
      {/* {data?.map((item) => (
        <CommonTable
          data={item?.actions.flat()}
          columns={columns}
          titleData={() => (
            <>
              <Space>
                <Typography
                  style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    fontWeight: "lighter",
                    fontSize: "10px",
                  }}
                >
                  Type
                </Typography>{" "}
                <Tag color="blue" className={tagStyles.smRndTag}>
                  Advised By {capitalizeFirstLetter(item?.advised_by)}
                </Tag>
              </Space>
              <Typography
                style={{
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  marginTop: "4px",
                  fontWeight: "lighter",
                  fontSize: "14px",
                }}
              >
                {tracheostomyOptions?.find(
                  (option) => option.value === item?.drug_name
                )?.label || "-"}
              </Typography>
            </>
          )}
        />
      ))} */}

      {data?.map((item, index) => (
        <>
          <Space size={200}>
            <Typography
              style={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontWeight: "lighter",
                fontSize: "10px",
              }}
            >
              Type
            </Typography>
            <Tag color="blue" className={tagStyles.smRndTag}>
              Advised By {capitalizeFirstLetter(item?.advised_by)}
            </Tag>
          </Space>
          <Typography
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              marginTop: "4px",
              fontWeight: "lighter",
              fontSize: "14px",
            }}
          >
            {tracheostomyOptions?.find(
              (option) => option.value === item?.drug_name
            )?.label || "-"}
          </Typography>
          <Collapse
            expandIconPosition="right"
            className="custom-collapse"
            style={{ marginTop: "10px" }}
            items={cItems(index)}
            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
          />
        </>
      ))}
    </div>
  );
};

export default Tracheostomy;
