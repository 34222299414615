import { Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import BarthelIndexItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/BarthelIndexComponents/BarthelIndexItems";
import BarthelIndexPdf from "../../../../../assets/scalesInfo/BarthelIndexInfo.pdf";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";
const scaleInfo = {
  name: "Barthel Index",
  type: "pdf",
  url: BarthelIndexPdf,
};

const BarthelIndexForm = ({ onValuesChange, formData }) => {
  const [refresh, setRefresh] = useState(false);
  const [form] = Form.useForm();
  const [openInfo, setOpenInfo] = useState(false);
  const formValues = form.getFieldsValue();
  console.log(formValues);
  const score =
    Object?.values(formValues)?.reduce((acc, cur) => acc + (cur || 0), 0) ||
    formData?.barthel?.score;
  const interpretation =
    score < 20
      ? "Totally Dependent"
      : score < 40
      ? "Very Dependent"
      : score < 60
      ? "Partially Dependent"
      : score < 80
      ? "Minimally Dependent"
      : "Independent";

  return (
    <div className="op-forms">
      <Form
        id="barthel-index-form"
        onValuesChange={(cur, all) =>
          onValuesChange("barthel", {
            ...all,
            score: Object?.values(all)?.reduce(
              (acc, cur) => acc + (cur || 0),
              0
            ),
            interpretation: interpretation,
          })
        }
        form={form}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.barthel}
      >
        <Typography>
          <Row>
            <Col span={8}>Barthel Index</Col>
            <Col span={16}>
              {score}/100 : {interpretation}
            </Col>
          </Row>
        </Typography>

        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
          style={{ marginBottom: 15 }}
        >
          Info
        </Button>
        <BarthelIndexItems
          states={{}}
          handleChange={() => setRefresh((prev) => !prev)}
        />
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default BarthelIndexForm;
