import React, { useState } from "react";
import PeakFlowMeterItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/PeakFlowMeterComponents/PeakFlowMeterItems";
import { Form } from "antd";

const PeakFlowMeterForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [predict, setPredict] = useState(
    formData?.peak_flow_meter_test?.predict_pefr || null
  );
  const predictPefr = () => {
    const gender = form.getFieldValue("gender");
    const age = form.getFieldValue("age");
    const height = form.getFieldValue("height");
    if (gender && age && height) {
      switch (gender) {
        case "male adult":
          return Math.round(((height / 100) * 5.48 + 1.58 - age * 0.041) * 60);
        case "female adult":
          return Math.round(((height / 100) * 3.72 + 2.24 - age * 0.03) * 60);
        case "child":
          return Math.round((height - 100) * 5 + 100);
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <Form
        id="peak-flow-meter-form"
        onValuesChange={(cur, all) => {
          const result = predictPefr();
          setPredict(result);
          onValuesChange("peak_flow_meter_test", {
            ...all,
            predict_pefr: result,
          });
        }}
        layout="vertical"
        form={form}
        className="ip-forms"
        initialValues={formData?.peak_flow_meter_test}
      >
        <PeakFlowMeterItems form={form} predict={predict} />
      </Form>
    </>
  );
};

export default PeakFlowMeterForm;
