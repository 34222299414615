import { Form } from "antd";
import React from "react";
import CoordinationItems from "../../../../IpEMR/InspectionForms/Examination/SystemicExaminationForm/CNSForm/CoordinationItems";

const CoordinationForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        id="coordination"
        form={form}
        onValuesChange={(cur, all) => onValuesChange("coordination", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.coordination}
      >
        <CoordinationItems form={form} />
      </Form>
    </>
  );
};

export default CoordinationForm;
