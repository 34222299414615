import { Descriptions } from "antd";
import React from "react";
import { checkEmpty } from "../../../../../helpers/utility";
import {
  activePassiveOptions,
  cardioCongenitalOptions,
  cardioPastMedicalOptions,
  opExaminationOptions,
  sportsChiefComplaintOptions,
  sportsHistoryOptions,
} from "../OpPhysioOptions";
import {
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getLabelTagYesNO,
  getTherapyLabelTags,
} from "../../../../IpEMR/InspectionSummaries/constructTextFromValues";
import {
  alcoholOptions,
  smokingOptions,
} from "../../../../IpEMR/Options/historyOptions";
import {
  mmtScaleOptions,
  sportsDiagnosisOptions,
  sportsTherapyOptions,
} from "../../../../IpEMR/Options/physioOptions";
import { barthelIndexOptions } from "../../../../IpEMR/Options/functionalAsessmentOptions";
import {
  ankleOptions,
  cervicalOptions,
  elbowOptions,
  hipOptions,
  kneeOptions,
  lumbarOptions,
  sacroiliacOptions,
  shoulderOptions,
  thoracicOptions,
  tnjOptions,
  wristAndHandOptions,
} from "../Orthoform/orthoOptions";

const SportsSummary = ({ formData }) => {
  console.log(formData);
  const constructSportsSummaryItems = () => {
    const getRomSummary = (list, data) => {
      const enteredOptions = list.reduce((acc, item) => {
        const filtered = Object.entries(data).filter(([key, value]) =>
          key.endsWith(item)
        );
        return [...acc, ...filtered];
      }, []);

      return enteredOptions?.length === 0
        ? null
        : enteredOptions?.map(([key, value]) =>
            getLabelTagInput(
              key
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" "),
              value
            )
          );
    };
    const sportsSummaryItems = [];
    const pushSummaryItem = (key, label, children) => {
      sportsSummaryItems.push({ key, label, children });
    };

    if (checkEmpty(formData?.chief_complaint)) {
      pushSummaryItem(
        "1",
        "Chief Complaints",
        <>
          {getLabelTagsArray(
            sportsChiefComplaintOptions,
            formData?.chief_complaint?.chief_complaints
          )}
          {getLabelTagInput("Other Details", formData?.comments)}
        </>
      );
    }

    if (checkEmpty(formData?.history)) {
      pushSummaryItem(
        "2",
        "History",
        <>
          {getLabelTagInput(
            "History",
            <>
              {getLabelTagsArray(
                sportsHistoryOptions,
                formData?.history?.history
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.history_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Past Medical History",
            <>
              {getLabelTagsArray(
                cardioPastMedicalOptions,
                formData?.history?.past_medical_history
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.past_medical_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Congenital Disorders",
            <>
              {getLabelTagsArray(
                cardioCongenitalOptions,
                formData?.history?.congenital_disorders
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.congenital_disorders_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Past Surgical History",
            <>
              {getLabelTagYesNO(formData?.history?.past_surgical_history)}
              {getLabelTagInput(
                "Comments",
                formData?.history?.past_surgical_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Smoking",
            getLabelTag(smokingOptions, formData?.history?.smoking)
          )}
          {getLabelTagInput(
            "Alcohol",
            getLabelTag(alcoholOptions, formData?.history?.alcohol)
          )}
          {getLabelTagInput(
            "Life Style",
            getLabelTag(activePassiveOptions, formData?.history?.life_style)
          )}
          {getLabelTagInput("Comments", formData?.history?.habits_comments)}
        </>
      );
    }

    if (checkEmpty(formData?.examination)) {
      pushSummaryItem(
        "3",
        "Examination",
        <>
          {getLabelTagsWithDescription(
            opExaminationOptions,
            formData?.examination
          )}
          {getLabelTagInput("Pain level", formData?.examination?.pain)}
          {getLabelTagInput(
            "Interpretation",
            formData?.examination?.pain_interpretation
          )}
          {getLabelTagInput(
            "Comments",
            formData?.examination?.examination_comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.mmt)) {
      pushSummaryItem(
        "4",
        "MMT",
        <>
          {getLabelTagInput(
            "Left Upper Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.upper_left_limb)
          )}
          {getLabelTagInput(
            "Right Upper Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.upper_right_limb)
          )}
          {getLabelTagInput(
            "Left Lower Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.lower_left_limb)
          )}
          {getLabelTagInput(
            "Right Lower Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.lower_right_limb)
          )}
        </>
      );
    }

    if (checkEmpty(formData?.finger_grip_test)) {
      pushSummaryItem(
        "5",
        "Finger Grip Test",
        <>
          {getLabelTagInput("Age", formData?.finger_grip_test?.age)}
          {getLabelTagInput(
            "Left Hand Grip Strength",
            formData?.finger_grip_test?.left_hand
          )}
          {getLabelTagInput(
            "Left Hand Grip Interpretation",
            formData?.finger_grip_test?.left_hand_interpretation
          )}

          {getLabelTagInput(
            "Right Hand Grip Strength",
            formData?.finger_grip_test?.right_hand
          )}
          {getLabelTagInput(
            "Right Hand Grip Interpretation",
            formData?.finger_grip_test?.right_hand_interpretation
          )}
        </>
      );
    }

    if (checkEmpty(formData?.rom)) {
      pushSummaryItem(
        "6",
        "ROM",
        <>
          {formData?.rom?.rom?.shoulder_joint &&
            getLabelTagInput(
              "Shoulder Joint",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "abduction",
                  "adduction",
                  "medial_rotation",
                  "lateral_rotation",
                ],
                formData?.rom?.rom?.shoulder_joint
              )
            )}
          {formData?.rom?.rom?.elbow_joint &&
            getLabelTagInput(
              "Elbow Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.elbow_joint
              )
            )}
          {formData?.rom?.rom?.forearm_joint &&
            getLabelTagInput(
              "Forearm Joint",
              getRomSummary(
                ["supination", "pronation"],
                formData?.rom?.rom?.forearm_joint
              )
            )}
          {formData?.rom?.rom?.wrist_joint &&
            getLabelTagInput(
              "Wrist Joint",
              getRomSummary(
                ["flexion", "extension", "radial_deviation", "ulnar_deviation"],
                formData?.rom?.rom?.wrist_joint
              )
            )}
          {formData?.rom?.rom?.thumb_joint &&
            getLabelTagInput(
              "Thumb Joint",
              getRomSummary(
                ["cmc_flexion", "cmc_extension", "cmc_abduction", "ip_flexion"],
                formData?.rom?.rom?.thumb_joint
              )
            )}
          {formData?.rom?.rom?.metacarpel_joint &&
            getLabelTagInput(
              "Meta Carpel",
              getRomSummary(
                ["flexion", "hyperextension", "abduction"],
                formData?.rom?.rom?.metacarpel_joint
              )
            )}
          {formData?.rom?.rom?.proximal_joint &&
            getLabelTagInput(
              "Proximal Interphalangeal Joint",
              getRomSummary(["flexion"], formData?.rom?.rom?.proximal_joint)
            )}
          {formData?.rom?.rom?.distal_joint &&
            getLabelTagInput(
              "Disital Interphalangeal Joint",
              getRomSummary(
                ["flexion", "hyperextension"],
                formData?.rom?.rom?.distal_joint
              )
            )}
          {formData?.rom?.rom?.hip_joint &&
            getLabelTagInput(
              "Hip Joint",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "abduction",
                  "adduction",
                  "medial_rotation",
                  "lateral_rotation",
                ],
                formData?.rom?.rom?.hip_joint
              )
            )}
          {formData?.rom?.rom?.knee_joint &&
            getLabelTagInput(
              "Knee Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.knee_joint
              )
            )}
          {formData?.rom?.rom?.ankle_joint &&
            getLabelTagInput(
              "Ankle Joint",
              getRomSummary(
                ["dorsiflexion", "plantarflexion"],
                formData?.rom?.rom?.ankle_joint
              )
            )}
          {formData?.rom?.rom?.foot_joint &&
            getLabelTagInput(
              "Foot Joint",
              getRomSummary(
                ["inversion", "eversion"],
                formData?.rom?.rom?.foot_joint
              )
            )}
          {formData?.rom?.rom?.great_toe_joint &&
            getLabelTagInput(
              "Great Toe Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.great_toe_joint
              )
            )}
          {formData?.rom?.rom?.inter_phalangeal_joint &&
            getLabelTagInput(
              "Inter Phalangeal Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.inter_phalangeal_joint
              )
            )}
          {formData?.rom?.rom?.cervical_spine &&
            getLabelTagInput(
              "Cervical Spine",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "right_rotation",
                  "left_rotation",
                  "right_lateral_rotation",
                  "left_lateral_rotation",
                ],
                formData?.rom?.rom?.cervical_spine
              )
            )}
          {formData?.rom?.rom?.thoraco_lumbar_spine &&
            getLabelTagInput(
              "Thoraco Lumbar Spine",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "right_rotation",
                  "left_rotation",
                  "right_lateral_rotation",
                  "left_lateral_rotation",
                ],
                formData?.rom?.rom?.thoraco_lumbar_spine
              )
            )}
        </>
      );
    }

    if (checkEmpty(formData?.tcms)) {
      pushSummaryItem(
        "7",
        "TCMS",
        <>
          {getLabelTagInput("Score", formData?.tcms?.score)}
          {getLabelTagInput("Interpretation", formData?.tcms?.interpretation)}
          {getLabelTagInput(
            "Static Sitting Balance",
            formData?.tcms?.sitting_balance
          )}
          {getLabelTagInput(
            "Selective Movement Control",
            formData?.tcms?.selective_movement_control
          )}
          {getLabelTagInput(
            "Dynamic Reaching",
            formData?.tcms?.sitting_to_standing
          )}
        </>
      );
    }

    if (checkEmpty(formData?.barthel)) {
      pushSummaryItem(
        "8",
        "Barthel Index",
        <>
          {getLabelTagInput("Score", formData?.barthel?.score)}
          {getLabelTagInput(
            "Interpretation",
            formData?.barthel?.interpretation
          )}
          {getLabelScalesTags(barthelIndexOptions, formData?.barthel)}
        </>
      );
    }

    if (checkEmpty(formData?.special_tests)) {
      pushSummaryItem(
        "9",
        "Special Tests",
        <>
          {checkEmpty(formData?.special_tests?.shoulder_special_tests) &&
            getLabelTagInput(
              "Shoulder Special Tests",
              getLabelScalesTags(
                shoulderOptions,
                formData?.special_tests?.shoulder_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.elbow_special_tests) &&
            getLabelTagInput(
              "Elbow Special Tests",
              getLabelScalesTags(
                elbowOptions,
                formData?.special_tests?.elbow_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.wrist_hand_special_tests) &&
            getLabelTagInput(
              "Wrist And Hand Special Tests",
              getLabelScalesTags(
                wristAndHandOptions,
                formData?.special_tests?.wrist_hand_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.hip_special_tests) &&
            getLabelTagInput(
              "Hip Special Tests",
              getLabelScalesTags(
                hipOptions,
                formData?.special_tests?.hip_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.knee_special_tests) &&
            getLabelTagInput(
              "Knee Special Tests",
              getLabelScalesTags(
                kneeOptions,
                formData?.special_tests?.knee_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.ankle_foot_special_tests) &&
            getLabelTagInput(
              "Ankle Special Tests",
              getLabelScalesTags(
                ankleOptions,
                formData?.special_tests?.ankle_foot_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.cervical_special_tests) &&
            getLabelTagInput(
              "Cervical Special Tests",
              getLabelScalesTags(
                cervicalOptions,
                formData?.special_tests?.cervical_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.thoracic_special_tests) &&
            getLabelTagInput(
              "Thoracic Special Tests",
              getLabelScalesTags(
                thoracicOptions,
                formData?.special_tests?.thoracic_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.lumbar_special_tests) &&
            getLabelTagInput(
              "Lumbar Special Tests",
              getLabelScalesTags(
                lumbarOptions,
                formData?.special_tests?.lumbar_special_tests
              )
            )}
          {checkEmpty(
            formData?.special_tests?.sacroiliac_joint_special_tests
          ) &&
            getLabelTagInput(
              "Sacroiliac Joint Special Tests",
              getLabelScalesTags(
                sacroiliacOptions,
                formData?.special_tests?.sacroiliac_joint_special_tests
              )
            )}
          {checkEmpty(formData?.special_tests?.tmj_special_tests) &&
            getLabelTagInput(
              "TMJ Special Tests",
              getLabelScalesTags(
                tnjOptions,
                formData?.special_tests?.tmj_special_tests
              )
            )}
        </>
      );
    }

    if (checkEmpty(formData?.functional_diagnosis)) {
      pushSummaryItem(
        "10",
        "Functional Diagnosis",
        <>
          {getLabelTagsArray(
            sportsDiagnosisOptions,
            formData?.functional_diagnosis?.diagnosis
          )}
          {getLabelTagInput(
            "Comments",
            formData?.functional_diagnosis?.diagnosis_comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.therapy)) {
      pushSummaryItem(
        "11",
        "Therapy",
        <>
          {getTherapyLabelTags(sportsTherapyOptions, formData?.therapy)}
          {getLabelTagInput(
            "Postural Correction",
            formData?.therapy?.postural_correction
          )}
          {getLabelTagInput("Comments", formData?.therapy?.therapy_comments)}
        </>
      );
    }

    if (checkEmpty(formData?.review_notes)) {
      pushSummaryItem(
        "13",
        "Review Notes",
        <>
          {getLabelTagInput(
            "Progress Notes",
            formData?.review_notes?.progress_notes
          )}
          {getLabelTagInput(
            "Review Notes",
            formData?.review_notes?.review_notes
          )}
        </>
      );
    }

    return sportsSummaryItems;
  };

  const sportsSummaryItems = constructSportsSummaryItems();
  return (
    <div className="summaries">
      <Descriptions
        bordered
        column={1}
        title={"Sports EMR"}
        items={sportsSummaryItems}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default SportsSummary;
