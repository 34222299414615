import { Col, Form, Input, Row, Select, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  activitySelectionOptions,
  frictionSelectionOptions,
  mobilitySelectionOptions,
  moistureSelectionOptions,
  nutritionSelectionOptions,
  perceptionSelectionOptions,
} from "../../../Options/functionalAsessmentOptions";

const Braden = ({ context, form }) => {
  const { formData } = useContext(context);
  const [states, setStates] = useState({
    sensory_perception: 0,
    moisture: 0,
    activity: 0,
    mobility: 0,
    nutrition: 0,
    friction_and_shear: 0,
  });

  useEffect(() => {
    const { score, interpretation, ...rest } =
      formData?.functional_status?.braden_scale || {};
    setStates({ ...rest });
  }, [formData]);

  const handleChange = (value, target) => {
    setStates((prev) => ({ ...prev, [target]: value }));
  };

  const aggregate = () => {
    const total = Object.values(states).reduce((sum, value) => sum + value, 0);
    form.setFieldValue(["braden_scale", "score"], total);
    let risk = "Severe Risk";
    if (total > 19) {
      risk = "Low Risk";
    } else if (total > 15) {
      risk = "At Risk";
    } else if (total > 13) {
      risk = "Moderate Risk";
    } else if (total > 10) {
      risk = "High Risk";
    }
    form.setFieldValue(["braden_scale", "interpretation"], risk);
    return (
      <span>
        {total} : {risk}
      </span>
    );
  };

  return (
    <>
      <Typography>Braden Scale for Predicting Pressure Ulcer Risk</Typography>
      <Form.Item name={["braden_scale", "score"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={["braden_scale", "interpretation"]} hidden>
        <Input />
      </Form.Item>
      <div className="subsection-card">
        <Typography>{aggregate()}</Typography>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Sensory Perception"
              name={["braden_scale", "sensory_perception"]}
            >
              <Select
                options={perceptionSelectionOptions}
                onChange={(value) => handleChange(value, "sensory_perception")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Moisture" name={["braden_scale", "moisture"]}>
              <Select
                options={moistureSelectionOptions}
                onChange={(value) => handleChange(value, "moisture")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Activity" name={["braden_scale", "activity"]}>
              <Select
                options={activitySelectionOptions}
                onChange={(value) => handleChange(value, "activity")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Mobility" name={["braden_scale", "mobility"]}>
              <Select
                options={mobilitySelectionOptions}
                onChange={(value) => handleChange(value, "mobility")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Nutrition" name={["braden_scale", "nutrition"]}>
              <Select
                options={nutritionSelectionOptions}
                onChange={(value) => handleChange(value, "nutrition")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Friction & Shear"
              name={["braden_scale", "friction_and_shear"]}
            >
              <Select
                options={frictionSelectionOptions}
                onChange={(value) => handleChange(value, "friction_and_shear")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Braden;
