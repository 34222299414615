import React, { useState, useRef, useEffect } from "react";
import {
  Drawer,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Space,
  Input,
  Select,
  message,
  DatePicker,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import btnStyles from "../../../styles/Custom/Button.module.css";
import { validateConfig } from "../../../config/validate.config";
import inputStyles from "../../../styles/Custom/Input.module.css";
import { genderKeys } from "../receptionKeys";
import {
  partnerOnboardApi,
  updatepartnerOnboardApi,
} from "../../../services/partner.services";
import dayjs from "dayjs";

const dateFormat = "YYYY-MM-DD";
const OnboardingForm = ({ open, setOpen, editData, refresh }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  console.log(editData, "ddd");
  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        dob: editData?.dob && dayjs(editData?.dob),
      });
    }
  }, []);

  const onFinish = (val) => {
    const formData = { ...val, dob: dayjs(val?.dob).format(dateFormat) };
    if (editData?.id) {
      updatepartnerOnboardApi(editData?.user_id, formData)
        .then((res) => {
          refresh();
          message.success(res?.data?.message);
          setOpen(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          console.log(err);
        });
    } else {
      partnerOnboardApi(formData)
        .then((res) => {
          refresh();
          message.success(res?.data?.message);
          setOpen(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          console.log(err);
        });
    }
  };

  function disabledDate(current) {
    return current && current > dayjs().endOf("day");
  }
  return (
    <div>
      <Drawer
        width={"40%"}
        open={open}
        title={
          <Row>
            <Col span={20} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData?.id ? "Edit Partner" : "Add Partner"}
              </Typography>
            </Col>

            <Col span={4} className="flex-end">
              <CloseOutlined onClick={() => setOpen(false)} />
            </Col>
          </Row>
        }
        onClose={() => setOpen(false)}
        closeIcon={null}
        footer={
          <div className="flex-end" style={{ gap: "24px" }}>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form layout="vertical" onFinish={onFinish} form={form} ref={formRef}>
          <Form.Item
            label="Contact Number"
            name="mobile"
            rules={[
              { required: true, message: "Please enter mobile number" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
          >
            <Input
              style={{ width: "300px" }}
              maxLength="10"
              placeholder="Mobile number"
              addonBefore={"+91"}
              className={inputStyles.mdAddonInput}
            />
          </Form.Item>
          <Space>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={validateConfig.isRequired("First Name")}
            >
              <Input
                className={inputStyles.mdInput}
                style={{ width: "250px" }}
                placeholder="Enter first name"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={validateConfig.isRequired("Last Name")}
            >
              <Input
                className={inputStyles.mdInput}
                style={{ width: "250px" }}
                placeholder="Enter last name"
              />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item label="Date of Birth" name="dob">
              <DatePicker
                disabledDate={disabledDate}
                className={inputStyles.mdDateInput}
                style={{ width: "200px" }}
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select
                allowClear
                placeholder="Select Gender"
                className={inputStyles.mdSelect}
                style={{ width: "180px" }}
                options={genderKeys}
              />
            </Form.Item>
          </Space>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter Email Id" },
              ,
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input
              placeholder="Enter email"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Form.Item label="Address" name="address">
            <Input.TextArea
              placeholder="Enter address"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
          <Form.Item
            label="Hospital / Organisation"
            name="partner_organization"
            rules={[
              {
                required: true,
                message: "Please enter hospital / organisation",
              },
            ]}
          >
            <Input
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
              placeholder="Enter hospital / organisation"
            />
          </Form.Item>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea
              placeholder="Enter notes"
              className={inputStyles.mdInput}
              style={{ width: "300px" }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default OnboardingForm;
