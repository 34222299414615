import React from "react";
import { Form, Input, Select, Space } from "antd";
import inputStypes from "../../../../styles/Custom/Input.module.css";

const ConsultantForm = ({ form }) => {
  return (
    <>
      <Space>
        <Form.Item label="Consultant Name" name="consultant_name">
          <Select className={inputStypes.mdSelect} style={{ width: "300px" }} />
        </Form.Item>
        <Form.Item label="Description" name="consultant_description">
          <Input className={inputStypes.mdInput} style={{ width: "300px" }} />
        </Form.Item>
      </Space>
      <br />
      <Space>
        <Form.Item label="Physiotherapist" name="physiotherapist">
          <Select className={inputStypes.mdSelect} style={{ width: "300px" }} />
        </Form.Item>
        <Form.Item label="Description" name="physiotherapist_description">
          <Input className={inputStypes.mdInput} style={{ width: "300px" }} />
        </Form.Item>
      </Space>
      <br />
      <Space>
        <Form.Item label="Nutritionist" name="nutritionist">
          <Select className={inputStypes.mdSelect} style={{ width: "300px" }} />
        </Form.Item>
        <Form.Item label="Description" name="nutritionist_description">
          <Input className={inputStypes.mdInput} style={{ width: "300px" }} />
        </Form.Item>
      </Space>
      <br />
      <Space>
        <Form.Item label="Specialization" name="specialization">
          <Input className={inputStypes.mdInput} style={{ width: "240px" }} />
        </Form.Item>
        <Form.Item label="Specialist Consultant" name="specialist_consultant">
          <Input className={inputStypes.mdInput} style={{ width: "240px" }} />
        </Form.Item>
        <Form.Item label="Qualification & Designation" name="qualification">
          <Input className={inputStypes.mdInput} style={{ width: "240px" }} />
        </Form.Item>
      </Space>
    </>
  );
};

export default ConsultantForm;
