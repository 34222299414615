import React, { useRef } from "react";
import { Collapse, Form } from "antd";
import ConsultantForm from "./CommonComponents/ConsultantForm";
import PatientProfile from "./CommonComponents/PatientProfile";
import ClinicalImpression from "./CommonComponents/ClinicalImpression";
import HistoryIllness from "./CommonComponents/HistoryIllness";
import FunctionalStatus from "./CommonComponents/FunctionalStatus";
import ClinicalProgress from "./CommonComponents/ClinicalProgress/ClinicalProgress";
import Medication from "./CommonComponents/Medication";
import Nebulization from "./CommonComponents/Nebulization";
import Vitals from "./CommonComponents/Vitals";
import DischargeFunctionalStatus from "./DischargeFunctionalStatus";

const ReferralForm = () => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const commonStyle = {
    // background: "#fff",
    boxShadow:
      "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
  };
  return (
    <div>
      <Form form={form} ref={formRef} layout="vertical">
        <Collapse
          expandIconPosition="right"
          className="custom-collapse"
          defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
        >
          <Collapse.Panel header="Patient Profile" style={commonStyle} key="1">
            <PatientProfile />
          </Collapse.Panel>
          <Collapse.Panel header="Consultants" style={commonStyle} key="2">
            <ConsultantForm form={form} />
          </Collapse.Panel>
          <Collapse.Panel
            header="Clinical Impression/ Diagnosis"
            style={commonStyle}
            key="3"
          >
            <ClinicalImpression form={form} />
          </Collapse.Panel>
          <Collapse.Panel
            header="History of Present Illness"
            style={commonStyle}
            key="4"
          >
            <HistoryIllness form={form} />
          </Collapse.Panel>
          <Collapse.Panel
            header="Functional Status at the time of Admission"
            style={commonStyle}
            key="5"
          >
            <FunctionalStatus form={form} />
          </Collapse.Panel>
          <Collapse.Panel
            header="Clinical Progress"
            style={commonStyle}
            key="6"
          >
            <ClinicalProgress form={form} />
          </Collapse.Panel>
          <Collapse.Panel header="Medication" style={commonStyle} key="7">
            <Medication form={form} />
          </Collapse.Panel>
          <Collapse.Panel header="Nebulization" style={commonStyle} key="8">
            <Nebulization form={form} />
          </Collapse.Panel>
          <Collapse.Panel
            header="Functional Status at Discharge / Referral"
            style={commonStyle}
            key="9"
          >
            <DischargeFunctionalStatus form={form} />
          </Collapse.Panel>
          <Collapse.Panel header="Vitals" style={commonStyle} key="9">
            <Vitals form={form} />
          </Collapse.Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default ReferralForm;
