import { Badge, Card, Col, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import StatCardLayout from "../StatCardLayout";
import {
  getFinancialAnalyticsAPI,
  getFinancialMetricsAPI,
} from "../../../../services/orgDashboard.services";
import {
  CartesianAxis,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { filterOptions } from "../DashboardOptions";
import billIcon from "../../../../assets/dashboardIcons/bill.svg";
import opRevenueIcon from "../../../../assets/dashboardIcons/graph.svg";
import totalCollectionsIcon from "../../../../assets/dashboardIcons/totalBilled.svg";

const FinancialMetrics = ({ currentFacility, date }) => {
  const [financialData, setFinancialData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartFilter, setChartFilter] = useState("month");
  useEffect(() => {
    getFinancialMetricsAPI(currentFacility, date)
      .then((res) => setFinancialData(res?.data?.result))
      .catch((error) => console.log(`Error:${error}`));
  }, [currentFacility, date]);

  useEffect(() => {
    getFinancialAnalyticsAPI(chartFilter, currentFacility, date)
      .then((res) => {
        const chartData = Object?.entries(res?.data?.result)?.map(
          ([key, value]) => ({
            label: key,
            total_collections: value?.total_collections,
            total_op_charges: value?.total_op_charges,
            total_ip_charges: value?.total_ip_charges,
          })
        );
        setChartData(chartData);
      })
      .catch((error) => console.log(error));
  }, [chartFilter, currentFacility, date]);
  return (
    <>
      <Row gutter={[8, 30]}>
        <Col span={8}>
          <StatCardLayout
            title={"Net Total Billed IP"}
            val={financialData?.total_ip_charges}
            footer={" "}
            icon={billIcon}
          />
        </Col>
        <Col span={8}>
          <StatCardLayout
            title={"OP Revenue"}
            val={financialData?.total_op_charges}
            footer={" "}
            icon={opRevenueIcon}
          />
        </Col>
        <Col span={8}>
          <StatCardLayout
            title={"Total Collections"}
            val={financialData?.total_collections || 0}
            footer={" "}
            icon={totalCollectionsIcon}
          />
        </Col>
        <Col span={24}>
          <Card
            title={
              <Row>
                <Col span={12} style={{ fontWeight: 400, fontSize: 12 }}>
                  <Space size={"large"}>
                    <Badge color="#FEC53D" text="Total Collections" />
                    <Badge color="#9A7BDE" text="Net Total Billed IP" />
                    <Badge color="#4AD991" text="OP Revenue" />
                  </Space>
                </Col>
                <Col span={12} className="flex-end">
                  <Select
                    value={chartFilter}
                    options={filterOptions}
                    onSelect={(val) => setChartFilter(val)}
                  />
                </Col>
              </Row>
            }
          >
            <ResponsiveContainer width={"100%"} height={600}>
              <LineChart width={1500} height={600} data={chartData}>
                <CartesianAxis stroke="#ccc" />
                <CartesianGrid
                  stroke="#ccc"
                  horizontal={true}
                  vertical={false}
                />
                <XAxis dataKey={"label"} />
                <YAxis />

                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="total_collections"
                  name="Total Collections"
                  stroke="#FEC53D"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="total_ip_charges"
                  name="Net Total Billed IP"
                  stroke="#9A7BDE"
                  activeDot={{ r: 6 }}
                />
                <Line
                  type="monotone"
                  dataKey="total_op_charges"
                  name="OP Revenue"
                  stroke="#4AD991"
                  activeDot={{ r: 6 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FinancialMetrics;
