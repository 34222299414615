import React, { useContext, useEffect } from "react";
import Built from "./GeneralExaminationComponents/Built";
import VitalsAdmission from "./GeneralExaminationComponents/VitalsAdmission";
import { Divider } from "antd";
import Gcs from "./GeneralExaminationComponents/Gcs";
import PainScale from "./GeneralExaminationComponents/PainScale";
import { PhysioInitialContext } from "../../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
import PhysioGcs from "./GeneralExaminationComponents/PhysioGcs";

const PhysioGeneralExaminationForm = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  useEffect(() => {
    setTimeout(() => form.setFieldsValue(formData?.general_examination), 1000);
  }, [formData]);
  return (
    <>
      <Built form={form} />
      <Divider />
      <VitalsAdmission />
      <Divider />
      <PhysioGcs formData={formData} />
      <Divider />
      <PainScale context={PhysioInitialContext} form={form} />
    </>
  );
};

export default PhysioGeneralExaminationForm;
