import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  Row,
  Modal,
  InputNumber,
  message,
  Input,
  TimePicker,
  Card,
  Divider,
  Tooltip,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  addIoMonitoringData,
  updateIoMonitoringData,
} from "../../../../services/nurse.services";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";

const format = "hh A";

const IoModalForm = ({ open, setOpen, editData, refresh, date }) => {
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [hourlyIntake, setHourlyIntake] = useState(0);
  const [hourlyOutput, setHourlyOutput] = useState(0);
  const [form] = Form.useForm();
  const formRef = useRef(null);

  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        process_time: dayjs(editData?.process_time),
      });
      setHourlyIntake(editData?.hourly_intake);
      setHourlyOutput(editData?.hourly_output);
    }
  }, [editData]);

  // Function to calculate hourly intake and output
  const calculateValues = (changedValues, allValues) => {
    const newHourlyIntake =
      (allValues?.oral_tube_intake || 0) +
      (allValues?.flush || 0) +
      (allValues?.iv_fluids || 0);

    const newHourlyOutput =
      (allValues?.uo || 0) +
      (allValues?.gi_losses || 0) +
      (allValues?.drain || 0);

    setHourlyIntake(newHourlyIntake);
    setHourlyOutput(newHourlyOutput);
  };

  const onValuesChange = (changedValues, allValues) => {
    calculateValues(changedValues, allValues);
  };

  const onFinish = (values) => {
    console.log();
    setLoading(true);

    // Combine date prop with the selected time from TimePicker
    const selectedTime = values?.process_time
      ? dayjs(values?.process_time).format("HH:mm")
      : null;
    const combinedDateTime = selectedTime
      ? dayjs(date)
          .set("hour", dayjs(values?.process_time).hour())
          .set("minute", dayjs(values?.process_time).minute())
          .format("YYYY-MM-DDTHH:mm:ss")
      : editData?.process_time;
    console.log(
      combinedDateTime,
      // combinedDateTime?.toISOString(),
      selectedTime
    );
    // Adding computed fields to the values object
    const updatedValues = {
      ...values,
      process_time: combinedDateTime, // Use the combined date and time
      hourly_intake: hourlyIntake || 0,
      hourly_output: hourlyOutput || 0,
    };

    if (editData?.id) {
      updateIoMonitoringData(params?.patient, editData?.id, updatedValues)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message?.error(err?.response?.data?.message);
        });
    } else {
      addIoMonitoringData(params?.patient, updatedValues)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message?.error(err?.response?.data?.message);
        });
    }
  };

  const formItems = [
    { name: "Oral/Tube", value: "oral_tube_intake" },
    { name: "GI Losses", value: "gi_losses" },
    { name: "Flush", value: "flush" },
    { name: "UO", value: "uo" },
    { name: "IV", value: "iv_fluids" },
    { name: "Drain", value: "drain" },
  ];

  return (
    <div>
      <Modal
        open={open}
        width={"40%"}
        onCancel={() => setOpen(false)}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                Input/Output monitoring
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={() => setOpen(false)} />
            </Col>
          </Row>
        }
        closeIcon={null}
        footer={
          <>
            <Divider style={{ margin: "5px" }} />
            <div className="flex-end" style={{ gap: "24px" }}>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  className={btnStyles.mdBtn}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.submit();
                    }
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </>
        }
      >
        <Divider style={{ margin: "5px" }} />
        <Form
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange} // Trigger calculation on field change
          form={form}
          ref={formRef}
        >
          <Form.Item
            label="Time"
            name="process_time"
            rules={[{ required: true, message: "Please Select time" }]}
          >
            <TimePicker format={format} needConfirm={false} />
          </Form.Item>
          <Row gutter={24}>
            {formItems?.map((item) => (
              <Col xs={24} sm={12}>
                <Form.Item
                  key={item.value}
                  label={item.name}
                  name={item.value}
                  rules={[
                    { type: "number", message: "Please enter a valid number" },
                  ]}
                >
                  <InputNumber addonAfter="ml" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            ))}

            <Col xs={24}>
              <Row align="middle" gutter={24}>
                <Col xs={24} md={12}>
                  {" "}
                  <Typography.Text>
                    Hourly Intake: {hourlyIntake} ml{" "}
                    <Tooltip
                      title={
                        <span style={{ fontSize: "10px" }}>
                          Oral/Tube + Flush + IV fluids
                        </span>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Typography.Text>
                </Col>

                <Col xs={24} md={12}>
                  {" "}
                  <Typography.Text>
                    Hourly Output: {hourlyOutput} ml{" "}
                    <Tooltip
                      title={
                        <span style={{ fontSize: "10px" }}>
                          GI Losses + UO + Drain
                        </span>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Typography.Text>
                </Col>
              </Row>
              <div style={{ marginTop: "10px" }}>
                <Form.Item label="Remarks :" name="additional_info">
                  <Input.TextArea />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default IoModalForm;
