import React, { useEffect, useState } from "react";
import Bmi from "./Bmi";
import { Divider } from "antd";
import NutritionistInvestigations from "./NutritionistInvestigations";
import NutritionalScreeningRating from "./NutritionalScreeningRating";
import NutritionalRisk from "./NutritionalRisk";
import { NutritionistInitialContext } from "../HistoryForm/NutritionistInitialAssessmentProvider";

const NutritionistClinicalEvaluationForm = ({ form, formData }) => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    form.setFieldsValue(formData?.clinical_evaluation);
    setTimeout(() => setRefresh((prev) => !prev), 1000);
  }, [formData]);
  return (
    <>
      <Bmi context={NutritionistInitialContext} />
      <Divider />
      <NutritionistInvestigations />
      <Divider />
      <NutritionalScreeningRating form={form} />
      <Divider />
      <NutritionalRisk context={NutritionistInitialContext} form={form} />
    </>
  );
};

export default NutritionistClinicalEvaluationForm;
