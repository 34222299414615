import React from "react";
import noPainSvg from "../../../../../../assets/images/scalesImages/noPain.svg";
import lightPainSvg from "../../../../../../assets/images/scalesImages/lightPain.svg";
import moderatePainSvg from "../../../../../../assets/images/scalesImages/moderatePain.svg";
import severePainSvg from "../../../../../../assets/images/scalesImages/severePain.svg";
import verySeverePainSvg from "../../../../../../assets/images/scalesImages/verySeverePain.svg";
import worstPainSvg from "../../../../../../assets/images/scalesImages/worstPain.svg";
import { Form, Input, Radio } from "antd";

const PainSelection = ({ form }) => {
  const vasOptions = [
    {
      value: 0,
      label: "No Pain",
      image: noPainSvg,
    },
    {
      value: 1,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 2,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 3,
      label: "Mild",
      image: lightPainSvg,
    },
    {
      value: 4,
      label: "Moderate",
      image: moderatePainSvg,
    },
    {
      value: 5,
      label: "Moderate",
      image: moderatePainSvg,
    },
    {
      value: 6,
      label: "Severe",
      image: severePainSvg,
    },
    {
      value: 7,
      label: "Severe",
      image: severePainSvg,
    },
    {
      value: 8,
      label: "Very Severe",
      image: verySeverePainSvg,
    },
    {
      value: 9,
      label: "Very Severe",
      image: verySeverePainSvg,
    },
    {
      value: 10,
      label: "Worst Pain",
      image: worstPainSvg,
    },
  ];

  const handleRadioClick = (value) => {
    const currentValue = form.getFieldValue("pain");
    const setValue = currentValue === value ? null : value;
    form.setFieldValue("pain", setValue);
    form.setFieldValue("pain_interpretation", vasOptions[setValue]?.label);
  };
  return (
    <div className="physio-scales">
      <Form.Item name={"pain_interpretation"} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="pain">
        <Radio.Group className="radio-group">
          {vasOptions.map((option) => (
            <Radio.Button
              key={option.value}
              value={option.value}
              style={{
                height: "100px",
                width: "80px",
                padding: 0,
                margin: "0 2px",
              }}
              onClick={() => handleRadioClick(option?.value)}
            >
              <div className="visual-option">
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.label}
                    className="visual-image"
                  />
                )}
                <div
                  className="visual-label"
                  style={{ fontSize: "10px", lineHeight: "1.2" }}
                >
                  {option.value}
                  <br />
                  {option.label}
                </div>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default PainSelection;
