import React from "react";
import { Form, Typography, Input, Collapse } from "antd";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import PastMedicalHistory from "./History/PastMedicalHistory";
import PastSurgicalHistory from "./History/PastSurgicalHistory";
import KnownAllergies from "./History/KnownAllergies";

const HistoryIllness = () => {
  return (
    <>
      {" "}
      <Typography>
        <Typography.Text>
          Mr. Raju, 31 years old, male was admitted in{" "}
        </Typography.Text>
        <Form.Item style={{ display: "inline-block" }} name="hospital">
          <Input
            className={inputStyles.mdInput}
            style={{ width: "250px" }}
            placeholder="Hospital"
          />
        </Form.Item>
        <Typography.Text> with </Typography.Text>
        <Form.Item style={{ display: "inline-block" }} name="history">
          <Input
            className={inputStyles.mdInput}
            style={{ width: "250px" }}
            placeholder="History of present illness"
          />
        </Form.Item>{" "}
        <Typography.Text> and he underwent </Typography.Text>
        <Form.Item
          style={{ display: "inline-block" }}
          name="surgical_treatment"
        >
          <Input
            className={inputStyles.mdInput}
            style={{ width: "250px" }}
            placeholder="Surgical/Medical Treatment"
          />
        </Form.Item>{" "}
        <Typography.Text>
          Patient gradually recovered, but with some residual deficits and after
          discharge was admitted in Ucchvas transitional care rehabilitation
          center for functional recovery.
        </Typography.Text>
      </Typography>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={["1", "2", "3", "4", "5"]}
      >
        <Collapse.Panel header="Past Medical History" key="1">
          <PastMedicalHistory />
        </Collapse.Panel>
        <Collapse.Panel header="Past Surgical History" key="2">
          <PastSurgicalHistory />
        </Collapse.Panel>
        <Collapse.Panel header="Known Allergies" key="3">
          <KnownAllergies />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default HistoryIllness;
