import React, { createContext, useState } from "react";

export const PhysioOPContext = createContext({});
const PhysioOPFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  return (
    <PhysioOPContext.Provider value={{ formData, setFormData }}>
      {children}
    </PhysioOPContext.Provider>
  );
};

export default PhysioOPFormProvider;
