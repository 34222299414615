import { Button, Col, Row, Space, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import btnStyles from "../../../styles/Custom/Button.module.css";
import OPSummary from "../../OpEMR/Physiotherapist/OPSummary/OPSummary";
import PhysioOP from "../../OpEMR/Physiotherapist/PhysioOP";
import PhysioOPFormProvider from "../../OpEMR/Physiotherapist/PhysioOPFormProvider";
import OPHistory from "./OPHistory";
import { getOpHistoryAPI } from "../../../services/physio.services";

const OPLandingLayout = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshOp = () => {
    setRefresh((prev) => !prev);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams?.get("service");
  const admissionId = searchParams?.get("aid");
  const patientId = searchParams?.get("pid");
  const navigate = useNavigate();
  const [patientHistory, setPatientHistory] = useState({});
  const admission = patientHistory?.admission;
  const history = patientHistory?.history;
  const patientData = patientHistory?.patient_data;
  const [currentEmr, setCurrentEmr] = useState({});
  console.log(currentEmr);

  const handlePrev = () => {
    if (status) {
      searchParams?.delete("service");
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate(`/physiotherapist/home?patient-type=outPatients&status=today`);
    }
  };

  const handleStartService = () => {
    searchParams?.set("service", "start");
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const getHistory = async () => {
    getOpHistoryAPI(patientId, admissionId)
      .then((res) => setPatientHistory(res?.data?.data))
      .catch((error) => console.log(`Error:${error}`));
  };

  useEffect(() => {
    getHistory();
  }, [admissionId, refresh]);

  return (
    <>
      <Row gutter={[8, 16]}>
        <Col span={12}>
          <Space>
            <LeftOutlined
              onClick={handlePrev}
              style={{
                fontSize: "18px",
                padding: "0px 10px",
                // marginTop: "15px",
              }}
            />
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.first_name || " "} ${
                patientData?.last_name || " "
              }`}
            </Typography>
          </Space>
          <br />
          <Space style={{ marginLeft: "48px" }}>
            <Tag>
              {patientData?.age} {patientData?.gender}
            </Tag>
            <Tag color="">OP ID: {admission?.op_id}</Tag>
          </Space>
        </Col>
        <Col span={12} className="flex-end">
          {status !== "start" && (
            <Button
              type="primary"
              className={btnStyles.lgbtn}
              onClick={handleStartService}
            >
              Start Service
            </Button>
          )}
        </Col>
        {status ? (
          <PhysioOPFormProvider>
            <PhysioOP
              patientData={patientData}
              admission={admission}
              refreshOp={refreshOp}
            />
          </PhysioOPFormProvider>
        ) : (
          <>
            <Col sm={24} md={8} lg={8} xl={6}>
              <OPHistory
                admission={admission}
                history={history}
                setCurrentEmr={setCurrentEmr}
              />
            </Col>

            <Col sm={24} md={16} lg={16} xl={18}>
              <OPSummary currentEmr={currentEmr} />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default OPLandingLayout;
