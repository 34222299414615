import React from "react";
import CommonTable from "./CommonTable";
import dayjs from "dayjs";

const IoData = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "process_time",
      key: "process_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "Oral Tube Intake",
      dataIndex: "oral_tube_intake",
      key: "oral_tube_intake",
    },
    {
      title: "Flush",
      dataIndex: "flush",
      key: "flush",
    },
    {
      title: "IV Fluids",
      dataIndex: "iv_fluids",
      key: "iv_fluids",
    },
    {
      title: "Hourly Intake",
      dataIndex: "hourly_intake",
      key: "hourly_intake",
    },
    {
      title: "UO",
      dataIndex: "uo",
      key: "uo",
    },
    {
      title: "GI Losses",
      dataIndex: "gi_losses",
      key: "gi_losses",
    },
    {
      title: "Drain",
      dataIndex: "drain",
      key: "drain",
    },
    {
      title: "Hourly Output",
      dataIndex: "hourly_output",
      key: "hourly_output",
    },
  ];
  return (
    <div>
      <CommonTable data={data} columns={columns} />
    </div>
  );
};

export default IoData;
