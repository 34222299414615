import { Checkbox, Form, Typography } from "antd";
import React, { useState } from "react";
import MMTScaleItems from "../../PhysioScales/MMTScaleComponents/MMTScaleItems";

const motorSystemOptions = [
  { label: "MMT", value: "mmt" },
  { label: "MAS", value: "mas" },
  { label: "VCG", value: "vcg" },
];
const PhysioMotorSystem = ({ form }) => {
  const [refresh, setRefresh] = useState(false);
  return (
    <>
      <Typography>Motor System</Typography>
      {motorSystemOptions?.map((checkbox) => (
        <>
          <Form.Item
            name={["motor_system", checkbox?.value]}
            valuePropName="checked"
          >
            <Checkbox onChange={() => setRefresh((prev) => !prev)}>
              {checkbox?.label}
            </Checkbox>
          </Form.Item>
          {form.getFieldValue(["motor_system", checkbox?.value]) && (
            <MMTScaleItems scaleType={checkbox?.value} initial={true} />
          )}
        </>
      ))}
    </>
  );
};

export default PhysioMotorSystem;
