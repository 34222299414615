import React from "react";
import { Table, Typography } from "antd";
import { capitalizeFirstLetter } from "../../../helpers/utility";

const CommonTable = ({ data, columns, titleData }) => {
  const commonColumns = [
    ...columns,
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (value, record) => (
        <Typography>
          {record?.nurse_data?.remarks || record?.additional_info || value}
        </Typography>
      ),
    },
    {
      title: "Processed By",
      dataIndex: "processed_by_name",
      key: "processed_by_name",
      render: (value, record) =>
        record?.nurse_data?.processed_by_name ||
        record?.submitted_by_name ||
        record?.updated_by ||
        value,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, record) =>
        capitalizeFirstLetter(
          record?.nurse_data?.action || record?.action || record?.status
        ),
    },
  ];
  return (
    <Table
      style={{ marginTop: "10px" }}
      columns={commonColumns}
      dataSource={data}
      pagination={false}
      {...(titleData && typeof titleData === "function"
        ? { title: titleData }
        : {})}
    />
  );
};

export default CommonTable;
