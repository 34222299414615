import React from "react";
import CommonTable from "../../CommonTable";
import dayjs from "dayjs";

const TrailOfDecannulation = ({ data }) => {
  const columns = [
    {
      title: "S.NO",
      dataIndex: "key",
      render: (val) => val + 1,
    },
    {
      title: "Trail No.",
      dataIndex: "trail_no",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => dayjs(date)?.format("DD-MM-YYYY"),
    },
    {
      title: "Hours",
      dataIndex: "hours",
    },
    {
      title: "Trial Remarks",
      dataIndex: "trial_remarks",
    },
    {
      title: "Time",
      dataIndex: "process_time",
      render: (time) => dayjs(time)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <>
      <CommonTable
        data={
          data?.respiratory_care_tracheostomy_care_trail_of_decannulation
            ?.nurse_data
        }
        columns={columns}
        //   titleData={() => {}}
      />
    </>
  );
};

export default TrailOfDecannulation;
