import React, { useContext, useEffect, useState } from "react";
import {
  cardioGoalsOptions,
  neuroGoalsOptions,
  orthoGoalsOptions,
  physioDiagnosisOptions,
  sportsGoalsOptions,
} from "../../Options/physioOptions";
import { Checkbox, Form, Input } from "antd";
import { PhysioInitialContext } from "../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
import { getInitialValuesForChild } from "../../../../helpers/getInitialValues";

const PhysioTherapeuticGoals = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  const [checkboxes, setCheckboxes] = useState({});
  const [showOthers, setShowOthers] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckboxes((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const handleSubCheckboxChange = (values, mainCategory) => {
    const hasOthers = values.includes("others");
    setShowOthers((prev) => ({
      ...prev,
      [mainCategory]: hasOthers,
    }));
  };

  const getOptions = (value) => {
    switch (value) {
      case "cardio":
        return cardioGoalsOptions;
      case "ortho":
        return orthoGoalsOptions;
      case "sports":
        return sportsGoalsOptions;
      case "neuro":
        return neuroGoalsOptions;
      default:
        return [];
    }
  };

  useEffect(() => {
    form.setFieldsValue(formData?.therapeutic_goals);
  }, [formData]);

  useEffect(() => {
    const initialCheckboxes = physioDiagnosisOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        getInitialValuesForChild(
          physioDiagnosisOptions,
          formData?.therapeutic_goals !== null && formData?.therapeutic_goals
        )[checkbox.value] || false; // Initialize all checkboxes based on formData
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);

    // Initialize showOthers state
    const initialShowOthers = physioDiagnosisOptions.reduce((acc, checkbox) => {
      const hasDescription =
        formData?.therapeutic_goals?.[`${checkbox.value}_description`];
      acc[checkbox.value] = !!hasDescription;
      return acc;
    }, {});
    setShowOthers(initialShowOthers);
  }, [formData]);

  return (
    <>
      {physioDiagnosisOptions?.map((option) => (
        <div key={option.value}>
          <Form.Item name={option.value} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={checkboxes[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[option?.value] && option?.value === "others" ? (
            <>
              <Form.Item name={"others_description"}>
                <Input.TextArea rows={4} placeholder="Description" />
              </Form.Item>
            </>
          ) : (
            checkboxes[option.value] && (
              <div className="subsection-card">
                <Form.Item name={`${option.value}_details`}>
                  <Checkbox.Group
                    // options={getOptions(option.value)}
                    onChange={(values) =>
                      handleSubCheckboxChange(values, option.value)
                    }
                    value={
                      formData?.therapeutic_goals?.[
                        `${option.value}_details`
                      ] || []
                    }
                  >
                    {getOptions(option.value).map((option) => (
                      <div style={{ minWidth: "600px", padding: "6px" }}>
                        <Checkbox key={option.value} value={option.value}>
                          {option.label}
                        </Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
                {showOthers[option.value] && (
                  <Form.Item name={`${option.value}_description`}>
                    <Input placeholder="Please enter the description" />
                  </Form.Item>
                )}
              </div>
            )
          )}
        </div>
      ))}
    </>
  );
};

export default PhysioTherapeuticGoals;
