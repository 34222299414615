import { Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CheckListFBYInput from "../../../../../blocks/FormItems/CheckListFBYInput";
import { opExaminationOptions } from "../OpPhysioOptions";
import PainSelection from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/VAScaleComponents/PainSelection";

const OPExaminationForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [checkboxes, setCheckboxes] = useState([]);
  useEffect(() => {
    const initialCheckboxes = opExaminationOptions?.reduce((acc, checkbox) => {
      acc[checkbox.value] = formData?.examination?.[checkbox.value];
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData?.examination]);
  return (
    <>
      <Form
        id="op-examination"
        onValuesChange={(cur, all) => onValuesChange("examination", all)}
        className="ip-forms"
        layout="vertical"
        form={form}
        initialValues={formData?.examination}
      >
        <Typography>Examination</Typography>
        <CheckListFBYInput
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          options={opExaminationOptions}
        />
        {form.getFieldValue("pain_op") && <PainSelection form={form} />}
        <Form.Item name={"examination_comments"}>
          <Input.TextArea
            rows={2}
            placeholder="Comments"
            style={{ width: "60%" }}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default OPExaminationForm;
