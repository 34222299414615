import React, { useContext, useEffect, useState } from "react";
import { Form, Checkbox, Radio, Input } from "antd";

import { PhysioInitialContext } from "../../Physiotherapist/PhysiotherapistInitialInspection/PhysiotherapistInitialAssessmentProvider";
import { getInitialValuesForChild } from "../../../../helpers/getInitialValues";
import {
  cardioTherapyOptions,
  checkboxOptions,
  orthoTherapyOptions,
  sportsTherapyOptions,
  daysUptoFifteenOptions,
  weeksStartingFourOptions,
  weeksStartingTwoOptions,
} from "../../Options/physioOptions";
import DeselectableRadioGroup from "../../../../blocks/FormItems/DeselectableRadioGroup";

const Therapy = ({ form }) => {
  const { formData } = useContext(PhysioInitialContext);
  const [checkboxes, setCheckboxes] = useState({
    ortho: false,
    cardio: false,
    sports: false,
    neuro: false,
  });

  // Handle checkbox change
  const handleCheckboxChange = (value) => {
    setCheckboxes((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  // Get options based on the therapy type
  const getOptions = (value) => {
    switch (value) {
      case "ortho":
        return orthoTherapyOptions;
      case "cardio":
        return cardioTherapyOptions;
      case "sports":
        return sportsTherapyOptions;
      case "neuro":
        return null; // Neuro options to be filled in later
      default:
        return [];
    }
  };

  // Initialize checkboxes state based on formData
  useEffect(() => {
    const initialCheckboxes = checkboxOptions.reduce((acc, checkbox) => {
      acc[checkbox.value] =
        getInitialValuesForChild(
          checkboxOptions,
          formData?.therapy !== null && formData?.therapy
        )[checkbox.value] || false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);
  }, [formData]);

  return (
    <>
      {checkboxOptions.map((option) => (
        <div key={option.value}>
          <Form.Item name={option.value} valuePropName="checked">
            <Checkbox
              onChange={() => handleCheckboxChange(option.value)}
              checked={checkboxes[option.value]}
            >
              {option.label}
            </Checkbox>
          </Form.Item>
          {checkboxes[option.value] && getOptions(option.value) && (
            <div className="subsection-card">
              {getOptions(option.value)?.map((subOption) => (
                <DeselectableRadioGroup
                  name={[`${option?.value}_details`, subOption?.name_key]}
                  label={subOption?.label}
                  options={subOption?.options}
                  form={form}
                />
              ))}
              {["ortho", "sports"].includes(option.value) && (
                <Form.Item
                  label={"Postural Correction"}
                  name={[`${option.value}_details`, "postural_correction"]}
                >
                  <Input placeholder="Postural Correction" />
                </Form.Item>
              )}
            </div>
          )}
        </div>
      ))}
      {checkboxes["neuro"] && (
        <div className="subsection-card">
          <DeselectableRadioGroup
            name={["neuro_details", "stroke_rehab"]}
            label={"Stroke Rehab"}
            options={weeksStartingFourOptions}
            form={form}
          />
          <Form.Item
            label="Brunnstromm Approach"
            name={["neuro_details", "brustromme_approach"]}
          >
            <Input placeholder="Brunnstromm Approach" />
          </Form.Item>
          <Form.Item
            label="MRP Approach"
            name={["neuro_details", "mrp_approach"]}
          >
            <Input placeholder="MRP Approach" />
          </Form.Item>
          <Form.Item
            label="NDT Approach"
            name={["neuro_details", "ndt_approach"]}
          >
            <Input placeholder="NDT Approach" />
          </Form.Item>
          <Form.Item
            label="CIMT Approach"
            name={["neuro_details", "cimt_approach"]}
          >
            <Input placeholder="CIMT Approach" />
          </Form.Item>
          <Form.Item
            label="VOJTA Approach"
            name={["neuro_details", "vojta_approach"]}
          >
            <Input placeholder="VOJTA Approach" />
          </Form.Item>
          <Form.Item
            label="PNF Approach"
            name={["neuro_details", "pnf_approach"]}
          >
            <Input placeholder="PNF Approach" />
          </Form.Item>
          <Form.Item
            label="Electic Approach"
            name={["neuro_details", "electic_approach"]}
          >
            <Input placeholder="Electic Approach" />
          </Form.Item>
          <Form.Item
            label="ROODS Approach"
            name={["neuro_details", "roods_approach"]}
          >
            <Input placeholder="ROODS Approach" />
          </Form.Item>
          <Form.Item
            label="Speech therapy"
            name={["neuro_details", "speech_therapy"]}
          >
            <Input placeholder="Speech therapy" />
          </Form.Item>
          <Form.Item
            label="Electrical Muscle Stimulation"
            name={["neuro_details", "electrical_muscle_stimulation"]}
          >
            <Input placeholder="Electrical Muscle Stimulation" />
          </Form.Item>
          <Form.Item
            label="Mile Stone Training"
            name={["neuro_details", "mile_stone_training"]}
          >
            <Input placeholder="Mile Stone Training" />
          </Form.Item>
          <Form.Item
            label="Cognitive Rehab"
            name={["neuro_details", "cognitive_rehab"]}
          >
            <Input placeholder="Cognitive Rehab" />
          </Form.Item>
          <Form.Item
            label="Neuro Conditioning Training"
            name={["neuro_details", "neuro_conditioning_training"]}
          >
            <Input placeholder="Neuro Conditioning Training" />
          </Form.Item>
          <Form.Item
            label="Sensory Integration"
            name={["neuro_details", "sensory_integration"]}
          >
            <Input placeholder="Sensory Integration" />
          </Form.Item>
          <Form.Item
            label="ADL Training"
            name={["neuro_details", "adl_training"]}
          >
            <Input placeholder="ADL Training" />
          </Form.Item>
          <Form.Item
            label="Wheel Chair Training"
            name={["neuro_details", "wheel_chair_training"]}
          >
            <Input placeholder="Wheel Chair Training" />
          </Form.Item>

          <DeselectableRadioGroup
            name={["neuro_details", "ultrasound"]}
            label={"Ultrasound"}
            options={daysUptoFifteenOptions}
            form={form}
          />

          <DeselectableRadioGroup
            name={["neuro_details", "splinting"]}
            label={"Splinting/Bracing"}
            options={weeksStartingTwoOptions}
            form={form}
          />
          <Form.Item
            label="Postural Correction"
            name={["neuro_details", "postural_correction"]}
          >
            <Input placeholder="Postural Correction" />
          </Form.Item>
          <Form.Item label="Robotics" name={["neuro_details", "robotics"]}>
            <Input placeholder="Robotics" />
          </Form.Item>
          <Form.Item
            label="Gait Training"
            name={["neuro_details", "gait_training"]}
          >
            <Input placeholder="Gait Training" />
          </Form.Item>
          <Form.Item label="Comments" name={["neuro_details", "description"]}>
            <Input placeholder="Please enter the description" />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default Therapy;
