import { Form, Typography } from "antd";
import React, { useState } from "react";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import { notAssessableAndAssessableOptions } from "../../../../Options/commonOptions";
import RASScaleItems from "../../PhysioScales/RASScaleComponents/RASScaleItems";
import PsychologistMocaScale from "../../../Scales/PsychologistMocaScale";

const PhysioMentalStatus = ({ form }) => {
  const [refresh, setRefresh] = useState(false);
  const showRass = form.getFieldValue("rass_status");
  const showMoca = form.getFieldValue("moca_status");
  return (
    <>
      <Typography>Mental Status</Typography>
      <DeselectableRadioGroup
        label={"RASS"}
        name={"rass_status"}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {showRass === "assessable" && (
        <RASScaleItems form={form} setRefresh={setRefresh} />
      )}
      <DeselectableRadioGroup
        label={"MOCA"}
        name={"moca_status"}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {showMoca === "assessable" && <PsychologistMocaScale form={form} />}
    </>
  );
};

export default PhysioMentalStatus;
