import { Descriptions, Image, Space, Tag } from "antd";
import React from "react";
import {
  getFractureSummary,
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithCheckGroup,
  getLabelTagsWithDescription,
  getLabelTagsWithRadioOptions,
  getLabelTagsWithSelectItems,
  getLabelTagYesNO,
} from "../constructTextFromValues";
import {
  abnormalBreathZoneOptions,
  abnormalGaitOptions,
  auscultationOptions,
  breathSoundsOptions,
  coordinationOptions,
  cranialNervesAbnormalOptions,
  cranialNerveSeverityOptions,
  cranialNervesOptions,
  fractureAreasOptions,
  gaitOptions,
  heartSoundsOptions,
  jointPainLocationOptions,
  jointPainSeverityOptions,
  jvpOptions,
  leftRightOptions,
  limbDeformityOptions,
  mentalAbnormalOptions,
  mentalStatusOptions,
  motorAbnormalRadioItems,
  motorSystemAbnormalOptions,
  motorSystemOptions,
  nursePressureSoreGradeOptions,
  periPulsesVolumeOptions,
  pressureSoreAreaOptions,
  reflexes,
  sensoryExtremitiesOptions,
  sensorySystemAbnormalOptions,
  sensorySystemOptions,
  skullOptions,
  softTissueInjuryAreaOptions,
  spineOptions,
  typeOfBreathingOptions,
} from "../../Options/systemicExamOptions";
import {
  noneYesOptions,
  normalAbsentOptions,
  notAssessableAndAssessableOptions,
  positiveNegativeOptions,
  regularIrregularOptions,
} from "../../Options/commonOptions";
import {
  bergScaleOptions,
  postureOptions,
  tcmOptions,
} from "../../Options/physioOptions";
import { FileSearchOutlined } from "@ant-design/icons";
import { checkEmpty } from "../../../../helpers/utility";
import respiratoryIcon from "../../../../assets/doctorIcons/lungs.svg";
import centralNervousSystemIcon from "../../../../assets/doctorIcons/cns.svg";
import cardioIcon from "../../../../assets/doctorIcons/cardio-vascular.svg";
import skinIcon from "../../../../assets/doctorIcons/skin.svg";
import musculoSkeletalIcon from "../../../../assets/doctorIcons/musculoSkeletal.svg";

const PhysioSystemicExaminationSummary = ({ formData }) => {
  const getReflexesSummary = () => {
    const reflexOptions = reflexes
      .map((reflex) => {
        const leftValue =
          formData?.systemic_examination?.[reflex.value + "_left"];
        const rightValue =
          formData?.systemic_examination?.[reflex.value + "_right"];

        if (leftValue || rightValue) {
          let labelCreated = `${reflex.label} Left: ${
            leftValue || "N/A"
          }, Right: ${rightValue || "N/A"}`;
          return (
            <Tag bordered={false} key={reflex.value} className="break-line-tag">
              {labelCreated}
            </Tag>
          );
        }
        return null;
      })
      .filter(Boolean); // Remove any null values

    return reflexOptions.length === 0 ? null : reflexOptions;
  };

  const constructskinSummaryItems = () => {
    const skinSummaryItems = [];
    if (formData?.systemic_examination?.pressure_sore_details?.pressure_sores) {
      skinSummaryItems.push({
        key: "1",
        label: "Pressure Sores",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.systemic_examination?.pressure_sore_details
                ?.pressure_sores
            )}
            {getLabelTagsWithRadioOptions(
              pressureSoreAreaOptions,
              nursePressureSoreGradeOptions,
              formData?.systemic_examination?.pressure_sore_details
            )}
          </>
        ),
      });
    }

    if (
      getLabelTagsWithDescription(
        softTissueInjuryAreaOptions,
        formData?.systemic_examination?.soft_tissue_injury
      )
    ) {
      skinSummaryItems.push({
        key: "2",
        label: "Soft Tissue Injury",
        children: getLabelTagsWithDescription(
          softTissueInjuryAreaOptions,
          formData?.systemic_examination?.soft_tissue_injury
        ),
      });
    }
    if (formData?.systemic_examination?.scar) {
      skinSummaryItems.push({
        key: "3",
        label: "Scar",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.scar)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.scar_description
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.surgical_incision) {
      skinSummaryItems.push({
        key: "4",
        label: "Surgical Incision",
        children: (
          <>
            {getLabelTagYesNO(
              formData?.systemic_examination?.surgical_incision
            )}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.surgical_incision_description
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.swellings) {
      skinSummaryItems.push({
        key: "5",
        label: "Swellings",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.swellings)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.swelling_description
            )}
          </>
        ),
      });
    }
    return skinSummaryItems;
  };
  const constructCardioVascularSystemSummaryItems = () => {
    const cardioVascularSystemSummaryItems = [];
    if (
      formData?.systemic_examination?.peripheral_regular ||
      formData?.systemic_examination?.peripheral_volume ||
      formData?.systemic_examination?.peripheral_desc
    ) {
      cardioVascularSystemSummaryItems.push({
        key: "1",
        label: "Peripheral Pulses",
        children: (
          <>
            {getLabelTag(
              regularIrregularOptions,
              formData?.systemic_examination?.peripheral_regular
            )}
            {getLabelTag(
              periPulsesVolumeOptions,
              formData?.systemic_examination?.peripheral_volume
            )}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.peripheral_desc
            )}
          </>
        ),
      });
    }
    if ((jvpOptions, formData?.systemic_examination?.jvp)) {
      cardioVascularSystemSummaryItems.push({
        key: "2",
        label: "JVP",
        children: getLabelTag(jvpOptions, formData?.systemic_examination?.jvp),
      });
    }
    if (
      getLabelTagsWithDescription(
        heartSoundsOptions,
        formData?.systemic_examination
      )
    ) {
      cardioVascularSystemSummaryItems.push({
        key: "3",
        label: "Heart Sounds",
        children: getLabelTagsWithDescription(
          heartSoundsOptions,
          formData?.systemic_examination
        ),
      });
    }
    return cardioVascularSystemSummaryItems;
  };

  const constructRespiratorySystemSummaryItems = () => {
    const respiratorySystemSummaryItems = [];

    if (formData?.systemic_examination?.type_of_breathing) {
      respiratorySystemSummaryItems.push({
        key: "1",
        label: "Type of Breathing",
        children: (
          <>
            {getLabelTag(
              typeOfBreathingOptions,
              formData?.systemic_examination?.type_of_breathing
            )}
            {getLabelTagInput(
              "Other details",
              formData?.systemic_examination?.type_of_breathing_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.ausculation) {
      respiratorySystemSummaryItems.push({
        key: "2",
        label: "Auscultation",
        children: (
          <>
            {getLabelTag(
              auscultationOptions,
              formData?.systemic_examination?.ausculation
            )}
            {getLabelTagsWithSelectItems(
              abnormalBreathZoneOptions,
              breathSoundsOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    return respiratorySystemSummaryItems;
  };
  const constructMusculoSkeletalSummaryItems = () => {
    const getRomSummary = (list, data) => {
      const enteredOptions = list.reduce((acc, item) => {
        const filtered = Object.entries(data).filter(([key, value]) =>
          key.endsWith(item)
        );
        return [...acc, ...filtered];
      }, []);

      return enteredOptions?.length === 0
        ? null
        : enteredOptions?.map(([key, value]) =>
            getLabelTagInput(
              key
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" "),
              value
            )
          );
    };
    const musculoSkeletalSummaryItems = [];
    if (formData?.systemic_examination?.limb_deformity) {
      musculoSkeletalSummaryItems.push({
        key: "1",
        label: "Limb Deformity",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.limb_deformity)}
            {getLabelTagsWithDescription(
              limbDeformityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.fractures) {
      musculoSkeletalSummaryItems.push({
        key: "2",
        label: "Fractures",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.fractures)}
            {getFractureSummary(
              fractureAreasOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.skull) {
      musculoSkeletalSummaryItems.push({
        key: "3",
        label: "Skull",
        children: (
          <>
            {getLabelTag(skullOptions, formData?.systemic_examination?.skull)}
            {getLabelTagInput(
              "Description",
              formData?.systemic_examination?.skull_description
            )}
          </>
        ),
      });
    }
    if (
      getLabelTagsWithDescription(spineOptions, formData?.systemic_examination)
    ) {
      musculoSkeletalSummaryItems.push({
        key: "4",
        label: "Spine",
        children: getLabelTagsWithDescription(
          spineOptions,
          formData?.systemic_examination
        ),
      });
    }
    if (formData?.systemic_examination?.joint_pain) {
      musculoSkeletalSummaryItems.push({
        key: "5",
        label: "Joint Pain",
        children: (
          <>
            {getLabelTag(
              noneYesOptions,
              formData?.systemic_examination?.joint_pain
            )}
            {getLabelTagsWithCheckGroup(
              jointPainLocationOptions,
              jointPainSeverityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.contractures) {
      musculoSkeletalSummaryItems.push({
        key: "6",
        label: "Contractures",
        children: (
          <>
            {getLabelTagYesNO(formData?.systemic_examination?.contractures)}
            {/* Elbow: */}
            {getLabelTagInput(
              "Elbow",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_elbow
              )
            )}
            {/* Wrist: */}
            {getLabelTagInput(
              "Wrist",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_wrist
              )
            )}
            {/* Shoulder: */}
            {getLabelTagInput(
              "Shoulder",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_shoulder
              )
            )}
            {/* Hip: */}
            {getLabelTagInput(
              "Hip",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_hip
              )
            )}
            {/* Knee: */}
            {getLabelTagInput(
              "Knee",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_knee
              )
            )}
            {/* Ankle: */}
            {getLabelTagInput(
              "Ankle",
              getLabelTag(
                leftRightOptions,
                formData?.systemic_examination?.contractures_ankle
              )
            )}
          </>
        ),
      });
    }

    if (formData?.systemic_examination?.rom) {
      musculoSkeletalSummaryItems.push({
        key: "9",
        label: "ROM",
        children: (
          <>
            {formData?.systemic_examination?.rom?.shoulder_joint &&
              getLabelTagInput(
                "Shoulder Joint",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "abduction",
                    "adduction",
                    "medial_rotation",
                    "lateral_rotation",
                  ],
                  formData?.systemic_examination?.rom?.shoulder_joint
                )
              )}
            {formData?.systemic_examination?.rom?.elbow_joint &&
              getLabelTagInput(
                "Elbow Joint",
                getRomSummary(
                  ["flexion", "extension"],
                  formData?.systemic_examination?.rom?.elbow_joint
                )
              )}
            {formData?.systemic_examination?.rom?.forearm_joint &&
              getLabelTagInput(
                "Forearm Joint",
                getRomSummary(
                  ["supination", "pronation"],
                  formData?.systemic_examination?.rom?.forearm_joint
                )
              )}
            {formData?.systemic_examination?.rom?.wrist_joint &&
              getLabelTagInput(
                "Wrist Joint",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "radial_deviation",
                    "ulnar_deviation",
                  ],
                  formData?.systemic_examination?.rom?.wrist_joint
                )
              )}
            {formData?.systemic_examination?.rom?.thumb_joint &&
              getLabelTagInput(
                "Thumb Joint",
                getRomSummary(
                  [
                    "cmc_flexion",
                    "cmc_extension",
                    "cmc_abduction",
                    "ip_flexion",
                  ],
                  formData?.systemic_examination?.rom?.thumb_joint
                )
              )}
            {formData?.systemic_examination?.rom?.metacarpel_joint &&
              getLabelTagInput(
                "Meta Carpel",
                getRomSummary(
                  ["flexion", "hyperextension", "abduction"],
                  formData?.systemic_examination?.rom?.metacarpel_joint
                )
              )}
            {formData?.systemic_examination?.rom?.proximal_joint &&
              getLabelTagInput(
                "Proximal Interphalangeal Joint",
                getRomSummary(
                  ["flexion"],
                  formData?.systemic_examination?.rom?.proximal_joint
                )
              )}
            {formData?.systemic_examination?.rom?.distal_joint &&
              getLabelTagInput(
                "Disital Interphalangeal Joint",
                getRomSummary(
                  ["flexion", "hyperextension"],
                  formData?.systemic_examination?.rom?.distal_joint
                )
              )}
            {formData?.systemic_examination?.rom?.hip_joint &&
              getLabelTagInput(
                "Hip Joint",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "abduction",
                    "adduction",
                    "medial_rotation",
                    "lateral_rotation",
                  ],
                  formData?.systemic_examination?.rom?.hip_joint
                )
              )}
            {formData?.systemic_examination?.rom?.knee_joint &&
              getLabelTagInput(
                "Knee Joint",
                getRomSummary(
                  ["flexion", "extension"],
                  formData?.systemic_examination?.rom?.knee_joint
                )
              )}
            {formData?.systemic_examination?.rom?.ankle_joint &&
              getLabelTagInput(
                "Ankle Joint",
                getRomSummary(
                  ["dorsiflexion", "plantarflexion"],
                  formData?.systemic_examination?.rom?.ankle_joint
                )
              )}
            {formData?.systemic_examination?.rom?.foot_joint &&
              getLabelTagInput(
                "Foot Joint",
                getRomSummary(
                  ["inversion", "eversion"],
                  formData?.systemic_examination?.rom?.foot_joint
                )
              )}
            {formData?.systemic_examination?.rom?.great_toe_joint &&
              getLabelTagInput(
                "Great Toe Joint",
                getRomSummary(
                  ["flexion", "extension"],
                  formData?.systemic_examination?.rom?.great_toe_joint
                )
              )}
            {formData?.systemic_examination?.rom?.inter_phalangeal_joint &&
              getLabelTagInput(
                "Inter Phalangeal Joint",
                getRomSummary(
                  ["flexion", "extension"],
                  formData?.systemic_examination?.rom?.inter_phalangeal_joint
                )
              )}
            {formData?.systemic_examination?.rom?.cervical_spine &&
              getLabelTagInput(
                "Cervical Spine",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "right_rotation",
                    "left_rotation",
                    "right_lateral_rotation",
                    "left_lateral_rotation",
                  ],
                  formData?.systemic_examination?.rom?.cervical_spine
                )
              )}
            {formData?.systemic_examination?.rom?.thoraco_lumbar_spine &&
              getLabelTagInput(
                "Thoraco Lumbar Spine",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "right_rotation",
                    "left_rotation",
                    "right_lateral_rotation",
                    "left_lateral_rotation",
                  ],
                  formData?.systemic_examination?.rom?.thoraco_lumbar_spine
                )
              )}
            {getLabelTagInput(
              "Comments",
              formData?.systemic_examination?.rom?.comments
            )}
          </>
        ),
      });
    }
    return musculoSkeletalSummaryItems;
  };

  const constructCentralNervousSystemSummaryItems = () => {
    const centralNervousSystemSummaryItems = [];

    if (formData?.systemic_examination?.mental_status) {
      centralNervousSystemSummaryItems.push({
        key: "1",
        label: "Mental Status",
        children: (
          <>
            {getLabelTag(
              mentalStatusOptions,
              formData?.systemic_examination?.mental_status
            )}
            {getLabelTag(
              mentalAbnormalOptions,
              formData?.systemic_examination?.mental_status_details
            )}
            {getLabelTagInput(
              "Others details",
              formData?.systemic_examination?.mental_status_details_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.gait) {
      centralNervousSystemSummaryItems.push({
        key: "2",
        label: "Gait",
        children: (
          <>
            {getLabelTag(gaitOptions, formData?.systemic_examination?.gait)}
            {getLabelTag(
              abnormalGaitOptions,
              formData?.systemic_examination?.gait_details
            )}
            {getLabelTagInput(
              "Others Details",
              formData?.systemic_examination?.gait_details_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.posture) {
      centralNervousSystemSummaryItems.push({
        key: "3",
        label: "Posture",
        children: (
          <>
            {getLabelTag(
              postureOptions,
              formData?.systemic_examination?.posture
            )}
            {getLabelTagInput(
              "Others details",
              formData?.systemic_examination?.posture_others
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.berg_scale?.status) {
      centralNervousSystemSummaryItems.push({
        key: "4",
        label: "Berg Balance Scale",
        children: (
          <>
            {getLabelTag(
              notAssessableAndAssessableOptions,
              formData?.systemic_examination?.berg_scale?.status
            )}
            {getLabelScalesTags(
              bergScaleOptions,
              formData?.systemic_examination?.berg_scale
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.cranial_nerves) {
      centralNervousSystemSummaryItems.push({
        key: "5",
        label: "Cranial Nerves",
        children: (
          <>
            {getLabelTag(
              cranialNervesOptions,
              formData?.systemic_examination?.cranial_nerves
            )}
            {getLabelTagsWithRadioOptions(
              cranialNervesAbnormalOptions,
              cranialNerveSeverityOptions,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.sensory_system) {
      centralNervousSystemSummaryItems.push({
        key: "6",
        label: "Sensory System",
        children: (
          <>
            {getLabelTag(
              sensorySystemOptions,
              formData?.systemic_examination?.sensory_system
            )}
            {/* Sensation: */}
            {getLabelTagInput(
              "Sensation",

              getLabelTagsWithRadioOptions(
                sensorySystemAbnormalOptions,
                sensoryExtremitiesOptions,
                formData?.systemic_examination
              )
            )}

            {/* Tactile Localization: */}
            {/* 2 Point Discrimination: */}
            {getLabelTagInput(
              "Tactile Localization\n2 point Discrimination",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.two_point_discrimination
              )
            )}
            {/* Stereognosis: */}
            {getLabelTagInput(
              "Stereognosis",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.stereognosis
              )
            )}
            {/* Graphesthesia: */}
            {getLabelTagInput(
              "Graphesthesia",
              getLabelTag(
                normalAbsentOptions,
                formData?.systemic_examination?.graphesthesia
              )
            )}
            {/* Romberg's Sign: */}
            {getLabelTagInput(
              "Romberg's Sign",
              getLabelTag(
                positiveNegativeOptions,
                formData?.systemic_examination?.rombergs_sign
              )
            )}
          </>
        ),
      });
    }
    if (formData?.systemic_examination?.motor_system) {
      centralNervousSystemSummaryItems.push({
        key: "7",
        label: "Motor System",
        children: (
          <>
            {getLabelTag(
              motorSystemOptions,
              formData?.systemic_examination?.motor_system
            )}
            {getLabelTagsWithRadioOptions(
              motorSystemAbnormalOptions,
              motorAbnormalRadioItems,
              formData?.systemic_examination
            )}
          </>
        ),
      });
    }
    if (getReflexesSummary()) {
      centralNervousSystemSummaryItems.push({
        key: "8",
        label: "Reflexes",
        children: getReflexesSummary(),
      });
    }
    if (formData?.systemic_examination?.coordination) {
      centralNervousSystemSummaryItems.push({
        key: "9",
        label: "Co-ordination",
        children: (
          <>
            {getLabelTag(
              coordinationOptions,
              formData?.systemic_examination?.coordination
            )}
            {getLabelTagInput(
              "Finger-to-nose test",
              formData?.systemic_examination?.finger_to_nose_test
            )}
            {getLabelTagInput(
              "Finger-to-finger test",
              formData?.systemic_examination?.finger_to_finger_test
            )}
            {getLabelTagInput(
              "Adiadokokinesia or dysdiadokokinesia",
              formData?.systemic_examination?.adiadokokinesia
            )}
            {getLabelTagInput(
              "Rebound phenomena",
              formData?.systemic_examination?.rebound_phenomena
            )}
            {getLabelTagInput(
              "Walking along a straight line",
              formData?.systemic_examination?.walking_along_a_straight_line
            )}
            {getLabelTagInput(
              "Rombergs test",
              formData?.systemic_examination?.rombergs_test
            )}
            {getLabelTagInput(
              "Heel on shin test",
              formData?.systemic_examination?.heel_on_shin_test
            )}
          </>
        ),
      });
    }

    if (formData?.systemic_examination?.tcms?.status) {
      centralNervousSystemSummaryItems.push({
        key: "10",
        label: "TCMS",
        children: (
          <>
            {getLabelTag(
              notAssessableAndAssessableOptions,
              formData?.systemic_examination?.tcms?.status
            )}
            {getLabelTagInput(
              "Score",
              formData?.systemic_examination?.tcms?.score
            )}
            {getLabelTagInput(
              "Interpretation",
              formData?.systemic_examination?.tcms?.interpretation
            )}
            {getLabelTagInput(
              "Static Sitting Balance",
              formData?.systemic_examination?.tcms?.sitting_balance
            )}
            {getLabelTagInput(
              "Selective Movement Control",
              formData?.systemic_examination?.tcms?.selective_movement_control
            )}
            {getLabelTagInput(
              "Dynamic Reaching",
              formData?.systemic_examination?.tcms?.sitting_to_standing
            )}
          </>
        ),
      });
    }
    return centralNervousSystemSummaryItems;
  };

  const skinSummaryItems = constructskinSummaryItems();
  const cardioVascularSystemSummaryItems =
    constructCardioVascularSystemSummaryItems();
  const respiratorySystemSummaryItems =
    constructRespiratorySystemSummaryItems();
  const musculoSkeletalSummaryItems = constructMusculoSkeletalSummaryItems();
  const centralNervousSystemSummaryItems =
    constructCentralNervousSystemSummaryItems();
  return (
    <div>
      {/* <Typography.Title level={5}>Systemic Examination</Typography.Title> */}
      {skinSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image src={skinIcon} width={"18px"} preview={false} />
                <span>Skin</span>
              </Space>
            </span>
          }
          column={1}
          items={skinSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {cardioVascularSystemSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image width="18px" src={cardioIcon} preview={false} />
                <span>Cardio Vascular System</span>
              </Space>
            </span>
          }
          column={1}
          items={cardioVascularSystemSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {respiratorySystemSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image width="18px" src={respiratoryIcon} preview={false} />
                <span>Respiratory System</span>
              </Space>
            </span>
          }
          column={1}
          items={respiratorySystemSummaryItems}
          className="initial-insp-desc"
        />
      )}

      {musculoSkeletalSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image
                  src={musculoSkeletalIcon}
                  width={"18px"}
                  preview={false}
                />
                <span>Musculo Skeletal</span>
              </Space>
            </span>
          }
          column={1}
          items={musculoSkeletalSummaryItems}
          className="initial-insp-desc"
        />
      )}
      {centralNervousSystemSummaryItems.length > 0 && (
        <Descriptions
          bordered
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image
                  width="18px"
                  src={centralNervousSystemIcon}
                  preview={false}
                />
                <span>Central Nervous System</span>
              </Space>
            </span>
          }
          column={1}
          items={centralNervousSystemSummaryItems}
          className="initial-insp-desc"
        />
      )}
    </div>
  );
};

export default PhysioSystemicExaminationSummary;
