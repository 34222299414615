import React, { useEffect, useState } from "react";
import PastMedicalHistory from "./PastMedicalHistory/PastMedicalHistory";
import { NutritionistInitialContext } from "./NutritionistInitialAssessmentProvider";
import { Divider } from "antd";
import FoodAllergies from "./KnownAllergies/FoodAllergies";
import NutritionistFunctionalCapacity from "./FunctionalCapacity/NutritionistFunctionalCapacity";
import MetabolicStress from "./MetabolicStress/MetabolicStress";
import AppetitePriorAdmission from "./AppetitePriorAdmission/AppetitePriorAdmission";
import GLSymptoms from "./GLSymptoms/GLSymptoms";
import { getDoctorInspectionAPI } from "../../../../services/doctor.services";

const NutritionistHistoryForm = ({ form, formData, patientData, editId }) => {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const setHistoryData = async () => {
      if (!editId) {
        try {
          const response = await getDoctorInspectionAPI(
            patientData?.doctor_intial_assessment
          );
          const historyData = response?.data?.data?.history || {};
          form.setFieldsValue(historyData);
        } catch (error) {
          console.log(`Error:`, error);
        }
      } else {
        form.setFieldsValue(formData?.history || {});
      }
    };
    setHistoryData();
    setTimeout(() => setRefresh((prev) => !prev), 1000);
  }, [formData]);
  return (
    <>
      <PastMedicalHistory context={NutritionistInitialContext} form={form} />
      <Divider />
      <FoodAllergies context={NutritionistInitialContext} form={form} />
      <Divider />
      <NutritionistFunctionalCapacity form={form} />
      <Divider />
      <MetabolicStress form={form} />
      <Divider />
      <AppetitePriorAdmission form={form} />
      <Divider />
      <GLSymptoms context={NutritionistInitialContext} form={form} />
    </>
  );
};

export default NutritionistHistoryForm;
