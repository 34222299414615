import { Col, Form, Input, Radio, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../../Options/commonOptions";
import TCMItems from "../../PhysioScales/TCMScaleComponents/TCMItems";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import { checkEmpty } from "../../../../../../helpers/utility";

const TCMAssessment = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);

  const [states, setStates] = useState({});
  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    setStates(
      Object?.fromEntries(
        Object?.entries(formData?.systemic_examination?.tcms || {})?.filter(
          ([key]) =>
            [
              "selective_movement_control",
              "sitting_balance",
              "sitting_to_standing",
            ]?.includes(key)
        )
      ) || {}
    );
  }, [formData]);
  const calcScore = () => {
    const sum = Object.values(states).reduce((acc, cur) => acc + cur, 0);
    form.setFieldValue(["tcms", "score"], sum);
    form.setFieldValue(
      ["tcms", "interpretation"],
      sum === 58 ? "Normal Trunk Balance" : "Poor Trunk Balance"
    );
    return sum;
  };
  const score = calcScore();
  const interpretation =
    score === 58 ? "Normal Trunk Balance" : "Poor Trunk Balance";
  const showTcms = form.getFieldValue(["tcms", "status"]) === "assessable";
  return (
    <>
      <Typography>
        <Row>
          <Col span={10}>Trunk Control Management Scale</Col>
          <Col span={14}>
            {showTcms && checkEmpty(states) && (
              <>
                {score}/58 : {interpretation}
              </>
            )}
          </Col>
        </Row>
      </Typography>
      <Form.Item name={["tcms", "score"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={["tcms", "interpretation"]} hidden>
        <Input />
      </Form.Item>

      <DeselectableRadioGroup
        name={["tcms", "status"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {showTcms && (
        <div className="subsection-card">
          <TCMItems handleChange={handleChange} nameKey={"tcms"} />
        </div>
      )}
    </>
  );
};

export default TCMAssessment;
