import axiosInstance from "../config/axios.config";

export const physioInspectionAPI = (payload) => {
  return axiosInstance.post(`inpatient/physio-initial-assessment/`, payload);
};
export const updatePhysioInspectionAPI = (id, payload) => {
  return axiosInstance.patch(
    `inpatient/physio-initial-assessment/${id}/`,
    payload
  );
};

export const getPhysioInspectionAPI = (admisssionId) => {
  return axiosInstance.get(
    `inpatient/physio-initial-assessment/${admisssionId}/`
  );
};

export const physioReviewAPI = (payload) => {
  return axiosInstance.post(`inpatient/physio-review/`, payload);
};
export const updatePhysioReviewAPI = (id, payload) => {
  return axiosInstance.patch(`inpatient/physio-review/${id}/`, payload);
};

export const getPhysioReviewAPI = (admisssionId) => {
  return axiosInstance.get(`inpatient/physio-review/${admisssionId}/`);
};

export const getLatestPhysioReviewAPI = (admissionId) => {
  return axiosInstance.get(
    `inpatient/latest-physio-review/?admission_id=${admissionId}`
  );
};

export const gcScaleAPI = (payload) => {
  return axiosInstance.post("/scales/gcs/", payload);
};

export const getGcScaleAPI = (admissionId, id) => {
  return axiosInstance.get(`/scales/gcs/?ip_admission=${admissionId}&id=${id}`);
};

export const updateGcScaleApi = (id, payload) => {
  return axiosInstance.patch(`/scales/gcs/${id}/`, payload);
};

export const nihsScaleAPI = (payload) => {
  return axiosInstance.post("/scales/nihss/", payload);
};

export const getNihsScaleAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/nihss/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateNihsScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/nihss/${id}/`, payload);
};

export const mocaScaleAPI = (payload) => {
  return axiosInstance.post("/scales/moca/", payload);
};

export const getMocaScaleAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/moca/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateMocaScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/moca/${id}/`, payload);
};

export const rasScaleAPI = (payload) => {
  return axiosInstance.post("/scales/rass/", payload);
};

export const getRasScaleAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/rass/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateRasScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/rass/${id}/`, payload);
};

export const vaScaleAPI = (payload) => {
  return axiosInstance.post("/scales/vas/", payload);
};

export const getVaScaleApi = (admissionId, id) => {
  return axiosInstance.get(`/scales/vas/?ip_admission=${admissionId}&id=${id}`);
};

export const updateVaScaleApi = (id, payload) => {
  return axiosInstance.patch(`/scales/vas/${id}/`, payload);
};

export const maScaleAPI = (payload) => {
  return axiosInstance.post("/scales/mas/", payload);
};

export const getMaScaleAPI = (admissionId, id) => {
  return axiosInstance.get(`/scales/mas/?ip_admission=${admissionId}&id=${id}`);
};

export const updateMaScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/mas/${id}/`, payload);
};

export const mmtScaleAPI = (payload) => {
  return axiosInstance.post("/scales/mmt/", payload);
};

export const getMmtScaleAPI = (admissionId, id) => {
  return axiosInstance.get(`/scales/mmt/?ip_admission=${admissionId}&id=${id}`);
};

export const updateMmtScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/mmt/${id}/`, payload);
};

export const vcgScaleAPI = (payload) => {
  return axiosInstance.post("/scales/vcg/", payload);
};

export const getVcgScaleAPI = (admissionId, id) => {
  return axiosInstance.get(`/scales/vcg/?ip_admission=${admissionId}&id=${id}`);
};

export const updateVcgScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/vcg/${id}/`, payload);
};

export const rpeScaleAPI = (payload) => {
  return axiosInstance.post("/scales/rpe/", payload);
};

export const getRpeScaleAPI = (admissionId, id) => {
  return axiosInstance.get(`/scales/rpe/?ip_admission=${admissionId}&id=${id}`);
};

export const updateRpeScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/rpe/${id}/`, payload);
};

export const reflexesAPI = (payload) => {
  return axiosInstance.post("/scales/reflexes/", payload);
};

export const getReflexesAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/reflexes/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateReflexesAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/reflexes/${id}/`, payload);
};

export const peakFlowMeterAPI = (payload) => {
  return axiosInstance.post("/scales/peak-flow/", payload);
};

export const getPeakFlowMeterAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/peak-flow/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updatePeakFlowMeterAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/peak-flow/${id}/`, payload);
};

export const sixMinuteWalkAPI = (payload) => {
  return axiosInstance.post("/scales/six-min-walk/", payload);
};

export const getSixMinuteWalkAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/six-min-walk/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateSixMinuteWalkAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/six-min-walk/${id}/`, payload);
};

export const coordinationAPI = (payload) => {
  return axiosInstance.post("/scales/coordination-test/", payload);
};

export const getCoordinationAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/coordination-test/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateCoordinationAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/coordination-test/${id}/`, payload);
};

export const fingerGripAPI = (payload) => {
  return axiosInstance.post("/scales/finger-grip/", payload);
};

export const getFingerGripAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/finger-grip/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateFingerGripAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/finger-grip/${id}/`, payload);
};

export const barthelIndexAPI = (payload) => {
  return axiosInstance.post("/scales/barthel-index/", payload);
};

export const getBarthelIndexAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/barthel-index/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateBarthelIndexAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/barthel-index/${id}/`, payload);
};

export const tcmScaleApi = (payload) => {
  return axiosInstance.post("/scales/trunk-control/", payload);
};

export const getTcmScaleAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/trunk-control/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateTcmScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/trunk-control/${id}/`, payload);
};

export const romScaleApi = (payload) => {
  return axiosInstance.post("/scales/rom/", payload);
};

export const getRomScaleAPI = (admissionId, id) => {
  return axiosInstance.get(`/scales/rom/?ip_admission=${admissionId}&id=${id}`);
};

export const updateRomScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/rom/${id}/`, payload);
};

export const asiaScaleApi = (payload) => {
  return axiosInstance.post("/scales/asia/", payload);
};

export const getAsiaScaleAPI = (admissionId, id) => {
  return axiosInstance.get(
    `/scales/asia/?ip_admission=${admissionId}&id=${id}`
  );
};

export const updateAsiaScaleAPI = (id, payload) => {
  return axiosInstance.patch(`/scales/asia/${id}/`, payload);
};

export const physioCareAPI = (payload) => {
  return axiosInstance.post("/inpatient/physio-care-items/", payload);
};

export const getPhysioCareBySession = (date, admissionId) => {
  return axiosInstance.get(
    `/inpatient/physio-care-items/?session_date=${date}&admission_id=${admissionId}`
  );
};

export const getPhysioCareAPI = (id) => {
  return axiosInstance.get(`/inpatient/physio-care-items/${id}/`);
};

export const updatePhysioCareAPI = (id, payload) => {
  return axiosInstance.patch(`/inpatient/physio-care-items/${id}/`, payload);
};

export const deletePhysioCareAPI = (id) => {
  return axiosInstance.delete(`/inpatient/physio-care-items/${id}/`);
};

export const getTherapiesAPI = () => {
  return axiosInstance.get(`/careservices/therapies/`);
};

export const getTherapiesBySearchAPI = (search) => {
  return axiosInstance.get(`/careservices/therapies/search/?search=${search}`);
};

export const therapiesAPI = (payload) => {
  return axiosInstance.post(`/careservices/therapies/`, payload);
};

export const getScalesDataAPI = (id) => {
  return axiosInstance.get(`/scales/progression-scales-counts/${id}/`);
};

export const getGraphDataAPI = (id, key) => {
  return axiosInstance.get(
    `/scales/progression-scales-data/${id}/?model=${key}`
  );
};

export const opServiceAPI = (service, payload) => {
  return axiosInstance.post(`outpatient/${service}/`, payload);
};

export const getOpHistoryAPI = (patientId, admissionId) => {
  return axiosInstance.get(
    `outpatient/emr/${patientId}/admission/${admissionId}/`
  );
};

export const getServiceAPI = (service, id) => {
  return axiosInstance.get(`outpatient/${service}/${id}/`);
};

export const getPhysioPractitionerListApi = (params) => {
  return axiosInstance.get(
    `accounts/practitioners-list/?facility=${params}&role=Physiotherapist`
  );
};
