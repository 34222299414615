import { Button, Col, DatePicker, message, Row, Select, Spin } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Role } from "../../../../constants/defaultKeys";
import { UserContext } from "../../../../context/UserContext";
import { getCookie } from "../../../../helpers/localStorage";
import useQuery from "../../../../hooks/useQuery";
import {
  downloadEmrApi,
  getEmrDownloadApi,
} from "../../../../services/discharge.services";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { DownloadOutlined } from "@ant-design/icons";
import { rolesInfo } from "../../../../helpers/utility";

const dateFormat = "YYYY-MM-DD";
const EmrDownload = () => {
  const { userDetails } = useContext(UserContext);
  const curDate = dayjs().format(dateFormat);
  const [data, setData] = useState(null);
  const [date, setDate] = useState(curDate);
  const params = useQuery();
  const role = getCookie(Role) || userDetails?.currRole;
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Doctor");

  useEffect(() => {
    setLoading(true);
    getEmrDownloadApi(
      params?.patient,
      `date=${date}&practitioner=${selectedRole}`
    )
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [date, selectedRole]);

  const disabledDate = (current) => current && current > dayjs().endOf("day");

  const onDateChange = (date, dateString) => {
    setDate(date ? dateString : null);
  };

  const download = () => {
    setBtnLoading(true);
    downloadEmrApi(
      params?.patient,
      `date=${date}&practitioner=${selectedRole}&response_type=file`
    )
      .then((response) => {
        console.log("Download Response:", response); // Debugging step
        if (response?.data?.link) {
          const link = document.createElement("a");
          link.href = response.data.link;
          link.setAttribute("target", "_blank"); // Open link in a new tab if necessary
          link.download = `${selectedRole}_emr_${date}.pdf`; // Name the file
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          message.error("Failed to retrieve the download link.");
        }
      })
      .catch((error) => {
        message.error("Download failed. Please try again.");
        console.error("Download Error:", error);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const userRoles = ["Doctor", "Nurse", "Physiotherapist"];
  return (
    <Row>
      <Col span={24} className="flex-end">
        <Select
          style={{ width: "150px", marginRight: "10px" }}
          className={inputStyles.mdSelect}
          onChange={(val) => setSelectedRole(val)}
          defaultValue={selectedRole}
        >
          {userRoles?.map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>
        <DatePicker
          style={{
            // color: "#8c8c8c",
            border: "1px solid #a182e3",
            height: "38px",
            width: "150px",
          }}
          disabledDate={disabledDate}
          className={inputStyles.mdDateInput}
          onChange={onDateChange}
          defaultValue={dayjs(curDate)}
          allowClear={false}
        />
        <Button
          style={{ marginLeft: "10px" }}
          icon={<DownloadOutlined />}
          type="primary"
          size="large"
          onClick={download}
          loading={btnLoading}
        >
          Export
        </Button>
      </Col>
      <Col span={24}>
        {loading ? (
          <Spin />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          ></div>
        )}
      </Col>
    </Row>
  );
};

export default EmrDownload;
