import React, { useState } from "react";
import { Form, InputNumber, Typography } from "antd";
import {
  alcoholOptions,
  educationalOptions,
  maritalStatusOptions,
  smokingOptions,
} from "../../../Options/historyOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const { Text } = Typography;

const PersonalHistory = ({ form }) => {
  const [refresh, setRefresh] = useState(false);
  const smoking = form.getFieldValue("smoking");
  return (
    <>
      <Text>Personal History</Text>
      <div className="subsection-card">
        <DeselectableRadioGroup
          label="Marital Status"
          name="marital_status"
          options={maritalStatusOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label="Education"
          name="education"
          options={educationalOptions}
          form={form}
        />
        <DeselectableRadioGroup
          label="Smoking"
          name="smoking"
          options={smokingOptions}
          form={form}
          setRefresh={setRefresh}
        />
        {smoking === "smoker" && (
          <Form.Item name={"smoking_since"}>
            <InputNumber
              placeholder="Smoking since no of years?"
              style={{ width: 200 }}
            />
          </Form.Item>
        )}
        {smoking === "stopped_smoking" && (
          <Form.Item name={"stopped_smoking_since"}>
            <InputNumber
              placeholder="Stopped since no of years?"
              style={{ width: 200 }}
            />
          </Form.Item>
        )}
        <DeselectableRadioGroup
          label="Alcohol"
          name="alcohol"
          options={alcoholOptions}
          form={form}
        />
      </div>
    </>
  );
};

export default PersonalHistory;
