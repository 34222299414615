import { Checkbox, Form } from "antd";
import React from "react";
import { neuroHistoryOptions } from "../OpPhysioOptions";

const NeuroHistory = () => {
  return (
    <>
      <Form.Item label="History" name={"history"}>
        <Checkbox.Group options={neuroHistoryOptions} />
      </Form.Item>
    </>
  );
};

export default NeuroHistory;
