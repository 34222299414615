import axiosInstance from "../config/axios.config";

export const partnerOnboardApi = (payload) => {
  return axiosInstance.post("accounts/partner-onboard/", payload);
};
export const getPartnerListApi = () => {
  return axiosInstance.get("accounts/list-partners/");
};
export const updatepartnerOnboardApi = (partner_id, payload) => {
  return axiosInstance.patch(`accounts/update-partner/${partner_id}/`, payload);
};
