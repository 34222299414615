import React, { useRef, useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Space,
  Input,
  Button,
  Row,
  Col,
  Select,
  Typography,
  message,
  InputNumber,
} from "antd";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import inputStyles from "../../../../styles/Custom/Input.module.css";
import { validateConfig } from "../../../../config/validate.config";
import FacilityServiceModal from "./FacilityServiceModal";
import { getFacilityListApi } from "../../../../services/facilities.services";
import {
  createServiceApi,
  getCategoryApi,
  updateServiceApi,
} from "../../../../services/service.services";

const { Option } = Select;
const ServiceForm = ({ open, setOpen, refreshPage, editListData }) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [facilities, setFacilities] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [categories, setCategories] = useState(null);
  const [facilityPrice, setFacilityPrice] = useState(null);
  const priceTagList = [
    { name: "With tax", id: true },
    { name: "Without tax", id: false },
  ];
  useEffect(() => {
    getFacilityListApi()
      .then((res) => {
        setFacilities(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCategoryApi()
      .then((res) => setCategories(res?.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (editListData) {
      form.setFieldsValue({
        ...editListData,
        category: editListData?.category?.id,
      });
    }
  }, []);

  const onFinish = (values) => {
    setBtnLoading(true);
    const formData = {
      ...values,
      facility_services: facilityPrice
        ? facilityPrice
        : editListData?.id
        ? editListData?.facility_services
        : facilities?.map((facility) => ({
            is_available: true,
            facility: facility?.id,
            cash_withtax: false,
            insurance_withtax: false,
            cash_price: values?.cash_price,
            insurance_price: values?.insurance_price,
          })),
    };

    if (editListData) {
      updateServiceApi(editListData?.id, formData)
        .then((res) => {
          message?.success(res?.data?.message);
          setOpen(false);
          refreshPage();
          setBtnLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
          setBtnLoading(false);
        });
    } else {
      createServiceApi(formData)
        .then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refreshPage();
          setBtnLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.message || "Something went wrong");
          setBtnLoading(false);
        });
    }
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        maskClosable={false}
        open={open}
        width={"30%"}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editListData ? "Edit Service" : " Add Service"}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined onClick={onClose} />
            </Col>
          </Row>
        }
        footer={
          <div style={{ textAlign: "right" }}>
            <Space>
              {facilities?.length > 1 && (
                <Form.Item>
                  <Button
                    // loading={btnLoading}
                    className={btnStyles.lgBtn}
                    type="primary"
                    htmlType="submit"
                    ghost
                    onClick={() => {
                      setVisible(true);
                      // if (formRef.current) {
                      //   formRef.current.submit();
                      // }
                    }}
                  >
                    <SettingOutlined /> Facilitywise Availability and Pricing
                  </Button>
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className={btnStyles.lgBtn}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.submit();
                    }
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </div>
        }
        onClose={onClose}
        closeIcon={null}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form
              ref={formRef}
              form={form}
              onFinish={onFinish}
              // style={{ maxWidth: 600 }}
              autoComplete="off"
              layout="vertical"
              // requiredMark={false}
              onValuesChange={(allValues, changedValues) =>
                setFormValues({ ...allValues, ...changedValues })
              }
              initialValues={{
                cash_withtax: false,
                insurance_withtax: false,
                tax: 0,
              }}
            >
              <Form.Item
                label="Service Name"
                name="name"
                rules={validateConfig.isRequired("Service Name")}
              >
                <Input
                  placeholder="Enter service name"
                  className={inputStyles.mdInput}
                  style={{ width: "80%" }}
                />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  className={inputStyles.mdInput}
                  style={{ width: "80%" }}
                  rows={2}
                />
              </Form.Item>
              <Form.Item
                label="Category"
                name="category"
                rules={validateConfig.isRequired("Category")}
              >
                <Select
                  style={{ minWidth: "80%" }}
                  placeholder="Choose Category"
                  className={inputStyles.mdSelect}
                  optionFilterProp="children"
                >
                  {categories?.map((dept) => (
                    <Option value={dept?.id} key={dept?.id}>
                      {dept?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Space.Compact>
                <Form.Item
                  label="Cash Price"
                  name="cash_price"
                  rules={validateConfig.isRequired("Price")}
                >
                  <InputNumber
                    style={{ width: "250px" }}
                    placeholder="Price"
                    prefix="Rs"
                    className={`${inputStyles.mdNumberInput}`}
                  />
                </Form.Item>
                <Form.Item
                  label=" "
                  name="cash_withtax"
                  style={{ width: "50%" }}
                >
                  <Select className={inputStyles.mdSelect} defaultValue={false}>
                    {priceTagList?.map((data) => {
                      return (
                        <Select.Option key={data?.id} value={data?.id}>
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Space.Compact>
              <Space.Compact>
                <Form.Item
                  label="Insurance Price"
                  name="insurance_price"
                  rules={validateConfig.isRequired("Price")}
                >
                  <InputNumber
                    placeholder="Price"
                    style={{ width: "250px" }}
                    prefix="Rs"
                    className={`${inputStyles.mdNumberInput}`}
                  />
                </Form.Item>
                <Form.Item
                  label=" "
                  name="insurance_withtax"
                  style={{ width: "50%" }}
                >
                  <Select className={inputStyles.mdSelect} defaultValue={false}>
                    {priceTagList?.map((data) => {
                      return (
                        <Select.Option key={data?.id} value={data?.id}>
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Space.Compact>
              <Form.Item
                label="GST%"
                name="tax"
                // rules={validateConfig.isRequired("Price")}
              >
                <InputNumber
                  placeholder="Enter GST%"
                  // addonBefore="Rs"
                  style={{ width: "200px" }}
                  className={`${inputStyles.mdNumberInput}`}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
      {visible && (
        <FacilityServiceModal
          visible={visible}
          setVisible={setVisible}
          facilities={facilities}
          formValues={formValues}
          facilityPrice={facilityPrice}
          setFacilityPrice={setFacilityPrice}
          editListData={editListData}
        />
      )}
    </>
  );
};

export default ServiceForm;
