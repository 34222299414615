import { Col, Form, Input, InputNumber, Row, Typography } from "antd";
import React from "react";

const gripTest = [
  { ageMin: 10, ageMax: 11, weak: 12.6, strong: 22.4 },
  { ageMin: 12, ageMax: 13, weak: 19.4, strong: 31.2 },
  { ageMin: 14, ageMax: 15, weak: 28.5, strong: 44.3 },
  { ageMin: 16, ageMax: 17, weak: 32.6, strong: 52.4 },
  { ageMin: 18, ageMax: 19, weak: 35.7, strong: 55.5 },
  { ageMin: 20, ageMax: 24, weak: 36.8, strong: 56.6 },
  { ageMin: 25, ageMax: 29, weak: 37.7, strong: 57.5 },
  { ageMin: 30, ageMax: 34, weak: 36.0, strong: 55.8 },
  { ageMin: 35, ageMax: 39, weak: 35.8, strong: 55.6 },
  { ageMin: 40, ageMax: 44, weak: 35.5, strong: 55.3 },
  { ageMin: 45, ageMax: 49, weak: 34.7, strong: 54.5 },
  { ageMin: 50, ageMax: 54, weak: 32.9, strong: 50.7 },
  { ageMin: 55, ageMax: 59, weak: 30.7, strong: 48.5 },
  { ageMin: 60, ageMax: 64, weak: 30.2, strong: 48.0 },
  { ageMin: 65, ageMax: 69, weak: 28.2, strong: 44.0 },
  { ageMin: 70, ageMax: 99, weak: 21.3, strong: 35.0 },
];
const FingerGripItems = ({ form, handleChange, states }) => {
  const checkGripStrength = (grip) => {
    if (states.age && grip) {
      for (let group of gripTest) {
        if (states.age >= group.ageMin && states.age <= group.ageMax) {
          if (grip < group.weak) {
            return "Weak";
          } else if (grip > group.strong) {
            return "Strong";
          } else {
            return "Normal";
          }
        }
      }
    }
    return "N/A"; // Default if conditions are not met
  };

  const leftInterpretation = checkGripStrength(states?.left_hand);
  const rightInterpretation = checkGripStrength(states?.right_hand);

  return (
    <>
      <Form.Item label="Age" name="age">
        <InputNumber
          max={99}
          min={0}
          onChange={(value) => handleChange("age", value)}
          addonAfter="yrs"
          style={{ width: "24%" }}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Left Hand Grip Strength" name="left_hand">
            <InputNumber
              onChange={(value) => handleChange("left_hand", value)}
              max={200}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"left_hand_interpretation"} hidden>
            <Input />
          </Form.Item>
          {states.left_hand ? (
            <Typography>
              {leftInterpretation}
              {form.setFieldValue(
                "left_hand_interpretation",
                leftInterpretation
              )}
            </Typography>
          ) : null}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Right Hand Grip Strength" name="right_hand">
            <InputNumber
              onChange={(value) => handleChange("right_hand", value)}
              max={200}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"right_hand_interpretation"} hidden>
            <Input />
          </Form.Item>
          {states?.right_hand ? (
            <Typography>
              {rightInterpretation}
              {form.setFieldValue(
                "right_hand_interpretation",
                rightInterpretation
              )}
            </Typography>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default FingerGripItems;
