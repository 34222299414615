import axiosInstance from "../config/axios.config";
//add patients
export const addPatientApi = (payload) => {
  return axiosInstance.post(`accounts/add-patient/`, payload);
};
export const updatePatientApi = (id, payload) => {
  return axiosInstance.patch(`accounts/update-patient/${id}/`, payload);
};
export const getPatientListApi = (params) => {
  return axiosInstance.get(`accounts/patient-list/?${params}`);
};
//appointments
export const addAppointmentApi = (payload) => {
  return axiosInstance.post(`appointments/`, payload);
};
export const updateAppointmentApi = (payload) => {
  return axiosInstance.patch(`appointments/`, payload);
};
export const getAppointmentListApi = (params) => {
  return axiosInstance.get(`appointments/facility-appointments/?${params}`);
};
export const getPractitionerSearchApi = (params) => {
  return axiosInstance.get(`accounts/users/search/?${params}`);
};
export const getSlotsApi = (params) => {
  return axiosInstance.get(`appointments/?${params}`);
};
//ip admissions
export const addIpAdmissionApi = (payload) => {
  return axiosInstance.post(`appointments/ip-admissions/`, payload);
};
export const updateIpAdmissionApi = (ip_id, payload) => {
  return axiosInstance.patch(`appointments/ip-admissions/${ip_id}/`, payload);
};
export const getIpAdmissionApi = (params) => {
  return axiosInstance.get(`appointments/ip-admissions/list/?${params}`);
};
export const searchPatientsApi = (params) => {
  return axiosInstance.get(`/accounts/patients/search/?${params}`);
};
export const opSearchPatientsApi = (params) => {
  return axiosInstance.get(`/accounts/op-patients/search/?${params}`);
};
export const getPatientDetailsApi = (patient_id) => {
  return axiosInstance.get(`accounts/patient-details/${patient_id}/`);
};
export const getInPatientDetailsApi = (ip_id) => {
  return axiosInstance.get(`appointments/ip-admissions/${ip_id}/`);
};
export const getInPatientInvestigationsApi = (facility_id, params) => {
  return axiosInstance.get(
    `inpatient/patient-investigations/facility/${facility_id}/?${params}`
  );
};
//bills
export const addBillingApi = (id, payload) => {
  return axiosInstance.post(`billing/add/${id}/`, payload);
};
export const getBillsListApi = (params) => {
  return axiosInstance.get(`billing/list/?${params}`);
};
export const getBillDetailsApi = (id) => {
  return axiosInstance.get(`billing/op/${id}/`);
};
export const getIPBillDetailsApi = (id) => {
  return axiosInstance.get(`billing/view/ip/${id}/`);
};
export const addPaymentApi = (id, payload) => {
  return axiosInstance.post(`billing/payment/${id}/`, payload);
};
export const shareBillApi = (id, params) => {
  return axiosInstance.post(`billing/share/${id}/?${params}`);
};
export const updateBillApi = (id, payload) => {
  return axiosInstance.post(`billing/op/${id}/`, payload);
};
export const cancelBillApi = (id) => {
  return axiosInstance.delete(`billing/op/${id}/`);
};
export const generateIpBillApi = (id, payload) => {
  return axiosInstance.post(`billing/generate-bill/${id}/`, payload);
};
//services
export const getIpServicesListApi = (id) => {
  return axiosInstance.get(`/inpatient/service/patient/${id}/`);
};
export const assignIpServicesApi = (id, params, payload) => {
  return axiosInstance.post(
    `/inpatient/service/patient/${id}/?${params}`,
    payload
  );
};
export const updateIpServicesApi = (patient_id, payload) => {
  return axiosInstance.patch(
    `/inpatient/service/patient/update/${patient_id}/`,
    payload
  );
};
export const deleteIpServicesApi = (patient_service_id) => {
  return axiosInstance.delete(
    `/inpatient/service/patient/update/${patient_service_id}/`
  );
};
export const getPackageDetailsApi = (id) => {
  return axiosInstance.get(`/careservices/packages/${id}/`);
};
//Bed Management
export const getIpBedsListApi = (id, params) => {
  return axiosInstance.get(`inpatient/beds/${id}/?${params}`);
};
export const updateIpBedApi = (id, payload) => {
  return axiosInstance.patch(`appointments/ip-admissions/${id}/`, payload);
};
export const changeIpBedApi = (id, payload) => {
  return axiosInstance.patch(`inpatient/change-bed/${id}/`, payload);
};
export const viewIpBedApi = (id) => {
  return axiosInstance.get(`inpatient/view-beds/${id}/`);
};
//Packages
export const assignPackageApi = (id, payload) => {
  return axiosInstance.post(`inpatient/patient-package/${id}/`, payload);
};
export const stopPackageApi = (id, payload) => {
  return axiosInstance.patch(`inpatient/patient-package/stop/${id}/`, payload);
};
export const getPatientPackagesApi = (id) => {
  return axiosInstance.get(`inpatient/patient-package/${id}/`);
};

export const getPractitionerListApi = (params) => {
  return axiosInstance.get(`accounts/practitioners-list/?facility=${params}`);
};
export const printSummaryApi = (id, params) => {
  return axiosInstance.get(`billing/view/ip/${id}/?${params}`);
};
