import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Form, Input } from "antd";
import {
  cardioDiagnosisOptions,
  physioDiagnosisOptions,
  neuroDiagnosisOptions,
  orthoDiagnosisOptions,
  spinalCordOptions,
  sportsDiagnosisOptions,
  strokeOptions,
} from "../../Options/physioOptions";

const FunctionalDiagnosis = ({ context }) => {
  const { formData } = useContext(context);
  const [checkboxes, setCheckboxes] = useState({});
  const [showOthers, setShowOthers] = useState({});
  const [showSubOptions, setShowSubOptions] = useState({});
  const [showSubSubOptions, setShowSubSubOptions] = useState({});

  const getOptions = (category) => {
    const optionsMap = {
      cardio: cardioDiagnosisOptions,
      ortho: orthoDiagnosisOptions,
      sports: sportsDiagnosisOptions,
      neuro: neuroDiagnosisOptions,
    };
    return optionsMap[category] || [];
  };

  const getSubOptions = (condition) => {
    if (["stroke", "head_injury"].includes(condition)) return strokeOptions;
    if (condition === "spinal_cord_injury") return spinalCordOptions;
    return [];
  };

  const handleCheckboxChange = (value) => {
    setCheckboxes((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const handleSubCheckboxChange = (
    checkedValue,
    checked,
    mainCategory,
    subOption
  ) => {
    const isOthers = subOption.value === "others";

    if (isOthers) {
      setShowOthers((prev) => ({
        ...prev,
        [mainCategory]: checked,
      }));
    }

    if (
      ["stroke", "head_injury", "spinal_cord_injury"].includes(subOption.value)
    ) {
      setShowSubOptions((prev) => ({
        ...prev,
        [subOption.value]: checked,
      }));
    }
  };

  const handleSubSubCheckboxChange = (
    checkedValues,
    mainCategory,
    subOption
  ) => {
    const key = `${mainCategory}_${subOption.value}`;

    // Update the state for "others" key based on the selected values
    setShowSubSubOptions((prev) => ({
      ...prev,
      [subOption.value]: checkedValues.includes("others"), // true if "others" is selected
    }));
  };

  useEffect(() => {
    // if (!formData?.functional_diagnosis) return;

    const initialCheckboxes = physioDiagnosisOptions?.reduce((acc, option) => {
      acc[option.value] =
        formData?.functional_diagnosis?.[option.value] || false;
      return acc;
    }, {});
    setCheckboxes(initialCheckboxes);

    const initialShowOthers = physioDiagnosisOptions?.reduce((acc, option) => {
      acc[option.value] =
        !!formData?.functional_diagnosis?.[`${option.value}_description`];
      return acc;
    }, {});
    setShowOthers(initialShowOthers);

    const subOptions = neuroDiagnosisOptions?.reduce((acc, option) => {
      acc[option.value] =
        formData?.functional_diagnosis?.neuro_head_injury || false;
      return acc;
    }, {});
    setShowSubOptions(subOptions);

    const subSubOptions = [
      "stroke",
      "head_injury",
      "spinal_cord_injury",
    ].reduce((acc, key) => {
      acc[key] =
        !!formData?.functional_diagnosis?.[`neuro_${key}_options`]?.includes(
          "others"
        );
      return acc;
    }, {});
    setShowSubSubOptions(subSubOptions);
  }, [formData]);

  return (
    <>
      {physioDiagnosisOptions.map((mainOption) => (
        <>
          <Form.Item name={mainOption.value} valuePropName="checked">
            <Checkbox
              checked={checkboxes[mainOption.value]}
              onChange={() => handleCheckboxChange(mainOption.value)}
            >
              {mainOption.label}
            </Checkbox>
          </Form.Item>

          {checkboxes[mainOption?.value] && mainOption?.value === "others" ? (
            <>
              <Form.Item name={"others_description"}>
                <Input.TextArea rows={4} placeholder="Description" />
              </Form.Item>
            </>
          ) : (
            checkboxes[mainOption?.value] && (
              <div className="subsection-card">
                {getOptions(mainOption.value).map((subOption) => (
                  <div key={subOption.value}>
                    <Form.Item
                      name={`${mainOption.value}_${subOption.value}`}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) =>
                          handleSubCheckboxChange(
                            subOption.value,
                            e.target.checked,
                            mainOption.value,
                            subOption
                          )
                        }
                      >
                        {subOption.label}
                      </Checkbox>
                    </Form.Item>

                    {["stroke", "head_injury", "spinal_cord_injury"]?.includes(
                      subOption.value
                    ) &&
                      showSubOptions[subOption.value] && (
                        <div className="subsection-card">
                          <Form.Item
                            name={`${mainOption.value}_${subOption.value}_options`}
                          >
                            <Checkbox.Group
                              onChange={(checkedValues) =>
                                handleSubSubCheckboxChange(
                                  checkedValues,
                                  mainOption.value,
                                  subOption
                                )
                              }
                            >
                              {getSubOptions(subOption.value).map(
                                (thirdOption) => (
                                  <Checkbox value={thirdOption.value}>
                                    {thirdOption.label}
                                  </Checkbox>
                                )
                              )}
                            </Checkbox.Group>
                          </Form.Item>

                          {showSubSubOptions[`${subOption.value}`] && (
                            <Form.Item
                              name={`${mainOption.value}_${subOption.value}_description`}
                            >
                              <Input placeholder="Please enter the description" />
                            </Form.Item>
                          )}
                        </div>
                      )}
                  </div>
                ))}

                {showOthers[mainOption.value] && (
                  <Form.Item name={`${mainOption.value}_description`}>
                    <Input placeholder="Please enter the description" />
                  </Form.Item>
                )}
              </div>
            )
          )}
        </>
      ))}
    </>
  );
};

export default FunctionalDiagnosis;
