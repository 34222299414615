import { Button, Form } from "antd";
import React, { useState } from "react";
import FingerGripItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/FingerGripComponents/FingerGripItems";
import FingerGripImg from "../../../../../assets/scalesInfo/FingerGripInfo.png";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";
const scaleInfo = {
  name: "Finger grip",
  type: "image",
  url: FingerGripImg,
};
const FingerGripForm = ({ onValuesChange, formData }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [form] = Form.useForm();
  const [states, setStates] = useState(formData?.finger_grip_test || {});
  const handleChange = (key, value) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="op-forms">
      <Form
        id="finger-grip"
        form={form}
        onValuesChange={(cur, all) => onValuesChange("finger_grip_test", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.finger_grip_test}
      >
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <FingerGripItems
          form={form}
          handleChange={handleChange}
          states={states}
        />
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default FingerGripForm;
