import { Button, Col, Form, Row, Typography } from "antd";
import React, { useState } from "react";
import Gcs from "../../../../IpEMR/InspectionForms/Examination/GeneralExaminationForm/GeneralExaminationComponents/Gcs";
import GcsPdf from "../../../../../assets/scalesInfo/GCSInfo.pdf";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";

const scaleInfo = {
  name: "Gcs",
  type: "pdf",
  url: GcsPdf,
};
const GCSForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [openInfo, setOpenInfo] = useState(false);
  const fValues = form.getFieldValue("gcs") || {};
  console.log(fValues);
  const calcScore = (values) => {
    return Object?.entries(values)
      ?.filter(([key, value]) => key !== "score" && key !== "interpretation")
      ?.reduce((acc, [, value]) => acc + value, 0);
  };
  const score = calcScore(fValues) || formData?.gcs?.score;

  const interpretation =
    score < 9
      ? "Severe Brain Injury"
      : score < 13
      ? "Moderate Brain Injury"
      : score < 15
      ? "Mild Brain Injury"
      : "Normal";
  return (
    <div className="op-forms">
      <Form
        id="gcs-form"
        form={form}
        onValuesChange={(cur, all) => {
          console.log(all?.gcs);
          console.log(calcScore(all?.gcs));
          onValuesChange("gcs", {
            ...all,
            score: calcScore(all?.gcs),
            interpretation: interpretation,
          });
        }}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.gcs}
      >
        <Typography>
          <Row>
            <Col span={8}>Glasgow Coma Scale</Col>
            <Col span={16}>
              {score}/15 : {interpretation}
            </Col>
          </Row>
        </Typography>
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <Gcs initial={true} handleChange={() => {}} />
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default GCSForm;
