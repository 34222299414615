import { Button, message, Tabs } from "antd";
import React, { useState } from "react";
import ChiefComplaintsForm from "../OpFormComponents/ChiefComplaintsForm";
import OPExaminationForm from "../OpFormComponents/OPExaminationForm";
import MMTForm from "../OpFormComponents/MMTForm";
import ROMForm from "../OpFormComponents/ROMForm";
import BarthelIndexForm from "../OpFormComponents/BarthelIndexForm";
import OpFunctionalDiagnosis from "../CardiopulmonaryForm/OpFunctionalDiagnosis";
import OpExerciseForm from "../OpFormComponents/OpExerciseForm";
import ReviewNotes from "../OpFormComponents/ReviewNotes";
import OpHistoryForm from "../OpFormComponents/OpHistoryForm";
import OrthoSpecialTestsForm from "../OpFormComponents/SpecialTestsForm";
import TCMSForm from "../OpFormComponents/TCMSForm";
import FingerGripForm from "../OpFormComponents/FingerGripForm";
import SportsHistoryForm from "./SportsHistoryForm";
import SportsTherapy from "./SportsTherapy";
import SportsSummary from "./SportsSummary";
import { opServiceAPI } from "../../../../../services/physio.services";

const tabItems = [
  { label: "Chief Complaints", key: "1" },
  { label: "History", key: "2" },
  { label: "Examination", key: "3" },
  { label: "MMT", key: "4" },
  { label: "Finger Grip Test", key: "5" },
  { label: "ROM", key: "6" },
  { label: "TCMS", key: "7" },
  { label: "Barthel Index", key: "8" },
  { label: "Special Tests", key: "9" },
  { label: "Functional Diagnosis", key: "10" },
  { label: "Therapy", key: "11" },
  { label: "Exercise", key: "12" },
  { label: "Review Notes", key: "13" },
  { label: "Summary", key: "14" },
];
const SportsForm = ({ admissionId, handleClose }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [formData, setFormData] = useState({});

  const handleSubmit = async () => {
    try {
      await opServiceAPI("sports", {
        ...formData,
        admission_id: admissionId,
      });
      message.success("Sports EMR Submitted Successfully");
      handleClose();
    } catch (error) {
      message.error("Form Failed To Load");
      console.log(`Error:${error}`);
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleButton = () => {
    activeTab < "14"
      ? setActiveTab((cur) => (parseInt(cur) + 1).toString())
      : handleSubmit();
  };
  const onValuesChange = (key, values) => {
    setFormData((prev) => ({
      ...prev,
      [key]: values,
    }));
  };

  return (
    <>
      <Tabs items={tabItems} activeKey={activeTab} onChange={handleTabChange} />{" "}
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          <div className="ia">
            {(() => {
              switch (activeTab) {
                case "1":
                  return (
                    <ChiefComplaintsForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                      formType={"sports"}
                    />
                  );
                case "2":
                  return (
                    <OpHistoryForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    >
                      <SportsHistoryForm />
                    </OpHistoryForm>
                  );
                case "3":
                  return (
                    <OPExaminationForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "4":
                  return (
                    <MMTForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "5":
                  return (
                    <FingerGripForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );

                case "6":
                  return (
                    <ROMForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );

                case "7":
                  return (
                    <TCMSForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "8":
                  return (
                    <BarthelIndexForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "9":
                  return (
                    <OrthoSpecialTestsForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "10":
                  return (
                    <OpFunctionalDiagnosis
                      onValuesChange={onValuesChange}
                      formData={formData}
                      formType={"sports"}
                    />
                  );
                case "11":
                  return (
                    <SportsTherapy
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "12":
                  return (
                    <OpExerciseForm
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "13":
                  return (
                    <ReviewNotes
                      onValuesChange={onValuesChange}
                      formData={formData}
                    />
                  );
                case "14":
                  return <SportsSummary formData={formData} />;
              }
            })()}
          </div>
        </div>
      </div>
      <div className="flex-end">
        <Button type="primary" onClick={handleButton}>
          {activeTab === "14" ? "Save" : "Next"}
        </Button>
      </div>
    </>
  );
};

export default SportsForm;
