import { Col, Form, Row, Slider } from "antd";
import React from "react";
import { nihssOptions } from "../../../../Options/physioOptions";

const NIHSScaleItems = ({ handleChange, states }) => {
  const marks = {
    level_of_Consiousness: { 0: "0", 1: "1", 2: "2", 3: "3" },
    loc_questions: { 0: "0", 1: "1", 2: "2" },
    loc_commands: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    best_gaze: { 0: "0", 1: "1", 2: "2" },
    visual: { 0: "0", 1: "1", 2: "2", 3: "3" },
    facial_palsy: { 0: "0", 1: "1", 2: "2", 3: "3" },
    motor_arm_left: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    motor_arm_right: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    motor_leg_left: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    motor_leg_right: { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4" },
    limb_ataxia: { 0: "0", 1: "1", 2: "2" },
    sensory: { 0: "0", 1: "1", 2: "2" },
    best_language: { 0: "0", 1: "1", 2: "2", 3: "3" },
    dysarthria: { 0: "0", 1: "1", 2: "2" },
    extinction_and_inattention: { 0: "0", 1: "1", 2: "2" },
  };
  return (
    <>
      <Row gutter={[32, 8]}>
        {nihssOptions.map((option) => (
          <Col span={8}>
            <Form.Item
              key={option.value}
              label={option.label}
              name={option.value}
            >
              <Slider
                className="selection-slider"
                marks={marks[option.value]}
                step={1}
                value={states[option.value]}
                onChange={(value) => handleChange(value, option.value)}
                max={option.max}
                min={0}
                // style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default NIHSScaleItems;
