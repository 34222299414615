import {
  Button,
  Card,
  Col,
  Drawer,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import CardiopulmonaryForm from "./Forms/CardiopulmonaryForm/CardiopulmonaryForm";
import NeuroForm from "./Forms/NeuroForm/NeuroForm";
import OrthoForm from "./Forms/Orthoform/OrthoForm";
import "./OPForms.css";
import SportsForm from "./Forms/SportsForm/SportsForm";

const emrOptions = [
  { label: "Cardiopulmonary EMR", value: "cardiopulmonary_emr" },
  { label: "Neuro EMR", value: "neuro_emr" },
  { label: "Ortho EMR", value: "ortho_emr" },
  { label: "Sports EMR", value: "sports_emr" },
];

const PhysioOP = ({ patientData, admission, refreshOp }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const admissionId = searchParams?.get("aid");
  const status = searchParams?.get("service");
  const type = searchParams?.get("type");
  const navigate = useNavigate();
  const [open, setOpen] = useState(status);
  const [radioValue, setRadioValue] = useState("cardiopulmonary_emr");

  const handleNext = () => {
    searchParams.set("type", radioValue);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handlePrev = () => {
    type
      ? searchParams?.delete("type")
      : status
      ? searchParams?.delete("service")
      : navigate(`/physiotherapist/home?patient-type=outPatients&status=today`);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleClose = () => {
    searchParams?.delete("type");
    searchParams?.delete("service");
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    refreshOp();
  };

  useEffect(() => setOpen(status), [status]);

  const renderComponent = () => {
    switch (type) {
      case "cardiopulmonary_emr":
        return (
          <CardiopulmonaryForm
            admissionId={admissionId}
            handleClose={handleClose}
          />
        );
      case "neuro_emr":
        return (
          <NeuroForm admissionId={admissionId} handleClose={handleClose} />
        );
      case "ortho_emr":
        return (
          <OrthoForm admissionId={admissionId} handleClose={handleClose} />
        );
      case "sports_emr":
        return (
          <SportsForm admissionId={admissionId} handleClose={handleClose} />
        );
    }
  };

  const getLabel = () => {
    switch (type) {
      case "cardiopulmonary_emr":
        return "Cardiopulmonary EMR";
      case "neuro_emr":
        return "Neuro EMR";
      case "ortho_emr":
        return "Ortho EMR";
      case "sports_emr":
        return "Sports EMR";
    }
  };

  return (
    <>
      {type ? (
        <>
          <Drawer
            open={open}
            onClose={handleClose}
            width={"100%"}
            title={
              <Row gutter={[8, 8]}>
                <Col span={10}>
                  <Space>
                    <LeftOutlined
                      onClick={handlePrev}
                      style={{
                        fontSize: "18px",
                        padding: "0px 10px",
                        // marginTop: "15px",
                      }}
                    />
                    <Typography
                      style={{
                        color: "#101828",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {`${patientData?.first_name || " "} ${
                        patientData?.last_name || " "
                      }`}
                    </Typography>
                  </Space>
                  <br />
                  <Space style={{ marginLeft: "48px" }}>
                    <Tag>
                      {patientData?.age} {patientData?.gender}
                    </Tag>
                    <Tag color="">OP ID: {admission?.op_id}</Tag>
                  </Space>
                </Col>
                <Col span={14}>{getLabel()}</Col>
              </Row>
            }
            closeIcon={false}
          >
            {renderComponent()}
          </Drawer>
        </>
      ) : (
        <Card
          title={
            <Typography style={{ fontSize: "1.5rem", fontWeight: 600 }}>
              Choose Type of EMR
            </Typography>
          }
          // headStyle={{border:"none"}}
          style={{ width: "100%" }}
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              {
                <Radio.Group
                  value={radioValue}
                  onChange={(e) => setRadioValue(e?.target?.value)}
                >
                  {emrOptions?.map((option) => (
                    <>
                      <Radio
                        style={{
                          marginBottom: 10,
                          fontSize: "1.2rem",
                          fontWeight: 500,
                        }}
                        value={option?.value}
                      >
                        {option.label}
                      </Radio>
                      <br />
                    </>
                  ))}
                </Radio.Group>
              }
            </Col>
            <Col span={24} className="flex-end">
              <Button type="primary" onClick={handleNext}>
                Next
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      {/* <div className="footer">
        <Button 
      </div> */}
    </>
  );
};

export default PhysioOP;
