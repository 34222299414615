import { Descriptions, Image, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getAsiaScaleAPI,
  getBarthelIndexAPI,
  getCoordinationAPI,
  getFingerGripAPI,
  getGcScaleAPI,
  getMaScaleAPI,
  getMmtScaleAPI,
  getMocaScaleAPI,
  getNihsScaleAPI,
  getPeakFlowMeterAPI,
  getRasScaleAPI,
  getReflexesAPI,
  getRomScaleAPI,
  getRpeScaleAPI,
  getSixMinuteWalkAPI,
  getTcmScaleAPI,
  getVaScaleApi,
  getVcgScaleAPI,
} from "../../../../services/physio.services";
import {
  getLabel,
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
} from "../constructTextFromValues";
import {
  asiaImpairmentGradeOptions,
  maScaleOptions,
  mmtScaleOptions,
  nihssOptions,
  segmentsOptions,
  vcgScaleOptions,
} from "../../Options/physioOptions";
import { genderOptions, reflexes } from "../../Options/systemicExamOptions";
import {
  barthelIndexOptions,
  minsWalkedOptions,
  reasonOptions,
  rpeOptions,
} from "../../Options/functionalAsessmentOptions";
import { yesNoOptions } from "../../Options/commonOptions";
import scalesIcon from "../../../../assets/doctorIcons/scales.svg";

const PhysioScalesReviewSummary = ({ formData, editId }) => {
  const location = useLocation();
  const id = Object.fromEntries(new URLSearchParams(location?.search))?.patient;

  const [scalesSummaryItems, setScalesSummaryItems] = useState([]);

  const constructScalesSummaryItems = async () => {
    const getRomSummary = (list, data) => {
      const enteredOptions = list.reduce((acc, item) => {
        const filtered = Object.entries(data).filter(([key, value]) =>
          key.endsWith(item)
        );
        return [...acc, ...filtered];
      }, []);

      return enteredOptions?.length === 0
        ? null
        : enteredOptions?.map(([key, value]) =>
            getLabelTagInput(
              key
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" "),
              value
            )
          );
    };
    const getReflexesSummary = (data) => {
      const reflexOptions = reflexes
        .map((reflex) => {
          const leftValue = data?.[reflex.value + "_left"];
          const rightValue = data?.[reflex.value + "_right"];

          if (leftValue || rightValue) {
            let labelCreated = `${reflex.label} Left: ${leftValue}, Right: ${rightValue}`;
            return getLabel(labelCreated);
          }
          return null;
        })
        .filter(Boolean);

      return reflexOptions.length === 0 ? null : reflexOptions;
    };
    const scalesSummaryItems = [];
    const pushScalesSummaryItems = (key, label, children) => {
      scalesSummaryItems.push({ key, label, children });
    };
    const scales = formData?.scales || {};
    if (scales?.gcs) {
      try {
        const response = await getGcScaleAPI(id, scales?.gcs);
        const data = response?.data;
        pushScalesSummaryItems(
          "1",
          "GCS",
          <>
            {getLabelTagInput("Score", data?.score)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {/* {getLabelTagInput("E", data?.eye_opening_response)}
            {getLabelTagInput("V", data?.verbal_response)}
            {getLabelTagInput("M", data?.motor_response)} */}
            {getLabel(
              `E${data?.eye_opening_response} V${data?.verbal_response} M${data?.motor_response}`
            )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.error("Error fetching GCS data:", error);
      }
    }
    if (scales?.nihss) {
      try {
        const response = await getNihsScaleAPI(id, scales?.nihss);
        const data = response?.data;
        pushScalesSummaryItems(
          "2",
          "NIHSS",
          <>
            {getLabelTagInput("Score", data?.score)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelScalesTags(nihssOptions, data)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.moca) {
      try {
        const response = await getMocaScaleAPI(id, scales?.moca);
        const data = response?.data;
        pushScalesSummaryItems(
          "3",
          "MOCA",
          <>
            {getLabelTagInput("Score", data?.score)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelTagInput("Visual/Executive", data?.visual_executive)}
            {getLabelTagInput("Naming", data?.naming)}
            {getLabelTagInput("Attention", data?.attention)}
            {getLabelTagInput("Language", data?.language)}
            {getLabelTagInput("Abstraction", data?.abstraction)}
            {getLabelTagInput("Delayed Recall", data?.delayed_recall)}
            {getLabelTagInput("Orientation", data?.orientation)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.rass) {
      try {
        const response = await getRasScaleAPI(id, scales?.rass);
        const data = response?.data;
        pushScalesSummaryItems(
          "4",
          "RASS",
          <>
            {getLabelTagInput("Score", data?.rass_scale)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.asia) {
      try {
        const response = await getAsiaScaleAPI(id, scales?.asia);
        const data = response?.data;
        pushScalesSummaryItems(
          "5",
          "ASIA",
          <>
            {getLabelTagInput(
              "Lower Extremity Motor Score",
              data?.lower_extremity_motor_score
            )}
            {getLabelTagInput(
              "Upper Extremity Motor Score",
              data?.upper_extremity_motor_score
            )}
            {getLabelTagInput(
              "Left Total Motor Score",
              data?.left_total_motor_score
            )}
            {getLabelTagInput(
              "Right Total Motor Score",
              data?.right_total_motor_score
            )}
            {getLabelTagInput(
              "Left Total Sensory Score",
              data?.left_total_sensory_score
            )}
            {getLabelTagInput(
              "Right Total Sensory Score",
              data?.right_total_sensory_score
            )}
            {getLabelTagInput("Total LT", data?.total_lt)}
            {getLabelTagInput("Total PP", data?.total_pp)}
            {getLabelTagInput("DAP", getLabelTag(yesNoOptions, data?.dap))}
            {getLabelTagInput("VAC", getLabelTag(yesNoOptions, data?.vac))}
            {getLabelTagInput("Status", data?.interpretation)}
            {getLabelTagInput(
              "Sensory Left",
              getLabelTag(segmentsOptions, data?.sensory_left)
            )}
            {getLabelTagInput(
              "Sensory Right",
              getLabelTag(segmentsOptions, data?.sensory_right)
            )}
            {getLabelTagInput(
              "Motor Left",
              getLabelTag(segmentsOptions, data?.motor_left)
            )}
            {getLabelTagInput(
              "Motor Right",
              getLabelTag(segmentsOptions, data?.motor_right)
            )}
            {getLabelTagInput(
              "Neurological Level Injury",
              getLabelTag(segmentsOptions, data?.nerological_level_of_injury)
            )}
            {getLabelTagInput(
              "ASIA Impairment Scale Grade",
              getLabelTag(
                asiaImpairmentGradeOptions,
                data?.asia_impairment_scale
              )
            )}
            {getLabelTagInput(
              "Zone of Partial Preservation",
              getLabelTag(segmentsOptions, data?.zone_of_partial_preservation)
            )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.vas) {
      try {
        const response = await getVaScaleApi(id, scales?.vas);
        const data = response?.data;
        pushScalesSummaryItems(
          "6",
          "VAS",
          <>
            {getLabelTagInput("Score", data?.pain)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.mas) {
      try {
        const response = await getMaScaleAPI(id, scales?.mas);
        const data = response?.data;
        pushScalesSummaryItems(
          "7",
          "MAS",
          <>
            {getLabelTagInput(
              "Left Lower Limb",
              getLabelTag(maScaleOptions, data?.lower_left_limb)
            )}
            {getLabelTagInput(
              "Right Lower Limb",
              getLabelTag(maScaleOptions, data?.lower_right_limb)
            )}
            {getLabelTagInput(
              "Left Upper Limb",
              getLabelTag(maScaleOptions, data?.upper_left_limb)
            )}
            {getLabelTagInput(
              "Right Upper Limb",
              getLabelTag(maScaleOptions, data?.upper_right_limb)
            )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.mmts) {
      try {
        const response = await getMmtScaleAPI(id, scales?.mmts);
        const data = response?.data;
        pushScalesSummaryItems(
          "8",
          "MMT",
          <>
            {getLabelTagInput(
              "Left Lower Limb",
              getLabelTag(mmtScaleOptions, data?.lower_left_limb)
            )}
            {getLabelTagInput(
              "Right Lower Limb",
              getLabelTag(mmtScaleOptions, data?.lower_right_limb)
            )}
            {getLabelTagInput(
              "Left Upper Limb",
              getLabelTag(mmtScaleOptions, data?.upper_left_limb)
            )}
            {getLabelTagInput(
              "Right Upper Limb",
              getLabelTag(mmtScaleOptions, data?.upper_right_limb)
            )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.rom) {
      try {
        const response = await getRomScaleAPI(id, scales?.rom);
        const data = response?.data;
        pushScalesSummaryItems(
          "9",
          "ROM",
          <>
            {data?.shoulder_joint &&
              getLabelTagInput(
                "Shoulder Joint",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "abduction",
                    "adduction",
                    "medial_rotation",
                    "lateral_rotation",
                  ],
                  data?.shoulder_joint
                )
              )}
            {data?.elbow_joint &&
              getLabelTagInput(
                "Elbow Joint",
                getRomSummary(["flexion", "extension"], data?.elbow_joint)
              )}
            {data?.forearm_joint &&
              getLabelTagInput(
                "Forearm Joint",
                getRomSummary(["supination", "pronation"], data?.forearm_joint)
              )}
            {data?.wrist_joint &&
              getLabelTagInput(
                "Wrist Joint",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "radial_deviation",
                    "ulnar_deviation",
                  ],
                  data?.wrist_joint
                )
              )}
            {data?.thumb_joint &&
              getLabelTagInput(
                "Thumb Joint",
                getRomSummary(
                  [
                    "cmc_flexion",
                    "cmc_extension",
                    "cmc_abduction",
                    "ip_flexion",
                  ],
                  data?.thumb_joint
                )
              )}
            {data?.metacarpel_joint &&
              getLabelTagInput(
                "Meta Carpel",
                getRomSummary(
                  ["flexion", "hyperextension", "abduction"],
                  data?.metacarpel_joint
                )
              )}
            {data?.proximal_joint &&
              getLabelTagInput(
                "Proximal Interphalangeal Joint",
                getRomSummary(["flexion"], data?.proximal_joint)
              )}
            {data?.distal_joint &&
              getLabelTagInput(
                "Disital Interphalangeal Joint",
                getRomSummary(["flexion", "hyperextension"], data?.distal_joint)
              )}
            {data?.hip_joint &&
              getLabelTagInput(
                "Hip Joint",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "abduction",
                    "adduction",
                    "medial_rotation",
                    "lateral_rotation",
                  ],
                  data?.hip_joint
                )
              )}
            {data?.knee_joint &&
              getLabelTagInput(
                "Knee Joint",
                getRomSummary(["flexion", "extension"], data?.knee_joint)
              )}
            {data?.ankle_joint &&
              getLabelTagInput(
                "Ankle Joint",
                getRomSummary(
                  ["dorsiflexion", "plantarflexion"],
                  data?.ankle_joint
                )
              )}
            {data?.foot_joint &&
              getLabelTagInput(
                "Foot Joint",
                getRomSummary(["inversion", "eversion"], data?.foot_joint)
              )}
            {data?.great_toe_joint &&
              getLabelTagInput(
                "Great Toe Joint",
                getRomSummary(["flexion", "extension"], data?.great_toe_joint)
              )}
            {data?.inter_phalangeal_joint &&
              getLabelTagInput(
                "Inter Phalangeal Joint",
                getRomSummary(
                  ["flexion", "extension"],
                  data?.inter_phalangeal_joint
                )
              )}
            {data?.cervical_spine &&
              getLabelTagInput(
                "Cervical Spine",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "right_rotation",
                    "left_rotation",
                    "right_lateral_rotation",
                    "left_lateral_rotation",
                  ],
                  data?.cervical_spine
                )
              )}
            {data?.thoraco_lumbar_spine &&
              getLabelTagInput(
                "Thoraco Lumbar Spine",
                getRomSummary(
                  [
                    "flexion",
                    "extension",
                    "right_rotation",
                    "left_rotation",
                    "right_lateral_rotation",
                    "left_lateral_rotation",
                  ],
                  data?.thoraco_lumbar_spine
                )
              )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.vcgs) {
      try {
        const response = await getVcgScaleAPI(id, scales?.vcgs);
        const data = response?.data;
        pushScalesSummaryItems(
          "10",
          "VCG",
          <>
            {getLabelTagInput(
              "Left Lower Limb",
              getLabelTag(vcgScaleOptions, data?.lower_left_limb)
            )}
            {getLabelTagInput(
              "Right Lower Limb",
              getLabelTag(vcgScaleOptions, data?.lower_right_limb)
            )}
            {getLabelTagInput(
              "Left Upper Limb",
              getLabelTag(vcgScaleOptions, data?.upper_left_limb)
            )}
            {getLabelTagInput(
              "Right Upper Limb",
              getLabelTag(vcgScaleOptions, data?.upper_right_limb)
            )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.rpe) {
      try {
        const response = await getRpeScaleAPI(id, scales?.rpe);
        const data = response?.data;
        pushScalesSummaryItems(
          "11",
          "RPE",
          <>
            {getLabelTagInput("Score", data?.exertion)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.reflexes) {
      try {
        const response = await getReflexesAPI(id, scales?.reflexes);
        const data = response?.data;
        pushScalesSummaryItems(
          "12",
          "Reflexes",
          <>
            {" "}
            {getReflexesSummary(data)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.pefr) {
      try {
        const response = await getPeakFlowMeterAPI(id, scales?.pefr);
        const data = response?.data;
        pushScalesSummaryItems(
          "13",
          "Peak Flow Meter Test",
          <>
            {getLabelTag(genderOptions, data?.gender)}
            {getLabelTagInput("Age", data?.age)}
            {getLabelTagInput("Height", data?.height, "cm")}
            {getLabelTagInput("Predicted PEFR", data?.predicted_PEFR)}
            {getLabelTagInput("Tested PEFR", data?.test_PEFR)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.smwt) {
      try {
        const response = await getSixMinuteWalkAPI(id, scales?.smwt);
        const data = response?.data;
        pushScalesSummaryItems(
          "14",
          "Six Minute Walk Test",
          <>
            {getLabelTag(genderOptions, data?.gender)}
            {getLabelTagInput("Age", data?.age)}
            {getLabelTagInput("Height", data?.height, "cms")}
            {getLabelTagInput("Weight", data?.weight, "kgs")}
            {getLabelTagInput("Predicted Distance", data?.predicted_distance)}
            {getLabelTagInput(
              "Pre Vitals",
              <>
                {getLabelTagInput("Heart Rate", data?.heart_rate)}
                {getLabelTagInput("SBP", data?.sbp)}
                {getLabelTagInput("DBP", data?.dbp)}
                {getLabelTagInput("SPO2", data?.spo2)}
                {getLabelTagInput("RR", data?.rr)}
                {getLabelTagInput("RPE", getLabelTag(rpeOptions, data?.rpe))}
              </>
            )}
            {getLabelTagInput(
              "Post Vitals",
              <>
                {getLabelTagInput("Heart Rate", data?.post_vital_heart_rate)}
                {getLabelTagInput("SBP", data?.post_vital_sbp)}
                {getLabelTagInput("DBP", data?.post_vital_dbp)}
                {getLabelTagInput("SPO2", data?.post_vital_spo2)}
                {getLabelTagInput("RR", data?.post_vital_rr)}
                {getLabelTagInput(
                  "RPE",
                  getLabelTag(rpeOptions, data?.post_vital_rpe)
                )}
              </>
            )}
            {getLabelTagInput("No Of Laps", data?.no_of_laps)}
            {getLabelTagInput("Distance Walked", data?.distance_covered, "mtr")}
            {getLabelTagInput(
              "Final Partial Lap",
              data?.final_lap_in_meters,
              "mtr"
            )}
            {getLabelTagInput(
              "Mins Walked",
              getLabelTag(minsWalkedOptions, data?.mins_walked)
            )}
            {getLabelTagInput(
              "Reasons for Stopping",
              getLabelTag(reasonOptions, data?.reason_for_immediate_stopping)
            )}
            {getLabelTagInput(
              "Others details",
              data?.reason_for_immediate_stopping_others
            )}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.coordination) {
      try {
        const response = await getCoordinationAPI(id, scales?.coordination);
        const data = response?.data;
        pushScalesSummaryItems(
          "15",
          "Coordination",
          <>
            {getLabelTagInput("Finger-to-Nose Test", data?.finger_to_nose)}
            {getLabelTagInput("Finger-to-Finger Test", data?.finger_to_finger)}
            {getLabelTagInput(
              "Adiadokokinesia or Dysdiadokokinesia",
              data?.adiadochokinesia_or_dysdiadokokinesia
            )}
            {getLabelTagInput("Rebound Phenomenon", data?.rebound_phenomenon)}
            {getLabelTagInput(
              "Walking Along a Straight Line",
              data?.walking_along_straight_line
            )}
            {getLabelTagInput("Romberg's Test", data?.rombergs_test)}
            {getLabelTagInput("Heel on Shin Test", data?.heel_to_shin)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.tcm) {
      try {
        const response = await getTcmScaleAPI(id, scales?.tcm);
        const data = response?.data;
        pushScalesSummaryItems(
          "16",
          "TCM",
          <>
            {getLabelTagInput("Score", data?.score)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelTagInput("Static Sitting Balance", data?.sitting_balance)}
            {getLabelTagInput(
              "Selective Movement Control",
              data?.selective_movement_control
            )}
            {getLabelTagInput("Dynamic Reaching", data?.sitting_to_standing)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }
    if (scales?.finger_grip) {
      try {
        const response = await getFingerGripAPI(id, scales?.finger_grip);
        const data = response?.data;
        pushScalesSummaryItems(
          "17",
          "Finger Grip Test",
          <>
            {getLabelTagInput("Age", data?.age)}
            {getLabelTagInput("Left Hand Grip Strength", data?.left_hand)}
            {getLabelTagInput(
              "Left Hand Grip Interpretation",
              data?.left_hand_interpretation
            )}

            {getLabelTagInput("Right Hand Grip Strength", data?.right_hand)}
            {getLabelTagInput(
              "Left Hand Grip Interpretation",
              data?.left_hand_interpretation
            )}

            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }

    if (scales?.barthel_index) {
      try {
        const response = await getBarthelIndexAPI(id, scales?.barthel_index);
        const data = response?.data;
        pushScalesSummaryItems(
          "18",
          "Barthel Index",
          <>
            {getLabelTagInput("Score", data?.score)}
            {getLabelTagInput("Interpretation", data?.interpretation)}
            {getLabelScalesTags(barthelIndexOptions, data)}
            {getLabelTagInput("Comments", data?.comments)}
          </>
        );
      } catch (error) {
        console.log(`Error:`, error);
      }
    }

    setScalesSummaryItems(scalesSummaryItems);
  };

  useEffect(() => {
    // setTimeout(() => {
    //   constructScalesSummaryItems();
    // }, 1000);
    constructScalesSummaryItems();
  }, [formData?.scales]);

  return (
    <>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={scalesIcon} width={"18px"} preview={false} />
              <span>Scales</span>
            </Space>
          </span>
        }
        items={scalesSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </>
  );
};

export default PhysioScalesReviewSummary;
