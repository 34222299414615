import { Button, Col, Form, Row, Select } from "antd";
import React, { useState } from "react";
import {
  maScaleOptions,
  mmtScaleOptions,
  vcgScaleOptions,
} from "../../../../Options/physioOptions";
import upperRightLimb from "../../../../../../assets/images/scalesImages/upperRightLimb.svg";
import upperLeftLimb from "../../../../../../assets/images/scalesImages/upperLeftLimb.svg";
import lowerRightLimb from "../../../../../../assets/images/scalesImages/lowerRightLimb.svg";
import lowerLeftLimb from "../../../../../../assets/images/scalesImages/lowerLeftLimb.svg";
import ScalesInfoViewer from "../ScalesInfoViewer";
import MASImg from "../../../../../../assets/scalesInfo/MASInfo.png";
import MMTImg from "../../../../../../assets/scalesInfo/MMTInfo.png";
import VCGImg from "../../../../../../assets/scalesInfo/VCGInfo.png";

const limbOptions = [
  {
    title: "Left",
    options: [
      { label: "Upper Left Limb", value: "upper_left_limb" },
      { label: "Lower Left Limb", value: "lower_left_limb" },
    ],
  },
  {
    title: "Right",
    options: [
      { label: "Upper Right Limb", value: "upper_right_limb" },
      { label: "Lower Right Limb", value: "lower_right_limb" },
    ],
  },
];

const MMTScaleItems = ({ scaleType, initial = false }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const getOptions = () => {
    switch (scaleType) {
      case "mas":
        return maScaleOptions;
      case "mmt":
        return mmtScaleOptions;
      case "vcg":
        return vcgScaleOptions;
    }
  };

  const getImage = (index) => {
    switch (index) {
      case 0:
        return upperLeftLimb;
      case 2:
        return upperRightLimb;
      case 1:
        return lowerLeftLimb;
      case 3:
        return lowerRightLimb;
    }
  };
  let iterationCounter = 0;
  const scaleInfo = {
    // name: "MMT",
    type: "image",
    url: scaleType === "mas" ? MASImg : scaleType === "mmt" ? MMTImg : VCGImg,
  };
  return (
    <div className="op-forms">
      <Button
        className="scale-info"
        onClick={() => {
          setOpenInfo(true);
        }}
      >
        Info
      </Button>
      <Row gutter={[8, 8]}>
        {limbOptions?.map((option, index) => (
          <Col
            span={12}
            style={{
              textAlign: index % 2 === 0 ? "right" : "left",
            }}
          >
            {option?.options?.map((limb) => {
              const currentIteration = iterationCounter++;
              return (
                <React.Fragment key={limb?.value}>
                  <Form.Item
                    name={initial ? [scaleType, limb?.value] : limb?.value}
                    label={limb?.label}
                    labelCol={{
                      style: {
                        textAlign: index % 2 === 0 ? "right" : "left",
                        paddingRight: index % 2 === 0 ? "150px" : "0px",
                        paddingLeft: index % 2 !== 0 ? "150px" : "0px",
                      },
                    }}
                  >
                    <Select
                      style={{
                        height: 200,
                        backgroundImage: `url(${getImage(currentIteration)})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        //   backgroundPosition: "center",
                      }}
                      options={getOptions()?.map((option) => ({
                        label: (
                          <div
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            {option?.label}
                          </div>
                        ),
                        value: option?.value,
                      }))}
                      className="no-bg"
                    />
                  </Form.Item>
                </React.Fragment>
              );
            })}
            <br />
          </Col>
        ))}
      </Row>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default MMTScaleItems;
