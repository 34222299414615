import React from "react";
import {
  Form,
  Typography,
  Input,
  Collapse,
  DatePicker,
  Select,
  Button,
} from "antd";
import inputStyles from "../../../../../styles/Custom/Input.module.css";
import PressureSoreTable from "./PressureSoreModal";
const ClinicalProgress = () => {
  return (
    <>
      <Typography>
        <Typography.Text>
          Over the course of stay with the center, He was undergoing regular
          physiotherapy , other supportive and symptomatic therapy. He remained
          cardio vascularly stable. Tracheostomy Care and Respiratory Support
          was continued. As the patient condition improved Tracheostomy was
          successfully decannulated on{" "}
        </Typography.Text>
        <Form.Item style={{ display: "inline-block" }}>
          <DatePicker
            className={inputStyles.mdDatePicker}
            style={{ width: "150px" }}
          />
        </Form.Item>
      </Typography>
      <Typography>
        <Typography.Text>He received</Typography.Text>
        <Form.Item style={{ display: "inline-block" }}>
          <Select
            className={inputStyles.mdInput}
            style={{ width: "250px" }}
            placeholder="DVT Prophylaxis"
          />
        </Form.Item>
        <Typography.Text>as DVT Prophylaxis</Typography.Text>
      </Typography>
      <Typography>
        <Typography.Text>
          He received regular wound care, with frequent change of positioning
          with medical support for the following Pressure Sores
        </Typography.Text>{" "}
        <PressureSoreTable />
      </Typography>
    </>
  );
};

export default ClinicalProgress;
