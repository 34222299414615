import { Form } from "antd";
import React from "react";
import { cardioTherapyOptions } from "../../../../IpEMR/Options/physioOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const CardioTherapyForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        id="op-cardio-therapy"
        form={form}
        onValuesChange={(cur, all) => onValuesChange("therapy", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.therapy}
      >
        {cardioTherapyOptions?.map((option) => (
          <DeselectableRadioGroup
            label={option?.label}
            name={option?.name_key}
            options={option?.options}
            form={form}
          />
        ))}
      </Form>
    </>
  );
};

export default CardioTherapyForm;
