import React, { useState } from "react";
import { coordinationOptions } from "../../../../Options/systemicExamOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import CoordinationItems from "./CoordinationItems";

const Coordination = ({ context, form }) => {
  const [refesh, setRefresh] = useState(false);

  return (
    <>
      <DeselectableRadioGroup
        label={"Co-ordination"}
        name={"coordination"}
        options={coordinationOptions}
        form={form}
        setRefresh={setRefresh}
      />

      {form.getFieldValue("coordination") === "abnormal" && (
        <div className="subsection-card">
          <CoordinationItems form={form} />
        </div>
      )}
    </>
  );
};

export default Coordination;
