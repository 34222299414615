import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RespiratoryReviewContext } from "./RespiratoryTherapistReviewProvider";
import {
  getLatestRespiratoryReviewAPI,
  getRespiratoryInspectionAPI,
  getRespiratoryReviewAPI,
  respiratoryReviewAPI,
  updateRespiratoryReviewAPI,
} from "../../../../services/respiratory.services";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import RespiratoryPatientAssessment from "../../InspectionForms/PatientAssessment/RespiratoryPatientAssessment";
import RespiratoryTherapistTreatmentPlan from "../../InspectionForms/TreatmentPlan/RespiratoryTherapistTreatmentPlan";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
const RespiratoryTherapistReviewForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  iAssessment,
  addMode,
  setAddMode,
  setActiveSegment,
  reviewData,
  setDailyReviewRefresh,
  dailyRefresh,
  segmentData,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [patientForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const { formData, setFormData } = useContext(RespiratoryReviewContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);
  const newReview = reviewData?.length === 0;
  const reviewId = reviewData?.[0]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;
  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });

  const handleSubmit = async (values, flag) => {
    try {
      if (editId || newReviewId) {
        await updateRespiratoryReviewAPI(editId || newReviewId, values);
        setDailyReviewRefresh();
      } else {
        await respiratoryReviewAPI(values);
        refreshPage();
        setAddMode(false);
        setDailyReviewRefresh();
      }

      message.success(`${flag} submitted successfully`);

      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onPatientSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        patient_assessment: values,
        admission_id: params?.patient,
      },
      "Patient Assessment"
    );
  };

  const onTreatmentSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        treatment_plan: values,
        admission_id: params?.patient,
      },
      "Treatment Plan"
    );
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getRespiratoryReviewAPI(editId);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestRespiratoryReviewAPI(params?.patient);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getRespiratoryInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId, dailyRefresh]);

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return patientForm.submit();
      case "2":
        return treatmentForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      switch (activeTab) {
        case "1":
          patientForm.validateFields().then((values) => {
            setIsDirty(false);
            onPatientSubmit(values);
            setActiveTab(key);
          });
          break;
        case "2":
          treatmentForm.validateFields().then((values) => {
            setIsDirty(false);
            onTreatmentSubmit(values);
            setActiveTab(key);
          });
          break;
        default:
          setActiveTab(key);
      }
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <div>
      <Drawer
        open={visible}
        width="100%"
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                getFormKey();
                const nextTab = (parseInt(activeTab) + 1).toString();
                if (activeTab === "2") {
                  setVisible(false);
                } else {
                  setActiveTab(nextTab);
                }
              }}
              type="primary"
            >
              {activeTab === "2" ? "Done" : " Save & Proceed"}
            </Button>
            {"  "}
            {activeTab !== "2" && (
              <Button
                onClick={() => {
                  getFormKey();
                  setVisible(false);
                }}
                type="primary"
              >
                Save & Close
              </Button>
            )}
          </div>
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(reviewData[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Row>
          <Col span={9}>
            <Space>
              <LeftOutlined
                onClick={() => {
                  Modal.confirm({
                    title: "Confirmation",
                    content: "Are you sure you want to close?",
                    onOk: () => {
                      setVisible(false);
                      setActiveSegment(reviewData[0]?.id);
                    },
                  });
                }}
                style={{}}
              />
              <Typography
                style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
              </Typography>
              {patientData?.admission?.bed?.bed_no && (
                <Tag color="orange">
                  <Image src={bedImg} preview={false} />{" "}
                  {patientData?.admission?.bed?.bed_no}
                </Tag>
              )}
            </Space>
            <br />
            <Space style={{ marginTop: "10px" }}>
              <Tag>
                {patientData?.admission?.patient?.gender}{" "}
                {patientData?.admission?.patient?.age}
              </Tag>
              <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
            </Space>
          </Col>
          <Col span={11}>
            <Tabs
              activeKey={activeTab}
              items={[
                { key: "1", label: "Patient Assessment" },
                { key: "2", label: "Treatment Plan" },
              ]}
              onChange={handleTabChange}
            />
          </Col>
          <Col span={4} className="flex-end">
            <Typography>{reviewDate}</Typography>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0px" }} />
        <div
          style={{ height: "90%", overflow: "hidden", position: "relative" }}
        >
          <div
            style={{
              height: "calc(100% - 38px)",
              overflowY: "auto",
              backgroundColor: "#f6f5ff",
              borderRadius: "5px",
              border: "1px solid #e6e6e6",
            }}
          >
            {activeTab === "1" && (
              <Form
                id="review-patient-assessment"
                form={patientForm}
                layout="vertical"
                onFinish={onPatientSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <RespiratoryPatientAssessment
                  review={true}
                  form={patientForm}
                  context={RespiratoryReviewContext}
                />
              </Form>
            )}
            {activeTab === "2" && (
              <Form
                id="review-treatment-plan"
                form={treatmentForm}
                layout="vertical"
                onFinish={onTreatmentSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <RespiratoryTherapistTreatmentPlan
                  form={treatmentForm}
                  context={RespiratoryReviewContext}
                />
              </Form>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default RespiratoryTherapistReviewForm;
