import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  speechInspectionAPI,
  updateSpeechInspectionAPI,
} from "../../../../services/speech.services";
import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import SpeechSwallowGeneralExamination from "../../InspectionForms/Examination/GeneralExaminationForm/SpeechSwallowGeneralExamination";
import SpeechSwallowScales from "../../InspectionForms/Scales/SpeechSwallowScales";
import SpeechSwallowInitialFunctionalDiagnosis from "../../InspectionForms/FunctionalDiagnosis/SpeechSwallowInitialFunctionalDiagnosis";
import { SpeechInitialContext } from "./SpeechSwallowInitialAssessmentProvider";
import { LeftOutlined } from "@ant-design/icons";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;
const SpeechSwallowInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
  setConfirmModal,
  patientData,
}) => {
  const screen = useBreakpoint();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  // const [historyForm] = Form.useForm();
  const [generalForm] = Form.useForm();

  const [functionalForm] = Form.useForm();
  const [scalesForm] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(SpeechInitialContext);
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values, flag) => {
    try {
      if (editId) {
        await updateSpeechInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await speechInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success(`${flag} submitted successfully`);
      if (activeTab === "3") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      console.log(error, "Error while submitting");
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onGeneralSubmit = (values) => {
    handleSubmit(
      {
        general_examination: values,
        admission_id: params?.patient,
      },
      "General Examination"
    );
  };

  const onFunctionalSubmit = (values) => {
    handleSubmit(
      {
        functional_diagnosis: values,
        admission_id: params?.patient,
      },
      "Functional Diagnosis"
    );
  };
  const onScalesSubmit = (values) => {
    handleSubmit(
      {
        scales: values,
        admission_id: params?.patient,
      },
      "Scales"
    );
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return generalForm.submit();
      case "2":
        return scalesForm.submit();
      case "3":
        return functionalForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              generalForm.validateFields().then((values) => {
                setIsDirty(false);
                onGeneralSubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              scalesForm.validateFields().then((values) => {
                setIsDirty(false);
                onScalesSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              functionalForm.validateFields().then((values) => {
                setIsDirty(false);
                onFunctionalSubmit(values);
                setActiveTab(key);
              });
              break;

            default:
              setActiveTab(key);
          }
        },
        onCancel: () => {
          setActiveTab(key);
          setIsDirty(false);
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Space>
            <LeftOutlined onClick={() => setConfirmModal(true)} />
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
            </Typography>
            {patientData?.admission?.bed?.bed_no && (
              <Tag color="orange">
                <Image src={bedImg} preview={false} />{" "}
                {patientData?.admission?.bed?.bed_no}
              </Tag>
            )}
          </Space>
          <br />
          <Space style={{ marginTop: "10px" }}>
            <Tag>
              {patientData?.admission?.patient?.age}{" "}
              {patientData?.admission?.patient?.gender}
            </Tag>
            <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
          </Space>
        </Col>
        {(screen.xxl === false ||
          screen.xl === false ||
          screen.lg === false) && (
          <Col
            xs={{ flex: 1 }}
            sm={{ flex: 1 }}
            md={{ flex: 1 }}
            lg={0}
            xl={0}
            xxl={0}
            className="flex-end"
          >
            <Row gutter={[8, 8]} style={{ flexDirection: "column" }}>
              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                {formData?.submitted_by_first_name && (
                  <Tag>
                    Done by: {formData?.submitted_by_first_name}{" "}
                    {formData?.submitted_by_last_name}
                  </Tag>
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                <Tag>
                  {dayjs(formData?.updated_at || new Date()).format(
                    "DD/MM/YYYY"
                  )}
                </Tag>
              </Col>
            </Row>
          </Col>
        )}

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={10}
          xxl={10}
          className="flex-center"
        >
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "General Examination" },
              { key: "2", label: "Scales" },
              { key: "3", label: "Functional Diagnosis" },
            ]}
            onChange={handleTabChange}
          />
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={{ flex: 1 }}
          xl={{ flex: 1 }}
          xxl={{ flex: 1 }}
          className="flex-end"
        >
          <Row gutter={[8, 8]} style={{ flexDirection: "column" }}>
            <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
              {formData?.submitted_by_first_name && (
                <Tag>
                  Done by: {formData?.submitted_by_first_name}{" "}
                  {formData?.submitted_by_last_name}
                </Tag>
              )}
            </Col>
            <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
              <Tag>
                {dayjs(formData?.updated_at || new Date()).format("DD/MM/YYYY")}
              </Tag>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0px" }} />

      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "calc(100% - 38px)",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          {activeTab === "1" && (
            <Form
              id="general_examination-form"
              form={generalForm}
              layout="vertical"
              onFinish={onGeneralSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <SpeechSwallowGeneralExamination
                form={generalForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="scales-form"
              form={scalesForm}
              layout="vertical"
              onFinish={onScalesSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <SpeechSwallowScales form={scalesForm} formData={formData} />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="functional-diagnosis-form"
              form={functionalForm}
              layout="vertical"
              onFinish={onFunctionalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <SpeechSwallowInitialFunctionalDiagnosis
                form={functionalForm}
                context={SpeechInitialContext}
              />
            </Form>
          )}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "3" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpeechSwallowInitialInspectionForm;
