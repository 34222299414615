import { Checkbox, Form, Input } from "antd";
import React from "react";
import { cardioPastMedicalOptions } from "../OpPhysioOptions";

const PastMedicalOP = () => {
  return (
    <>
      <Form.Item label="Past Medical History" name={"past_medical_history"}>
        <Checkbox.Group options={cardioPastMedicalOptions} />
      </Form.Item>
      <Form.Item name={"past_medical_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item>
    </>
  );
};

export default PastMedicalOP;
