import { Form, Typography } from "antd";
import React from "react";
import ROMItems from "../../../../IpEMR/InspectionForms/Examination/SystemicExaminationForm/MusculoSkeletalForm/ROMItems";

const ROMForm = ({ onValuesChange, formData }) => {
  return (
    <>
      <Form
        id="rom-form"
        onValuesChange={(cur, all) => onValuesChange("rom", all)}
        className="ip-forms"
        layout="vertical"
        initialValues={formData?.rom}
      >
        <Typography>Range of Motion</Typography>
        <ROMItems />
      </Form>
    </>
  );
};

export default ROMForm;
