import React, { useContext, useEffect, useState, useCallback } from "react";
import DrHistorySummaryDR from "../../DailyReviewSummaries/HistorySummaryDR/DrHistorySummaryDR";
import DrGeneralExaminationSummaryDR from "../../DailyReviewSummaries/GeneralExaminationSummaryDR/DrGeneralExaminationSummaryDR";
import DrSystemicExaminationSummaryDR from "../../DailyReviewSummaries/SystemicExaminationSummaryDR/DrSystemicExaminationSummaryDR";
import DrClinicalImpressionSummaryDR from "../../DailyReviewSummaries/ClinicalImpressionSummaryDR/DrClinicalImpressionSummaryDR";
import { getDoctorDailyReviewAPI } from "../../../../services/doctor.services";
import { DailyReviewContext } from "./DailyReviewFormProvider";
import { Col, Row, message } from "antd";
import "../../summary.css";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import noReview from "../../../../assets/doctorIcons/noReview.svg";
import LoadingIndicator from "../../../Loaders/LoadingIndicator";

const checkEmptyData = (data) => {
  return (
    Object.keys(data?.clinical_diagnosis || {}).length === 0 &&
    !data?.functional_diagnosis &&
    Object.keys(data?.general_examination?.gcs || {}).length === 0 &&
    Object.keys(data?.history || {}).length === 0
  );
};

const DailyReviewSummary = ({ editId, dailyRefresh }) => {
  const [loading, setLoading] = useState(true);
  const { formData, setFormData } = useContext(DailyReviewContext);
  const [noData, setNoData] = useState(true);

  const openEdit = useCallback(async () => {
    if (editId) {
      try {
        const response = await getDoctorDailyReviewAPI(editId);
        const data = response.data.data;

        setFormData(data);
        setNoData(checkEmptyData(data));
      } catch (error) {
        console.log(`Error: ${error}`);
        message.error("Unable to Fetch Data");
      } finally {
        setLoading(false);
      }
    }
  }, [editId, setFormData]);

  useEffect(() => {
    openEdit();
  }, [openEdit, dailyRefresh]);

  return (
    <>
      {loading ? (
        <LoadingIndicator message={"Loading..."} />
      ) : (
        <Row className="summaries" gutter={[16, 16]}>
          {noData ? (
            <Col span={24}>
              <NoDataPage
                title={"No Review Data Available on This Date"}
                image={noReview}
              />
            </Col>
          ) : (
            <>
              <Col span={12}>
                {formData?.clinical_diagnosis && (
                  <DrClinicalImpressionSummaryDR context={DailyReviewContext} />
                )}
                {formData?.history && <DrHistorySummaryDR />}
              </Col>
              <Col span={12}>
                {formData?.general_examination && (
                  <DrGeneralExaminationSummaryDR />
                )}
                {formData?.systemic_examination && (
                  <DrSystemicExaminationSummaryDR />
                )}
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
};

export default DailyReviewSummary;
