import { Button, Col, Form, Row, Typography } from "antd";
import React, { useState } from "react";
import TCMItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/TCMScaleComponents/TCMItems";
import TCMSPdf from "../../../../../assets/scalesInfo/TCMSInfo.pdf";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";
const scaleInfo = {
  name: "TCMS",
  type: "pdf",
  url: TCMSPdf,
};
const TCMSForm = ({ onValuesChange, formData }) => {
  const [states, setStates] = useState(formData?.tcms ?? {});
  const handleChange = (value, key) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };
  const [openInfo, setOpenInfo] = useState(false);
  const calcScore = (values) => {
    return Object?.entries(values)
      ?.filter(([key, val]) => key !== "score" && key !== "interpretation")
      ?.reduce((acc, [_, val]) => acc + val, 0);
  };
  const score = calcScore(states) || formData?.tcms?.score;
  const interpretation =
    score === 58 ? "Normal Trunk Balance" : "Poor Trunk Balance";
  return (
    <div className="op-forms">
      <Form
        id="tcms"
        onValuesChange={(cur, all) =>
          onValuesChange("tcms", {
            ...all,
            score: Object?.values(all ?? {})?.reduce(
              (acc, cur) => acc + (cur || 0),
              0
            ),
            interpretation: interpretation,
          })
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.tcms}
      >
        <Typography>
          {" "}
          <Row>
            <Col span={8}>Trunk Control Measurement Scale</Col>
            <Col span={16}>
              {score}/58 : {interpretation}
            </Col>
          </Row>
        </Typography>
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <TCMItems handleChange={handleChange} />
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default TCMSForm;
