import { Form, Typography } from "antd";
import React, { useState } from "react";
import RASScaleItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/RASScaleComponents/RASScaleItems";

const RASSForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [refresh, setRefresh] = useState(false);
  const score =
    form.getFieldValue("rass_scale") || formData?.ras_scale?.rass_scale;
  const getInterpretation = (score) => {
    switch (score) {
      case -5:
        return "No response to voice or physical stimulation";
      case -4:
        return "No response to voice, but any movement to physical stimulation";
      case -3:
        return "Any movement(but no eye contact) to voice";
      case -2:
        return "Briefly (less than 10 seconds) awakens with eye contact to voice";
      case -1:
        return "Not fully alert, but has sustained (more than 10 seconds) awakening, with eye contact, to voice";
      case 0:
        return "Spontaneously pays attention to caregiver";
      case 1:
        return "Anxious or apprehensive but movements not aggresive or vigorous";
      case 2:
        return "Frequent nonpurposeful movement or patient-ventilator dyssynchrony";
      case 3:
        return "Pulls on or removes tube(s) or catheter(s) or has aggresive behaviour toward staff";
      case 4:
        return "Overtly combative or violent; Immediate danger to staff";
      default:
        return null;
    }
  };
  const interpretation =
    getInterpretation(score) || formData?.ras_scale?.interpretation;

  return (
    <>
      <Form
        id="rass-form"
        form={form}
        onValuesChange={(cur, all) =>
          onValuesChange("ras_scale", {
            ...all,
            interpretation: getInterpretation(all?.rass_scale),
          })
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.ras_scale}
      >
        <Typography>Richmond Agitation-Sedation Scale</Typography>
        <Typography>
          {score} : {interpretation}
        </Typography>
        <RASScaleItems form={form} setRefresh={setRefresh} />
      </Form>
    </>
  );
};

export default RASSForm;
