import React, { useEffect, useState } from "react";
import {
  getAdmissionAnalyticsAPI,
  getAdmissionReportAPI,
} from "../../../../services/orgDashboard.services";
import StatCardLayout from "../StatCardLayout";
import { Card, Col, Row, Select, Typography } from "antd";
import admissionIcon from "../../../../assets/dashboardIcons/newAdmission.svg";
import reAdmissionIcon from "../../../../assets/dashboardIcons/reAdmission.svg";
import activeIPIcon from "../../../../assets/dashboardIcons/graph.svg";
import totalOPIcon from "../../../../assets/dashboardIcons/totalOP.svg";
import {
  Area,
  AreaChart,
  CartesianAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { filterOptions } from "../DashboardOptions";

const AdmissionsOverview = ({ currentFacility, date }) => {
  const [admissionData, setAdmissionData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartFilter, setChartFilter] = useState("week");
  useEffect(() => {
    getAdmissionReportAPI(currentFacility, date)
      .then((res) => setAdmissionData(res?.data))
      .catch((error) => console.log(`Error:${error}`));
  }, [currentFacility, date]);

  useEffect(() => {
    getAdmissionAnalyticsAPI(chartFilter, currentFacility, date)
      .then((res) => {
        const chartData = Object?.entries(res?.data?.result)?.map(
          ([key, value]) => ({ label: key, admissions: value })
        );
        setChartData(chartData);
      })
      .catch((error) => console.log(error));
  }, [chartFilter, currentFacility, date]);

  return (
    <>
      <Row gutter={[8, 30]}>
        <Col span={6}>
          <StatCardLayout
            title={"New Admission"}
            val={admissionData?.ip_admissions?.total_new_admissions}
            icon={admissionIcon}
          />
        </Col>

        <Col span={6}>
          <StatCardLayout
            title={"Re Admission"}
            val={admissionData?.ip_admissions?.total_readmissions}
            icon={reAdmissionIcon}
          />
        </Col>

        <Col span={6}>
          <StatCardLayout
            title={"Active IP Admission"}
            val={admissionData?.ip_admissions?.total_active_admissions}
            icon={activeIPIcon}
          />
        </Col>

        <Col span={6}>
          <StatCardLayout
            title={"Total OP"}
            val={admissionData?.op_admissions?.total_admissions}
            icon={totalOPIcon}
          />
        </Col>
        <Col span={24}>
          {/* <div style={{ width: "100%", height: 200 }}> */}
          <Card
            title={
              <Row>
                <Col span={12} style={{ padding: 10 }}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: 24,
                      lineHeight: "20px",
                    }}
                  >
                    New Admissions
                  </Typography>
                </Col>
                <Col span={12} className="flex-end">
                  <Select
                    value={chartFilter}
                    options={filterOptions}
                    onSelect={(val) => setChartFilter(val)}
                  />
                </Col>
              </Row>
            }
          >
            <ResponsiveContainer width={"100%"} height={500}>
              <AreaChart width={1500} height={600} data={chartData}>
                <CartesianAxis stroke="#ccc" />
                <CartesianGrid
                  stroke="#ccc"
                  horizontal={true}
                  vertical={false}
                />
                <XAxis dataKey={"label"} />
                <YAxis />
                <Area
                  type="monotone"
                  dataKey="admissions"
                  name="Admissions"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.2}
                />
                <Tooltip />
              </AreaChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AdmissionsOverview;
