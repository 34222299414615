import React, { useContext, useEffect, useState } from "react";
import NurseHistoryForm from "../../InspectionForms/HistoryForm/NurseHistoryForm";
import NurseExaminationForm from "../../InspectionForms/Examination/NurseExaminationForm";
import NurseFunctionalAssessment from "../../InspectionForms/FunctionalStatusAssessment/NurseFunctionalAssessment";
import NurseSpecialCareRequirement from "../../InspectionForms/NurseSpecialCareRequirement/NurseSpecialCareRequirement";
import {
  Button,
  Col,
  Form,
  Grid,
  Image,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import { NurseInitialContext } from "../../InspectionForms/HistoryForm/NurseInitialAssessmentFormProvider";
import { useLocation, useNavigate } from "react-router-dom";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import {
  nurseInspectionAPI,
  updateNurseInspectionAPI,
} from "../../../../services/nurse.services";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;
const NurseInitialInspectionForm = ({
  setVisible,
  setConfirmModal,
  refreshPage,
  initialRefresh,
  editId,
  patientData,
  removeUrlParams,
}) => {
  const screen = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const params = Object.fromEntries(new URLSearchParams(location?.search));

  const [historyForm] = Form.useForm();
  const [examinationForm] = Form.useForm();
  const [functionalForm] = Form.useForm();
  const [specialCareForm] = Form.useForm();

  const [activeTab, setActiveTab] = useState();
  const { formData } = useContext(NurseInitialContext);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tabFromUrl = query.get("tab") || "1";

    setActiveTab(tabFromUrl);
  }, [location.search]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (activeTab) {
      query.set("tab", activeTab);
      navigate(`?${query.toString()}`);
    }
  }, [activeTab, navigate]);

  const handleSubmit = async (values, flag) => {
    try {
      if (editId) {
        await updateNurseInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await nurseInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success(`${flag} submitted successfully`);
      if (activeTab === "4") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      console.log(error, "Error while submitting");
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onHistorySubmit = (values) => {
    handleSubmit({ history: values, admission_id: params?.patient }, "History");
  };
  const onExaminationSubmit = (values) => {
    handleSubmit(
      {
        examination: values,
        admission_id: params?.patient,
      },
      "Examination"
    );
  };

  const onFunctionalSubmit = (values) => {
    handleSubmit(
      {
        functional_status: values,
        admission_id: params?.patient,
      },
      "Functional Status Assessment"
    );
  };
  const onSpecialCareSubmit = (values) => {
    handleSubmit(
      {
        special_care: values,
        admission_id: params?.patient,
        completed: true,
      },
      "Area of Special Care Requirement"
    );
  };

  const getFormKey = async () => {
    switch (activeTab) {
      case "1":
        return historyForm.submit();
      case "2":
        return examinationForm.submit();
      case "3":
        return functionalForm.submit();
      case "4":
        await specialCareForm.submit();
        removeUrlParams();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      // setActiveTab(key);
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              historyForm.validateFields().then((values) => {
                setIsDirty(false);
                onHistorySubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              examinationForm.validateFields().then((values) => {
                setIsDirty(false);
                onExaminationSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              functionalForm.validateFields().then((values) => {
                setIsDirty(false);
                onFunctionalSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              specialCareForm.validateFields().then((values) => {
                setIsDirty(false);
                onSpecialCareSubmit(values);
                setActiveTab(key);
              });
            default:
              setActiveTab(key);
          }
        },
        onCancel: () => {
          setActiveTab(key);
          setIsDirty(false);
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Row gutter={[0, 8]} align={"middle"}>
        <Col>
          <LeftOutlined
            onClick={() => setConfirmModal(true)}
            style={{
              fontSize: "18px",
              padding: "0px 10px",
            }}
          />
        </Col>
        <Col xs={11} sm={11} md={11} lg={4} xl={5} xxl={4}>
          <Space>
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.admission?.patient?.first_name || " "} ${
                patientData?.admission?.patient?.last_name || " "
              }`}
            </Typography>
            {patientData?.admission?.bed?.bed_no && (
              <Tag color="orange">
                <Image src={bedImg} preview={false} />{" "}
                {patientData?.admission?.bed?.bed_no}
              </Tag>
            )}
          </Space>
          <br />
          <Space>
            <Tag>
              {patientData?.admission?.patient?.age}{" "}
              {patientData?.admission?.patient?.gender}
            </Tag>
            <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
          </Space>
        </Col>

        <Col
          xs={0}
          sm={0}
          md={0}
          lg={0}
          xl={14}
          xxl={16}
          // className="flex-center"
        >
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "History" },
              { key: "2", label: "Examination" },
              { key: "3", label: "Functional Status Assessment" },
              { key: "4", label: "Area of Special Care Requirement" },
            ]}
            onChange={handleTabChange}
          />
        </Col>

        <Col
          xs={{ flex: 1 }}
          sm={{ flex: 1 }}
          md={{ flex: 1 }}
          lg={{ flex: 1 }}
          xl={{ flex: 1 }}
          xxl={{ flex: 1 }}
          className="flex-end"
        >
          <Row gutter={[8, 8]} style={{ flexDirection: "column" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {formData?.submitted_by_first_name && (
                <Tag>
                  Done by: {formData?.submitted_by_first_name}{" "}
                  {formData?.submitted_by_last_name}
                </Tag>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Tag>
                {dayjs(formData?.updated_at || new Date()).format("DD/MM/YYYY")}
              </Tag>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 4 }}
          xl={0}
          xxl={0}
          // className="flex-center"
        >
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "History" },
              { key: "2", label: "Examination" },
              { key: "3", label: "Functional Status Assessment" },
              { key: "4", label: "Area of Special Care Requirement" },
            ]}
            onChange={handleTabChange}
          />
        </Col>
        {/* <Col flex={1} className="flex-end" style={{ marginBottom: "30px" }}>
          <CloseOutlined onClick={() => setConfirmModal(true)} />
        </Col> */}
      </Row>

      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "calc(100% - 38px)",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          {activeTab === "1" && (
            <Form
              id="history-form"
              form={historyForm}
              layout="vertical"
              onFinish={onHistorySubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseHistoryForm
                form={historyForm}
                formData={formData}
                patientData={patientData}
                editId={editId}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="examination-form"
              form={examinationForm}
              layout="vertical"
              onFinish={onExaminationSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseExaminationForm
                form={examinationForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="functional-status-form"
              form={functionalForm}
              layout="vertical"
              onFinish={onFunctionalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseFunctionalAssessment
                form={functionalForm}
                formData={formData}
              />
            </Form>
          )}
          {activeTab === "4" && (
            <Form
              id="special-care-form"
              form={specialCareForm}
              layout="vertical"
              onFinish={onSpecialCareSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <NurseSpecialCareRequirement
                form={specialCareForm}
                formData={formData}
              />
            </Form>
          )}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
              style={{ fontWeight: 500 }}
            >
              {activeTab === "4" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NurseInitialInspectionForm;
