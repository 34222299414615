import React, { useContext } from "react";
import { PhysioInitialContext } from "./PhysiotherapistInitialAssessmentProvider";
import { Col, Row, Tag } from "antd";
import DoctorHistorySummary from "../../InspectionSummaries/HistorySummary/DoctorHistorySummary";
import PhysiotherapistGeneralExaminationSummary from "../../InspectionSummaries/GeneralExaminationSummary/PhysiotherapistGeneralExaminationSummary";
import PhysioFunctionalStatusSummary from "../../InspectionSummaries/FunctionalExaminationSummary/PhysioFunctionalStatusSummary";
import PhysioFunctionalDiagnosisSummary from "../../InspectionSummaries/FunctionalExaminationSummary/PhysioFunctionalDiagnosisSummary";
import PhysioTherapeuticGoalsSummary from "../../InspectionSummaries/Goals/PhysioTherapeuticGoalsSummary";
import PhysioSystemicExaminationSummary from "../../InspectionSummaries/SystemicExaminationSummary/PhysioSystemicExaminationSummary";
import PhysioTherapySummary from "../../InspectionSummaries/Therapy/PhysioTherapySummary";
import PhysioTreatmentPlanSummary from "../../InspectionSummaries/TreatmentPlan/PhysioTreatmentPlanSummary";
import { checkEmpty } from "../../../../helpers/utility";
import dayjs from "dayjs";

const PhysiotherapistInitialInspectionSummary = () => {
  const { formData, setFormData } = useContext(PhysioInitialContext);
  return (
    <Row className="summaries" gutter={[16, 16]}>
      <Col span={24} className="flex-start">
        {(formData?.submitted_by_first_name ||
          formData?.submitted_by_last_name) && (
          <Tag>
            Submitted by: {formData?.submitted_by_first_name || ""}{" "}
            {formData?.submitted_by_last_name || ""}
          </Tag>
        )}
        {formData?.updated_at && (
          <Tag>
            Updated at:{" "}
            {dayjs(formData?.updated_at).format("DD/MM/YYYY hh:mm A")}
          </Tag>
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.history) && (
          <DoctorHistorySummary formData={formData} />
        )}
        {checkEmpty(formData?.systemic_examination) && (
          <PhysioSystemicExaminationSummary formData={formData} />
        )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        {checkEmpty(formData?.general_examination) && (
          // checkEmpty(formData?.general_examination?.gcs)) &&
          <PhysiotherapistGeneralExaminationSummary formData={formData} />
        )}
        {checkEmpty(formData?.functional_status) && (
          <PhysioFunctionalStatusSummary formData={formData} />
        )}
        {checkEmpty(formData?.functional_diagnosis) && (
          <PhysioFunctionalDiagnosisSummary formData={formData} />
        )}
        {checkEmpty(formData?.therapeutic_goals) && (
          <PhysioTherapeuticGoalsSummary formData={formData} />
        )}
        {checkEmpty(formData?.therapy) && (
          <PhysioTherapySummary formData={formData} />
        )}
        {checkEmpty(formData?.treatment_plan) && (
          <PhysioTreatmentPlanSummary formData={formData} />
        )}
      </Col>
    </Row>
  );
};

export default PhysiotherapistInitialInspectionSummary;
