import { Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { noYesOptions } from "../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const RiskOfFall = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  const aggregate = () => {
    const previousFall =
      form.getFieldValue(["risk_of_fall", "previous_fall"]) === "yes" ? 25 : 0;
    const ambulation =
      form.getFieldValue(["risk_of_fall", "needs_aids_for_ambulation"]) ===
      "yes"
        ? 25
        : 0;
    const gait =
      form.getFieldValue(["risk_of_fall", "abnormal_gait"]) === "yes" ? 20 : 0;
    const sensoryDeficit =
      form.getFieldValue(["risk_of_fall", "sensory_deficit"]) === "yes"
        ? 10
        : 0;
    const muscularWeakness =
      form.getFieldValue(["risk_of_fall", "muscular_weakness"]) === "yes"
        ? 10
        : 0;
    const oldAge =
      form.getFieldValue(["risk_of_fall", "age_greater_than_65"]) === "yes"
        ? 15
        : 0;
    const total =
      previousFall +
      ambulation +
      gait +
      sensoryDeficit +
      muscularWeakness +
      oldAge;
    form.setFieldValue(["risk_of_fall", "score"], total);
    const interpretation =
      total > 45
        ? "High Risk of Fall"
        : total > 30
        ? "Moderate Risk of Fall"
        : "Low Risk of Fall";
    form.setFieldValue(["risk_of_fall", "interpretation"], interpretation);
    return (
      <span>
        {total} : {interpretation}
      </span>
    );
  };

  return (
    <>
      <Typography>Risk of Fall Balance Scale</Typography>
      <Form.Item name={["risk_of_fall", "score"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={["risk_of_fall", "interpretation"]}>
        <Input />
      </Form.Item>
      <div className="subsection-card">
        <Typography>{aggregate()}</Typography>
        <br />
        <DeselectableRadioGroup
          label={"H/O Previous Fall"}
          name={["risk_of_fall", "previous_fall"]}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />

        <DeselectableRadioGroup
          label={"Needs aids for Ambulation"}
          name={["risk_of_fall", "needs_aids_for_ambulation"]}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />

        <DeselectableRadioGroup
          label={"Abnormal Gait"}
          name={["risk_of_fall", "abnormal_gait"]}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />

        <DeselectableRadioGroup
          label={"Sensory Deficit"}
          name={["risk_of_fall", "sensory_deficit"]}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />

        <DeselectableRadioGroup
          label={"Muscular Weakness"}
          name={["risk_of_fall", "muscular_weakness"]}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />

        <DeselectableRadioGroup
          label={"Age > 65 years"}
          name={["risk_of_fall", "age_greater_than_65"]}
          options={noYesOptions}
          form={form}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default RiskOfFall;
