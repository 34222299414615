import { Form, Input } from "antd";
import React from "react";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";
import {
  daysUptoFifteenOptions,
  weeksStartingFourOptions,
  weeksStartingTwoOptions,
} from "../../../../IpEMR/Options/physioOptions";

const NeuroTherapy = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        id="neuro-therapy"
        form={form}
        onValuesChange={(cur, all) => onValuesChange("therapy", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.therapy}
      >
        <DeselectableRadioGroup
          name={"stroke_rehab"}
          label={"Stroke Rehab"}
          options={weeksStartingFourOptions}
          form={form}
        />
        <Form.Item name={"brustromme_approach"}>
          <Input placeholder="Brustromme Approach" />
        </Form.Item>
        <Form.Item name={"mrp_approach"}>
          <Input placeholder="MRP Approach" />
        </Form.Item>
        <Form.Item name={"ndt_approach"}>
          <Input placeholder="NDT Approach" />
        </Form.Item>
        <Form.Item name={"cimt_approach"}>
          <Input placeholder="CIMT Approach" />
        </Form.Item>
        <Form.Item name={"vojta_approach"}>
          <Input placeholder="VOJTA Approach" />
        </Form.Item>
        <Form.Item name={"pnf_approach"}>
          <Input placeholder="PNF Approach" />
        </Form.Item>
        <Form.Item name={"electic_approach"}>
          <Input placeholder="Electic Approach" />
        </Form.Item>
        <Form.Item name={"roods_approach"}>
          <Input placeholder="ROODS Approach" />
        </Form.Item>
        <Form.Item name={"speech_therapy"}>
          <Input placeholder="Speech therapy" />
        </Form.Item>
        <Form.Item name={"electrical_muscle_stimulation"}>
          <Input placeholder="Electrical Muscle Stimulation" />
        </Form.Item>
        <Form.Item name={"mile_stone_training"}>
          <Input placeholder="Mile Stone Training" />
        </Form.Item>
        <Form.Item name={"cognitive_rehab"}>
          <Input placeholder="Cognitive Rehab" />
        </Form.Item>
        <Form.Item name={"neuro_conditioning_training"}>
          <Input placeholder="Neuro Conditioning Training" />
        </Form.Item>
        <Form.Item name={"sensory_integration"}>
          <Input placeholder="Sensory Integration" />
        </Form.Item>
        <Form.Item name={"adl_training"}>
          <Input placeholder="ADL Training" />
        </Form.Item>
        <Form.Item name={"wheel_chair_training"}>
          <Input placeholder="Wheel Chair Training" />
        </Form.Item>

        <DeselectableRadioGroup
          name={"ultrasound"}
          label={"Ultrasound"}
          options={daysUptoFifteenOptions}
          form={form}
        />

        <DeselectableRadioGroup
          name={"splinting"}
          label={"Splinting/Bracing"}
          options={weeksStartingTwoOptions}
          form={form}
        />
        <Form.Item name={"postural_correction"}>
          <Input placeholder="Postural Correction" />
        </Form.Item>
        <Form.Item name={"robotics"}>
          <Input placeholder="Robotics" />
        </Form.Item>
        <Form.Item name={"gait_training"}>
          <Input placeholder="Gait Training" />
        </Form.Item>
        <Form.Item name={"description"}>
          <Input placeholder="Please enter the description" />
        </Form.Item>
      </Form>
    </>
  );
};

export default NeuroTherapy;
