import { Descriptions } from "antd";
import React from "react";
import { checkEmpty } from "../../../../../helpers/utility";
import {
  activePassiveOptions,
  cardioCongenitalOptions,
  cardioPastMedicalOptions,
  neuroChiefComplaintOptions,
  neuroDiagnosisOptions,
  neuroHistoryOptions,
  opExaminationOptions,
  spinalOptions,
  strokeHeadInjuryOptions,
} from "../OpPhysioOptions";
import {
  getLabel,
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getLabelTagYesNO,
} from "../../../../IpEMR/InspectionSummaries/constructTextFromValues";
import {
  alcoholOptions,
  smokingOptions,
} from "../../../../IpEMR/Options/historyOptions";
import {
  asiaImpairmentGradeOptions,
  daysUptoFifteenOptions,
  maScaleOptions,
  mmtScaleOptions,
  nihssOptions,
  segmentsOptions,
  vcgScaleOptions,
  weeksStartingFourOptions,
  weeksStartingTwoOptions,
} from "../../../../IpEMR/Options/physioOptions";
import { reflexes } from "../../../../IpEMR/Options/systemicExamOptions";
import { barthelIndexOptions } from "../../../../IpEMR/Options/functionalAsessmentOptions";
import { yesNoOptions } from "../../../../../constants/defaultKeys";

const NeuroSummary = ({ formData }) => {
  console.log(formData);
  const constructNeuroSummaryItems = () => {
    const getReflexesSummary = (data) => {
      const reflexOptions = reflexes
        .map((reflex) => {
          const leftValue = data?.[reflex.value + "_left"];
          const rightValue = data?.[reflex.value + "_right"];

          if (leftValue || rightValue) {
            let labelCreated = `${reflex.label} Left: ${leftValue}, Right: ${rightValue}`;
            return getLabel(labelCreated);
          }
          return null;
        })
        .filter(Boolean);

      return reflexOptions.length === 0 ? null : reflexOptions;
    };
    const getRomSummary = (list, data) => {
      const enteredOptions = list.reduce((acc, item) => {
        const filtered = Object.entries(data).filter(([key, value]) =>
          key.endsWith(item)
        );
        return [...acc, ...filtered];
      }, []);

      return enteredOptions?.length === 0
        ? null
        : enteredOptions?.map(([key, value]) =>
            getLabelTagInput(
              key
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" "),
              value
            )
          );
    };
    const neuroSummaryItems = [];
    const pushSummaryItem = (key, label, children) => {
      neuroSummaryItems.push({ key, label, children });
    };

    if (checkEmpty(formData?.chief_complaint)) {
      pushSummaryItem(
        "1",
        "Chief Complaints",
        <>
          {getLabelTagsArray(
            neuroChiefComplaintOptions,
            formData?.chief_complaint?.chief_complaints
          )}
          {getLabelTagInput(
            "Other Details",
            formData?.chief_complaint?.comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.history)) {
      pushSummaryItem(
        "2",
        "History",
        <>
          {getLabelTagInput(
            "History",
            <>
              {getLabelTagsArray(
                neuroHistoryOptions,
                formData?.history?.history
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.history_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Past Medical History",
            <>
              {getLabelTagsArray(
                cardioPastMedicalOptions,
                formData?.history?.past_medical_history
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.past_medical_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Congenital Disorders",
            <>
              {getLabelTagsArray(
                cardioCongenitalOptions,
                formData?.history?.congenital_disorders
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.congenital_disorders_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Past Surgical History",
            <>
              {getLabelTagYesNO(formData?.history?.past_surgical_history)}
              {getLabelTagInput(
                "Comments",
                formData?.history?.past_surgical_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Smoking",
            getLabelTag(smokingOptions, formData?.history?.smoking)
          )}
          {getLabelTagInput(
            "Alcohol",
            getLabelTag(alcoholOptions, formData?.history?.alcohol)
          )}
          {getLabelTagInput(
            "Life Style",
            getLabelTag(activePassiveOptions, formData?.history?.life_style)
          )}
          {getLabelTagInput("Comments", formData?.history?.habits_comments)}
        </>
      );
    }

    if (checkEmpty(formData?.examination)) {
      pushSummaryItem(
        "3",
        "Examination",
        <>
          {getLabelTagsWithDescription(
            opExaminationOptions,
            formData?.examination
          )}
          {getLabelTagInput("Pain level", formData?.examination?.pain)}
          {getLabelTagInput(
            "Interpretation",
            formData?.examination?.pain_interpretation
          )}
          {getLabelTagInput(
            "Comments",
            formData?.examination?.examination_comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.gcs)) {
      pushSummaryItem(
        "4",
        "GCS",
        <>
          {getLabelTagInput("Score", formData?.gcs?.score)}
          {getLabelTagInput("Interpretation", formData?.gcs?.interpretation)}
          {getLabel(
            `E${formData?.gcs?.gcs?.eye_opening_response} V${formData?.gcs?.gcs?.verbal_response} M${formData?.gcs?.gcs?.motor_response}`
          )}
        </>
      );
    }

    if (checkEmpty(formData?.ras_scale)) {
      pushSummaryItem(
        "5",
        "RAS",
        <>
          {getLabelTagInput("Score", formData?.ras_scale?.rass_scale)}
          {getLabelTagInput(
            "Interpretation",
            formData?.ras_scale?.interpretation
          )}
        </>
      );
    }

    if (checkEmpty(formData?.mmt)) {
      pushSummaryItem(
        "6",
        "MMT",
        <>
          {" "}
          {getLabelTagInput(
            "Left Lower Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.lower_left_limb)
          )}
          {getLabelTagInput(
            "Right Lower Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.lower_right_limb)
          )}
          {getLabelTagInput(
            "Left Upper Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.upper_left_limb)
          )}
          {getLabelTagInput(
            "Right Upper Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.upper_right_limb)
          )}
        </>
      );
    }

    if (checkEmpty(formData?.mas)) {
      pushSummaryItem(
        "7",
        "MAS",
        <>
          {getLabelTagInput(
            "Left Lower Limb",
            getLabelTag(maScaleOptions, formData?.mas?.lower_left_limb)
          )}
          {getLabelTagInput(
            "Right Lower Limb",
            getLabelTag(maScaleOptions, formData?.mas?.lower_right_limb)
          )}
          {getLabelTagInput(
            "Left Upper Limb",
            getLabelTag(maScaleOptions, formData?.mas?.upper_left_limb)
          )}
          {getLabelTagInput(
            "Right Upper Limb",
            getLabelTag(maScaleOptions, formData?.mas?.upper_right_limb)
          )}
        </>
      );
    }

    if (checkEmpty(formData?.vcg)) {
      pushSummaryItem(
        "8",
        "VCG",
        <>
          {getLabelTagInput(
            "Left Lower Limb",
            getLabelTag(vcgScaleOptions, formData?.vcg?.lower_left_limb)
          )}
          {getLabelTagInput(
            "Right Lower Limb",
            getLabelTag(vcgScaleOptions, formData?.vcg?.lower_right_limb)
          )}
          {getLabelTagInput(
            "Left Upper Limb",
            getLabelTag(vcgScaleOptions, formData?.vcg?.upper_left_limb)
          )}
          {getLabelTagInput(
            "Right Upper Limb",
            getLabelTag(vcgScaleOptions, formData?.vcg?.upper_right_limb)
          )}
        </>
      );
    }

    if (checkEmpty(formData?.moca)) {
      pushSummaryItem(
        "9",
        "MOCA",
        <>
          {getLabelTagInput("Score", formData?.moca?.score)}
          {getLabelTagInput("Interpretation", formData?.moca?.interpretation)}
          {getLabelTagInput(
            "Visual/Executive",
            formData?.moca?.visual_executive
          )}
          {getLabelTagInput("Naming", formData?.moca?.naming)}
          {getLabelTagInput("Attention", formData?.moca?.attention)}
          {getLabelTagInput("Language", formData?.moca?.language)}
          {getLabelTagInput("Abstraction", formData?.moca?.abstraction)}
          {getLabelTagInput("Delayed Recall", formData?.moca?.delayed_recall)}
          {getLabelTagInput("Orientation", formData?.moca?.orientation)}
        </>
      );
    }

    if (checkEmpty(formData?.finger_grip_test)) {
      pushSummaryItem(
        "10",
        "Finger Grip Test",
        <>
          {getLabelTagInput("Age", formData?.finger_grip_test?.age)}
          {getLabelTagInput(
            "Left Hand Grip Strength",
            formData?.finger_grip_test?.left_hand
          )}
          Fi
          {getLabelTagInput(
            "Left Hand Grip Interpretation",
            formData?.finger_grip_test?.left_hand_interpretation
          )}
          {getLabelTagInput(
            "Right Hand Grip Strength",
            formData?.finger_grip_test?.right_hand
          )}
          {getLabelTagInput(
            "Right Hand Grip Interpretation",
            formData?.finger_grip_test?.right_hand_interpretation
          )}
        </>
      );
    }
    if (checkEmpty(formData?.rom)) {
      pushSummaryItem(
        "11",
        "ROM",
        <>
          {formData?.rom?.rom?.shoulder_joint &&
            getLabelTagInput(
              "Shoulder Joint",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "abduction",
                  "adduction",
                  "medial_rotation",
                  "lateral_rotation",
                ],
                formData?.rom?.rom?.shoulder_joint
              )
            )}
          {formData?.rom?.rom?.elbow_joint &&
            getLabelTagInput(
              "Elbow Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.elbow_joint
              )
            )}
          {formData?.rom?.rom?.forearm_joint &&
            getLabelTagInput(
              "Forearm Joint",
              getRomSummary(
                ["supination", "pronation"],
                formData?.rom?.rom?.forearm_joint
              )
            )}
          {formData?.rom?.rom?.wrist_joint &&
            getLabelTagInput(
              "Wrist Joint",
              getRomSummary(
                ["flexion", "extension", "radial_deviation", "ulnar_deviation"],
                formData?.rom?.rom?.wrist_joint
              )
            )}
          {formData?.rom?.rom?.thumb_joint &&
            getLabelTagInput(
              "Thumb Joint",
              getRomSummary(
                ["cmc_flexion", "cmc_extension", "cmc_abduction", "ip_flexion"],
                formData?.rom?.rom?.thumb_joint
              )
            )}
          {formData?.rom?.rom?.metacarpel_joint &&
            getLabelTagInput(
              "Meta Carpel",
              getRomSummary(
                ["flexion", "hyperextension", "abduction"],
                formData?.rom?.rom?.metacarpel_joint
              )
            )}
          {formData?.rom?.rom?.proximal_joint &&
            getLabelTagInput(
              "Proximal Interphalangeal Joint",
              getRomSummary(["flexion"], formData?.rom?.rom?.proximal_joint)
            )}
          {formData?.rom?.rom?.distal_joint &&
            getLabelTagInput(
              "Disital Interphalangeal Joint",
              getRomSummary(
                ["flexion", "hyperextension"],
                formData?.rom?.rom?.distal_joint
              )
            )}
          {formData?.rom?.rom?.hip_joint &&
            getLabelTagInput(
              "Hip Joint",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "abduction",
                  "adduction",
                  "medial_rotation",
                  "lateral_rotation",
                ],
                formData?.rom?.rom?.hip_joint
              )
            )}
          {formData?.rom?.rom?.knee_joint &&
            getLabelTagInput(
              "Knee Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.knee_joint
              )
            )}
          {formData?.rom?.rom?.ankle_joint &&
            getLabelTagInput(
              "Ankle Joint",
              getRomSummary(
                ["dorsiflexion", "plantarflexion"],
                formData?.rom?.rom?.ankle_joint
              )
            )}
          {formData?.rom?.rom?.foot_joint &&
            getLabelTagInput(
              "Foot Joint",
              getRomSummary(
                ["inversion", "eversion"],
                formData?.rom?.rom?.foot_joint
              )
            )}
          {formData?.rom?.rom?.great_toe_joint &&
            getLabelTagInput(
              "Great Toe Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.great_toe_joint
              )
            )}
          {formData?.rom?.rom?.inter_phalangeal_joint &&
            getLabelTagInput(
              "Inter Phalangeal Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.inter_phalangeal_joint
              )
            )}
          {formData?.rom?.rom?.cervical_spine &&
            getLabelTagInput(
              "Cervical Spine",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "right_rotation",
                  "left_rotation",
                  "right_lateral_rotation",
                  "left_lateral_rotation",
                ],
                formData?.rom?.rom?.cervical_spine
              )
            )}
          {formData?.rom?.rom?.thoraco_lumbar_spine &&
            getLabelTagInput(
              "Thoraco Lumbar Spine",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "right_rotation",
                  "left_rotation",
                  "right_lateral_rotation",
                  "left_lateral_rotation",
                ],
                formData?.rom?.rom?.thoraco_lumbar_spine
              )
            )}
        </>
      );
    }

    if (checkEmpty(formData?.reflexes)) {
      pushSummaryItem(
        "12",
        "Reflexes",
        <> {getReflexesSummary(formData?.reflexes)}</>
      );
    }

    if (checkEmpty(formData?.coordination)) {
      pushSummaryItem(
        "13",
        "Coordination",
        <>
          {getLabelTagInput(
            "Finger-to-Nose Test",
            formData?.coordination?.finger_to_nose_test
          )}
          {getLabelTagInput(
            "Finger-to-Finger Test",
            formData?.coordination?.finger_to_finger_test
          )}
          {getLabelTagInput(
            "Adiadokokinesia or Dysdiadokokinesia",
            formData?.coordination?.adiadokokinesia
          )}
          {getLabelTagInput(
            "Rebound Phenomena",
            formData?.coordination?.rebound_phenomena
          )}
          {getLabelTagInput(
            "Walking Along a Straight Line",
            formData?.coordination?.walking_along_a_straight_line
          )}
          {getLabelTagInput(
            "Romberg's Test",
            formData?.coordination?.rombergs_test
          )}
          {getLabelTagInput(
            "Heel on Shin Test",
            formData?.coordination?.heel_on_shin_test
          )}
        </>
      );
    }

    if (checkEmpty(formData?.tcms)) {
      pushSummaryItem(
        "14",
        "TCMS",
        <>
          {getLabelTagInput("Score", formData?.tcms?.score)}
          {getLabelTagInput("Interpretation", formData?.tcms?.interpretation)}
          {getLabelTagInput(
            "Static Sitting Balance",
            formData?.tcms?.sitting_balance
          )}
          {getLabelTagInput(
            "Selective Movement Control",
            formData?.tcms?.selective_movement_control
          )}
          {getLabelTagInput(
            "Dynamic Reaching",
            formData?.tcms?.sitting_to_standing
          )}
        </>
      );
    }

    if (checkEmpty(formData?.barthel)) {
      pushSummaryItem(
        "15",
        "Barthel Index",
        <>
          {getLabelTagInput("Score", formData?.barthel?.score)}
          {getLabelTagInput(
            "Interpretation",
            formData?.barthel?.interpretation
          )}
          {getLabelScalesTags(barthelIndexOptions, formData?.barthel)}
        </>
      );
    }

    if (checkEmpty(formData?.asia)) {
      pushSummaryItem(
        "16",
        "ASIA",
        <>
          {getLabelTagInput(
            "Lower Extremity Motor Score",
            formData?.asia?.lower_extremity_motor_score
          )}
          {getLabelTagInput(
            "Upper Extremity Motor Score",
            formData?.asia?.upper_extremity_motor_score
          )}
          {getLabelTagInput(
            "Left Total Motor Score",
            formData?.asia?.left_total_motor_score
          )}
          {getLabelTagInput(
            "Right Total Motor Score",
            formData?.asia?.right_total_motor_score
          )}
          {getLabelTagInput(
            "Left Total Sensory Score",
            formData?.asia?.left_total_sensory_score
          )}
          {getLabelTagInput(
            "Right Total Sensory Score",
            formData?.asia?.right_total_sensory_score
          )}
          {getLabelTagInput("Total LT", formData?.asia?.total_lt)}
          {getLabelTagInput("Total PP", formData?.asia?.total_pp)}
          {getLabelTagInput(
            "DAP",
            getLabelTag(yesNoOptions, formData?.asia?.dap)
          )}
          {getLabelTagInput(
            "VAC",
            getLabelTag(yesNoOptions, formData?.asia?.vac)
          )}
          {getLabelTagInput("Status", formData?.asia?.interpretation)}
          {getLabelTagInput(
            "Sensory Left",
            getLabelTag(segmentsOptions, formData?.asia?.sensory_left)
          )}
          {getLabelTagInput(
            "Sensory Right",
            getLabelTag(segmentsOptions, formData?.asia?.sensory_right)
          )}
          {getLabelTagInput(
            "Motor Left",
            getLabelTag(segmentsOptions, formData?.asia?.motor_left)
          )}
          {getLabelTagInput(
            "Motor Right",
            getLabelTag(segmentsOptions, formData?.asia?.motor_right)
          )}
          {getLabelTagInput(
            "Neurological Level Injury",
            getLabelTag(
              segmentsOptions,
              formData?.asia?.nerological_level_of_injury
            )
          )}
          {getLabelTagInput(
            "ASIA Impairment Scale Grade",
            getLabelTag(
              asiaImpairmentGradeOptions,
              formData?.asia?.asia_impairment_scale
            )
          )}
          {getLabelTagInput(
            "Zone of Partial Preservation",
            getLabelTag(
              segmentsOptions,
              formData?.asia?.zone_of_partial_preservation
            )
          )}
        </>
      );
    }

    if (checkEmpty(formData?.nihss)) {
      pushSummaryItem(
        "17",
        "NIHSS",
        <>
          {getLabelTagInput("Score", formData?.nihss?.score)}
          {getLabelTagInput("Interpretation", formData?.nihss?.interpretation)}
          {getLabelScalesTags(nihssOptions, formData?.nihss)}
        </>
      );
    }

    if (checkEmpty(formData?.functional_diagnosis)) {
      pushSummaryItem(
        "18",
        "Functional Diagnosis",
        <>
          {getLabelTagsArray(
            neuroDiagnosisOptions,
            formData?.functional_diagnosis?.diagnosis
          )}
          {getLabelTagInput(
            "Stroke",
            getLabelTag(
              strokeHeadInjuryOptions,
              formData?.functional_diagnosis?.stroke
            )
          )}
          {getLabelTagInput(
            "Head Injury",
            getLabelTag(
              strokeHeadInjuryOptions,
              formData?.functional_diagnosis?.head_injury
            )
          )}
          {getLabelTagInput(
            "Spinal Cord Injury",
            getLabelTag(
              spinalOptions,
              formData?.functional_diagnosis?.spinal_cord_injury
            )
          )}
          {getLabelTagInput(
            "Comments",
            formData?.functional_diagnosis?.diagnosis_comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.therapy)) {
      pushSummaryItem(
        "19",
        "Therapy",
        <>
          {getLabelTagInput(
            "Stroke Rehab",
            getLabelTag(
              weeksStartingFourOptions,
              formData?.therapy?.stroke_rehab
            )
          )}
          {getLabelTagInput(
            "Brustromme Approach",
            formData?.therapy?.brustromme_approach
          )}
          {getLabelTagInput("MRP Approach", formData?.therapy?.mrp_approach)}
          {getLabelTagInput("NDT Approach", formData?.therapy?.ndt_approach)}
          {getLabelTagInput("CIMT Approach", formData?.therapy?.cimt_approach)}
          {getLabelTagInput(
            "VOJTA Approach",
            formData?.therapy?.vojta_approach
          )}
          {getLabelTagInput("PNF Approach", formData?.therapy?.pnf_approach)}
          {getLabelTagInput(
            "Electic Approach",
            formData?.therapy?.electic_approach
          )}
          {getLabelTagInput(
            "ROODS Approach",
            formData?.therapy?.roods_approach
          )}
          {getLabelTagInput(
            "Speech Therapy",
            formData?.therapy?.speech_therapy
          )}
          {getLabelTagInput(
            "Electical Muscle Stimulation",
            formData?.therapy?.electrical_muscle_stimulation
          )}
          {getLabelTagInput(
            "Mile Stone Training",
            formData?.therapy?.mile_stone_training
          )}
          {getLabelTagInput(
            "Cognitive Rehab",
            formData?.therapy?.cognitive_rehab
          )}
          {getLabelTagInput(
            "Neuro Conditioning Training",
            formData?.therapy?.neuro_conditioning_training
          )}
          {getLabelTagInput(
            "Sensory Integration",
            formData?.therapy?.sensory_integration
          )}
          {getLabelTagInput("ADL Training", formData?.therapy?.adl_training)}
          {getLabelTagInput(
            "Wheel Chair Training",
            formData?.therapy?.wheel_chair_training
          )}
          {getLabelTagInput(
            "Ultrasound",
            getLabelTag(daysUptoFifteenOptions, formData?.therapy?.ultrasound)
          )}
          {getLabelTagInput(
            "Splinting/ Bracing",
            getLabelTag(weeksStartingTwoOptions, formData?.therapy?.splinting)
          )}
          {getLabelTagInput(
            "Postural Correction",
            formData?.therapy?.postural_correction
          )}
          {getLabelTagInput("Robotics", formData?.therapy?.robotics)}
          {getLabelTagInput("Gait Training", formData?.therapy?.gait_training)}
          {getLabelTagInput("Therapy Comments", formData?.therapy?.description)}
        </>
      );
    }

    if (checkEmpty(formData?.review_notes)) {
      pushSummaryItem(
        "21",
        "Therapy",
        <>
          {getLabelTagInput(
            "Progress Notes",
            formData?.review_notes?.progress_notes
          )}
          {getLabelTagInput(
            "Review Notes",
            formData?.review_notes?.review_notes
          )}
        </>
      );
    }

    return neuroSummaryItems;
  };

  const neuroSummaryItems = constructNeuroSummaryItems();
  return (
    <div className="summaries">
      <Descriptions
        bordered
        column={1}
        title={"Neuro EMR"}
        items={neuroSummaryItems}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default NeuroSummary;
