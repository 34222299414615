import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/Auth/Login/Login";
import PageNotFound from "../components/ErrorPages/PageNotFound";
import Dashboard from "../components/Dashboard";
import PrivateRoute from "./PrivateRoutes";
import { UserProvider } from "../context/UserContext";
import AuthProvider from "../context/AuthProvider";
import Logout from "../components/Auth/Logout";
import Otp from "../components/Auth/Login/Otp";
import Buildings from "../components/Dashboard/Facilities/FloorPlan/Buildings";
import AccountDashboard from "../components/Account";
import OrganizationDashboard from "../components/OrganizationSettings";
import { ConfigProvider } from "antd";
import { customLightTheme } from "../theme/customTheme";
import NurseView from "../components/NurseView/NurseView";
import Receptionist from "../components/Receptionist/Receptionist";
import TimeTest from "../components/TimeTest";
import CommonView from "../components/PatientsIP/CommonView/CommonView";
import SetPassword from "../components/Auth/SetPassword/SetPassword";
import SwitchRole from "../components/Layout/CustomLayout/SwitchRole";
import CommonOPView from "../components/PatientsOP/CommonOPView/CommonOPView";
const ProjectRoutes = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <AuthProvider>
          <ConfigProvider theme={customLightTheme}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/otp/:token" element={<Otp />} />
              <Route path="/time-formats" element={<TimeTest />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/set-password" element={<SetPassword />} />
              <Route path="/logout" element={<Logout />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard/:tab" element={<Dashboard />} />
                <Route path="/switch-role/:tab" element={<SwitchRole />} />
                <Route path="/receptionist/:tab" element={<Receptionist />} />
                <Route path="/buildings" element={<Buildings />} />
                <Route path="/accounts/:tab" element={<AccountDashboard />} />
                <Route path="/doctor/:tab" element={<CommonView />} />
                <Route path="/nurse/:tab" element={<NurseView />} />
                <Route path="/nutritionist/:tab" element={<CommonView />} />
                <Route path="/physiotherapist/:tab" element={<CommonView />} />
                <Route
                  path="/respiratory-therapist/:tab"
                  element={<CommonView />}
                />
                <Route
                  path="/speech-swallow-therapist/:tab"
                  element={<CommonView />}
                />
                <Route path="/psychologist/:tab" element={<CommonView />} />
                <Route
                  path="/organization-settings/:tab"
                  element={<OrganizationDashboard />}
                />
                <Route path="/op/:tab" element={<CommonOPView />} />
                {/* <Route
                  path="/op-physiotherapist/:tab"
                  element={<Navigate to="/physiotherapist/op" replace />}
                /> */}
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </ConfigProvider>
        </AuthProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default ProjectRoutes;
