import React from "react";
import NoDataPage from "../../../NoDataPages/NoDataPage";
import startService from "../../../../assets/images/OPImages/startService.svg";
import { Col, Row } from "antd";
import { checkEmpty } from "../../../../helpers/utility";
import DisplaySummary from "./DisplaySummary";

const OPSummary = ({ currentEmr }) => {
  return checkEmpty(currentEmr) ? (
    <DisplaySummary currentEmr={currentEmr} />
  ) : (
    <Row
      //   gutter={[16, 16]}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
      }}
    >
      <Col span={24} style={{ textAlign: "center" }}>
        <NoDataPage
          subtitle="Click the 'Start Service' button to begin."
          image={startService}
        />
      </Col>
    </Row>
  );
};

export default OPSummary;
