import React from "react";
import MentalStatus from "./MentalStatus";
import CranialNerves from "./CranialNerves";
import SensorySystem from "./SensorySystem";
import MotorSystem from "./MotorSystem";
import Coordination from "./Coordination";
import Gait from "./Gait";
import MeningealSigns from "./MeningealSigns";
import CNSDetail from "./CNSDetail";
import { Divider, Form, Typography } from "antd";
import ReflexesForm from "./ReflexesForm";
import BergScale from "../../PhysioScales/BergScale";
import Posture from "./Posture";
import { DoctorInitialContext } from "../../../HistoryForm/DoctorHistoryFormProvider";
import TCMAssessment from "./TCMAssessment";
import PhysioMentalStatus from "./PhysioMentalStatus";
import PhysioMotorSystem from "./PhysioMotorSystem";

const CNSForm = ({ context, dailyReview = false, form }) => {
  return (
    <>
      {dailyReview ? (
        <CNSDetail />
      ) : (
        <>
          {context ? (
            <>
              <PhysioMentalStatus form={form} />
              <Divider />
              <Gait context={context} form={form} />
              <Divider />
              <Posture form={form} />
              <Divider />
              <BergScale context={context} form={form} />
              <Divider />
              <CranialNerves context={context} form={form} />
              <Divider />
              <SensorySystem context={context} form={form} />
              <Divider />
              {/* <MotorSystem context={context} form={form} /> */}
              <PhysioMotorSystem form={form} />
              <Divider />
              <ReflexesForm form={form} />
              <Divider />
              <Coordination context={context} form={form} />
              <Divider />
              <TCMAssessment context={context} form={form} />
            </>
          ) : (
            <>
              <MentalStatus context={DoctorInitialContext} form={form} />
              <Divider />

              <CranialNerves context={DoctorInitialContext} form={form} />
              <Divider />

              <SensorySystem context={DoctorInitialContext} form={form} />
              <Divider />

              <MotorSystem context={DoctorInitialContext} form={form} />
              <Divider />
              <Typography>Reflexes</Typography>
              <div className="subsection-card">
                <ReflexesForm />
              </div>
              <Divider />

              <Coordination context={DoctorInitialContext} form={form} />
              <Divider />

              <Gait context={DoctorInitialContext} form={form} />
              <Divider />

              <MeningealSigns form={form} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CNSForm;
