import React from "react";
import { Form, Input, Radio } from "antd";
import veryLightSvg from "../../../../../../assets/images/scalesImages/veryLight.svg";
import light1Svg from "../../../../../../assets/images/scalesImages/light1.svg";
import light2Svg from "../../../../../../assets/images/scalesImages/light2.svg";
import moderate1Svg from "../../../../../../assets/images/scalesImages/moderate1.svg";
import moderate2Svg from "../../../../../../assets/images/scalesImages/moderate2.svg";
import moderate3Svg from "../../../../../../assets/images/scalesImages/moderate3.svg";
import vigorous1Svg from "../../../../../../assets/images/scalesImages/vigorous1.svg";
import vigorous2Svg from "../../../../../../assets/images/scalesImages/vigorous2.svg";
import veryHardSvg from "../../../../../../assets/images/scalesImages/veryHard.svg";
import maxEffortSvg from "../../../../../../assets/images/scalesImages/maxEffort.svg";

const RPESelection = ({ nameKey = null, form, setRefresh = () => {} }) => {
  const rpeOptions = [
    {
      value: 1,
      label: "Very Light",
      image: veryLightSvg,
    },
    {
      value: 2,
      label: "Light",
      image: light1Svg,
    },
    {
      value: 3,
      label: "Light",
      image: light2Svg,
    },
    {
      value: 4,
      label: "Moderate",
      image: moderate1Svg,
    },
    {
      value: 5,
      label: "Moderate",
      image: moderate2Svg,
    },
    {
      value: 6,
      label: "Moderate",
      image: moderate3Svg,
    },
    {
      value: 7,
      label: "Vigorous",
      image: vigorous1Svg,
    },
    {
      value: 8,
      label: "Vigorous",
      image: vigorous2Svg,
    },
    {
      value: 9,
      label: "Very Hard",
      image: veryHardSvg,
    },
    {
      value: 10,
      label: "Max Effort",
      image: maxEffortSvg,
    },
  ];

  const handleRadioClick = (value) => {
    const name = nameKey ? [nameKey, "exertion"] : "exertion";
    const currentValue = form.getFieldValue(name);
    if (Array.isArray(name)) {
      // Create a nested object dynamically based on the array
      const nestedFieldUpdate = name.reduceRight(
        (acc, key, index) =>
          index === name.length - 1
            ? { [key]: currentValue === value ? null : value }
            : { [key]: acc },
        {}
      );

      form.setFieldsValue(nestedFieldUpdate);
      if (currentValue === value) {
        form.setFieldValue(
          nameKey ? [nameKey, "interpretation"] : "interpretation",
          ""
        );
      } else {
        form.setFieldValue(
          nameKey ? [nameKey, "interpretation"] : "interpretation",
          rpeOptions[value - 1]?.label
        );
        console.log(rpeOptions[value - 1]?.label);
      }
    } else {
      form.setFieldsValue({ [name]: currentValue === value ? null : value });
    }
    setRefresh((prev) => !prev);
  };
  return (
    <div className="physio-scales">
      <Form.Item
        name={nameKey ? [nameKey, "interpretation"] : "interpretation"}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item name={nameKey ? [nameKey, "exertion"] : "exertion"}>
        <Radio.Group className="radio-group">
          {rpeOptions.map((option) => (
            <Radio.Button
              key={option.value}
              value={option.value}
              style={{
                height: "100px",
                width: "80px",
                padding: 0,
                margin: "0 2px",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleRadioClick(option?.value)}
            >
              <div className="visual-option">
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.label}
                    className="visual-image"
                  />
                )}
                <div
                  className="visual-label"
                  style={{ fontSize: "10px", lineHeight: "1.2" }}
                >
                  {option.value}
                  <br />
                  {option.label}
                </div>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default RPESelection;
