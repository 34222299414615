import { Button, Form, Typography } from "antd";
import React, { useState } from "react";
import MotorSensoryScale from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ASIAScaleComponents/MotorSensoryScale";
import DapVac from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ASIAScaleComponents/DapVac";
import NeurologicalLevels from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ASIAScaleComponents/NeurologicalLevels";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";
import AsiaPdf from "../../../../../assets/scalesInfo/ASIAInfo.pdf";

const scaleInfo = {
  name: "ASIA",
  type: "pdf",
  url: AsiaPdf,
};
const ASIAForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [openInfo, setOpenInfo] = useState(false);
  const [scores, setScores] = useState({});
  console.log(formData);

  return (
    <div className="op-forms">
      <Form
        id="asia-form"
        form={form}
        onValuesChange={(cur, all) =>
          // console.log(all)
          onValuesChange("asia", {
            ...all,
            lower_extremity_motor_score:
              scores?.leftLowerMotor + scores?.rightLowerMotor,
            upper_extremity_motor_score:
              scores?.leftUpperMotor + scores?.rightUpperMotor,
            left_total_motor_score:
              scores?.leftLowerMotor + scores?.leftUpperMotor,
            right_total_motor_score:
              scores?.rightLowerMotor + scores?.rightUpperMotor,
            left_total_sensory_score: scores?.ltl + scores?.ppl,
            right_total_sensory_score: scores?.ltr + scores?.ppr,
            total_lt: scores?.ltl + scores?.ppl,
            total_pp: scores?.ppl + scores?.ppr,
          })
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.asia}
      >
        <Typography>American Spinal Injury Association</Typography>
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <MotorSensoryScale scores={scores} setScores={setScores} form={form} />
        <DapVac form={form} />
        <NeurologicalLevels />
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default ASIAForm;
