import React from "react";
import CommonTable from "../../CommonTable";
import dayjs from "dayjs";

const InnerCannulaView = ({ data }) => {
  const columns = [
    {
      title: "Time",
      dataIndex: "process_time",
      render: (time) => dayjs(time)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "Patency",
      dataIndex: "patency",
    },
    {
      title: "Cleaning",
      dataIndex: "cleaning",
    },
    {
      title: "Change",
      dataIndex: "change",
    },
  ];
  return (
    <>
      <CommonTable
        data={
          data?.respiratory_care_tracheostomy_care_inner_cannula?.nurse_data
        }
        columns={columns}
        //   titleData={() => {}}
      />
    </>
  );
};

export default InnerCannulaView;
