import { Card, Col, Image, Row, Statistic } from "antd";
import React from "react";
const StatCardLayout = ({
  title,
  val = 0,
  precision = 0,
  color = "#845EC2",
  prefix = null,
  suffix = null,
  icon,
  footer = null,
}) => {
  return (
    <>
      <Card bordered={false} style={{ height: 120 }}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Statistic
              title={title}
              value={val}
              precision={precision}
              valueStyle={{ color: color }}
              prefix={prefix}
              suffix={suffix}
            />
          </Col>
          <Col span={12} className="flex-end">
            <Image src={icon} preview={false} />
          </Col>
          {footer && <Col span={24}>{footer}</Col>}
        </Row>
      </Card>
    </>
  );
};

export default StatCardLayout;
