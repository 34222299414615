import React from "react";
import { Row, Col, Typography } from "antd";

const { Text } = Typography;

const PatientProfile = () => {
  const patientData = {
    name: "Raju",
    age: 31,
    sex: "Male",
    contactNumber: "9121634210",
    ipNumber: "883",
    uidNumber: "498",
    address: "-----",
    dateOfAdmission: "November 26, 2024",
    dateOfDischarge: "Active Patient",
    primaryConsultant: "-----",
  };

  return (
    <div style={{ background: "#fff", borderRadius: "8px" }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Name</Text>
          <br />
          <Text>{patientData.name}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Sex</Text>
          <br />
          <Text>{patientData.sex}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Contact Number</Text>
          <br />
          <Text>{patientData.contactNumber}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>IP Number</Text>
          <br />
          <Text>{patientData.ipNumber}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Date of Admission</Text>
          <br />
          <Text>{patientData.dateOfAdmission}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Date of Discharge</Text>
          <br />
          <Text>{patientData.dateOfDischarge}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Age</Text>
          <br />
          <Text>{patientData.age}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Address</Text>
          <br />
          <Text>{patientData.address}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>UID Number</Text>
          <br />
          <Text>{patientData.uidNumber}</Text>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text strong>Primary Consultant</Text>
          <br />
          <Text>{patientData.primaryConsultant}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default PatientProfile;
