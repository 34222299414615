import { Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import CommonTable from "../../CommonTable";

const DailyReviewTable = ({ data }) => {
  const columns = [
    {
      title: "Time",
      dataIndex: "process_time",
      render: (time) => dayjs(time)?.format("DD, MMM hh:mm A"),
    },
    {
      title: "TT Diameter",
      dataIndex: "tt_diameter",
    },
    {
      title: "Cuffed (cmH20)",
      dataIndex: "cuffed",
    },
    {
      title: "Fenestrated",
      dataIndex: "fenestrated",
    },
    {
      title: "Speaking Value",
      dataIndex: "speaking_value",
    },
    {
      title: "Sutures",
      dataIndex: "sutures",
    },
    {
      title: "Dressing",
      dataIndex: "dressing",
    },
  ];
  return (
    <>
      <CommonTable
        data={data?.respiratory_care_tracheostomy_care_daily_review?.nurse_data}
        columns={columns}
        //   titleData={() => {}}
      />
    </>
  );
};

export default DailyReviewTable;
