import React, { useState } from "react";
import { Table, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../helpers/utility";
import OnboardingForm from "./OnboardingForm";

const OnboardingTable = ({ data, refresh, loading }) => {
  const [editData, setEditData] = useState({});
  const [open, setOpen] = useState(false);

  const columns = [
    { title: "Mobile", dataIndex: "mobile", key: "mobile" },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (value, record) =>
        capitalizeFirstLetter(value) +
        " " +
        capitalizeFirstLetter(record?.last_name),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (value) => (value ? value : "-"),
    },
    { title: "Address", dataIndex: "address", key: "address" },
    {
      title: "Hospital",
      dataIndex: "partner_organization",
      key: "partner_organization",
    },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button
          onClick={() => {
            setOpen(true);
            setEditData(record);
          }}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];
  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        loading={loading}
      />
      {open && (
        <OnboardingForm
          open={open}
          setOpen={setOpen}
          editData={editData}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default OnboardingTable;
