import React, { useContext } from "react";
import { Col, Menu, Row, Image } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dashboardIcon from "../../../assets/images/menuImages/dashboard.svg";
import facilityIcon from "../../../assets/images/menuImages/facilities.svg";
import activeFacility from "../../../assets/images/menuImages/fecilitiesActive.svg";
import services from "../../../assets/images/menuImages/services.svg";
import activeServices from "../../../assets/images/menuImages/servicesActive.svg";
import investigation from "../../../assets/images/menuImages/investigation.svg";
import medication from "../../../assets/images/menuImages/medication.svg";
import diet from "../../../assets/images/menuImages/diet.svg";
import patients from "../../../assets/images/menuImages/patients.svg";
import users from "../../../assets/images/menuImages/users.svg";
import activeUserIcon from "../../../assets/images/menuImages/activeUserIcon.svg";
import activeDiet from "../../../assets/images/menuImages/activeDiet.svg";
import patientsActive from "../../../assets/images/menuImages/patientsActive.svg";
import investigationActive from "../../../assets/images/menuImages/investigationActive.svg";
import medicalActive from "../../../assets/images/menuImages/medicalActive.svg";
import homeActive from "../../../assets/images/menuImages/homeActive.svg";
import partnerActive from "../../../assets/images/menuImages/partner-active.svg";
import partnerInActive from "../../../assets/images/menuImages/partner-inactive.svg";
import { UserContext } from "../../../context/UserContext";
import { getCookie } from "../../../helpers/localStorage";
import { Role } from "../../../constants/defaultKeys";

const SideMenu = ({ collapsed }) => {
  const { userDetails } = useContext(UserContext);
  const router = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const defaultRole = userDetails?.currRole || getCookie(Role);

  const facilityName =
    defaultRole === "Facility_Admin" ? "Facility" : "Facilities";

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menuImages = (icon) => {
    return (
      <span
        role="img"
        aria-label="user"
        class="anticon anticon-user ant-menu-item-icon"
      >
        <Image
          src={icon}
          preview={false}
          style={{ height: "20px", width: "auto", marginBottom: "8px" }}
        />
      </span>
    );
  };
  const items = [
    getItem(
      "Dashboard",
      "home",
      router?.tab === "home"
        ? menuImages(homeActive)
        : menuImages(dashboardIcon)
    ),
    getItem(
      facilityName,
      "facilities",
      router?.tab === "facilities"
        ? menuImages(activeFacility)
        : menuImages(facilityIcon)
    ),
    getItem(
      "Services & Packages",
      "services",
      router?.tab === "services"
        ? menuImages(activeServices)
        : menuImages(services)
    ),
    getItem(
      "Investigations",
      "investigation",
      router?.tab === "investigation"
        ? menuImages(investigationActive)
        : menuImages(investigation)
    ),
    getItem(
      "Medicines",
      "medication",
      router?.tab === "medication"
        ? menuImages(medicalActive)
        : menuImages(medication)
    ),
    getItem(
      "Diet Menu",
      "diet-menu",
      router?.tab === "diet-menu" ? menuImages(activeDiet) : menuImages(diet)
    ),
    getItem(
      "Patients",
      "patients",
      router?.tab === "patients"
        ? menuImages(patientsActive)
        : menuImages(patients)
    ),
    ...(defaultRole === "Organization_Admin" || defaultRole === "Facility_Admin"
      ? [
          getItem(
            "Partner Onboarding",
            "partner_onboarding",
            router?.tab === "partner_onboarding"
              ? menuImages(partnerActive)
              : menuImages(partnerInActive)
          ),
        ]
      : []),
    getItem(
      "Users",
      "users",
      router?.tab === "users" ? menuImages(activeUserIcon) : menuImages(users)
    ),
  ];

  const onClick = (e) => {
    const isFacilityAdmin = defaultRole === "Facility_Admin";
    const facilityParam = isFacilityAdmin
      ? `facility=${
          params?.facility
            ? params?.facility
            : userDetails?.activeOrg?.facilities?.[0]?.facility_id
        }`
      : "";

    let path;

    switch (e.key) {
      case "home":
        path = `/dashboard/${e.key}?type=admissions-overview`;
        break;
      case "users":
        path = `/dashboard/${e.key}?role=all`;
        break;
      case "medication":
        path = `/dashboard/${e.key}?medicine=medicine_list`;
        break;
      case "services":
        path = `/dashboard/${e.key}?type=categories`;
        break;
      default:
        path = `/dashboard/${e.key}`;
    }

    if (isFacilityAdmin) {
      path += path.includes("?") ? `&${facilityParam}` : `?${facilityParam}`;
    }

    navigate(path);
  };
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Menu
              style={{ border: 0 }}
              onClick={onClick}
              selectedKeys={[router?.tab]}
              defaultSelectedKeys={[router?.tab]}
              inlineCollapsed={collapsed}
              items={items}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SideMenu;
