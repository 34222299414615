import { Form, Input } from "antd";
import React from "react";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";
import { yesNoOptions } from "../../../../../constants/defaultKeys";

const PastSurgicalOP = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Past Surgical History"}
        name={"past_surgical_history"}
        form={form}
        options={yesNoOptions}
      />
      <Form.Item name={"past_surgical_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item>
    </>
  );
};

export default PastSurgicalOP;
