import React from "react";
import { Collapse } from "antd";
import Ambulation from "./FunctionalStatusues/Ambulation";
import BladderInconsistence from "./FunctionalStatusues/BladderInconsistence";
import BowelInconsistence from "./FunctionalStatusues/BowelInconsistence";

const FunctionalStatus = () => {
  return (
    <>
      <Collapse expandIconPosition="right" defaultActiveKey={["1", "2", "3"]}>
        <Collapse.Panel header="Ambulation" key="1">
          <Ambulation />
        </Collapse.Panel>
        <Collapse.Panel header="Bladder Incontinence" key="2">
          <BladderInconsistence />
        </Collapse.Panel>
        <Collapse.Panel header="Bowel Incontinence" key="3">
          <BowelInconsistence />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default FunctionalStatus;
