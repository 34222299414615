import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  List,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import Visual from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Visual";
import Naming from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Naming";
import Memory from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Memory";
import Attention from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Attention";
import Language from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Language";
import Abstraction from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Abstraction";
import DelayedRecall from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/DelayedRecall";
import Orientation from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/MOCAScaleComponents/Orientation";
import MocaPdf from "../../../../../assets/scalesInfo/MOCAInfo.pdf";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";

const scaleInfo = {
  name: "Moca",
  type: "pdf",
  url: MocaPdf,
};
const { Panel } = Collapse;
const MOCAForm = ({ onValuesChange, formData }) => {
  const [openInfo, setOpenInfo] = useState(false);
  useEffect(() => {
    setVisual(formData?.moca?.moca?.visual_executive_data || {});
    setNaming(formData?.moca?.moca?.naming_data || {});
    setAttention(formData?.moca?.moca?.attention_data || {});
    setLanguage(formData?.moca?.moca?.language_data || {});
    setAbstraction(formData?.moca?.moca?.abstraction_data || {});
    setDelayedRecall(formData?.moca?.moca?.delayed_recall_data || {});
    setOrientation(formData?.moca?.moca?.orientation_data || {});
  }, [formData]);
  const [form] = Form.useForm();
  const [visual, setVisual] = useState({});
  const [naming, setNaming] = useState({});
  const [attention, setAttention] = useState({});
  const [language, setLanguage] = useState({});
  const [abstraction, setAbstraction] = useState({});
  const [delayedRecall, setDelayedRecall] = useState({});
  const [orientation, setOrientation] = useState({});

  const calculateAggregate = (states, special) => {
    let sum = 0;
    if (special === false) {
      sum = Object.values(states).reduce(
        (acc, cur) => acc + (cur === "yes" ? 1 : 0),
        0
      );
    } else {
      sum = Object.values(states).reduce(
        (acc, cur) => acc + (cur === "yes" ? 1 : 0),
        0
      );
      const len = states?.attention_4?.length || 0;
      sum += len >= 4 ? 3 : len >= 2 ? 2 : len;
    }
    // form.setFieldsValue({ moca: { aggregate: sum } });
    return sum;
  };
  const visualAggregate = calculateAggregate(visual, false);
  const namingAggregate = calculateAggregate(naming, false);
  const attentionAggregate = calculateAggregate(attention, true);
  const languageAggregate = calculateAggregate(language, false);
  const abstractionAggregate = calculateAggregate(abstraction, false);
  const delayedRecallAggregate = calculateAggregate(delayedRecall, false);
  const orientationAggregate = calculateAggregate(orientation, false);
  const aggregate =
    (visualAggregate || 0) +
    (namingAggregate || 0) +
    (attentionAggregate || 0) +
    (languageAggregate || 0) +
    (abstractionAggregate || 0) +
    (delayedRecallAggregate || 0) +
    (orientationAggregate || 0);

  const interpretation =
    aggregate >= 26 ? "Normal Cognition" : "Abnormal Cognition";
  return (
    <div className="op-forms">
      <Form
        id="moca-form"
        form={form}
        onValuesChange={(cur, all) =>
          onValuesChange("moca", {
            ...all,
            score: aggregate + 1, //not a proper way
            interpretation:
              aggregate + 1 >= 26 ? "Normal Cognition" : "Abnormal Cognition",
            visual_executive: calculateAggregate(
              all?.moca?.visual_executive_data
            ),
            naming: calculateAggregate(all?.moca?.naming_data ?? {}),
            attention: calculateAggregate(all?.moca?.attention_data ?? {}),
            language: calculateAggregate(all?.moca?.language_data ?? {}),
            abstraction: calculateAggregate(all?.moca?.abstraction_data ?? {}),
            delayed_recall: calculateAggregate(
              all?.moca?.delayed_recall_data ?? {}
            ),
            orientation: calculateAggregate(all?.moca?.orientation_data ?? {}),
          })
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.moca}
      >
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <List bordered style={{ backgroundColor: "#fff" }}>
          <List.Item>
            <Typography.Text strong>
              MOCA Score: {aggregate}
              /30{form.setFieldsValue({ moca: { score: aggregate } })}
            </Typography.Text>
            <Form.Item name={["moca", "score"]} hidden>
              <Input />
            </Form.Item>
          </List.Item>
          <List.Item>
            <Typography.Text strong>
              Interpretation: {interpretation}
              {form.setFieldsValue({
                moca: { interpretation: interpretation },
              })}
            </Typography.Text>
            <Form.Item name={["moca", "interpretation"]} hidden>
              <Input />
            </Form.Item>
          </List.Item>
        </List>
        <Collapse defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}>
          <Panel
            header={
              <Row>
                <Col span={12}>Visual/Executive</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {visualAggregate} / 5
                </Col>
              </Row>
            }
            key="1"
          >
            <Visual psych={true} setVisual={setVisual} />
          </Panel>
          <Panel
            header={
              <Row>
                <Col span={12}>Naming</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {namingAggregate} / 3
                </Col>
              </Row>
            }
            key="2"
          >
            <Naming psych={true} setNaming={setNaming} />
          </Panel>
          <Panel header="Memory" key="3">
            <Memory psych={true} />
          </Panel>
          <Panel
            header={
              <Row>
                <Col span={12}>Attention</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {attentionAggregate} / 6
                </Col>
              </Row>
            }
            key="4"
          >
            <Attention psych={true} setAttention={setAttention} />
          </Panel>
          <Panel
            header={
              <Row>
                <Col span={12}>Language</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {languageAggregate} / 3
                </Col>
              </Row>
            }
            key="5"
          >
            <Language psych={true} setLanguage={setLanguage} />
          </Panel>
          <Panel
            header={
              <Row>
                <Col span={12}>Abstraction</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {abstractionAggregate} / 2
                </Col>
              </Row>
            }
            key="6"
          >
            <Abstraction psych={true} setAbstraction={setAbstraction} />
          </Panel>
          <Panel
            header={
              <Row>
                <Col span={12}>Delayed Recall</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {delayedRecallAggregate} / 5
                </Col>
              </Row>
            }
            key="7"
          >
            <DelayedRecall psych={true} setDelayedRecall={setDelayedRecall} />
          </Panel>
          <Panel
            header={
              <Row>
                <Col span={12}>Orientation</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  Score: {orientationAggregate} / 6
                </Col>
              </Row>
            }
            key="8"
          >
            <Orientation psych={true} setOrientation={setOrientation} />
          </Panel>
        </Collapse>
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default MOCAForm;
