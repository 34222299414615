import { Collapse } from "antd";
import React from "react";
import ShoulderJoint from "../../PhysioScales/ROMScaleComponents/ShoulderJoint";
import ElbowJoint from "../../PhysioScales/ROMScaleComponents/ElbowJoint";
import ForearmJoint from "../../PhysioScales/ROMScaleComponents/ForearmJoint";
import WristJoint from "../../PhysioScales/ROMScaleComponents/WristJoint";
import ThumbJoint from "../../PhysioScales/ROMScaleComponents/ThumbJoint";
import MetacarpelJoint from "../../PhysioScales/ROMScaleComponents/MetacarpelJoint";
import ProximalJoint from "../../PhysioScales/ROMScaleComponents/ProximalJoint";
import DisitalJoint from "../../PhysioScales/ROMScaleComponents/DisitalJoint";
import HipJoint from "../../PhysioScales/ROMScaleComponents/HipJoint";
import KneeJoint from "../../PhysioScales/ROMScaleComponents/KneeJoint";
import AnkleJoint from "../../PhysioScales/ROMScaleComponents/AnkleJoint";
import FootJoint from "../../PhysioScales/ROMScaleComponents/FootJoint";
import GreatToeJoint from "../../PhysioScales/ROMScaleComponents/GreatToeJoint";
import InterPhallengealJoint from "../../PhysioScales/ROMScaleComponents/InterPhallengealJoint";
import CervicalSpine from "../../PhysioScales/ROMScaleComponents/CervicalSpine";
import ThoracoLumbarSpine from "../../PhysioScales/ROMScaleComponents/ThoracoLumbarSpine";

const { Panel } = Collapse;
const ROMItems = () => {
  return (
    <>
      <Collapse
        defaultActiveKey={[
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
        ]}
      >
        <Panel header="Shoulder Joint" key="1">
          <ShoulderJoint initial={true} />
        </Panel>
        <Panel header="Elbow Joint" key="2">
          <ElbowJoint initial={true} />
        </Panel>
        <Panel header="Forearm Joint" key="3">
          <ForearmJoint initial={true} />
        </Panel>
        <Panel header="Wrist Joint" key="4">
          <WristJoint initial={true} />
        </Panel>
        <Panel header="Thumb Joint" key="5">
          <ThumbJoint initial={true} />
        </Panel>
        <Panel header="Meta Carpel Joint" key="6">
          <MetacarpelJoint initial={true} />
        </Panel>
        <Panel header="Proximal Interphalangeal Joint" key="7">
          <ProximalJoint initial={true} />
        </Panel>
        <Panel header="Disital Interphalangeal Joint" key="8">
          <DisitalJoint initial={true} />
        </Panel>
        <Panel header="Hip Joint" key="9">
          <HipJoint initial={true} />
        </Panel>
        <Panel header="Knee Joint" key="10">
          <KneeJoint initial={true} />
        </Panel>
        <Panel header="Ankle Joint" key="11">
          <AnkleJoint initial={true} />
        </Panel>
        <Panel header="Foot Joint" key="12">
          <FootJoint initial={true} />
        </Panel>
        <Panel header="Great Toe Joint" key="13">
          <GreatToeJoint initial={true} />
        </Panel>
        <Panel header="Interphalangeal Joint" key="14">
          <InterPhallengealJoint initial={true} />
        </Panel>
        <Panel header="Cervical Spine" key="15">
          <CervicalSpine initial={true} />
        </Panel>
        <Panel header="Thoraco Lumbar Spine" key="16">
          <ThoracoLumbarSpine initial={true} />
        </Panel>
      </Collapse>
    </>
  );
};

export default ROMItems;
