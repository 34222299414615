import { Button, Form, Typography } from "antd";
import React, { useState } from "react";
import RPESelection from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/RPEScaleComponents/RPESelection";
import RPEImg from "../../../../../assets/scalesInfo/RPEInfo.png";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";
import veryLightSvg from "../../../../../assets/images/scalesImages/veryLight.svg";
import light1Svg from "../../../../../assets/images/scalesImages/light1.svg";
import light2Svg from "../../../../../assets/images/scalesImages/light2.svg";
import moderate1Svg from "../../../../../assets/images/scalesImages/moderate1.svg";
import moderate2Svg from "../../../../../assets/images/scalesImages/moderate2.svg";
import moderate3Svg from "../../../../../assets/images/scalesImages/moderate3.svg";
import vigorous1Svg from "../../../../../assets/images/scalesImages/vigorous1.svg";
import vigorous2Svg from "../../../../../assets/images/scalesImages/vigorous2.svg";
import veryHardSvg from "../../../../../assets/images/scalesImages/veryHard.svg";
import maxEffortSvg from "../../../../../assets/images/scalesImages/maxEffort.svg";
const scaleInfo = {
  name: "RPE",
  type: "image",
  url: RPEImg,
};
const rpeOptions = [
  {
    value: 1,
    label: "Very Light",
    image: veryLightSvg,
  },
  {
    value: 2,
    label: "Light",
    image: light1Svg,
  },
  {
    value: 3,
    label: "Light",
    image: light2Svg,
  },
  {
    value: 4,
    label: "Moderate",
    image: moderate1Svg,
  },
  {
    value: 5,
    label: "Moderate",
    image: moderate2Svg,
  },
  {
    value: 6,
    label: "Moderate",
    image: moderate3Svg,
  },
  {
    value: 7,
    label: "Vigorous",
    image: vigorous1Svg,
  },
  {
    value: 8,
    label: "Vigorous",
    image: vigorous2Svg,
  },
  {
    value: 9,
    label: "Very Hard",
    image: veryHardSvg,
  },
  {
    value: 10,
    label: "Max Effort",
    image: maxEffortSvg,
  },
];
const RPEForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [openInfo, setOpenInfo] = useState(false);
  return (
    <div className="op-forms">
      <Form
        id="rpe-form"
        form={form}
        onValuesChange={(cur, all) =>
          onValuesChange("rpe", {
            ...all,
            interpretation: rpeOptions[all?.exertion - 1]?.label,
          })
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.rpe}
      >
        <Typography>Rating of perceived exertion</Typography>
        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
          style={{ marginBottom: 15 }}
        >
          Info
        </Button>
        <RPESelection form={form} />
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default RPEForm;
