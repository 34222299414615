import { Form } from "antd";
import React from "react";
import VitalsAdmission from "../../../../IpEMR/InspectionForms/Examination/GeneralExaminationForm/GeneralExaminationComponents/VitalsAdmission";

const VitalsForm = ({ onValuesChange, formData }) => {
  return (
    <>
      <Form
        id="vitals"
        onValuesChange={(cur, all) => onValuesChange("vitals", all)}
        className="ip-forms"
        initialValues={formData?.vitals}
      >
        <VitalsAdmission op={true} />
      </Form>
    </>
  );
};

export default VitalsForm;
