import React from "react";
import DoctorLayout from "../../Layout/DoctorLayout";
import PatientTypeContextProvider from "../../Layout/DoctorLayout/PatientTypeContextProvider";
import OPLandingLayout from "../OPLandingLayout/OPLandingLayout";

const CommonOPView = () => {
  return (
    <PatientTypeContextProvider>
      <DoctorLayout header={true}>
        <OPLandingLayout />
      </DoctorLayout>
    </PatientTypeContextProvider>
  );
};

export default CommonOPView;
