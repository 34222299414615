import React from "react";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";
import {
  alcoholOptions,
  smokingOptions,
} from "../../../../IpEMR/Options/historyOptions";
import { activePassiveOptions } from "../OpPhysioOptions";
import { Form, Input } from "antd";

const Habits = ({ form }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Smoking"}
        name={"smoking"}
        form={form}
        options={smokingOptions}
      />
      <DeselectableRadioGroup
        label={"Alcohol"}
        name={"alcohol"}
        form={form}
        options={alcoholOptions}
      />
      <DeselectableRadioGroup
        label={"Life Style"}
        name={"life_style"}
        form={form}
        options={activePassiveOptions}
      />
      <Form.Item name={"habits_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item>
    </>
  );
};

export default Habits;
