import { Form, Radio } from "antd";
import React from "react";
import unarousableSvg from "../../../../../../assets/images/scalesImages/unarousable.svg";
import deepSedationSvg from "../../../../../../assets/images/scalesImages/deepSedation.svg";
import moderateSedationSvg from "../../../../../../assets/images/scalesImages/moderateSedation.svg";
import lightSedationSvg from "../../../../../../assets/images/scalesImages/lightSedation.svg";
import drowsySvg from "../../../../../../assets/images/scalesImages/drowsy.svg";
import alertAndCalmSvg from "../../../../../../assets/images/scalesImages/alertAndCalm.svg";
import restlessSvg from "../../../../../../assets/images/scalesImages/restless.svg";
import agitatedSvg from "../../../../../../assets/images/scalesImages/agitated.svg";
import veryAgitatedSvg from "../../../../../../assets/images/scalesImages/veryAgitated.svg";
import combativeSvg from "../../../../../../assets/images/scalesImages/combative.svg";

const rassOptions = [
  {
    value: -5,
    label: "Unarousable",
    image: unarousableSvg,
  },
  { value: -4, label: "Deep Sedation", image: deepSedationSvg },
  { value: -3, label: "Moderate Sedation", image: moderateSedationSvg },
  { value: -2, label: "Light Sedation", image: lightSedationSvg },
  { value: -1, label: "Drowsy", image: drowsySvg },
  { value: 0, label: "Alert and Calm", image: alertAndCalmSvg },
  { value: 1, label: "Restless", image: restlessSvg },
  { value: 2, label: "Agitated", image: agitatedSvg },
  { value: 3, label: "Very Agitated", image: veryAgitatedSvg },
  { value: 4, label: "Combative", image: combativeSvg },
];

const RASScaleItems = ({ form, setRefresh }) => {
  const handleRadioClick = (value) => {
    const currentValue = form.getFieldValue("rass_scale");
    form.setFieldValue("rass_scale", currentValue === value ? null : value);
    setRefresh((prev) => !prev);
  };
  return (
    <div className="physio-scales">
      <Form.Item name="rass_scale">
        {/* <Typography>Richmond Agitation - Sedation Scale</Typography> */}
        <Radio.Group className="radio-group">
          {rassOptions.map((option) => (
            <Radio.Button
              key={option.value}
              value={option.value}
              style={{
                height: "100px",
                width: "90px",
                padding: 0,
                margin: "0 2px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleRadioClick(option?.value)}
            >
              <div className="visual-option">
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.label}
                    className="visual-image"
                  />
                )}
                <div
                  className="visual-label"
                  style={{ fontSize: "10px", lineHeight: "1.2" }}
                >
                  {option.value}
                  <br />
                  {option.label}
                </div>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default RASScaleItems;
