import { Descriptions, Image, Space } from "antd";
import React from "react";
import {
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagswithCheckbox,
} from "../constructTextFromValues";
import {
  cardioDiagnosisOptions,
  neuroDiagnosisOptions,
  orthoDiagnosisOptions,
  spinalCordOptions,
  sportsDiagnosisOptions,
  strokeOptions,
} from "../../Options/physioOptions";
import diagnosisIcon from "../../../../assets/doctorIcons/diagnosis.svg";

const PhysioFunctionalDiagnosisSummary = ({ formData }) => {
  const filteredNeuroOptions = neuroDiagnosisOptions.filter(
    (option) =>
      !["stroke", "head_injury", "spinal_cord_injury"].includes(option.value)
  );
  const constructFunctionalDiagnosisSummaryItems = () => {
    const functionalDiagnosisSummaryItems = [];
    const diagnosis = formData?.functional_diagnosis || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalDiagnosisSummaryItems.push({ key, label, children });
    };
    if (diagnosis?.cardio) {
      pushFunctionalItem(
        "1",
        "Cardio",
        // getLabelTagsArray(cardioDiagnosisOptions, diagnosis?.cardio_options)
        getLabelTagswithCheckbox(cardioDiagnosisOptions, "cardio", diagnosis)
      );
    }
    if (diagnosis?.ortho) {
      pushFunctionalItem(
        "2",
        "Ortho",
        getLabelTagswithCheckbox(orthoDiagnosisOptions, "ortho", diagnosis)
      );
    }
    if (diagnosis?.sports) {
      pushFunctionalItem(
        "3",
        "Sports",
        <>
          {getLabelTagswithCheckbox(
            sportsDiagnosisOptions,
            "sports",
            diagnosis
          )}
          {getLabelTagInput("Others", diagnosis?.sports_description)}
        </>
      );
    }
    if (diagnosis?.neuro) {
      pushFunctionalItem(
        "4",
        "Neuro",
        <>
          {diagnosis?.neuro_stroke_options?.length > 0 &&
            getLabelTagInput(
              "Stroke",
              <>
                {getLabelTagsArray(
                  strokeOptions,
                  diagnosis?.neuro_stroke_options
                )}
                {diagnosis?.neuro_stroke_description &&
                  getLabelTagInput(
                    "Others Details",
                    diagnosis?.neuro_stroke_description
                  )}
              </>
            )}
          {diagnosis?.neuro_head_injury_options?.length > 0 &&
            getLabelTagInput(
              "Head Injury",
              <>
                {getLabelTagsArray(
                  strokeOptions,
                  diagnosis?.neuro_head_injury_options
                )}
                {diagnosis?.neuro_head_injury_description &&
                  getLabelTagInput(
                    "Others Details",
                    diagnosis?.neuro_head_injury_description
                  )}
              </>
            )}
          {diagnosis?.neuro_spinal_cord_injury_options?.length > 0 &&
            getLabelTagInput(
              "Spinal Cord Injury",
              <>
                {getLabelTagsArray(
                  spinalCordOptions,
                  diagnosis?.neuro_spinal_cord_injury_options
                )}
                {diagnosis?.neuro_spinal_cord_injury_description &&
                  getLabelTagInput(
                    "Others Details",
                    diagnosis?.neuro_spinal_cord_injury_description
                  )}
              </>
            )}
          {getLabelTagswithCheckbox(filteredNeuroOptions, "neuro", diagnosis)}
          {getLabelTagInput("Other Details", diagnosis?.neuro_description)}
        </>
      );
    }
    return functionalDiagnosisSummaryItems;
  };
  const functionalDiagnosisSummaryItems =
    constructFunctionalDiagnosisSummaryItems();
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        title={
          <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
            <Space>
              <Image src={diagnosisIcon} width={18} preview={false} />
              <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                Functional Diagnosis
              </span>
            </Space>
          </span>
        }
        items={functionalDiagnosisSummaryItems}
        style={{ margin: "0", border: "none" }}
        className="initial-insp-desc"
      />
    </div>
  );
};

export default PhysioFunctionalDiagnosisSummary;
