import React, { useEffect, useState } from "react";
import { getServiceAPI } from "../../../../services/physio.services";
import CardioSummary from "../Forms/CardiopulmonaryForm/CardioSummary";
import NeuroSummary from "../Forms/NeuroForm/NeuroSummary";
import OrthoSummary from "../Forms/Orthoform/OrthoSummary";
import SportsSummary from "../Forms/SportsForm/SportsSummary";

const DisplaySummary = ({ currentEmr }) => {
  const renderSummary = () => {
    switch (service) {
      case "cardiopulmonary":
        return <CardioSummary formData={formData} />;
      case "neuro":
        return <NeuroSummary formData={formData} />;
      case "ortho":
        return <OrthoSummary formData={formData} />;
      case "sports":
        return <SportsSummary formData={formData} />;
    }
  };
  const [formData, setFormData] = useState([]);
  const service =
    currentEmr?.service_type === "cardio"
      ? "cardiopulmonary"
      : currentEmr?.service_type;
  const id = currentEmr?.id;

  useEffect(() => {
    getServiceAPI(service, id)
      .then((res) => setFormData(res?.data))
      .catch((error) => console.log(`Error: ${error}`));
  }, [service, id]);
  console.log(formData);

  return <>{renderSummary()}</>;
};

export default DisplaySummary;
