import React from "react";
import { Checkbox, Form, Input } from "antd";
import {
  cardioChiefComplaintOptions,
  neuroChiefComplaintOptions,
  orthoChiefComplaintOptions,
  sportsChiefComplaintOptions,
} from "../OpPhysioOptions";

const ChiefComplaintsForm = ({ onValuesChange, formData, formType }) => {
  const getOptions = () => {
    switch (formType) {
      case "cardio":
        return cardioChiefComplaintOptions;
      case "neuro":
        return neuroChiefComplaintOptions;
      case "ortho":
        return orthoChiefComplaintOptions;
      case "sports":
        return sportsChiefComplaintOptions;
      default:
        return [];
    }
  };
  return (
    <>
      <Form
        id="chief-complaints-form"
        onValuesChange={(cur, all) => onValuesChange("chief_complaint", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.chief_complaint}
      >
        <Form.Item label="Chief Complaints" name={"chief_complaints"}>
          <Checkbox.Group options={getOptions()} />
        </Form.Item>
        <Form.Item name={"comments"}>
          <Input.TextArea
            rows={3}
            placeholder="Comments"
            style={{ width: "60%" }}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default ChiefComplaintsForm;
