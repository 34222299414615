import { Col, Form, List, Radio, Row, Card, Typography } from "antd";
import React from "react";
import { barthelIndexOptions } from "../../../../Options/functionalAsessmentOptions";

const BarthelIndexItems = ({ handleChange, states, nameKey = null }) => {
  return (
    <>
      <Row>
        <List
          // grid={{ gutter: 16, column: 4 }}
          grid={{ gutter: 8, sm: 1, md: 2, lg: 3, xl: 4, xxl: 4 }}
          // bordered
          dataSource={barthelIndexOptions}
          style={{ backgroundColor: "transparent" }}
          renderItem={(option) => (
            <List.Item>
              <Card size="small">
                <Row
                  style={{ padding: "24px", height: "120px" }}
                  align="middle"
                  justify="space-between"
                >
                  <Col span={24}>
                    <Typography>{option.label}</Typography>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={nameKey ? [nameKey, option.value] : option.value}
                      style={{ marginBottom: 0 }}
                    >
                      <Radio.Group
                        defaultValue={states[option.value]}
                        onChange={(e) =>
                          handleChange(option.value, e.target.value)
                        }
                        className="solid-select-radio"
                        block
                        buttonStyle="solid"
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   width: "100%",
                        // }}
                        // className="barthel-index-buttons"
                      >
                        <Radio.Button value={0}>0</Radio.Button>
                        <Radio.Button value={5}>5</Radio.Button>
                        {!["bathing", "grooming"].includes(option.value) && (
                          <Radio.Button value={10}>10</Radio.Button>
                        )}
                        {["transfers", "mobility"].includes(option.value) && (
                          <Radio.Button value={15}>15</Radio.Button>
                        )}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Row>
    </>
  );
};

export default BarthelIndexItems;
