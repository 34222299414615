import React, { useContext, useEffect, useState } from "react";
import { Button, Typography, Modal, theme, Tag, Avatar, message } from "antd";
import buttonStyles from "../../../styles/Custom/Button.module.css";
import textStyles from "../../../styles/Custom/Typography.module.css";
import tagStyles from "../../../styles/Custom/Tag.module.css";

import {
  ACTIVEDOMAIN,
  FacilityID,
  ORGANISATONID,
  Role,
} from "../../../constants/defaultKeys";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  redirectToDashboard,
} from "../../../helpers/auth/helper";
import { setCookie } from "../../../helpers/localStorage";
import "./Login.css";
import { UserContext } from "../../../context/UserContext";
import CustomAvatar from "../../../blocks/Avatars/CustomAvatar";
import styles from "../../../styles/Custom/Dashboard/ChooseOrg.module.css";
import { downloadUrlApi } from "../../../services/common.services";
const { useToken } = theme;

const ChooseOrgModal = ({ openModal, closable, onCancel }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [logo, setLogo] = useState(null);
  const { token } = useToken();
  const navigate = useNavigate();

  return (
    <Modal
      open={openModal}
      centered
      onCancel={onCancel}
      footer={null}
      closable={closable}
      style={{ textAlign: "center", padding: "0px", position: "relative" }}
      className={styles?.orgModal}
    >
      <div
        style={{
          padding: "24px",
          background: "url('/images/auth/headerStars.png') no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "auto 170px",
        }}
      >
        {/* <Image
          src={refreshOutlined}
          alt="Business"
          style={{ height: "56px", width: "auto" }}
        /> */}
        <Typography
          className={`text-center ${textStyles.h6}`}
          style={{ marginBlock: "12px" }}
        >
          Choose Organisation
        </Typography>

        <div
          className={styles.scrollDiv}
          style={{
            maxHeight: "50vh",
            overflowY: "scroll",
          }}
        >
          {userDetails?.organizations?.map((org) => {
            return (
              <>
                <Button
                  key={org?.org_name}
                  className={`orgButton ${buttonStyles.mdBtn} ${
                    org?.is_inactive && "orgErrorButton"
                  }`}
                  style={{
                    width: "100%",
                    marginTop: "12px",
                    justifyContent: "center",
                    paddingBlock: "16px",
                  }}
                  onClick={() => {
                    if (!org?.is_inactive) {
                      setUserDetails({
                        ...userDetails,
                        activeOrg: org,
                        currFacility: org?.roles?.[0]?.facility_id,
                        currRole: org?.roles?.[0]?.role,
                      });
                      setCookie(
                        ORGANISATONID,
                        org?.org_id,
                        `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                        "/"
                      );
                      setCookie(
                        FacilityID,
                        org?.roles?.[0]?.facility_id,
                        `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                        "/"
                      );
                      setCookie(
                        Role,
                        org?.roles?.[0]?.role,
                        `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                        "/"
                      );

                      if (org?.domain) {
                        setCookie(
                          ACTIVEDOMAIN,
                          org?.domain,
                          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                          "/"
                        );

                        redirectToDashboard(org?.domain, org?.roles?.[0]?.role);
                      } else {
                        message?.error("No domain found");
                      }
                    }
                  }}
                >
                  <div className="flex-between" style={{ width: "100%" }}>
                    <div className="flex-start">
                      <CustomAvatar
                        name={org?.org_name}
                        // url={org?.org_logo}
                        indexId={org?.org_id}
                      />

                      <span style={{ color: "#344054", marginLeft: "12px" }}>
                        {" "}
                        {capitalizeFirstLetter(org?.org_name)}
                      </span>
                      <span style={{ marginLeft: "12px" }}>
                        <Tag color="blue" className={tagStyles.smRndTag}>
                          {org?.roles?.[0]?.role?.replaceAll("_", " ")}
                        </Tag>
                      </span>
                    </div>
                    {org?.is_inactive && (
                      <Tag color="red" className={tagStyles.mdRndTag}>
                        Access Denied
                      </Tag>
                    )}
                  </div>
                </Button>
                {org?.is_inactive && (
                  <div className={"orgButtonError"}>
                    <Typography
                      style={{
                        color: token.colorErrorHover,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      User ID disabled
                    </Typography>

                    <Typography style={{ textAlign: "left", fontSize: "12px" }}>
                      {
                        "Your user ID has been disabled. Please contact support (9154372355) for assistance."
                      }
                    </Typography>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ChooseOrgModal;
