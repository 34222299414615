import React from "react";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import { Col, Form, InputNumber, Row, Space, Typography } from "antd";
import { genderOptions } from "../../../../Options/systemicExamOptions";

const PeakFlowMeterItems = ({ form, predict }) => {
  return (
    <>
      <DeselectableRadioGroup
        label={"Gender"}
        name={"gender"}
        options={genderOptions}
        form={form}
      />
      <Row gutter={[32, 32]}>
        <Col span={12}>
          <Form.Item label="Age" name="age">
            <InputNumber max={150} min={0} addonAfter="yrs" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Height" name="height">
            <InputNumber min={0} addonAfter="cms" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Typography style={{ fontSize: "16px" }}>
            Predicted PEFR: {predict}
          </Typography>
        </Col>
        <Col span={12}>
          <Space>
            <Form.Item label="Tested PEFR" name="test_PEFR">
              <InputNumber />
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default PeakFlowMeterItems;
