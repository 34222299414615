import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PhysioReviewContext } from "./PhysioReviewFormProvider";
import {
  getLatestPhysioReviewAPI,
  getPhysioInspectionAPI,
  getPhysioReviewAPI,
  physioReviewAPI,
  updatePhysioReviewAPI,
} from "../../../../services/physio.services";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import PhysioScales from "../../InspectionForms/Examination/PhysioScales/PhysioScales";
import PhysioFunctionalDiagnosisReview from "../../InspectionForms/FunctionalDiagnosis/PhysioFunctionalDiagnosisReview";
import PhysioTreatmentPlanReview from "../../InspectionForms/TreatmentPlan/PhysioTreatmentPlanReview";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";

const PhysioReviewForm = ({
  editId,
  visible,
  setVisible,
  refreshPage,
  iAssessment,
  addMode,
  setAddMode,
  setActiveSegment,
  reviewData,
  setDailyReviewRefresh,
  dailyRefresh,
  segmentData,
  patientData,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [diagnosisForm] = Form.useForm();
  const [scalesForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const { formData, setFormData } = useContext(PhysioReviewContext);
  const [activeTab, setActiveTab] = useState("1");
  const [isDirty, setIsDirty] = useState(false);
  const newReview = reviewData?.length === 0;
  const reviewId = reviewData?.[0]?.id;
  const newReviewId = addMode === false && reviewId ? reviewId : null;
  const reviewDate =
    segmentData?.find(
      (option) => option.value === editId && option.value !== "others"
    )?.label ||
    new Date().toLocaleDateString("en-US", { month: "short", day: "2-digit" });
  const [scalesId, setScalesId] = useState({});
  const [scaleTab, setScaleTab] = useState("1");

  const handleSubmit = async (values, flag) => {
    try {
      if (editId || newReviewId) {
        await updatePhysioReviewAPI(editId || newReviewId, values);
        setDailyReviewRefresh();
      } else {
        await physioReviewAPI(values);
        refreshPage();
        setAddMode(false);
        setDailyReviewRefresh();
      }

      message.success(`${flag} submitted successfully`);

      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onDiagnosisSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        functional_diagnosis: values,
        admission_id: params?.patient,
      },
      "Functional Diagnosis"
    );
  };
  const onScalesSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        scales: values,
        admission_id: params?.patient,
      },
      "Scales"
    );
  };
  const onTreatmentSubmit = (values) => {
    handleSubmit(
      {
        ...formData,
        treatment_plan: values,
        admission_id: params?.patient,
      },
      "Treatment Plan"
    );
  };

  const openEdit = async () => {
    if (editId && newReview === false) {
      try {
        const response = await getPhysioReviewAPI(editId);
        setFormData(response?.data?.data);
        setScalesId(response?.data?.data?.scales);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else if (newReview === false) {
      try {
        const response = await getLatestPhysioReviewAPI(params?.patient);
        const { scales, ...rest } = response?.data?.data;
        setFormData(rest);
      } catch (error) {
        console.log(`Error: ${error}`);
        // message.error("Unable to Fetch Data");
      }
    } else {
      try {
        const response = await getPhysioInspectionAPI(iAssessment);
        setFormData(response.data.data);
      } catch (error) {
        console.log(`Error:${error}`);
      }
    }
  };

  useEffect(() => {
    openEdit();
  }, [editId]);

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return diagnosisForm.submit();
      case "2":
        return scalesForm.submit();
      case "3":
        return treatmentForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      switch (activeTab) {
        case "1":
          diagnosisForm.validateFields().then((values) => {
            setIsDirty(false);
            onDiagnosisSubmit(values);
            setActiveTab(key);
          });
          break;
        case "2":
          scalesForm.validateFields().then((values) => {
            setIsDirty(false);
            onScalesSubmit(values);
            setActiveTab(key);
          });
          break;
        case "3":
          treatmentForm.validateFields().then((values) => {
            setIsDirty(false);
            onTreatmentSubmit(values);
            setActiveTab(key);
          });
          break;
        default:
          setActiveTab(key);
      }
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <div>
      <Drawer
        open={visible}
        width="100%"
        className="physioreview-drawer"
        footer={
          activeTab !== "2" ? (
            <div style={{ textAlign: "right" }}>
              {activeTab !== "3" && (
                <Button
                  onClick={() => {
                    getFormKey();
                    setVisible(false);
                  }}
                >
                  Save & Close
                </Button>
              )}
              {"  "}
              <Button
                onClick={() => {
                  getFormKey();
                  const nextTab = (parseInt(activeTab) + 1).toString();
                  if (activeTab === "3") {
                    setVisible(false);
                  } else {
                    setActiveTab(nextTab);
                  }
                }}
                type="primary"
              >
                {activeTab === "3" ? "Done" : " Save & Proceed"}
              </Button>
            </div>
          ) : null
        }
        onClose={() => {
          setVisible(false);
          setActiveSegment(reviewData[0]?.id);
        }}
        closeIcon={null}
        keyboard={false}
      >
        <Row style={{ padding: "10px 24px 0 24px" }}>
          <Col span={8}>
            <Space>
              <LeftOutlined
                onClick={() => {
                  Modal.confirm({
                    title: "Confirmation",
                    content: "Are you sure you want to close?",
                    onOk: () => {
                      setVisible(false);
                      setActiveSegment(reviewData[0]?.id);
                    },
                  });
                }}
                style={{}}
              />
              <Typography
                style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
              >
                {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
                {/* ({reviewDate}) */}
              </Typography>
              {patientData?.admission?.bed?.bed_no && (
                <Tag color="orange">
                  <Image src={bedImg} preview={false} />{" "}
                  {patientData?.admission?.bed?.bed_no}
                </Tag>
              )}
            </Space>
            <br />
            <Space style={{ marginTop: "10px" }}>
              <Tag>
                {patientData?.admission?.patient?.age}{" "}
                {patientData?.admission?.patient?.gender}
              </Tag>
              <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
            </Space>
          </Col>
          <Col span={12}>
            <Tabs
              activeKey={activeTab}
              items={[
                { key: "1", label: "Functional Diagnosis" },
                { key: "2", label: "Scales" },
                { key: "3", label: "Treatment Plan" },
              ]}
              onChange={handleTabChange}
            />
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Typography>{reviewDate}</Typography>
          </Col>
        </Row>
        <Divider style={{ margin: "8px 0px" }} />
        <div
          className="no-scroll"
          style={{ height: "90%", overflow: "hidden", position: "relative" }}
        >
          <div
            className="no-scroll"
            style={{
              height: "calc(100% - 38px)",
              overflowY: "auto",
              backgroundColor: "#f6f5ff",
              borderRadius: "5px",
              border: "1px solid #e6e6e6",
            }}
          >
            {activeTab === "1" && (
              <Form
                id="daily-review-functional-diagnosis"
                form={diagnosisForm}
                layout="vertical"
                onFinish={onDiagnosisSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <PhysioFunctionalDiagnosisReview form={diagnosisForm} />
              </Form>
            )}

            {activeTab === "2" && (
              <PhysioScales
                admission={params?.patient}
                reviewData={reviewData}
                editId={editId}
                onScalesSubmit={onScalesSubmit}
                scalesId={scalesId}
                setScalesId={setScalesId}
                setActiveTab={setActiveTab}
                scaleTab={scaleTab}
                setScaleTab={setScaleTab}
                setVisible={setVisible}
              />
            )}

            {activeTab === "3" && (
              <Form
                id="daily-review-treatment-plan"
                form={treatmentForm}
                layout="vertical"
                onFinish={onTreatmentSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <PhysioTreatmentPlanReview form={treatmentForm} />
              </Form>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default PhysioReviewForm;
