import { Checkbox, Form, Input } from "antd";
import React from "react";
import {
  cardioDiagnosisOptions,
  orthoDiagnosisOptions,
} from "../../../../IpEMR/Options/physioOptions";
import {
  neuroDiagnosisOptions,
  spinalOptions,
  sportsDiagnosisOptions,
  strokeHeadInjuryOptions,
} from "../OpPhysioOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const OpFunctionalDiagnosis = ({ onValuesChange, formData, formType }) => {
  const [form] = Form.useForm();
  const getOptions = () => {
    switch (formType) {
      case "cardio":
        return cardioDiagnosisOptions;
      case "neuro":
        return neuroDiagnosisOptions;
      case "ortho":
        return orthoDiagnosisOptions;
      case "sports":
        return sportsDiagnosisOptions;
      default:
        return [];
    }
  };
  return (
    <>
      <Form
        form={form}
        id="op-functional-diagnosis"
        onValuesChange={(cur, all) =>
          onValuesChange("functional_diagnosis", all)
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.functional_diagnosis}
      >
        <Form.Item label="Functional Diagnosis" name={"diagnosis"}>
          <Checkbox.Group
            options={getOptions()}
            style={{ display: "flex", flexWrap: "wrap", gap: 30 }}
          />
        </Form.Item>
        {formType === "neuro" && (
          <>
            <DeselectableRadioGroup
              label={"Stroke"}
              name={"stroke"}
              options={strokeHeadInjuryOptions}
              form={form}
            />
            <DeselectableRadioGroup
              label={"Head Injury"}
              name={"head_injury"}
              options={strokeHeadInjuryOptions}
              form={form}
            />
            <DeselectableRadioGroup
              label={"Spinal Cord Injury"}
              name={"spinal_cord_injury"}
              options={spinalOptions}
              form={form}
            />
          </>
        )}
        <Form.Item name={"diagnosis_comments"}>
          <Input.TextArea rows={3} placeholder="Comments" />
        </Form.Item>
      </Form>
    </>
  );
};

export default OpFunctionalDiagnosis;
