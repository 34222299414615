import { Form, Select, Row, Col, Card, Typography } from "antd";
import React from "react";
import {
  asiaImpairmentGradeOptions,
  neurologicalOptions,
} from "../../../../Options/physioOptions";

const NeurologicalLevels = () => {
  return (
    <Card
      title={
        <Typography style={{ textAlign: "center" }}>
          Neurological Levels
        </Typography>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label="Sensory Left" name={"sensory_left"}>
            <Select
              allowClear
              options={neurologicalOptions}
              style={{ width: "70px" }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Motor Left" name={"motor_left"}>
            <Select
              allowClear
              options={neurologicalOptions}
              style={{ width: "70px" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="ASIA Impairment Scale Grade"
            name={"asia_impairment_scale"}
          >
            <Select
              allowClear
              options={asiaImpairmentGradeOptions}
              style={{ width: "70px" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Zone of Partial Preservation"
            name={"zone_of_partial_preservation"}
          >
            <Select
              allowClear
              options={neurologicalOptions}
              style={{ width: "30%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label="Sensory Right" name={"sensory_right"}>
            <Select
              allowClear
              options={neurologicalOptions}
              style={{ width: "70px" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Motor Right" name={"motor_right"}>
            <Select
              allowClear
              options={neurologicalOptions}
              style={{ width: "70px" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Neurological Level Injury"
            name={"nerological_level_of_injury"}
          >
            <Select
              allowClear
              options={neurologicalOptions}
              style={{ width: "70px" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default NeurologicalLevels;
