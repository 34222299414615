import { Button, Col, Form, Row, Typography } from "antd";
import React, { useState } from "react";
import NIHSScaleItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/NIHSScaleComponents/NIHSScaleItems";
import ScalesInfoViewer from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/ScalesInfoViewer";
import NihssPdf from "../../../../../assets/scalesInfo/NIHSSInfo.pdf";

const scaleInfo = {
  name: "Nihss",
  type: "pdf",
  url: NihssPdf,
};

const NIHSSForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [openInfo, setOpenInfo] = useState(false);
  const calcScore = (values) => {
    return Object?.entries(values)
      ?.filter(([key, value]) => key !== "score")
      ?.reduce((acc, [, value]) => acc + (value || 0), 0);
  };
  const fValues = form.getFieldsValue() || {};
  const score = calcScore(fValues) || formData?.nihss?.score;
  const getInterpretation = (val) => {
    return val < 6
      ? "Mild Stroke"
      : score < 14
      ? "Moderate Stroke"
      : score < 25
      ? "Severe Stroke"
      : "Very Severe Stroke";
  };
  const interpretation = getInterpretation(score);
  return (
    <div className="op-forms">
      <Form
        form={form}
        id="nihss-form"
        onValuesChange={(cur, all) => {
          const score = calcScore(all);
          onValuesChange("nihss", {
            ...all,
            score: score,
            interpretation: getInterpretation(score),
          });
        }}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.nihss}
      >
        <Typography>
          <Row>
            <Col span={8}>National Institutes of Health Stroke Scale</Col>
            <Col span={16}>
              {score}/44 :{interpretation}
              {}
            </Col>
          </Row>
        </Typography>

        <Button
          className="scale-info"
          onClick={() => {
            setOpenInfo(true);
          }}
        >
          Info
        </Button>
        <div className="physio-scales initial">
          <NIHSScaleItems
            handleChange={() => {}}
            states={formData?.nihss || {}}
          />
        </div>
      </Form>
      {openInfo && (
        <ScalesInfoViewer
          data={scaleInfo}
          open={openInfo}
          setOpen={setOpenInfo}
        />
      )}
    </div>
  );
};

export default NIHSSForm;
