import axiosInstance from "../config/axios.config";

export const getAdmissionReportAPI = (facility, date) => {
  if (facility && date.start_date && date.end_date) {
    return axiosInstance.get(
      `/billing/dashboard/admissions-report/?facility=${facility}&start_date=${date?.start_date}&end_date=${date?.end_date}`
    );
  } else if (facility) {
    return axiosInstance.get(
      `/billing/dashboard/admissions-report/?facility=${facility}`
    );
  } else {
    return axiosInstance.get(`/billing/dashboard/admissions-report/`);
  }
};

export const getAdmissionAnalyticsAPI = (filter, facility) => {
  if (facility) {
    return axiosInstance.get(
      `/billing/dashboard/admission-analytics/?range=${filter}&facility=${facility}`
    );
  } else {
    return axiosInstance.get(
      `/billing/dashboard/admission-analytics/?range=${filter}`
    );
  }
};

export const getFinancialMetricsAPI = (facility, date) => {
  if (facility && date.start_date && date.end_date) {
    return axiosInstance.get(
      `/billing/dashboard/financial-metrics/?facility=${facility}&start_date${date?.start_date}&end_date${date?.end_date}`
    );
  } else if (facility) {
    return axiosInstance.get(
      `/billing/dashboard/financial-metrics/?facility=${facility}`
    );
  } else {
    return axiosInstance.get(`/billing/dashboard/financial-metrics/`);
  }
};

export const getFinancialAnalyticsAPI = (filter, facility) => {
  if (facility) {
    return axiosInstance.get(
      `/billing/dashboard/financial-analytics/?range=${filter}&facility=${facility}`
    );
  } else {
    return axiosInstance.get(
      `/billing/dashboard/financial-analytics/?range=${filter}`
    );
  }
};

export const getBedAndBillsReportAPI = (facility, date) => {
  if (facility && date.start_date && date.end_date) {
    return axiosInstance.get(
      `billing/dashboard/bed-and-bills-report/?facility=${facility}&start_date${date?.start_date}&end_date${date?.end_date}`
    );
  } else if (facility) {
    return axiosInstance.get(
      `billing/dashboard/bed-and-bills-report/?facility=${facility}`
    );
  } else {
    return axiosInstance.get(`billing/dashboard/bed-and-bills-report/`);
  }
};

export const getBedOccupancyAnalyticsAPI = (filter, facility) => {
  if (facility) {
    return axiosInstance.get(
      `billing/dashboard/bed-occupancy-analytics/?range=${filter}&facility=${facility}`
    );
  } else {
    return axiosInstance.get(
      `billing/dashboard/bed-occupancy-analytics/?range=${filter}`
    );
  }
};

export const getArpobReportsAPI = (facility, date) => {
  if (facility && date.start_date && date.end_date) {
    return axiosInstance.get(
      `billing/dashboard/arpob-reports/?facility=${facility}&start_date${date?.start_date}&end_date${date?.end_date}`
    );
  } else if (facility) {
    return axiosInstance.get(
      `billing/dashboard/arpob-reports/?facility=${facility}`
    );
  } else {
    return axiosInstance.get(`billing/dashboard/arpob-reports/`);
  }
};

export const getArpobAnalyticsAPI = (filter, facility) => {
  if (facility) {
    return axiosInstance.get(
      `billing/dashboard/arpob-analytics/?range=${filter}&facility=${facility}`
    );
  } else {
    return axiosInstance.get(
      `billing/dashboard/arpob-analytics/?range=${filter}`
    );
  }
};
