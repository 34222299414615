import { Card, Col, Row, Slider, Tag, Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import React from "react";
import "../../OpEMR/Physiotherapist/OPForms.css";

const OPHistory = ({ admission, history, setCurrentEmr }) => {
  const getServiceType = (service) => {
    switch (service) {
      case "cardio":
        return "Cardiopulmonary EMR";
      case "neuro":
        return "Neuro EMR";
      case "ortho":
        return "Ortho EMR";
      case "sports":
        return "Sports EMR";
    }
  };
  const marks = [
    {
      label: (
        <div
          style={{
            width: 300,
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
            padding: "10px",
            backgroundColor: "inherit",
            cursor: "pointer",
          }}
          onClick={() => setCurrentEmr({})}
          className="history-cards"
        >
          <Row style={{ textAlign: "left", color: "#7C3B2B", fontWeight: 500 }}>
            <Row style={{ textAlign: "left" }}>
              <Col span={24}>
                <Typography.Text style={{ fontWeight: 600 }}>
                  {admission?.facility_name}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <CalendarOutlined style={{ marginRight: 8 }} /> Present
              </Col>
            </Row>
          </Row>
        </div>
      ),
      value: 0,
    },
  ];
  const sliderMarks = history?.map((record, index) => ({
    label: (
      <div
        key={index}
        style={{
          width: 300,
          border: "1px solid #d9d9d9",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "inherit",
          cursor: "pointer",
        }}
        onClick={() => setCurrentEmr(record)}
      >
        <Row style={{ textAlign: "left" }}>
          <Col span={24}>
            <Typography.Text style={{ fontWeight: 600 }}>
              {record?.facility_name}
            </Typography.Text>
          </Col>
          <div style={{ background: "#F9F8FF", width: "100%", padding: 10 }}>
            <Col span={24}>
              <Typography.Text style={{ fontWeight: 600 }}>
                Dr. {record?.doctor_name}
              </Typography.Text>
            </Col>
            <Col span={24} style={{ fontWeight: 400, color: "black" }}>
              Type{" "}
              <Tag
                style={{ marginLeft: 2, color: "#067647", fontWeight: 600 }}
                color="#ABEFC6"
              >
                {getServiceType(record?.service_type)}
              </Tag>
            </Col>
            <Col span={24} style={{ fontWeight: 400, color: "black" }}>
              Time{" "}
              <Tag
                style={{ color: "#B54708", fontWeight: 600 }}
                color="#FEDF89"
              >
                {dayjs(record?.created_at).format(" h:mm A")}
              </Tag>
            </Col>
          </div>
          <Col span={24} style={{ fontWeight: 400, color: "#7C3B2B" }}>
            <CalendarOutlined />{" "}
            {dayjs(record?.created_at).format("ddd, MMM DD YYYY ")}
          </Col>
        </Row>
      </div>
    ),
    value: index + 1,
  }));
  return (
    <div className="op-forms">
      <Card title="History" className="history-card">
        <Slider
          marks={[...marks, ...(sliderMarks || [])]}
          min={0}
          max={history?.length}
          step={1}
          vertical
          style={{
            height: history?.length ? `${history?.length * 180}px` : 200,
          }}
          reverse
          className="history-slider"
        />
      </Card>
    </div>
  );
};

export default OPHistory;
