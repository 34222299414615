export const cardioHistoryOptions = [
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "COPD", value: "copd" },
  { label: "Asthma", value: "asthma" },
  { label: "Cardiac Failure", value: "cardiac_failure" },
  { label: "General Deconditioning", value: "general_deconditioning" },
];

export const cardioPastMedicalOptions = [
  { label: "Hypertension", value: "hypertension" },
  { label: "Diabetes", value: "diabetes" },
  { label: "IHD", value: "ihd" },
  { label: "CVA", value: "cva" },
  { label: "TIA", value: "tia" },
  { label: "Epilepsy", value: "epilepsy" },
  { label: "CKD", value: "ckd" },
  { label: "COPD", value: "copd" },
  { label: "Asthma", value: "asthma" },
  { label: "Cardiac Disease", value: "cardiac_disease" },
  { label: "Others", value: "past_medical_others" },
];

export const cardioCongenitalOptions = [
  { label: "Cerebral Palsy", value: "cerebral_palsy" },
  { label: "Spina Bifida", value: "spina_bifida" },
  { label: "Hydrocephalous", value: "hydrocephalous" },
  { label: "Autism", value: "autism" },
  { label: "Skeletal Deformitites", value: "skeletal_deformities" },
  { label: "Other", value: "other" },
];

export const activePassiveOptions = [
  { label: "Active", value: "active" },
  { label: "Passive", value: "passive" },
];

export const opExaminationOptions = [
  { label: "Odema", value: "odema" },
  { label: "Wound", value: "wound" },
  { label: "Scar", value: "scar" },
  { label: "Warmth", value: "warmth" },
  { label: "Tenderness", value: "tenderness" },
  { label: "Abnormal Sensation", value: "abnormal_sensation" },
  { label: "Pain", value: "pain_op" },
];

export const cardioChiefComplaintOptions = [
  { label: "Pain With Radiation", value: "pain_with_radiation" },
  { label: "Pain Without Radiation", value: "pain_without_radiation" },
  { label: "Stiffness", value: "stiffness" },
  { label: "General Weakness", value: "general_weakness" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "SOB", value: "sob" },
  {
    label: "Agility / Endurance Training",
    value: "agility_endurance_training",
  },
  { label: "Swelling", value: "swelling" },
  { label: "Wheeze", value: "wheeze" },
  { label: "Vertigo", value: "vertigo" },
];

export const neuroChiefComplaintOptions = [
  { label: "Pain With Radiation", value: "pain_with_radiation" },
  { label: "Pain Without Radiation", value: "pain_without_radiation" },
  { label: "Stiffness", value: "stiffness" },
  { label: "General Weakness", value: "general_weakness" },
  { label: "Balance and Gait Disorder", value: "balance_an_gai_disorder" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "SOB", value: "sob" },
  { label: "Incoordination", value: "incoordination" },
  { label: "Cognitive Dysfunction", value: "cognitive_dysfunction" },
  { label: "Speech Disorder", value: "speech_disorder" },
  { label: "Delayed Milestones", value: "delayed_milestones" },
  { label: "ADHD", value: "adhd" },
  { label: "Autism Spectrum", value: "autism_spectrum" },
  { label: "Swelling", value: "swelling" },
  { label: "Vertigo", value: "vertigo" },
  { label: "Dysphagia", value: "dysphagia" },
  { label: "Memory Loss Disorder", value: "memory_loss_disorder" },
  { label: "Tingling & Numbness", value: "tingling_numbness" },
];

export const neuroDiagnosisOptions = [
  {
    label: "Amylotrophic lateral sclerosis",
    value: "amylotrophic_lateral_sclerosis",
  },
  { label: "Nerve compression", value: "nerve_compression" },
  { label: "Carpal tunnel syndrome", value: "carpal_tunnel_syndrome" },
  { label: "Aphasia", value: "aphasia" },
  { label: "Ataxia", value: "ataxia" },
  { label: "Parkinson's disease", value: "parkinsons_disease" },
  { label: "Multiple sclerosis", value: "multiple_sclerosis" },
  { label: "ADHD", value: "adhd" },
  { label: "AUTISM", value: "autism" },
  { label: "Bell's palsy", value: "bells_palsy" },
  { label: "Cranial nerve injury", value: "cranial_nerve_injury" },
  { label: "Brachial plexus injury", value: "brachial_plexus_injury" },
  { label: "Cerebral palsy", value: "cerebral_palsy" },
  { label: "Dementia", value: "dementia" },
  { label: "Alzheimer's", value: "alzheimers" },
  { label: "GBS", value: "gbs" },
  { label: "Transverse myelitis", value: "transverse_myelitis" },
];

export const orthoChiefComplaintOptions = [
  { label: "Pain With Radiation", value: "pain_with_radiation" },
  { label: "Pain Without Radiation", value: "pain_without_radiation" },
  { label: "Stiffness", value: "stiffness" },
  { label: "General Weakness", value: "general_weakness" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "Swelling", value: "swelling" },
  { label: "Tingling & Numbness", value: "tingling_numbness" },
];

export const orthoHistoryOptions = [
  { label: "Fracture", value: "fracture" },
  { label: "Soft tissue Injury", value: "soft_tissue_injury" },
  { label: "Road traffic Accident", value: "road_traffic_accident" },
  { label: "Spine injury", value: "spine_injury" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "Joint Replacement", value: "joint_replacement" },
  { label: "General Deconditioning", value: "general_deconditioning" },
];

export const sportsChiefComplaintOptions = [
  { label: "Pain With Radiation", value: "pain_with_radiation" },
  { label: "Pain Without Radiation", value: "pain_without_radiation" },
  { label: "Stiffness", value: "stiffness" },
  { label: "Balance and Gait Disorder", value: "balance_an_gai_disorder" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "Incoordination", value: "incoordination" },
  {
    label: "Agility / Endurance Training",
    value: "agility_endurance_training",
  },
  { label: "Swelling", value: "swelling" },
];

export const neuroHistoryOptions = [
  { label: "Fracture", value: "fracture" },
  { label: "Soft Tissue Injury", value: "soft_tissue_injury" },
  { label: "Head Injury", value: "head_injury" },
  { label: "Road Traffic Accident", value: "road_traffic_accident" },
  { label: "Spine Injury", value: "spine_injury" },
  { label: "Stroke", value: "stroke" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "Cardiac Failure", value: "cardiac_failure" },
  { label: "General Deconditioning", value: "general_deconditioning" },
];
export const sportsHistoryOptions = [
  { label: "Fracture", value: "fracture" },
  { label: "Soft tissue Injury", value: "soft_tissue_injury" },
  { label: "Spine injury", value: "spine_injury" },
  { label: "Pre Surgical Rehab", value: "pre_surgical_rehab" },
  { label: "Post Surgical Rehab", value: "post_surgical_rehab" },
  { label: "Joint Replacement", value: "joint_replacement" },
];
export const sportsDiagnosisOptions = [
  { label: "Cervical Spondylosis", value: "cervical_spondylosis" },
  { label: "Muscle Stiffness", value: "muscle_stiffness" },
  { label: "Trapezitis", value: "trapezitis" },
  { label: "Facet Joint Arthralgia", value: "facet_joint_arthralgia" },
  { label: "Kyphosis", value: "kyphosis" },
  { label: "Scoliosis", value: "scoliosis" },
  { label: "Athritis", value: "athritis" },
  { label: "Tendinitis", value: "tendinitis" },
  { label: "Bursitis", value: "bursitis" },
  {
    label: "Capsular Pattern Joint Tightness",
    value: "Capsular Pattern Joint Tightness",
  },
  { label: "Subluxation", value: "subluxation" },
  { label: "Tennis Elbow", value: "tennis_elbow" },
  { label: "Golfers Elbow", value: "golfers_elbow" },
  { label: "Fracture Joint", value: "fracture_joint" },
  { label: "Fascitis", value: "fascitis" },
  { label: "Frozen Shoulder", value: "frozen_shoulder" },
  { label: "Ligament Injury", value: "ligament_injury" },
  { label: "Fibromyalgia", value: "figromyalgia" },
  { label: "Muscle Sprain/Strain", value: "muscle_sprain" },
  { label: "Hamstring Pull", value: "hamstring_pull" },
  { label: "Muscle Tear", value: "muscle_tear" },
  { label: "Achilles Tendinitis", value: "achilles_tendinitis" },
  { label: "Patellar Syndrome", value: "patellar_syndrome" },
];

export const strokeHeadInjuryOptions = [
  { label: "Hemiplegia", value: "hemiplegia" },
  { label: "Diplegia", value: "diplegia" },
  { label: "Quadriplegia", value: "quadriplegia" },
  { label: "Monoplegia", value: "monoplegia" },
];

export const spinalOptions = [
  { label: "Paraplegia", value: "paraplegia" },
  { label: "Quadriplegia", value: "quadriplegia" },
];
