import { Card, Col, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import StatCardLayout from "../StatCardLayout";
import { filterOptions } from "../DashboardOptions";
import {
  Area,
  AreaChart,
  CartesianAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getBedAndBillsReportAPI,
  getBedOccupancyAnalyticsAPI,
} from "../../../../services/orgDashboard.services";
import bedRevenueIcon from "../../../../assets/dashboardIcons/bedRevenue.svg";
import otherRevenueIcon from "../../../../assets/dashboardIcons/graph.svg";
import totalBilledIcon from "../../../../assets/dashboardIcons/totalBilled.svg";

const BedOccupancy = ({ currentFacility, date }) => {
  const [bedData, setBedData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartFilter, setChartFilter] = useState("week");
  useEffect(() => {
    getBedAndBillsReportAPI(currentFacility, date)
      .then((res) => setBedData(res?.data?.result))
      .catch((error) => console.log(`Error:${error}`));
  }, [currentFacility, date]);
  useEffect(() => {
    getBedOccupancyAnalyticsAPI(chartFilter, currentFacility, date)
      .then((res) => {
        const chartData = Object?.entries(res?.data?.result)?.map(
          ([key, value]) => ({ label: key, beds: value })
        );
        setChartData(chartData);
      })
      .catch((error) => console.log(`Error:${error}`));
  }, [chartFilter, currentFacility, date]);
  return (
    <>
      <Row gutter={[8, 30]}>
        <Col span={8}>
          <StatCardLayout
            title={"Revenue as per occupied Bed"}
            footer={" "}
            val={bedData?.total_bed_charges}
            icon={bedRevenueIcon}
          />
        </Col>
        <Col span={8}>
          <StatCardLayout
            title={"Other Revenue"}
            val={bedData?.other_revenue}
            footer={" "}
            icon={otherRevenueIcon}
          />
        </Col>
        <Col span={8}>
          <StatCardLayout
            title={"Total Billed Amount"}
            val={bedData?.total_billed_amount}
            footer={" "}
            icon={totalBilledIcon}
          />
        </Col>
        <Col span={24}>
          <Card
            title={
              <Row>
                <Col span={12} style={{ padding: 15 }}>
                  <Col span={24}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 24,
                        lineHeight: "20px",
                      }}
                    >
                      Bed Occupancy Rate
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "26px",
                      }}
                    >
                      BOR =(Total Occupied Bed Days/Total Available Bed Days) x
                      100%
                    </Typography>
                  </Col>
                </Col>
                <Col span={12} className="flex-end">
                  <Select
                    value={chartFilter}
                    options={filterOptions}
                    onSelect={(val) => setChartFilter(val)}
                  />
                </Col>
              </Row>
            }
          >
            <ResponsiveContainer width={"100%"} height={600}>
              <AreaChart width={1500} height={600} data={chartData}>
                <CartesianAxis stroke="#ccc" />
                <CartesianGrid
                  stroke="#ccc"
                  horizontal={true}
                  vertical={false}
                />
                <XAxis dataKey={"label"} />
                <YAxis />
                <Area
                  type="monotone"
                  dataKey="beds"
                  name="BOR"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.2}
                />
                <Tooltip />
              </AreaChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BedOccupancy;
