import { Form, Input, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../Options/commonOptions";
import BarthelIndexItems from "../../Examination/PhysioScales/BarthelIndexComponents/BarthelIndexItems";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const PhysioDailyActivities = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);
  const { formData } = useContext(context);
  const [states, setStates] = useState({});

  useEffect(() => {
    setStates(
      Object.fromEntries(
        Object.entries(formData?.functional_status?.barthel_index || {}).filter(
          ([key]) =>
            [
              "feeding",
              "bathing",
              "grooming",
              "dressing",
              "bowels",
              "bladder",
              "toilet_use",
              "transfers",
              "mobility",
              "stairs",
            ].includes(key)
        )
      )
    );
  }, [formData]);

  const handleChange = (category, value) => {
    setStates((prev) => ({ ...prev, [category]: value }));
  };

  const calcScore = () => {
    const sum = Object.values(states).reduce((acc, cur) => acc + cur, 0);
    form.setFieldValue(["barthel_index", "score"], sum);
    const interpretation =
      sum < 20
        ? "Totally Dependent"
        : sum < 40
        ? "Very Dependent"
        : sum < 60
        ? "Partially Dependent"
        : sum < 80
        ? "Minimally Dependent"
        : "Independent";
    form.setFieldValue(["barthel_index", "interpretation"], interpretation);
    return sum;
  };

  const score = calcScore();
  const interpretation =
    score < 20
      ? "Totally Dependent"
      : score < 40
      ? "Very Dependent"
      : score < 60
      ? "Partially Dependent"
      : score < 80
      ? "Minimally Dependent"
      : "Independent";

  return (
    <>
      <DeselectableRadioGroup
        label={"Daily Activities"}
        name={"daily_activities"}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue("daily_activities") === "assessable" && (
        <>
          <Typography style={{ textAlign: "center" }}>
            {score}/100 : {interpretation}
          </Typography>
          <Form.Item name={["barthel_index", "score"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={["barthel_index", "interpretation"]} hidden>
            <Input />
          </Form.Item>
          <BarthelIndexItems
            states={states}
            handleChange={handleChange}
            nameKey={"barthel_index"}
          />
        </>
      )}
    </>
  );
};

export default PhysioDailyActivities;
