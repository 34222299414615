import React, { useEffect, useState } from "react";
import SideMenu from "../../Layout/CustomLayout/SideMenu";
import CustomLayout from "../../Layout/CustomLayout";
import {
  Col,
  DatePicker,
  Image,
  Row,
  Segmented,
  Select,
  Typography,
} from "antd";
import typoStyles from "../../../styles/Custom/Typography.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import calendarImg from "../../../assets/images/nurseIcons/calender.svg";
import inputStyles from "../../../styles/Custom/Input.module.css";
import AdmissionsOverview from "./AdmissionsOverview.js/AdmissionsOverview";
import BedOccupancy from "./BedOccupancy/BedOccupancy";
import FinancialMetrics from "./FinancialMetrics/FinancialMetrics";
import ARPOBAnalysis from "./ARPOBAnalysis/ARPOBAnalysis";
import { getFacilityListApi } from "../../../services/facilities.services";
import { getCookie } from "../../../helpers/localStorage";
import { FacilityID, Role } from "../../../constants/defaultKeys";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const segmentOptions = [
  { label: "Admissions Overview", value: "admissions-overview" },
  { label: "Bed Occupancy and Efficiency", value: "bed-occupancy" },
  { label: "Financial Metrics", value: "financial-metrics" },
  { label: "ARPOB Analysis", value: "arpob-analysis" },
];

const disabledDate = (current) => {
  return current && current > dayjs().endOf("day");
};

const OrgDashboard = () => {
  const [isFacilityAdmin, setIsFacilityAdmin] = useState(
    getCookie(Role) === "Facility_Admin" ? getCookie(FacilityID) : false
  );
  const curDate = dayjs().format(dateFormat);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const type = searchParams?.get("type");
  const [activeSegment, setActiveSegment] = useState("admissions-overview");
  const handleSegmentChange = (val) => {
    searchParams?.set("type", val);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // setActiveSegment(val);
  };

  const [date, setDate] = useState({ start_date: null, end_date: null });

  const onDateChange = (date) => {
    setDate((prev) => ({
      ...prev,
      start_date: dayjs(date[0]).format(dateFormat),
      end_date: dayjs(date[1]).format(dateFormat),
    }));
  };

  const [facilityData, setFacilityData] = useState([]);
  console.log(facilityData, isFacilityAdmin);
  const [currentFacility, setCurrentFacility] = useState(null);

  useEffect(() => {
    setActiveSegment(type);
  }, [type]);

  useEffect(() => {
    getFacilityListApi()
      .then((res) => {
        const data = res?.data;
        const facilityOptions = data?.map((option) => ({
          label: option?.name,
          value: option?.id,
        }));
        setFacilityData([{ label: "All", value: null }, ...facilityOptions]);
      })
      .catch((error) => console.log(`Error:${error}`));
  }, []);

  return (
    <>
      <CustomLayout
        header={
          <>
            <Typography className={typoStyles.h6}>
              Dashboard
              {/* {defaultRole === "Facility_Admin" ? "Facility" : "Facilities"} */}
            </Typography>
          </>
        }
        sideContent={<SideMenu />}
      >
        <Row gutter={[8, 32]}>
          <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={16}>
            <Segmented
              className="segment-wrap"
              style={{
                backgroundColor: "#F9FAFB",
              }}
              size="large"
              value={activeSegment}
              options={segmentOptions}
              onChange={handleSegmentChange}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            xl={10}
            xxl={8}
            className="flex-end"
          >
            <Row gutter={[5, 5]}>
              {!isFacilityAdmin && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={8}
                  className="flex-end"
                >
                  <Select
                    defaultValue={isFacilityAdmin || null}
                    options={facilityData}
                    onSelect={(val) => setCurrentFacility(val)}
                    style={{ width: 150 }}
                    size="large"
                  />
                </Col>
              )}

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={isFacilityAdmin ? 24 : 16}
                className="flex-end"
              >
                <RangePicker
                  style={{
                    color: "black",
                    border: "1px solid #a182e3",
                    height: "38px",
                  }}
                  disabledDate={disabledDate}
                  className={inputStyles.mdDateInput}
                  onChange={onDateChange}
                  allowClear={false}
                  suffixIcon={
                    <Image width={20} src={calendarImg} preview={false} />
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {(() => {
              switch (type) {
                case "admissions-overview":
                  return (
                    <AdmissionsOverview
                      currentFacility={currentFacility}
                      date={date}
                    />
                  );
                case "bed-occupancy":
                  return (
                    <BedOccupancy
                      currentFacility={currentFacility}
                      date={date}
                    />
                  );
                case "financial-metrics":
                  return (
                    <FinancialMetrics
                      currentFacility={currentFacility}
                      date={date}
                    />
                  );
                case "arpob-analysis":
                  return (
                    <ARPOBAnalysis
                      currentFacility={currentFacility}
                      date={date}
                    />
                  );
              }
            })()}
          </Col>
        </Row>
      </CustomLayout>
    </>
  );
};

export default OrgDashboard;
