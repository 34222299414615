import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  Row,
  Modal,
  InputNumber,
  message,
  Input,
  Divider,
  TimePicker,
} from "antd";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { CloseOutlined } from "@ant-design/icons";
import {
  addVitalsMonitoringApi,
  updateNurseVitalsMonitoringApi,
} from "../../../../services/nurse.services";
import useQuery from "../../../../hooks/useQuery";
import dayjs from "dayjs";
const format = "hh A";

const GrbsModal = ({ open, setOpen, editData, refresh, date }) => {
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  useEffect(() => {
    if (editData?.id) {
      form.setFieldsValue({
        ...editData,
        action_time: dayjs(editData?.action_time),
      });
    }
  }, []);
  const onFinish = (values) => {
    setLoading(true);

    const selectedTime = values?.action_time
      ? dayjs(values?.action_time).format("HH:mm")
      : null;
    const combinedDateTime = selectedTime
      ? dayjs(date)
          .set("hour", dayjs(values?.action_time).hour())
          .set("minute", dayjs(values?.action_time).minute())
          .format("YYYY-MM-DDTHH:mm:ss")
      : editData?.action_time;

    const updatedValues = {
      ...values,
      action_time: combinedDateTime,
    };

    if (editData?.id) {
      updateNurseVitalsMonitoringApi(editData?.id, updatedValues)
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      addVitalsMonitoringApi(params?.patient, {
        ...updatedValues,
        is_grbs: true,
      })
        ?.then((res) => {
          message.success(res?.data?.message);
          setOpen(false);
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          message?.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Row>
            <Col span={12} className="flex-start">
              <Typography
                style={{
                  marginLeft: "12px",
                  fontWeight: "400",
                  fontSize: "20px",
                }}
              >
                {editData?.id
                  ? "Edit Blood Sugar / GRBS "
                  : "Add Blood Sugar / GRBS "}
              </Typography>
            </Col>
            <Col span={12} className="flex-end">
              <CloseOutlined
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
          </Row>
        }
        closeIcon={null}
        footer={
          <>
            <Divider style={{ margin: "16px 0" }} />
            <div className="flex-end" style={{ gap: "24px" }}>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  className={`${btnStyles.mdBtn} inv-btn`}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  className={`${btnStyles.mdBtn} inv-btn`}
                  onClick={() => {
                    if (formRef.current) {
                      formRef.current.submit();
                    }
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </>
        }
      >
        <Divider style={{ margin: "16px 0" }} />
        <Form layout="vertical" onFinish={onFinish} form={form} ref={formRef}>
          <Form.Item
            label="Time"
            name="action_time"
            rules={[{ required: true, message: "Please Select time" }]}
          >
            <TimePicker format={format} needConfirm={false} />
          </Form.Item>
          <Row>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item
                label="GRBS"
                name="grbs"
                rules={[
                  {
                    type: "number",
                    min: 60,
                    max: 500,
                    message: `Out of Range (60-500)`,
                  },
                ]}
              >
                <InputNumber placeholder="60-500" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item label="Insulin" name="insulin">
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item label="Remarks" name="remarks">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default GrbsModal;
