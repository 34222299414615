import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { RespiratoryInitialContext } from "./RespiratoryTherapistInitialAssessmentProvider";
import {
  respiratoryInspectionAPI,
  updateRespiratoryInspectionAPI,
} from "../../../../services/respiratory.services";
import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Image,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import ClinicalImpressionForm from "../../InspectionForms/ClinicalImpressionForm/ClinicalImpressionForm";
import RespiratoryTherapistChartAssessment from "../../InspectionForms/ChartAssessment/RespiratoryTherapistChartAssessment";
import RespiratoryPatientAssessment from "../../InspectionForms/PatientAssessment/RespiratoryPatientAssessment";
import RespiratoryTherapistTreatmentPlan from "../../InspectionForms/TreatmentPlan/RespiratoryTherapistTreatmentPlan";
import RespiratorySpecialInstruction from "../../InspectionForms/SpecialAreas/RespiratorySpecialInstruction";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { LeftOutlined } from "@ant-design/icons";
import bedImg from "../../../../assets/images/patientIcons/bed.svg";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;
const RespiratoryTherapistInitialInspectionForm = ({
  setVisible,
  refreshPage,
  initialRefresh,
  editId,
  patientData,
  setConfirmModal,
}) => {
  const screen = useBreakpoint();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [clinicalForm] = Form.useForm();
  const [chartForm] = Form.useForm();
  const [patientForm] = Form.useForm();
  const [treatmentForm] = Form.useForm();
  const [specialForm] = Form.useForm();

  const [activeTab, setActiveTab] = useState("1");
  const { formData } = useContext(RespiratoryInitialContext);

  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = async (values, flag) => {
    try {
      if (editId) {
        await updateRespiratoryInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await respiratoryInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success(`${flag} submitted successfully`);

      if (activeTab === "5") {
        setVisible(false);
      } else {
        const nextTab = (parseInt(activeTab) + 1).toString();
        setActiveTab(nextTab);
      }
      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onClinicalSubmit = (values) => {
    handleSubmit(
      { clinical_diagnosis: values, admission_id: params?.patient },
      "Clinical Impression/ Diagnosis"
    );
  };
  const onChartSubmit = (values) => {
    handleSubmit(
      {
        chart_assessment: { ...values },
        admission_id: params?.patient,
      },
      "Chart Assessment"
    );
  };
  const onPatientSubmit = (values) => {
    handleSubmit(
      {
        patient_assessment: {
          ...values,
        },
        admission_id: params?.patient,
      },
      "Patient Assessment"
    );
  };
  const onTreatmentSubmit = (values) => {
    handleSubmit(
      {
        treatment_plan: values,
        admission_id: params?.patient,
      },
      "Treatment Plan"
    );
  };
  const onSpecialSubmit = (values) => {
    handleSubmit(
      {
        special_instruction: values,
        admission_id: params?.patient,
      },
      "Special Instructions"
    );
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return clinicalForm.submit();
      case "2":
        return chartForm.submit();
      case "3":
        return patientForm.submit();
      case "4":
        return treatmentForm.submit();
      case "5":
        return specialForm.submit();

      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    if (isDirty) {
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              clinicalForm.validateFields().then((values) => {
                setIsDirty(false);
                onClinicalSubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              chartForm.validateFields().then((values) => {
                setIsDirty(false);
                onChartSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              patientForm.validateFields().then((values) => {
                setIsDirty(false);
                onPatientSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              treatmentForm.validateFields().then((values) => {
                setIsDirty(false);
                onTreatmentSubmit(values);
                setActiveTab(key);
              });
              break;
            case "5":
              specialForm.validateFields().then((values) => {
                setIsDirty(false);
                onSpecialSubmit(values);
                setActiveTab(key);
              });
              break;

            default:
              setActiveTab(key);
          }
        },
        onCancel: () => {
          setActiveTab(key);
          setIsDirty(false);
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Space>
            <LeftOutlined onClick={() => setConfirmModal(true)} />
            <Typography
              style={{ color: "#101828", fontSize: "16px", fontWeight: 600 }}
            >
              {`${patientData?.admission?.patient?.first_name} ${patientData?.admission?.patient?.last_name}`}{" "}
            </Typography>
            {patientData?.admission?.bed?.bed_no && (
              <Tag color="orange">
                <Image src={bedImg} preview={false} />{" "}
                {patientData?.admission?.bed?.bed_no}
              </Tag>
            )}
          </Space>
          <br />
          <Space style={{ marginTop: "10px" }}>
            <Tag>
              {patientData?.admission?.patient?.age}{" "}
              {patientData?.admission?.patient?.gender}
            </Tag>
            <Tag>IP ID: {patientData?.admission?.ip_id}</Tag>
          </Space>
        </Col>
        {(screen.xl === false || screen.xxl === false) && (
          <Col
            xs={{ flex: 1 }}
            sm={{ flex: 1 }}
            md={{ flex: 1 }}
            lg={{ flex: 1 }}
            xl={0}
            xxl={0}
            className="flex-end"
          >
            <Row style={{ flexDirection: "column" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
                {formData?.submitted_by_first_name && (
                  <Tag>
                    Done by: {formData?.submitted_by_first_name}{" "}
                    {formData?.submitted_by_last_name}
                  </Tag>
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
                <Tag>
                  {dayjs(formData?.updated_at || new Date()).format(
                    "DD/MM/YYYY"
                  )}
                </Tag>
              </Col>
            </Row>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={14}
          xxl={14}
          className="flex-center"
        >
          <Tabs
            activeKey={activeTab}
            items={[
              { key: "1", label: "Clinical Impression " },
              { key: "2", label: "Chart Assessment" },
              { key: "3", label: "Patient Assessment" },
              { key: "4", label: "Treatment Plan" },
              { key: "5", label: "Special Instructions" },
            ]}
            onChange={handleTabChange}
          />
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={0}
          xl={{ flex: 1 }}
          xxl={{ flex: 1 }}
          className="flex-end"
        >
          <Row gutter={[8, 8]} style={{ flexDirection: "column" }}>
            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
              {formData?.submitted_by_first_name && (
                <Tag>
                  Done by: {formData?.submitted_by_first_name}{" "}
                  {formData?.submitted_by_last_name}
                </Tag>
              )}
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
              <Tag>
                {dayjs(formData?.updated_at || new Date()).format("DD/MM/YYYY")}
              </Tag>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ margin: "8px 0px" }} />
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "calc(100% - 38px)",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          {activeTab === "1" && (
            <Form
              id="clinical_diagnosis-form"
              form={clinicalForm}
              layout="vertical"
              onFinish={onClinicalSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <ClinicalImpressionForm
                form={clinicalForm}
                context={RespiratoryInitialContext}
                editId={editId}
                patientData={patientData}
              />
            </Form>
          )}
          {activeTab === "2" && (
            <Form
              id="chart-form"
              form={chartForm}
              layout="vertical"
              onFinish={onChartSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryTherapistChartAssessment form={chartForm} />
            </Form>
          )}
          {activeTab === "3" && (
            <Form
              id="patient-form"
              form={patientForm}
              layout="vertical"
              onFinish={onPatientSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryPatientAssessment
                form={patientForm}
                context={RespiratoryInitialContext}
              />
            </Form>
          )}
          {activeTab === "4" && (
            <Form
              id="treatment-form"
              form={treatmentForm}
              layout="vertical"
              onFinish={onTreatmentSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratoryTherapistTreatmentPlan
                form={treatmentForm}
                context={RespiratoryInitialContext}
              />
            </Form>
          )}
          {activeTab === "5" && (
            <Form
              id="special-instruction"
              form={specialForm}
              layout="vertical"
              onFinish={onSpecialSubmit}
              onValuesChange={onFormValuesChange}
              initialValues={formData}
              className="ip-forms"
            >
              <RespiratorySpecialInstruction form={specialForm} />
            </Form>
          )}

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
            >
              {activeTab === "5" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RespiratoryTherapistInitialInspectionForm;
