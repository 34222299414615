import React, { lazy, Suspense, useContext, useState } from "react";
import { Tabs, Button, message, Form, Modal, Row, Col, Typography } from "antd";
// import DoctorHistoryForm from "../../InspectionForms/HistoryForm/DoctorHistoryForm";
// import ClinicalImpressionForm from "../../InspectionForms/ClinicalImpressionForm/ClinicalImpressionForm";
// import GeneralExaminationForm from "../../InspectionForms/Examination/GeneralExaminationForm/GeneralExaminationForm";
// import SystemicExaminationForm from "../../InspectionForms/Examination/SystemicExaminationForm/SystemicExaminationForm";
// import FunctionalExaminationForm from "../../InspectionForms/Examination/FunctionalExaminationForm/FunctionalExaminationForm";

import "../../ipforms.css";
import { DoctorInitialContext } from "../../InspectionForms/HistoryForm/DoctorHistoryFormProvider";
import {
  doctorInspectionAPI,
  updateDoctorInspectionAPI,
} from "../../../../services/doctor.services";
import btnStyles from "../../../../styles/Custom/Button.module.css";
import { useLocation } from "react-router-dom";
import { initialTabs } from "../../../../helpers/medicineKeys";

const DoctorHistoryForm = lazy(() =>
  import("../../InspectionForms/HistoryForm/DoctorHistoryForm")
);
const ClinicalImpressionForm = lazy(() =>
  import("../../InspectionForms/ClinicalImpressionForm/ClinicalImpressionForm")
);
const GeneralExaminationForm = lazy(() =>
  import(
    "../../InspectionForms/Examination/GeneralExaminationForm/GeneralExaminationForm"
  )
);
const SystemicExaminationForm = lazy(() =>
  import(
    "../../InspectionForms/Examination/SystemicExaminationForm/SystemicExaminationForm"
  )
);
const FunctionalExaminationForm = lazy(() =>
  import(
    "../../InspectionForms/Examination/FunctionalExaminationForm/FunctionalExaminationForm"
  )
);

const InitialInspectionForm = ({
  refreshPage,
  editId,
  initialRefresh,
  setCurrent,
  setVisible,
  patientData,
  activeTab,
  setActiveTab,
}) => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const [historyForm] = Form.useForm();
  const [generalForm] = Form.useForm();
  const [systemicForm] = Form.useForm();
  const [functionalForm] = Form.useForm();
  const [clinicalForm] = Form.useForm();
  const { formData } = useContext(DoctorInitialContext);
  const [drainData, setDrainData] = useState(
    formData?.general_examination?.drain || []
  );
  const [openWounds, setOpenWounds] = useState(
    formData?.systemic_examination?.open_wounds || []
  );
  const [pressureSore, setPressureSore] = useState(
    formData?.systemic_examination?.pressure_sore || []
  );
  const [isDirty, setIsDirty] = useState(false);
  const [nextTab, setNextTab] = useState(null);

  const handleSubmit = async (values, flag) => {
    try {
      if (editId) {
        await updateDoctorInspectionAPI(editId, values);
        initialRefresh();
      } else {
        await doctorInspectionAPI(values);
        refreshPage();
        initialRefresh();
      }

      message.success(`${flag} submitted successfully`);
      const nextTab = (parseInt(activeTab) + 1).toString();
      if (activeTab !== "5") {
        setActiveTab(nextTab);
      } else if (activeTab === "5" && formData?.care_items_generated) {
        setVisible(false);
      } else {
        setCurrent(1);
      }
      setIsDirty(false);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const onHistorySubmit = (values) => {
    handleSubmit({ history: values, admission_id: params?.patient }, "History");
  };
  const onGeneralSubmit = (values) => {
    handleSubmit(
      {
        general_examination: { ...values, drain: drainData },
        admission_id: params?.patient,
      },
      "General Examination"
    );
  };
  const onSystemicSubmit = (values) => {
    handleSubmit(
      {
        systemic_examination: {
          // ...formData?.systemic_examination,
          pressure_sore: pressureSore,
          open_wounds: openWounds,
          ...values,
        },
        admission_id: params?.patient,
      },
      "Systemic Examination"
    );
  };
  const onFunctionalSubmit = (values) => {
    handleSubmit(
      {
        functional_examination: values,
        admission_id: params?.patient,
      },
      "Functional Examination"
    );
  };
  const onClinicalSubmit = (values) => {
    handleSubmit(
      {
        clinical_diagnosis: values,
        admission_id: params?.patient,
        intial_assessment_done: true,
      },
      "Clinical Impression/Diagnosis"
    );
  };

  const getFormKey = () => {
    switch (activeTab) {
      case "1":
        return historyForm.submit();
      case "2":
        return generalForm.submit();
      case "3":
        return systemicForm.submit();
      case "4":
        return functionalForm.submit();
      case "5":
        return clinicalForm.submit();
      default:
        return "";
    }
  };

  const handleTabChange = (key) => {
    console.log("tabchange");
    if (isDirty) {
      setNextTab(key);
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "You have unsaved changes. Are you sure you want to save these changes?",
        onOk: () => {
          switch (activeTab) {
            case "1":
              historyForm.validateFields().then((values) => {
                setIsDirty(false);
                onHistorySubmit(values);
                setActiveTab(key);
              });
              break;
            case "2":
              generalForm.validateFields().then((values) => {
                setIsDirty(false);
                onGeneralSubmit(values);
                setActiveTab(key);
              });
              break;
            case "3":
              systemicForm.validateFields().then((values) => {
                setIsDirty(false);
                onSystemicSubmit(values);
                setActiveTab(key);
              });
              break;
            case "4":
              functionalForm.validateFields().then((values) => {
                setIsDirty(false);
                onFunctionalSubmit(values);
                setActiveTab(key);
              });
              break;
            case "5":
              clinicalForm.validateFields().then((values) => {
                setIsDirty(false);
                onClinicalSubmit(values);
                setActiveTab(key);
              });
            default:
              setActiveTab(key);
          }
        },
        onCancel: () => {
          setActiveTab(key);
          setIsDirty(false);
        },
      });
    } else {
      setActiveTab(key);
    }
  };

  const onFormValuesChange = () => {
    setIsDirty(true);
    // refreshForm();
    // initialRefresh();
  };

  return (
    <>
      {!patientData && (
        <Row>
          <Col
            xs={24}
            sm={24}
            md={{ span: 23, offset: 1 }}
            lg={{ span: 21, offset: 3 }}
            xl={{ span: 19, offset: 5 }}
            xxl={{ span: 18, offset: 6 }}
          >
            <Tabs
              activeKey={activeTab}
              items={initialTabs}
              onChange={handleTabChange}
            />
          </Col>
        </Row>
      )}
      <div
        style={{
          height: `${patientData ? "100%" : "90%"}`,
          overflow: "hidden",
          position: "relative",
          background: "#f6f5ff",
        }}
      >
        <div
          style={{
            height: "calc(100% - 38px)",
            overflowY: "auto",
            marginTop: "0px",
          }}
        >
          <Suspense fallback={<Typography>Loading...</Typography>}>
            {activeTab === "1" && (
              <Form
                id="history-form"
                form={historyForm}
                layout="vertical"
                onFinish={onHistorySubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <DoctorHistoryForm
                  form={historyForm}
                  context={DoctorInitialContext}
                  // editId={editId}
                  patientData={patientData}
                />
              </Form>
            )}
            {activeTab === "2" && (
              <Form
                id="general-form"
                form={generalForm}
                layout="vertical"
                onFinish={onGeneralSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <GeneralExaminationForm
                  drainData={drainData}
                  setDrainData={setDrainData}
                  form={generalForm}
                />
              </Form>
            )}
            {activeTab === "3" && (
              <Form
                id="systemic-form"
                form={systemicForm}
                layout="vertical"
                onFinish={onSystemicSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <SystemicExaminationForm
                  form={systemicForm}
                  pressureSore={pressureSore}
                  setPressureSore={setPressureSore}
                  openWounds={openWounds}
                  setOpenWounds={setOpenWounds}
                />
              </Form>
            )}
            {activeTab === "4" && (
              <Form
                id="functional-form"
                form={functionalForm}
                layout="vertical"
                onFinish={onFunctionalSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <FunctionalExaminationForm form={functionalForm} />
              </Form>
            )}
            {activeTab === "5" && (
              <Form
                id="clinical-form"
                form={clinicalForm}
                layout="vertical"
                onFinish={onClinicalSubmit}
                onValuesChange={onFormValuesChange}
                initialValues={formData}
                className="ip-forms"
              >
                <ClinicalImpressionForm
                  form={clinicalForm}
                  context={DoctorInitialContext}
                  editId={editId}
                  patientData={patientData}
                />
              </Form>
            )}
          </Suspense>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              background: "#fff",
              paddingTop: "10px",
              borderTop: "1px solid #e8e8e8",
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => getFormKey()}
              type="primary"
              className={btnStyles.mdBtn}
              style={{ fontWeight: 500 }}
            >
              {activeTab === "5" ? "Done" : " Save & Proceed"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InitialInspectionForm;
