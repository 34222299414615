import React, { useEffect, useState } from "react";
import { Table, Select, Typography, Form, Row, Col, Card, Button } from "antd";
import { segmentsOptions } from "../../../../Options/physioOptions";

const { Option } = Select;

const upperExtremityOptions = ["C5", "C6", "C7", "C8", "T1"];
const lowerExtremityOptions = ["L2", "L3", "L4", "L5", "S1"];

// const motorOptions = [1, 2, 3, 4, 5, "NT"];
// const sensoryOptions = [0, 1, 2, "NT"];

const motorOptions = [
  { label: "", value: null },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: "NT", value: "NT" },
];
const sensoryOptions = [
  { label: "", value: null },
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: "NT", value: "NT" },
];

const MotorSensoryScale = ({ scores, setScores, form }) => {
  const [calcScore, setCalcScore] = useState(false);
  const handleMasterfieldChange = (key1, key2, value) => {
    // const fields = data.map((record) => (
    //   { [key1]: { [key2]: { [record.value]: value } } },
    // ));
    const fields = data.reduce((acc, record) => {
      acc[key1] = acc[key1] || {};
      acc[key1][key2] = acc[key1][key2] || {};
      acc[key1][key2][record.value] = value;
      return acc;
    }, {});
    form.setFieldsValue(fields);
    // setCalcScore((prev) => !prev);
    // console.log(fields);
  };

  const data = segmentsOptions.map((segment, index) => ({
    key: index,
    segment: segment.label,
    value: segment.value,
  }));

  const calculateScores = (data) => {
    const ltl = Object.values(data?.ltl ?? {})
      .filter((value) => typeof value === "number" && !isNaN(value))
      .reduce((acc, cur) => acc + cur, 0);
    setScores((prev) => ({ ...prev, ltl }));
    const ppl = Object.values(data?.ppl ?? {})
      .filter((value) => typeof value === "number" && !isNaN(value))
      .reduce((acc, cur) => acc + cur, 0);
    setScores((prev) => ({ ...prev, ppl }));
    const ppr = Object.values(data?.ppr ?? {})
      .filter((value) => typeof value === "number" && !isNaN(value))
      .reduce((acc, cur) => acc + cur, 0);
    setScores((prev) => ({ ...prev, ppr }));
    const ltr = Object.values(data?.ltr || {})
      .filter((value) => typeof value === "number" && !isNaN(value))
      .reduce((acc, cur) => acc + cur, 0);
    setScores((prev) => ({ ...prev, ltr }));
    const leftUpperMotor = Object.entries(data?.left_motor || {})
      .filter(([key, value]) => upperExtremityOptions.includes(key))
      .reduce(
        (acc, [key, value]) => acc + (typeof value === "number" ? value : 0),
        0
      );
    const leftLowerMotor = Object.entries(data?.left_motor || {})
      .filter(([key, value]) => lowerExtremityOptions.includes(key))
      .reduce(
        (acc, [key, value]) => acc + (typeof value === "number" ? value : 0),
        0
      );
    setScores((prev) => ({ ...prev, leftUpperMotor, leftLowerMotor }));
    const rightUpperMotor = Object.entries(data?.right_motor || {})
      .filter(([key, value]) => upperExtremityOptions.includes(key))
      .reduce(
        (acc, [key, value]) => acc + (typeof value === "number" ? value : 0),
        0
      );
    const rightLowerMotor = Object.entries(data?.right_motor || {})
      .filter(([key, value]) => lowerExtremityOptions.includes(key))
      .reduce(
        (acc, [key, value]) => acc + (typeof value === "number" ? value : 0),
        0
      );
    setScores((prev) => ({ ...prev, rightUpperMotor, rightLowerMotor }));
    console.log(scores);
  };

  useEffect(() => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        calculateScores(values?.motor_sensory_scale);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [calcScore]);

  const columns = [
    {
      title: "Left Sensory",
      children: [
        {
          title: (
            <>
              <Typography.Text>Motor</Typography.Text>
              <br />
              <Select
                style={{ width: 70 }}
                value={null}
                onSelect={(value) =>
                  handleMasterfieldChange(
                    "motor_sensory_scale",
                    "left_motor",
                    value
                  )
                }
              >
                {motorOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </>
          ),
          align: "center",
          dataIndex: "left_motor",
          render: (text, record) =>
            upperExtremityOptions.includes(record.value) ||
            lowerExtremityOptions.includes(record.value) ? (
              <Form.Item
                name={["motor_sensory_scale", "left_motor", record.value]}
              >
                <Select defaultValue={text} style={{ width: 70 }}>
                  {motorOptions.map((option) => (
                    <Option key={option?.value} value={option?.value}>
                      {option?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              ""
            ),
        },
        {
          title: (
            <>
              <Typography.Text>LTL</Typography.Text>
              <br />
              <Select
                style={{ width: 70 }}
                value={null}
                onChange={(value) =>
                  handleMasterfieldChange("motor_sensory_scale", "ltl", value)
                }
              >
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </>
          ),
          align: "center",
          dataIndex: "ltl",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ltl", record.value]}>
              <Select defaultValue={text} style={{ width: 70 }}>
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
        {
          title: (
            <>
              <Typography.Text>PPL</Typography.Text>
              <br />
              <Select
                style={{ width: 70 }}
                value={null}
                onChange={(value) =>
                  handleMasterfieldChange("motor_sensory_scale", "ppl", value)
                }
              >
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </>
          ),
          align: "center",
          dataIndex: "ppl",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ppl", record.value]}>
              <Select defaultValue={text} style={{ width: 70 }}>
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
      ],
    },
    {
      title: "Segment",
      dataIndex: "segment",
      align: "center",
    },
    {
      title: "Right Sensory",
      children: [
        {
          title: (
            <>
              <Typography.Text>PPR</Typography.Text>
              <br />
              <Select
                value={null}
                style={{ width: 70 }}
                onChange={(value) =>
                  handleMasterfieldChange("motor_sensory_scale", "ppr", value)
                }
              >
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </>
          ),
          align: "center",
          dataIndex: "ppr",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ppr", record.value]}>
              <Select defaultValue={text} style={{ width: 70 }}>
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
        {
          title: (
            <>
              <Typography.Text>LTR</Typography.Text>
              <br />
              <Select
                value={null}
                style={{ width: 70 }}
                onChange={(value) =>
                  handleMasterfieldChange("motor_sensory_scale", "ltr", value)
                }
              >
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </>
          ),
          align: "center",
          dataIndex: "ltr",
          render: (text, record) => (
            <Form.Item name={["motor_sensory_scale", "ltr", record.value]}>
              <Select defaultValue={text} style={{ width: 70 }}>
                {sensoryOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ),
        },
        {
          title: (
            <>
              <Typography.Text>Motor</Typography.Text>
              <br />
              <Select
                value={null}
                style={{ width: 70 }}
                onChange={(value) =>
                  handleMasterfieldChange(
                    "motor_sensory_scale",
                    "right_motor",
                    value
                  )
                }
              >
                {motorOptions.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </>
          ),
          align: "center",
          dataIndex: "right_motor",
          render: (text, record) =>
            upperExtremityOptions.includes(record.value) ||
            lowerExtremityOptions.includes(record.value) ? (
              <Form.Item
                name={["motor_sensory_scale", "right_motor", record.value]}
              >
                <Select defaultValue={text} style={{ width: 70 }}>
                  {motorOptions.map((option) => (
                    <Option key={option?.value} value={option?.value}>
                      {option?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              ""
            ),
        },
      ],
    },
  ];

  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        title={() => (
          <div style={{ textAlign: "center" }}>
            <Typography.Text strong>Motor and Sensory Scale</Typography.Text>
          </div>
        )}
        bordered
      />
      <Card
        title={
          <div style={{ textAlign: "right" }}>
            <Button onClick={() => setCalcScore((prev) => !prev)}>
              Calculate Scores
            </Button>
          </div>
        }
      >
        <Row style={{ textAlign: "center" }}>
          <Col span={12}>
            <Typography>
              Lower Extremity Motor Score:{" "}
              {(scores.leftLowerMotor || 0) + (scores.rightLowerMotor || 0)}
            </Typography>
            <Typography>
              Left Total Motor Score:{" "}
              {(scores.leftUpperMotor || 0) + (scores.leftLowerMotor || 0)}
            </Typography>
            <Typography>
              Left Total Sensory Score: {(scores.ltl || 0) + (scores.ppl || 0)}
            </Typography>
            <Typography>
              Total LT: {(scores.ltl || 0) + (scores.ltr || 0)}
            </Typography>
          </Col>
          <Col span={12} style={{ justifyContent: "space-around" }}>
            <Typography>
              Upper Extremity Motor Score:{" "}
              {(scores.leftUpperMotor || 0) + (scores.rightUpperMotor || 0)}
            </Typography>
            <Typography>
              Right Total Motor Score:{" "}
              {(scores.rightLowerMotor || 0) + (scores.rightUpperMotor || 0)}
            </Typography>
            <Typography>
              Right Total Sensory Score: {(scores.ltr || 0) + (scores.ppr || 0)}
            </Typography>
            <Typography>
              Total PP: {(scores.ppl || 0) + (scores.ppr || 0)}
            </Typography>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default MotorSensoryScale;
