import React from "react";
import { Checkbox, Form, Input } from "antd";
import { orthoHistoryOptions } from "../OpPhysioOptions";

const OrthoHistory = () => {
  return (
    <>
      <Form.Item label="History" name={"history"}>
        <Checkbox.Group options={orthoHistoryOptions} />
      </Form.Item>
      {/* <Form.Item name={"history_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item> */}
    </>
  );
};

export default OrthoHistory;
