import { Typography } from "antd";
import React, { useState } from "react";
import { notAssessableAndAssessableOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import ROMItems from "./ROMItems";

const ROMAssessment = ({ context, form }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <Typography>Range of Motion</Typography>

      <DeselectableRadioGroup
        name={["rom", "status"]}
        options={notAssessableAndAssessableOptions}
        form={form}
        setRefresh={setRefresh}
      />
      {form.getFieldValue(["rom", "status"]) === "assessable" && <ROMItems />}
    </>
  );
};

export default ROMAssessment;
