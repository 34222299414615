import React from "react";
import { Collapse, Form } from "antd";
import {
  ankleOptions,
  cervicalOptions,
  elbowOptions,
  hipOptions,
  kneeOptions,
  lumbarOptions,
  positiveNegativeOptions,
  sacroiliacOptions,
  shoulderOptions,
  specialTestCategories,
  thoracicOptions,
  tnjOptions,
  wristAndHandOptions,
} from "../Orthoform/orthoOptions";
import DeselectableRadioGroup from "../../../../../blocks/FormItems/DeselectableRadioGroup";

const optionsMap = {
  1: shoulderOptions,
  2: elbowOptions,
  3: wristAndHandOptions,
  4: hipOptions,
  5: kneeOptions,
  6: ankleOptions,
  7: cervicalOptions,
  8: thoracicOptions,
  9: lumbarOptions,
  10: sacroiliacOptions,
  11: tnjOptions,
};

const SpecialTestsForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();

  const renderRadioGroups = (key, val) => {
    const options = optionsMap[key] || [];
    return options?.map((subOption) => (
      <DeselectableRadioGroup
        name={[val, subOption.value]}
        label={subOption.label}
        options={positiveNegativeOptions}
        form={form}
      />
    ));
  };

  const items = specialTestCategories?.map((category) => ({
    key: category?.key,
    label: category?.label,
    children: renderRadioGroups(category?.key, category?.value),
  }));

  const defaultOpenKeys = specialTestCategories?.map(
    (category) => category.key
  );

  return (
    <Form
      id="special-test-form"
      form={form}
      onValuesChange={(cur, all) => onValuesChange("special_tests", all)}
      layout="vertical"
      className="ip-forms"
      initialValues={formData?.special_tests}
    >
      <Collapse
        defaultActiveKey={defaultOpenKeys}
        items={items}
        expandIconPosition="right"
      />
    </Form>
  );
};

export default SpecialTestsForm;
