import {
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Row,
  Col,
} from "antd";
import React, { useState } from "react";
import {
  genderOptions,
  minsWalkedOptions,
  reasonOptions,
  rpeOptions,
} from "../../../../Options/functionalAsessmentOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";
import SelectWithOtherInput from "../../../../../../blocks/FormItems/SelectWithOtherInput";

const SixMinWalkTestItems = ({
  handleChange,
  states,
  predictedDistance,
  nameKey = null,
  op = false,
  form,
}) => {
  const [refresh, setRefresh] = useState(false);
  return (
    // <div className={nameKey || op ? "physio-scales initial" : "physio-scales"}>
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <DeselectableRadioGroup
            label={"Gender"}
            name={nameKey ? ["endurance", "smwt", "gender"] : "gender"}
            form={form}
            options={genderOptions}
            radioProps={{
              onChange: (e) => handleChange("gender", e.target.value),
            }}
          />
        </Col>
        <Col span={8}>
          <Form.Item
            label="Age"
            name={nameKey ? ["endurance", "smwt", "age"] : "age"}
          >
            <InputNumber
              min={0}
              max={150}
              addonAfter="years"
              onChange={(value) => handleChange("age", value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Height"
            name={nameKey ? ["endurance", "smwt", "height"] : "height"}
          >
            <InputNumber
              min={0}
              addonAfter="cms"
              onChange={(value) => {
                handleChange("height", value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Weight"
            name={nameKey ? ["endurance", "smwt", "weight"] : "weight"}
          >
            <InputNumber
              min={0}
              max={500}
              addonAfter="kgs"
              onChange={(value) => {
                handleChange("weight", value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {states.age && states.age && states.height && states.weight && (
            <>
              <Typography>
                Predicted Distance: {predictedDistance} mtrs
              </Typography>
            </>
          )}
        </Col>
        <Divider
          orientation={"left"}
          className="division-header"
          // style={{ margin: "8px 0px", color: "white", borderColor: "#ececec" }}
        >
          Pre Vitals
        </Divider>

        <Col span={8}>
          {/* <Divider /> */}

          <Form.Item
            label="Heart Rate"
            name={nameKey ? ["endurance", "smwt", "heart_rate"] : "heart_rate"}
          >
            <InputNumber
              placeholder="Range-40 to 130"
              max={130}
              min={40}
              addonAfter="beats/min"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="SBP"
            name={nameKey ? ["endurance", "smwt", "sbp"] : "sbp"}
          >
            <InputNumber
              placeholder="Range-100 to 180"
              max={180}
              min={100}
              addonAfter="mmHg"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="DBP"
            name={nameKey ? ["endurance", "smwt", "dbp"] : "dbp"}
          >
            <InputNumber
              placeholder="Range-60 to 110"
              max={110}
              min={60}
              addonAfter="mmHg"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="SPO2"
            name={nameKey ? ["endurance", "smwt", "spo2"] : "spo2"}
          >
            <InputNumber
              placeholder="Range-90 to 100"
              max={100}
              min={90}
              addonAfter="%"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="RR"
            name={nameKey ? ["endurance", "smwt", "rr"] : "rr"}
          >
            <InputNumber
              placeholder="Range-12 to 30"
              max={30}
              min={12}
              addonAfter="breaths/min"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="RPE"
            name={nameKey ? ["endurance", "smwt", "rpe"] : "rpe"}
          >
            <Select options={rpeOptions} />
          </Form.Item>
        </Col>
        <Divider
          orientation="left"
          className="division-header"
          // style={{ margin: "8px 0px", color: "white", borderColor: "#ececec" }}
        >
          Post Vitals
        </Divider>
        <Col span={8}>
          {/* <Divider /> */}

          <Form.Item
            label="Heart Rate"
            name={
              nameKey
                ? ["endurance", "smwt", "post_vital_heart_rate"]
                : "post_vital_heart_rate"
            }
          >
            <InputNumber
              placeholder="Range-40 to 200"
              max={200}
              min={40}
              addonAfter="beats/min"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="SBP"
            name={
              nameKey
                ? ["endurance", "smwt", "post_vital_sbp"]
                : "post_vital_sbp"
            }
          >
            <InputNumber
              placeholder="Range-100 to 240"
              max={240}
              min={100}
              addonAfter="mmHg"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="DBP"
            name={
              nameKey
                ? ["endurance", "smwt", "post_vital_dbp"]
                : "post_vital_dbp"
            }
          >
            <InputNumber
              placeholder="Range-60 to 140"
              max={140}
              min={60}
              addonAfter="mmHg"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="SPO2"
            name={
              nameKey
                ? ["endurance", "smwt", "post_vital_spo2"]
                : "post_vital_spo2"
            }
          >
            <InputNumber
              placeholder="Range-80 to 100"
              max={100}
              min={80}
              addonAfter="%"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="RR"
            name={
              nameKey ? ["endurance", "smwt", "post_vital_rr"] : "post_vital_rr"
            }
          >
            <InputNumber
              placeholder="Range-12 to 60"
              max={60}
              min={12}
              addonAfter="breaths/min"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="RPE"
            name={
              nameKey
                ? ["endurance", "smwt", "post_vital_rpe"]
                : "post_vital_rpe"
            }
          >
            <Select options={rpeOptions} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="No of Laps"
            name={nameKey ? ["endurance", "smwt", "no_of_laps"] : "no_of_laps"}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Distance walked"
            name={
              nameKey
                ? ["endurance", "smwt", "distance_covered"]
                : "distance_covered"
            }
          >
            <InputNumber addonAfter="meters" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Final partial lap"
            name={
              nameKey
                ? ["endurance", "smwt", "final_lap_in_meters"]
                : "final_lap_in_meters"
            }
          >
            <InputNumber addonAfter="meters" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Mins walked"
            name={
              nameKey ? ["endurance", "smwt", "mins_walked"] : "mins_walked"
            }
          >
            <Select options={minsWalkedOptions} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <SelectWithOtherInput
            label="Reasons for immediately stopping a 6MWT"
            name={
              nameKey
                ? ["endurance", "smwt", "reason_for_immediate_stopping"]
                : "reason_for_immediate_stopping"
            }
            form={form}
            otherLabel={" "}
            options={reasonOptions}
            selectProps={{
              onChange: () => {
                setRefresh((prev) => !prev);
              },
            }}
            selectWidth={300}
            otherFieldName={
              nameKey
                ? ["endurance", "smwt", "reason_for_immediate_stopping_others"]
                : "reason_for_immediate_stopping_others"
            }
          />
        </Col>

        {!nameKey ||
          (op && (
            <Col span={24}>
              <Form.Item
                className="scales-comments-formitem"
                name={nameKey ? ["endurance", "smwt", "comments"] : "comments"}
              >
                <Input.TextArea rows={4} placeholder="Comments" />
              </Form.Item>
            </Col>
          ))}
      </Row>
      {/* </div> */}
    </>
  );
};

export default SixMinWalkTestItems;
