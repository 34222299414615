import React from "react";
import {
  getLabelTag,
  getLabelTagInput,
  getLabelTagsWithRadioOptions,
} from "../constructTextFromValues";
import {
  noYesOptions,
  notAssessableAndAssessableOptions,
  yesNoOptions,
} from "../../Options/commonOptions";
import {
  activitySelectionOptions,
  catheterOptions,
  dailyActivityDependenceOptions,
  dailyActivityOptions,
  frictionSelectionOptions,
  hearingOptions,
  incontinenceOptions,
  leftRightBothOptions,
  mobilitySelectionOptions,
  moistureSelectionOptions,
  nutritionSelectionOptions,
  perceptionSelectionOptions,
  speechTypeOptions,
  stoolOptions,
  urineOptions,
  visionOptions,
} from "../../Options/functionalAsessmentOptions";
import {
  ambulanceAssistanceOptions,
  ambulanceBedBoundOptions,
  ambulanceOptions,
} from "../../Options/funcExamOptions";
import { Descriptions, Image, Space, Tag } from "antd";
import { FileProtectOutlined } from "@ant-design/icons";
import FunctionalStatus from "../../../../assets/images/nurseIcons/functionalstatus.svg";

const NurseFunctionalAssessmentSummary = ({ formData }) => {
  const constructFunctionalAssessmentSummaryItems = () => {
    const functionalAssessmentSummaryItems = [];
    const functional_status = formData?.functional_status || {};
    const pushFunctionalItem = (key, label, children) => {
      functionalAssessmentSummaryItems.push({ key, label, children });
    };

    if (functional_status?.vision_details?.vision) {
      pushFunctionalItem(
        "1",
        "Vision",
        <>
          {getLabelTag(
            notAssessableAndAssessableOptions,
            functional_status?.vision_details?.vision
          )}
          {getLabelTagsWithRadioOptions(
            visionOptions,
            leftRightBothOptions,
            functional_status?.vision_details
          )}
        </>
      );
    }

    if (functional_status?.hearing_details?.hearing) {
      pushFunctionalItem(
        "2",
        "Hearing",
        <>
          {getLabelTag(
            notAssessableAndAssessableOptions,
            functional_status?.hearing_details?.hearing
          )}
          {getLabelTagsWithRadioOptions(
            hearingOptions,
            leftRightBothOptions,
            functional_status?.hearing_details
          )}
        </>
      );
    }

    if (functional_status?.speech_details?.speech) {
      pushFunctionalItem(
        "3",
        "Speech",
        <>
          {getLabelTag(
            notAssessableAndAssessableOptions,
            functional_status?.speech_details?.speech
          )}
          {getLabelTag(
            speechTypeOptions,
            functional_status?.speech_details?.speech_type
          )}
        </>
      );
    }

    if (functional_status?.ambulation) {
      pushFunctionalItem(
        "4",
        "Ambulation",
        <>
          {getLabelTag(ambulanceOptions, functional_status?.ambulation)}{" "}
          {getLabelTag(
            ambulanceAssistanceOptions,
            functional_status?.ambulation_assistance
          )}
          {getLabelTagInput(
            "Others details",
            functional_status?.ambulation_assistance_others
          )}
          {getLabelTag(
            ambulanceBedBoundOptions,
            functional_status?.ambulation_bed_bound
          )}
        </>
      );
    }

    if (
      getLabelTagsWithRadioOptions(
        dailyActivityOptions,
        dailyActivityDependenceOptions,
        functional_status?.daily_activities
      )
    ) {
      pushFunctionalItem(
        "5",
        "Daily Activities",
        getLabelTagsWithRadioOptions(
          dailyActivityOptions,
          dailyActivityDependenceOptions,
          functional_status?.daily_activities
        )
      );
    }

    if (
      functional_status?.bladder_needs?.indicates_need_to_void ||
      functional_status?.bladder_needs?.incontinence ||
      functional_status?.bladder_needs?.catheter ||
      functional_status?.bladder_needs?.catheter_days ||
      functional_status?.bladder_needs?.color_urine ||
      functional_status?.bladder_needs?.volume
    ) {
      pushFunctionalItem(
        "6",
        "Bladder Needs",
        <>
          {getLabelTagInput(
            "Indicates Need to Void",
            getLabelTag(
              yesNoOptions,
              functional_status?.bladder_needs?.indicates_need_to_void
            )
          )}
          {getLabelTagInput(
            "Incontinence",
            getLabelTag(
              incontinenceOptions,
              functional_status?.bladder_needs?.incontinence
            )
          )}
          {getLabelTagInput(
            "Catheter",
            getLabelTag(
              catheterOptions,
              functional_status?.bladder_needs?.catheter
            )
          )}
          {getLabelTagInput(
            "No:of days on catheter",
            functional_status?.bladder_needs?.catheter_days
          )}
          {getLabelTagInput(
            "Color Urine",
            getLabelTag(
              urineOptions,
              functional_status?.bladder_needs?.color_urine
            )
          )}
          {getLabelTagInput(
            "Volume(in ml)",
            functional_status?.bladder_needs?.volume
          )}
        </>
      );
    }

    if (
      functional_status?.bowel_needs?.indicates_need_to_pass_motion ||
      functional_status?.bowel_needs?.incontinence ||
      functional_status?.bowel_needs?.stools ||
      functional_status?.bowel_needs?.days_after_last_motion ||
      functional_status?.bowel_needs?.colostomy
    ) {
      pushFunctionalItem(
        "7",
        "Bowel Needs",
        <>
          {getLabelTagInput(
            "Indicates Urge to Pass MOtion",
            getLabelTag(
              yesNoOptions,
              functional_status?.bowel_needs?.indicates_need_to_pass_motion
            )
          )}
          {getLabelTagInput(
            "Incontinence",
            getLabelTag(
              incontinenceOptions,
              functional_status?.bowel_needs?.incontinence
            )
          )}
          {getLabelTagInput(
            "Stools",
            getLabelTag(stoolOptions, functional_status?.bowel_needs?.stools)
          )}
          {getLabelTagInput(
            "Days After Last Motion",
            functional_status?.bowel_needs?.days_after_last_motion
          )}
          {getLabelTagInput(
            "Colostomy",
            getLabelTag(yesNoOptions, functional_status?.bowel_needs?.colostomy)
          )}
        </>
      );
    }

    if (
      functional_status?.braden_scale?.sensory_perception ||
      functional_status?.braden_scale?.moisture ||
      functional_status?.braden_scale?.activity ||
      functional_status?.braden_scale?.mobility ||
      functional_status?.braden_scale?.nutrition ||
      functional_status?.braden_scale?.friction_and_shear
    ) {
      pushFunctionalItem(
        "8",
        "Braden Scale",
        <>
          {getLabelTagInput("Score", functional_status?.braden_scale?.score)}
          {getLabelTagInput(
            "Interpretation",
            functional_status?.braden_scale?.interpretation
          )}
          {getLabelTagInput(
            "Sensory Perception",
            getLabelTag(
              perceptionSelectionOptions,
              functional_status?.braden_scale?.sensory
            )
          )}
          {getLabelTagInput(
            "Moisture",
            getLabelTag(
              moistureSelectionOptions,
              functional_status?.braden_scale?.moisture
            )
          )}
          {getLabelTagInput(
            "Activity",
            getLabelTag(
              activitySelectionOptions,
              functional_status?.braden_scale?.activity
            )
          )}
          {getLabelTagInput(
            "Mobility",
            getLabelTag(
              mobilitySelectionOptions,
              functional_status?.braden_scale?.mobility
            )
          )}
          {getLabelTagInput(
            "Nutrition",
            getLabelTag(
              nutritionSelectionOptions,
              functional_status?.braden_scale?.nutrition
            )
          )}
          {getLabelTagInput(
            "Fricition & Shear",
            getLabelTag(
              frictionSelectionOptions,
              functional_status?.braden_scale?.friction_and_shear
            )
          )}
        </>
      );
    }

    if (
      functional_status?.risk_of_fall?.previous_fall ||
      functional_status?.risk_of_fall?.needs_aids_for_ambulation ||
      functional_status?.risk_of_fall?.abnormal_gait ||
      functional_status?.risk_of_fall?.sensory_deficit ||
      functional_status?.risk_of_fall?.muscular_weakness ||
      functional_status?.risk_of_fall?.age_greater_than_65
    ) {
      pushFunctionalItem(
        "9",
        "Risk of Fall",
        <>
          {getLabelTagInput("Score", functional_status?.risk_of_fall?.score)}
          {getLabelTagInput(
            "Interpretation",
            functional_status?.risk_of_fall?.interpretation
          )}
          {getLabelTagInput(
            "H/O Previous Fall",
            getLabelTag(
              noYesOptions,
              functional_status?.risk_of_fall?.previous_fall
            )
          )}
          {getLabelTagInput(
            "Needs aids for Ambulation",
            getLabelTag(
              noYesOptions,
              functional_status?.risk_of_fall?.needs_aids_for_ambulation
            )
          )}
          {getLabelTagInput(
            "Abnormal Gait",
            getLabelTag(
              noYesOptions,
              functional_status?.risk_of_fall?.abnormal_gait
            )
          )}
          {getLabelTagInput(
            "Sensory Deficit",
            getLabelTag(
              noYesOptions,
              functional_status?.risk_of_fall?.sensory_deficit
            )
          )}
          {getLabelTagInput(
            "Muscular Weakness",
            getLabelTag(
              noYesOptions,
              functional_status?.risk_of_fall?.muscular_weakness
            )
          )}
          {getLabelTagInput(
            "Age > 65",
            getLabelTag(
              noYesOptions,
              functional_status?.risk_of_fall?.age_greater_than_65
            )
          )}
        </>
      );
    }
    return functionalAssessmentSummaryItems;
  };
  const functionalAssessmentSummaryItems =
    constructFunctionalAssessmentSummaryItems();
  return (
    <>
      {functionalAssessmentSummaryItems.length !== 0 && (
        <Descriptions
          bordered
          column={1}
          title={
            <span style={{ fontWeight: "500", marginLeft: ".2rem" }}>
              <Space>
                <Image src={FunctionalStatus} preview={false} />
                <span>Functional Status Assessment</span>
              </Space>
            </span>
          }
          items={functionalAssessmentSummaryItems}
          contentStyle={{ padding: "4px 4px" }}
          className="initial-insp-desc"
        />
      )}
    </>
  );
};

export default NurseFunctionalAssessmentSummary;
