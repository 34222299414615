import { Form, Input } from "antd";
import React from "react";

const ReviewNotes = ({ onValuesChange, formData }) => {
  return (
    <>
      <Form
        id="review-notes"
        onValuesChange={(cur, all) => onValuesChange("review_notes", all)}
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.review_notes}
      >
        <Form.Item name={"progress_notes"}>
          <Input.TextArea rows={3} placeholder="Progress Notes" />
        </Form.Item>
        <Form.Item name={"review_notes"}>
          <Input.TextArea rows={3} placeholder="Review Notes" />
        </Form.Item>
      </Form>
    </>
  );
};

export default ReviewNotes;
