import { Form, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SixMinWalkTestItems from "../../../../IpEMR/InspectionForms/Examination/PhysioScales/SixMinWalkTest/SixMinWalkTestItems";

const SixMinWalkForm = ({ onValuesChange, formData }) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState({});

  const calculateDistance = () => {
    const { gender, age, height, weight } = states;
    return gender === "male adult"
      ? 7.57 * height - 5.02 * age - 1.76 * weight - 309
      : 2.11 * height - 2.29 * weight - 5.78 * age + 667;
  };

  const handleChange = (key, value) => {
    setStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const predictedDistance = calculateDistance().toFixed(0);

  useEffect(() => {
    setStates({
      gender: formData?.six_min_walk?.gender,
      age: formData?.six_min_walk?.age,
      height: formData?.six_min_walk?.height,
      weight: formData?.six_min_walk?.weight,
    });
  }, [formData?.six_min_walk]);
  return (
    <>
      <Form
        id="six-min-walk"
        form={form}
        onValuesChange={(cur, all) =>
          onValuesChange("six_min_walk", {
            ...all,
            predicted_distance:
              predictedDistance !== "NaN" ? predictedDistance : null,
          })
        }
        layout="vertical"
        className="ip-forms"
        initialValues={formData?.six_min_walk}
      >
        <div className="physio-scales initial">
          <Typography>Six Minute Walk Test</Typography>
          <SixMinWalkTestItems
            handleChange={handleChange}
            states={states}
            predictedDistance={predictedDistance}
            form={form}
            op={true}
          />
        </div>
      </Form>
    </>
  );
};

export default SixMinWalkForm;
