import { Checkbox, Form, Input } from "antd";
import React from "react";
import { cardioHistoryOptions } from "../OpPhysioOptions";

const CardioHistory = () => {
  return (
    <>
      <Form.Item label="History" name={"history"}>
        <Checkbox.Group options={cardioHistoryOptions} />
      </Form.Item>
      <Form.Item name={"history_comments"}>
        <Input.TextArea
          rows={2}
          placeholder="Comments"
          style={{ width: "60%" }}
        />
      </Form.Item>
    </>
  );
};

export default CardioHistory;
