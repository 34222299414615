import { Button, message, Tabs } from "antd";
import React, { useState } from "react";
import FingerGripForm from "../OpFormComponents/FingerGripForm";
import CoordinationForm from "../OpFormComponents/CoordinationForm";
import TCMSForm from "../OpFormComponents/TCMSForm";
import ChiefComplaintsForm from "../OpFormComponents/ChiefComplaintsForm";
import OpHistoryForm from "../OpFormComponents/OpHistoryForm";
import NeuroHistory from "./NeuroHistory";
import OPExaminationForm from "../OpFormComponents/OPExaminationForm";
import GCSForm from "../OpFormComponents/GCSForm";
import RASSForm from "../OpFormComponents/RASSForm";
import MMTForm from "../OpFormComponents/MMTForm";
import MASForm from "../OpFormComponents/MASForm";
import VCGForm from "../OpFormComponents/VCGForm";
import MOCAForm from "../OpFormComponents/MOCAForm";
import ROMForm from "../OpFormComponents/ROMForm";
import OpReflexesForm from "../OpFormComponents/OpReflexesForm";
import BarthelIndexForm from "../OpFormComponents/BarthelIndexForm";
import ASIAForm from "../OpFormComponents/ASIAForm";
import NIHSSForm from "../OpFormComponents/NIHSSForm";
import OpFunctionalDiagnosis from "../CardiopulmonaryForm/OpFunctionalDiagnosis";
import NeuroTherapy from "./NeuroTherapy";
import OpExerciseForm from "../OpFormComponents/OpExerciseForm";
import ReviewNotes from "../OpFormComponents/ReviewNotes";
import NeuroSummary from "./NeuroSummary";
import { opServiceAPI } from "../../../../../services/physio.services";

const tabItems = [
  { label: "Chief Complaints", key: "1" },
  { label: "History", key: "2" },
  { label: "Examination", key: "3" },
  { label: "GCS", key: "4" },
  { label: "RAS Scale", key: "5" },
  { label: "MMT", key: "6" },
  { label: "MAS", key: "7" },
  { label: "VCG", key: "8" },
  { label: "MOCA", key: "9" },
  { label: "Finger Grip Test", key: "10" },
  { label: "ROM", key: "11" },
  { label: "Reflexes", key: "12" },
  { label: "Coordination Test", key: "13" },
  { label: "TCMS", key: "14" },
  { label: "Barthel", key: "15" },
  { label: "ASIA", key: "16" },
  { label: "NIHSS", key: "17" },
  { label: "Functional Diagnosis", key: "18" },
  { label: "Therapy", key: "19" },
  { label: "Exercise", key: "20" },
  { label: "Review Notes", key: "21" },
  { label: "Summary", key: "22" },
];

const NeuroForm = ({ admissionId, handleClose }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [formData, setFormData] = useState({});

  const renderTab = () => {
    switch (activeTab) {
      case "1":
        return (
          <ChiefComplaintsForm
            onValuesChange={onValuesChange}
            formData={formData}
            formType={"neuro"}
          />
        );
      case "2":
        return (
          <OpHistoryForm onValuesChange={onValuesChange} formData={formData}>
            <NeuroHistory />
          </OpHistoryForm>
        );
      case "3":
        return (
          <OPExaminationForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "4":
        return <GCSForm onValuesChange={onValuesChange} formData={formData} />;
      case "5":
        return <RASSForm onValuesChange={onValuesChange} formData={formData} />;
      case "6":
        return <MMTForm onValuesChange={onValuesChange} formData={formData} />;
      case "7":
        return <MASForm onValuesChange={onValuesChange} formData={formData} />;
      case "8":
        return <VCGForm onValuesChange={onValuesChange} formData={formData} />;
      case "9":
        return <MOCAForm onValuesChange={onValuesChange} formData={formData} />;
      case "10":
        return (
          <FingerGripForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "11":
        return <ROMForm onValuesChange={onValuesChange} formData={formData} />;
      case "12":
        return (
          <OpReflexesForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "13":
        return (
          <CoordinationForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "14":
        return <TCMSForm onValuesChange={onValuesChange} formData={formData} />;
      case "15":
        return (
          <BarthelIndexForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "16":
        return <ASIAForm onValuesChange={onValuesChange} formData={formData} />;
      case "17":
        return (
          <NIHSSForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "18":
        return (
          <OpFunctionalDiagnosis
            onValuesChange={onValuesChange}
            formData={formData}
            formType={"neuro"}
          />
        );
      case "19":
        return (
          <NeuroTherapy onValuesChange={onValuesChange} formData={formData} />
        );
      case "20":
        return (
          <OpExerciseForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "21":
        return (
          <ReviewNotes onValuesChange={onValuesChange} formData={formData} />
        );
      case "22":
        return <NeuroSummary formData={formData} />;
    }
  };
  const handleSubmit = async () => {
    try {
      await opServiceAPI("neuro", {
        ...formData,
        admission_id: admissionId,
      });
      message.success("Neuro EMR Submitted Successfully");
      handleClose();
    } catch (error) {
      message.error("Form Failed To Load");
      console.log(`Error:${error}`);
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleButton = () => {
    activeTab !== "22"
      ? setActiveTab((cur) => (parseInt(cur) + 1).toString())
      : handleSubmit();
  };
  const onValuesChange = (key, values) => {
    setFormData((prev) => ({
      ...prev,
      [key]: values,
    }));
  };

  console.log(activeTab);
  return (
    <>
      <Tabs items={tabItems} activeKey={activeTab} onChange={handleTabChange} />
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          <div className="ia">{renderTab()}</div>
        </div>
      </div>
      <div className="flex-end">
        <Button type="primary" onClick={handleButton}>
          {activeTab === "22" ? "Save" : "Next"}
        </Button>
      </div>
    </>
  );
};

export default NeuroForm;
