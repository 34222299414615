import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { yesNoOptions } from "../../../../Options/commonOptions";
import DeselectableRadioGroup from "../../../../../../blocks/FormItems/DeselectableRadioGroup";

const DapVac = ({ form }) => {
  const [refresh, setRefresh] = useState(false);
  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col
          span={12}
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <DeselectableRadioGroup
            label={"DAP"}
            name={"dap"}
            options={yesNoOptions}
            form={form}
            setRefresh={setRefresh}
          />
        </Col>
        <Col
          span={12}
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <DeselectableRadioGroup
            label={"VAC"}
            name={"vac"}
            options={yesNoOptions}
            form={form}
            setRefresh={setRefresh}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default DapVac;
