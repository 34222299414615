import React, { useEffect, useState } from "react";
import {
  getArpobAnalyticsAPI,
  getArpobReportsAPI,
} from "../../../../services/orgDashboard.services";
import { Badge, Card, Col, Row, Select, Space, Typography } from "antd";
import StatCardLayout from "../StatCardLayout";
import { filterOptions } from "../DashboardOptions";
import {
  CartesianAxis,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import billIcon from "../../../../assets/dashboardIcons/bill.svg";
import graphIcon from "../../../../assets/dashboardIcons/graph.svg";
import cubeIcon from "../../../../assets/dashboardIcons/totalBilled.svg";

const ARPOBAnalysis = ({ currentFacility, date }) => {
  const [arpobData, setArpobData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [chartFilter, setChartFilter] = useState("week");
  useEffect(() => {
    getArpobReportsAPI(currentFacility, date)
      .then((res) => setArpobData(res?.data?.result))
      .catch((error) => console.log(`Error:${error}`));
  }, [currentFacility, date]);
  useEffect(() => {
    getArpobAnalyticsAPI(chartFilter, currentFacility, date)
      .then((res) => {
        const chartData = Object?.entries(res?.data?.result)?.map(
          ([key, value]) => ({
            label: key,
            ...value,
          })
        );
        setChartData(chartData);
      })
      .catch((error) => console.log(`Error:${error}`));
  }, [chartFilter, currentFacility, date]);
  return (
    <>
      <Row gutter={[8, 30]}>
        <Col span={8}>
          <StatCardLayout
            title={"ARPOB"}
            footer={" "}
            val={arpobData?.arpob}
            precision={2}
            icon={billIcon}
          />
        </Col>
        <Col span={8}>
          <StatCardLayout
            title={"ARPOB (P)"}
            val={arpobData?.arpob_p}
            footer={" "}
            precision={2}
            icon={graphIcon}
          />
        </Col>
        <Col span={8}>
          <StatCardLayout
            title={"ARPOB (O)"}
            val={arpobData?.arpob_o}
            footer={" "}
            precision={2}
            icon={cubeIcon}
          />
        </Col>
        <Col span={24}>
          <Card
            title={
              <Row>
                <Col
                  span={12}
                  style={{ fontWeight: 400, fontSize: 12, padding: 15 }}
                >
                  <Col span={24}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: 24,
                        lineHeight: "20px",
                      }}
                    >
                      ARPOB = ARPOB (P) + ARPOB (O)
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Space size={"large"}>
                      <Badge color="#D95691" text="ARPOB" />
                      <Badge color="#B692F6" text="ARPOB (P)" />
                      <Badge color="#9E8C38" text="ARPOB (O)" />
                    </Space>
                  </Col>
                </Col>
                <Col span={12} className="flex-end">
                  <Select
                    value={chartFilter}
                    options={filterOptions}
                    onSelect={(val) => setChartFilter(val)}
                  />
                </Col>
              </Row>
            }
          >
            <ResponsiveContainer width={"100%"} height={600}>
              <LineChart width={1500} height={600} data={chartData}>
                <CartesianAxis stroke="#ccc" />
                <CartesianGrid
                  stroke="#ccc"
                  horizontal={true}
                  vertical={false}
                />
                <XAxis dataKey={"label"} />
                <YAxis />

                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="arpob"
                  name="ARPOB"
                  stroke="#D95691"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="arpob_p"
                  name="ARPOB (P)"
                  stroke="#B692F6"
                />
                <Line
                  type="monotone"
                  dataKey="arpob_o"
                  name="ARPOB (O)"
                  stroke="#9E8C38"
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ARPOBAnalysis;
