import React, { useState } from "react";
import { Table, Button, Modal, Form, Input, Space } from "antd";

const PressureSoreTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState([]); // Array to store submitted form data
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Pressure Sore",
      dataIndex: "pressureSore",
      key: "pressureSore",
    },
    {
      title: "Grade 1",
      dataIndex: "grade1",
      key: "grade1",
    },
    {
      title: "Grade 2",
      dataIndex: "grade2",
      key: "grade2",
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields(); // Reset the form on modal close
  };

  const handleSubmit = (values) => {
    // Append new form data to the existing array
    setFormData((prev) => [...prev, values]);
    setIsModalOpen(false); // Close modal
    form.resetFields(); // Reset form fields
  };

  return (
    <>
      <Button type="primary" onClick={handleOpenModal} ghost>
        Add Pressure Sore
      </Button>
      <Table
        dataSource={formData.map((item, index) => ({
          key: index,
          ...item,
        }))}
        pagination={false}
        columns={columns}
        style={{ marginTop: 20 }}
      />
      <Modal
        title="Add Pressure Sore Details"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null} // Custom footer handled by the form
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Pressure Sore"
            name="pressureSore"
            rules={[{ required: true, message: "Please enter pressure sore" }]}
          >
            <Input placeholder="Enter pressure sore" />
          </Form.Item>
          <Form.Item
            label="Grade 1"
            name="grade1"
            rules={[{ required: true, message: "Please enter grade 1" }]}
          >
            <Input placeholder="Enter grade 1" />
          </Form.Item>
          <Form.Item
            label="Grade 2"
            name="grade2"
            rules={[{ required: true, message: "Please enter grade 2" }]}
          >
            <Input placeholder="Enter grade 2" />
          </Form.Item>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default PressureSoreTable;
