import { Divider, Form } from "antd";
import React from "react";
import PastMedicalOP from "./PastMedicalOP";
import CongenitalDisorders from "./CongenitalDisorders";
import PastSurgicalOP from "./PastSurgicalOP";
import Habits from "./Habits";

const OpHistoryForm = ({ onValuesChange, formData, children }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        id="cardio-history"
        onValuesChange={(cur, all) => onValuesChange("history", all)}
        layout="vertical"
        className="ip-forms"
        form={form}
        initialValues={formData?.history}
      >
        {children}
        <Divider />
        <PastMedicalOP />
        <Divider />
        <CongenitalDisorders />
        <Divider />
        <PastSurgicalOP form={form} />
        <Divider />
        <Habits form={form} />
      </Form>
    </>
  );
};

export default OpHistoryForm;
