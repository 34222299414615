import React from "react";
import CommonTable from "./CommonTable";
import dayjs from "dayjs";

const PositionChange = ({ data }) => {
  const columns = [
    {
      title: "Time Done",
      dataIndex: "action_time",
      key: "action_time",
      render: (value) => dayjs(value)?.format("DD, MMM hh:mm A"),
    },
  ];
  return (
    <div>
      <CommonTable data={data} columns={columns} />
    </div>
  );
};

export default PositionChange;
