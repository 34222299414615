import { Button, message, Tabs } from "antd";
import React, { useState } from "react";
import ChiefComplaintsForm from "../OpFormComponents/ChiefComplaintsForm";
import VitalsForm from "../OpFormComponents/VitalsForm";
import OPExaminationForm from "../OpFormComponents/OPExaminationForm";
import MMTForm from "../OpFormComponents/MMTForm";
import ROMForm from "../OpFormComponents/ROMForm";
import BarthelIndexForm from "../OpFormComponents/BarthelIndexForm";
import RPEForm from "../OpFormComponents/RPEForm";
import SixMinWalkForm from "../OpFormComponents/SixMinWalkForm";
import PeakFlowMeterForm from "../OpFormComponents/PeakFlowMeterForm";
import OpFunctionalDiagnosis from "./OpFunctionalDiagnosis";
import CardioTherapyForm from "./CardioTherapyForm";
import ReviewNotes from "../OpFormComponents/ReviewNotes";
import OpExerciseForm from "../OpFormComponents/OpExerciseForm";
import CardioSummary from "./CardioSummary";
import { opServiceAPI } from "../../../../../services/physio.services";
import OpHistoryForm from "../OpFormComponents/OpHistoryForm";
import CardioHistory from "./CardioHistory";

const tabItems = [
  { label: "Chief Complaints", key: "1" },
  { label: "History", key: "2" },
  { label: "Vitals", key: "3" },
  { label: "Examination", key: "4" },
  { label: "MMT", key: "5" },
  { label: "ROM", key: "6" },
  { label: "Barthel Index", key: "7" },
  { label: "RPE", key: "8" },
  { label: "6 Min Walk Test", key: "9" },
  { label: "Peak Flow Meter", key: "10" },
  { label: "Functional Diagnosis", key: "11" },
  { label: "Therapy", key: "12" },
  { label: "Exercise", key: "13" },
  { label: "Review Notes", key: "14" },
  { label: "Summary", key: "15" },
];

const CardiopulmonaryForm = ({ admissionId, handleClose }) => {
  const [activeTab, setActiveTab] = useState("1");
  console.log(activeTab);
  const [formData, setFormData] = useState({});

  const renderTab = () => {
    switch (activeTab) {
      case "1":
        return (
          <ChiefComplaintsForm
            onValuesChange={onValuesChange}
            formData={formData}
            formType={"cardio"}
          />
        );
      case "2":
        return (
          <OpHistoryForm onValuesChange={onValuesChange} formData={formData}>
            <CardioHistory />
          </OpHistoryForm>
        );
      case "3":
        return (
          <VitalsForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "4":
        return (
          <OPExaminationForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "5":
        return <MMTForm onValuesChange={onValuesChange} formData={formData} />;
      case "6":
        return <ROMForm onValuesChange={onValuesChange} formData={formData} />;
      case "7":
        return (
          <BarthelIndexForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "8":
        return <RPEForm onValuesChange={onValuesChange} formData={formData} />;
      case "9":
        return (
          <SixMinWalkForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "10":
        return (
          <PeakFlowMeterForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "11":
        return (
          <OpFunctionalDiagnosis
            onValuesChange={onValuesChange}
            formData={formData}
            formType={"cardio"}
          />
        );
      case "12":
        return (
          <CardioTherapyForm
            onValuesChange={onValuesChange}
            formData={formData}
          />
        );
      case "13":
        return (
          <OpExerciseForm onValuesChange={onValuesChange} formData={formData} />
        );
      case "14":
        return (
          <ReviewNotes onValuesChange={onValuesChange} formData={formData} />
        );
      case "15":
        return <CardioSummary formData={formData} />;
    }
  };
  const handleSubmit = async () => {
    try {
      await opServiceAPI("cardiopulmonary", {
        ...formData,
        admission_id: admissionId,
      });
      message.success("Cardiopulmonary EMR Submitted Successfully");
      handleClose();
    } catch (error) {
      message.error("Form Failed To Load");
      console.log(`Error:${error}`);
    }
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleButton = () => {
    activeTab !== "15"
      ? setActiveTab((cur) => (parseInt(cur) + 1).toString())
      : handleSubmit();
  };
  const onValuesChange = (key, values) => {
    setFormData((prev) => ({
      ...prev,
      [key]: values,
    }));
  };
  return (
    <>
      <Tabs items={tabItems} activeKey={activeTab} onChange={handleTabChange} />
      <div style={{ height: "90%", overflow: "hidden", position: "relative" }}>
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#f6f5ff",
            borderRadius: "5px",
            border: "1px solid #e6e6e6",
          }}
        >
          <div className="ia">{renderTab()}</div>
        </div>
      </div>
      <div className="flex-end">
        <Button type="primary" onClick={handleButton}>
          {activeTab === "15" ? "Save" : "Next"}
        </Button>
      </div>
    </>
  );
};

export default CardiopulmonaryForm;
