import { Descriptions } from "antd";
import React from "react";
import { checkEmpty } from "../../../../../helpers/utility";
import {
  activePassiveOptions,
  cardioChiefComplaintOptions,
  cardioCongenitalOptions,
  cardioHistoryOptions,
  cardioPastMedicalOptions,
  opExaminationOptions,
} from "../OpPhysioOptions";
import {
  getLabelScalesTags,
  getLabelTag,
  getLabelTagInput,
  getLabelTagsArray,
  getLabelTagsWithDescription,
  getLabelTagYesNO,
  getTherapyLabelTags,
} from "../../../../IpEMR/InspectionSummaries/constructTextFromValues";
import {
  alcoholOptions,
  smokingOptions,
} from "../../../../IpEMR/Options/historyOptions";
import {
  cardioDiagnosisOptions,
  cardioTherapyOptions,
  mmtScaleOptions,
} from "../../../../IpEMR/Options/physioOptions";
import {
  barthelIndexOptions,
  genderOptions,
  minsWalkedOptions,
  reasonOptions,
  rpeOptions,
} from "../../../../IpEMR/Options/functionalAsessmentOptions";
import "../../../../IpEMR/summary.css";

const CardioSummary = ({ formData }) => {
  console.log(formData);
  const constructCardioSummaryItems = () => {
    const getRomSummary = (list, data) => {
      const enteredOptions = list.reduce((acc, item) => {
        const filtered = Object.entries(data).filter(([key, value]) =>
          key.endsWith(item)
        );
        return [...acc, ...filtered];
      }, []);

      return enteredOptions?.length === 0
        ? null
        : enteredOptions?.map(([key, value]) =>
            getLabelTagInput(
              key
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" "),
              value
            )
          );
    };
    const cardioSummaryItems = [];
    const pushSummaryItem = (key, label, children) => {
      cardioSummaryItems.push({ key, label, children });
    };
    if (checkEmpty(formData?.chief_complaint)) {
      pushSummaryItem(
        "1",
        "Chief Complaints",
        <>
          {getLabelTagsArray(
            cardioChiefComplaintOptions,
            formData?.chief_complaint?.chief_complaints
          )}
          {getLabelTagInput(
            "Other Details",
            formData?.chief_complaint?.comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.history)) {
      pushSummaryItem(
        "2",
        "History",
        <>
          {getLabelTagInput(
            "History",
            <>
              {getLabelTagsArray(
                cardioHistoryOptions,
                formData?.history?.history
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.history_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Past Medical History",
            <>
              {getLabelTagsArray(
                cardioPastMedicalOptions,
                formData?.history?.past_medical_history
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.past_medical_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Congenital Disorders",
            <>
              {getLabelTagsArray(
                cardioCongenitalOptions,
                formData?.history?.congenital_disorders
              )}
              {getLabelTagInput(
                "Comments",
                formData?.history?.congenital_disorders_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Past Surgical History",
            <>
              {getLabelTagYesNO(formData?.history?.past_surgical_history)}
              {getLabelTagInput(
                "Comments",
                formData?.history?.past_surgical_comments
              )}
            </>
          )}
          {getLabelTagInput(
            "Smoking",
            getLabelTag(smokingOptions, formData?.history?.smoking)
          )}
          {getLabelTagInput(
            "Alcohol",
            getLabelTag(alcoholOptions, formData?.history?.alcohol)
          )}
          {getLabelTagInput(
            "Life Style",
            getLabelTag(activePassiveOptions, formData?.history?.life_style)
          )}
          {getLabelTagInput("Comments", formData?.history?.habits_comments)}
        </>
      );
    }

    if (checkEmpty(formData?.vitals)) {
      pushSummaryItem(
        "3",
        "Vitals",
        <>
          {getLabelTagInput("Temperature", formData?.vitals?.temperature)}
          {getLabelTagInput("Pulse Rate", formData?.vitals?.pulse_rate)}
          {getLabelTagInput(
            "Respiratory Rate",
            formData?.vitals?.respiratory_rate
          )}
          {getLabelTagInput("Systolic BP", formData?.vitals?.systolic_bp)}
          {getLabelTagInput("Diastolic BP", formData?.vitals?.diastolic_bp)}
          {getLabelTagInput("spO2", formData?.vitals?.spo2)}
        </>
      );
    }

    if (checkEmpty(formData?.examination)) {
      pushSummaryItem(
        "4",
        "Examination",
        <>
          {getLabelTagsWithDescription(
            opExaminationOptions,
            formData?.examination
          )}
          {getLabelTagInput("Pain level", formData?.examination?.pain)}
          {getLabelTagInput(
            "Interpretation",
            formData?.examination?.pain_interpretation
          )}
          {getLabelTagInput(
            "Comments",
            formData?.examination?.examination_comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.mmt)) {
      pushSummaryItem(
        "5",
        "MMT",
        <>
          {getLabelTagInput(
            "Left Upper Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.upper_left_limb)
          )}
          {getLabelTagInput(
            "Right Upper Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.upper_right_limb)
          )}
          {getLabelTagInput(
            "Left Lower Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.lower_left_limb)
          )}
          {getLabelTagInput(
            "Right Lower Limb",
            getLabelTag(mmtScaleOptions, formData?.mmt?.lower_right_limb)
          )}
        </>
      );
    }

    if (checkEmpty(formData?.rom)) {
      pushSummaryItem(
        "6",
        "ROM",
        <>
          {formData?.rom?.rom?.shoulder_joint &&
            getLabelTagInput(
              "Shoulder Joint",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "abduction",
                  "adduction",
                  "medial_rotation",
                  "lateral_rotation",
                ],
                formData?.rom?.rom?.shoulder_joint
              )
            )}
          {formData?.rom?.rom?.elbow_joint &&
            getLabelTagInput(
              "Elbow Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.elbow_joint
              )
            )}
          {formData?.rom?.rom?.forearm_joint &&
            getLabelTagInput(
              "Forearm Joint",
              getRomSummary(
                ["supination", "pronation"],
                formData?.rom?.rom?.forearm_joint
              )
            )}
          {formData?.rom?.rom?.wrist_joint &&
            getLabelTagInput(
              "Wrist Joint",
              getRomSummary(
                ["flexion", "extension", "radial_deviation", "ulnar_deviation"],
                formData?.rom?.rom?.wrist_joint
              )
            )}
          {formData?.rom?.rom?.thumb_joint &&
            getLabelTagInput(
              "Thumb Joint",
              getRomSummary(
                ["cmc_flexion", "cmc_extension", "cmc_abduction", "ip_flexion"],
                formData?.rom?.rom?.thumb_joint
              )
            )}
          {formData?.rom?.rom?.metacarpel_joint &&
            getLabelTagInput(
              "Meta Carpel",
              getRomSummary(
                ["flexion", "hyperextension", "abduction"],
                formData?.rom?.rom?.metacarpel_joint
              )
            )}
          {formData?.rom?.rom?.proximal_joint &&
            getLabelTagInput(
              "Proximal Interphalangeal Joint",
              getRomSummary(["flexion"], formData?.rom?.rom?.proximal_joint)
            )}
          {formData?.rom?.rom?.distal_joint &&
            getLabelTagInput(
              "Disital Interphalangeal Joint",
              getRomSummary(
                ["flexion", "hyperextension"],
                formData?.rom?.rom?.distal_joint
              )
            )}
          {formData?.rom?.rom?.hip_joint &&
            getLabelTagInput(
              "Hip Joint",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "abduction",
                  "adduction",
                  "medial_rotation",
                  "lateral_rotation",
                ],
                formData?.rom?.rom?.hip_joint
              )
            )}
          {formData?.rom?.rom?.knee_joint &&
            getLabelTagInput(
              "Knee Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.knee_joint
              )
            )}
          {formData?.rom?.rom?.ankle_joint &&
            getLabelTagInput(
              "Ankle Joint",
              getRomSummary(
                ["dorsiflexion", "plantarflexion"],
                formData?.rom?.rom?.ankle_joint
              )
            )}
          {formData?.rom?.rom?.foot_joint &&
            getLabelTagInput(
              "Foot Joint",
              getRomSummary(
                ["inversion", "eversion"],
                formData?.rom?.rom?.foot_joint
              )
            )}
          {formData?.rom?.rom?.great_toe_joint &&
            getLabelTagInput(
              "Great Toe Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.great_toe_joint
              )
            )}
          {formData?.rom?.rom?.inter_phalangeal_joint &&
            getLabelTagInput(
              "Inter Phalangeal Joint",
              getRomSummary(
                ["flexion", "extension"],
                formData?.rom?.rom?.inter_phalangeal_joint
              )
            )}
          {formData?.rom?.rom?.cervical_spine &&
            getLabelTagInput(
              "Cervical Spine",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "right_rotation",
                  "left_rotation",
                  "right_lateral_rotation",
                  "left_lateral_rotation",
                ],
                formData?.rom?.rom?.cervical_spine
              )
            )}
          {formData?.rom?.rom?.thoraco_lumbar_spine &&
            getLabelTagInput(
              "Thoraco Lumbar Spine",
              getRomSummary(
                [
                  "flexion",
                  "extension",
                  "right_rotation",
                  "left_rotation",
                  "right_lateral_rotation",
                  "left_lateral_rotation",
                ],
                formData?.rom?.rom?.thoraco_lumbar_spine
              )
            )}
        </>
      );
    }

    if (checkEmpty(formData?.barthel)) {
      pushSummaryItem(
        "7",
        "Barthel Index",
        <>
          {getLabelTagInput("Score", formData?.barthel?.score)}
          {getLabelTagInput(
            "Interpretation",
            formData?.barthel?.interpretation
          )}
          {getLabelScalesTags(barthelIndexOptions, formData?.barthel)}
        </>
      );
    }

    if (checkEmpty(formData?.rpe)) {
      pushSummaryItem(
        "8",
        "RPE",
        <>
          {" "}
          {getLabelTagInput("Score", formData?.rpe?.exertion)}
          {getLabelTagInput("Interpretation", formData?.rpe?.interpretation)}
          {getLabelTagInput("Comments", formData?.rpe?.comments)}
        </>
      );
    }

    if (checkEmpty(formData?.six_min_walk)) {
      pushSummaryItem(
        "9",
        "6 Min Walk Test",
        <>
          {getLabelTag(genderOptions, formData?.six_min_walk?.gender)}
          {getLabelTagInput("Age", formData?.six_min_walk?.age)}
          {getLabelTagInput("Height", formData?.six_min_walk?.height, "cms")}
          {getLabelTagInput("Weight", formData?.six_min_walk?.weight, "kgs")}
          {getLabelTagInput(
            "Predicted Distance",
            formData?.six_min_walk?.predicted_distance
          )}
          {getLabelTagInput(
            "Pre Vitals",
            <>
              {getLabelTagInput(
                "Heart Rate",
                formData?.six_min_walk?.heart_rate
              )}
              {getLabelTagInput("SBP", formData?.six_min_walk?.sbp)}
              {getLabelTagInput("DBP", formData?.six_min_walk?.dbp)}
              {getLabelTagInput("SPO2", formData?.six_min_walk?.spo2)}
              {getLabelTagInput("RR", formData?.six_min_walk?.rr)}
              {getLabelTagInput(
                "RPE",
                getLabelTag(rpeOptions, formData?.six_min_walk?.rpe)
              )}
            </>
          )}
          {getLabelTagInput(
            "Post Vitals",
            <>
              {getLabelTagInput(
                "Heart Rate",
                formData?.six_min_walk?.post_vital_heart_rate
              )}
              {getLabelTagInput("SBP", formData?.six_min_walk?.post_vital_sbp)}
              {getLabelTagInput("DBP", formData?.six_min_walk?.post_vital_dbp)}
              {getLabelTagInput(
                "SPO2",
                formData?.six_min_walk?.post_vital_spo2
              )}
              {getLabelTagInput("RR", formData?.six_min_walk?.post_vital_rr)}
              {getLabelTagInput(
                "RPE",
                getLabelTag(rpeOptions, formData?.six_min_walk?.post_vital_rpe)
              )}
            </>
          )}
          {getLabelTagInput("No Of Laps", formData?.six_min_walk?.no_of_laps)}
          {getLabelTagInput(
            "Distance Walked",
            formData?.six_min_walk?.distance_covered,
            "mtr"
          )}
          {getLabelTagInput(
            "Final Partial Lap",
            formData?.six_min_walk?.final_lap_in_meters,
            "mtr"
          )}
          {getLabelTagInput(
            "Mins Walked",
            getLabelTag(minsWalkedOptions, formData?.six_min_walk?.mins_walked)
          )}
          {getLabelTagInput(
            "Reasons for Stopping",
            getLabelTag(
              reasonOptions,
              formData?.six_min_walk?.reason_for_immediate_stopping
            )
          )}
          {getLabelTagInput(
            "Others details",
            formData?.six_min_walk?.reason_for_immediate_stopping_others
          )}
          {getLabelTagInput("Comments", formData?.six_min_walk?.comments)}
        </>
      );
    }

    if (checkEmpty(formData?.peak_flow_meter_test)) {
      pushSummaryItem(
        "10",
        "Peak Flow Meter Test",
        <>
          {" "}
          {getLabelTag(genderOptions, formData?.peak_flow_meter_test?.gender)}
          {getLabelTagInput("Age", formData?.peak_flow_meter_test?.age)}
          {getLabelTagInput(
            "Height",
            formData?.peak_flow_meter_test?.height,
            "cm"
          )}
          {getLabelTagInput(
            "Predicted PEFR",
            formData?.peak_flow_meter_test?.predicted_PEFR
          )}
          {getLabelTagInput(
            "Tested PEFR",
            formData?.peak_flow_meter_test?.test_PEFR
          )}
          {getLabelTagInput(
            "Comments",
            formData?.peak_flow_meter_test?.comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.functional_diagnosis)) {
      pushSummaryItem(
        "11",
        "Functional Diagnosis",
        <>
          {getLabelTagsArray(
            cardioDiagnosisOptions,
            formData?.functional_diagnosis?.diagnosis
          )}
          {getLabelTagInput(
            "Comments",
            formData?.functional_diagnosis?.diagnosis_comments
          )}
        </>
      );
    }

    if (checkEmpty(formData?.therapy)) {
      pushSummaryItem(
        "12",
        "Therapy",
        <>{getTherapyLabelTags(cardioTherapyOptions, formData?.therapy)}</>
      );
    }

    if (checkEmpty(formData?.review_notes)) {
      pushSummaryItem(
        "14",
        "Review Notes",
        <>
          {getLabelTagInput(
            "Progress Notes",
            formData?.review_notes?.progress_notes
          )}
          {getLabelTagInput(
            "Review Notes",
            formData?.review_notes?.review_notes
          )}
        </>
      );
    }

    return cardioSummaryItems;
  };
  const cardioSummaryItems = constructCardioSummaryItems();
  return (
    <div className="summaries">
      <Descriptions
        bordered
        column={1}
        title={"Cardiopulmonary EMR"}
        items={cardioSummaryItems}
        className="op-desc"
      />
    </div>
  );
};

export default CardioSummary;
